import React, { Component } from "react";
import "./TeethPage.css";
import NextTeethCheckPopup from "./NextTeethCheckPopup";
import TeethPopup from "./TeethPopup";
import HorseGallery from "../../../././Multimedia/HorseGallery";

export default class TeethPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      teethData: "",
    };
  }

  componentDidMount() {
    window.$("#bottomMenu").hide();
    window.TopMenu.Self.setVisible(false);
    this.loadData();
  }

  loadData = () => {
    window.db.getTeeth(this.props.horseId, (data) => {
      this.setState({
        teethData: data,
      });
    });
  };

  onDeleteButtonClick = (id) => {
    window.db.setTeethUpdate(id, "d", () => {
      this.loadData();
    });
  };

  onNextTeethCheckButtonClick = () => {
    let nextTeethCheckPopup = new NextTeethCheckPopup();
    nextTeethCheckPopup.showNextTeethCheckPopup(this.props.horseId, () => {
      this.loadData();
    });
  };

  onAllNextTeethButtonClick = () => {
    let teethPopup = new TeethPopup();
    teethPopup.showTeethAllPopup();
  };

  onOkClick = () => {
    window.Managers.extention.showHorsePage(this.props.horseId);
    window.$("#bottomMenu").show();
  };

  render() {
    const { teethData } = this.state;
    let horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == this.props.horseId
    );
    return (
      <div className="pageScrollable">
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            loading="lazy"
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={this.onOkClick}
          />
          <span className="npTitle">{window.lang.teeth}</span>
          <span
            style={{
              position: "absolute",
              right: "15%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {horse.name}
          </span>
          <img
            loading="lazy"
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onOkClick}
          />
        </div>
        <div id="teethTable">
          <table className="TeethTable">
            <tbody>
              <tr>
                <th>{window.lang.teethNewVisit}</th>
                <th>{window.lang.teethNextVisit}</th>
                <th></th>
              </tr>
              {teethData.length ? (
                teethData.map((i) => (
                  <tr key={Math.uuid()}>
                    <td>{Date.reformatToDMY(i.TDateFirst)}</td>
                    <td>{Date.reformatToDMY(i.TDateSecond)}</td>
                    <td>
                      {" "}
                      <button
                        className="ui-btn ui-shadow ui-corner-all"
                        style={{ width: "100%" }}
                        onClick={() => {
                          this.onDeleteButtonClick(i.Id);
                        }}
                      >
                        {window.lang.deleteL}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </table>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ verticalAlign: "top" }}>
                <button
                  className="ui-btn ui-shadow ui-corner-all"
                  style={{ width: "initial" }}
                  onClick={this.onNextTeethCheckButtonClick}
                >
                  {window.lang.teethNewVisit}
                </button>
              </td>
              <td style={{ verticalAlign: "top" }}>
                <button
                  className="ui-btn ui-shadow ui-corner-all"
                  style={{ width: "100%" }}
                  onClick={this.onAllNextTeethButtonClick}
                >
                  {window.lang.teethNextVisit}{" "}
                </button>
              </td>
            </tr>
          </table>
        </div>
        <HorseGallery
          showUploadButton={true}
          type={"Teeth"}
          horse={horse}
          title={"Teeth"}
        />
      </div>
    );
  }
}
