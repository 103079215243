import React, { Component } from "react";
import ReactDOM from "react-dom";
import ToggleView from "../Controls/ToggleView/ToggleView";

import "./Report.css";

class ReportSettingsPage extends Component {
  static AllReportsArr = [
    { value: 1, label: window.lang.vetReport },
    { value: 2, label: window.lang.farReport },
    { value: 3, label: window.lang.allHorsesAndTasksToday },
    { value: 4, label: window.lang.horse45days },
    { value: 5, label: window.lang.taskHorsesWithRemarks },
    { value: 6, label: window.lang.allHorsesByType },
    { value: 7, label: window.lang.stabletime },
    { value: 8, label: window.lang.allHorsesWithPrice },
    { value: 9, label: window.lang.allHorsesTodayWithNotes },
    { value: 10, label: window.lang.horse45daysRemarks },
    { value: 11, label: window.lang.allHorses45days },
    { value: 12, label: "Horses with id and notats" },
    { value: 13, label: window.lang.allVacinations },
    { value: 14, label: "Horses with sales info" },
    { value: 15, label: window.lang.ownerHorseType },
    { value: 16, label: window.lang.ownerHorse },
    { value: 17, label: window.lang.horseFollowsRider },
    { value: 18, label: window.lang.horseAge },
    { value: 19, label: window.lang.salesNotesHorse },
    { value: 20, label: window.lang.salesNotesHorseType },
    { value: 21, label: window.lang.trainingHistory },
    { value: 22, label: window.lang.vaccinationHorse },
    { value: 23, label: window.lang.vaccinationHorseType },
    { value: 24, label: window.lang.farrierHorse },
    { value: 25, label: window.lang.farrierHorseType },
    { value: 26, label: window.lang.vetHorse },
    { value: 27, label: window.lang.vetHorseType },
    { value: 28, label: window.lang.priceReport },
    { value: 29, label: "All horses all data" },
    { value: 30, label: "This horse with remarks and workouts" },
    { value: 31, label: "Stable History Report" },
  ];

  constructor(props) {
    super(props);

    const settingsReportStr = window.GlobalData.getItem(
      window.GlobalData.SETTINGS_REPPORT
    );
    let settingsReport = [];
    if (settingsReportStr) {
      settingsReport = JSON.parse(settingsReportStr);
    }

    let needToSave = false;
    for (let i = 0; i < ReportSettingsPage.AllReportsArr.length; i++) {
      const cr = ReportSettingsPage.AllReportsArr[i];
      if (!settingsReport.find((r) => r.value == cr.value)) {
        needToSave = true;
        let orderIndex =
          settingsReport.length > 0
            ? Math.max(...settingsReport.map((r) => r.orderIndex)) + 1
            : 1;
        settingsReport.push({ ...cr, isVisible: true, orderIndex: orderIndex });
      }
    }
    if (needToSave) {
      window.GlobalData.setItem(
        window.GlobalData.SETTINGS_REPPORT,
        JSON.stringify(settingsReport)
      );
    }

    settingsReport = settingsReport.sort((a, b) => a.orderIndex - b.orderIndex);

    this.state = {
      settingsReport: settingsReport,
    };
  }

  onIsVisibleChange = (rItemIndex) => {
    const settingsReport = [...this.state.settingsReport];
    settingsReport[rItemIndex] = {
      ...settingsReport[rItemIndex],
      isVisible: !settingsReport[rItemIndex].isVisible,
    };
    this.setState({ settingsReport });
  };

  swapOrder = (rItemIndex1, rItemIndex2) => {
    if (
      rItemIndex1 < 0 ||
      rItemIndex2 < 0 ||
      rItemIndex1 >= this.state.settingsReport.length ||
      rItemIndex2 >= this.state.settingsReport.length
    )
      return;

    let settingsReport = [...this.state.settingsReport];
    const orderIndex1 = settingsReport[rItemIndex1].orderIndex;
    const orderIndex2 = settingsReport[rItemIndex2].orderIndex;
    settingsReport[rItemIndex1] = {
      ...settingsReport[rItemIndex1],
      orderIndex: orderIndex2,
    };
    settingsReport[rItemIndex2] = {
      ...settingsReport[rItemIndex2],
      orderIndex: orderIndex1,
    };
    settingsReport = settingsReport.sort((a, b) => a.orderIndex - b.orderIndex);
    this.setState({ settingsReport });
  };

  onBackClick = () => {
    window.GlobalData.setItem(
      window.GlobalData.SETTINGS_REPPORT,
      JSON.stringify(this.state.settingsReport)
    );
    window.onBack();
  };

  render() {
    const { settingsReport } = this.state;

    return (
      <div>
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            loading="lazy"
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={this.onBackClick}
          />
          <span className="ctTitle ui-title">Shown reports</span>
          <img
            loading="lazy"
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onBackClick}
          />
        </div>
        <div data-role="content">
          <table className="reportTable" style={{ margin: "5px" }}>
            <tbody>
              <tr>
                <th>{window.lang.name}</th>
                <th>VIS rapports</th>
                <th colSpan={2} style={{ width: "20%" }}>
                  {window.lang.order}
                </th>
              </tr>
              {settingsReport.map((rItem, rItemIndex) => (
                <tr key={rItem.value}>
                  <td>{rItem.label}</td>
                  <td>
                    <ToggleView
                      checked={rItem.isVisible}
                      onClick={() => this.onIsVisibleChange(rItemIndex)}
                    />
                  </td>
                  <td>
                    <img
                      loading="lazy"
                      src="img/long_arrow_down.png"
                      className="arrowUp"
                      onClick={() => this.swapOrder(rItemIndex, rItemIndex - 1)}
                    />
                  </td>
                  <td>
                    <img
                      loading="lazy"
                      src="img/long_arrow_down.png"
                      className="arrowDown"
                      onClick={() => this.swapOrder(rItemIndex, rItemIndex + 1)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default class ReportSettings {
  show() {
    window.showPage("#showRepportPage");
    ReactDOM.render(
      <ReportSettingsPage />,
      document.getElementById("showRepportPage")
    );
  }
}
