import React, { Component } from "react";
import "./HorseViewPage.css";
import HorseEditPage from "./HorseEditPage";
import HorseGallery from "../Multimedia/HorseGallery";
import DataGateActions from "../DataGateActions";
import { Capacitor } from "@capacitor/core";

class HorseViewPage extends Component {
  constructor(props) {
    super(props);

    this.db = window.db;
    this.state = {
      isViewMode: props.horseId != -1,
      horseId: props.horseId,
      pedigreeText: "",
    };
    this.loadHorseData(props.horseId);
    this.isMobile = Capacitor.getPlatform() != "web";
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.horseId != this.props.horseId ||
      prevProps.date != this.props.date
    ) {
      this.setState({
        isViewMode: this.props.horseId != -1,
        horseId: this.props.horseId,
      });
      this.loadHorseData(this.props.horseId);
    }
  }

  loadHorseData = (horseId) => {
    try {
      this.setState({
        pedigreeText: "",
      });
    } catch {}
    if (horseId != -1 && horseId != null) {
      this.db.getPedigreeByHorse(horseId, (pedigreeArr) => {
        if (pedigreeArr.length != 0) {
          const data = JSON.parse(pedigreeArr[0].PedigreeJsonText);
          if (data.mather != "" || data.grmather_father != "") {
            this.setState({
              pedigreeText: data.mather + " / " + data.grmather_father,
            });
          }
        }
      });
    }
  };

  onEditClick = () => {
    let horseItem = window.DataStorage.getAllHorses().find(
      (h) => h.id == this.state.horseId
    );
    if (horseItem.userid != window.myid) {
      let role = window.DataStorage.rolesDataFromUsers.find(
        (r) => r.UserId == horseItem.userid
      );
      if (role != null && role.hWrite == false) {
        window.onBack();
        window.alert("You have no access to write");
        return;
      }
    }
    this.setState({
      isViewMode: false,
    });
  };

  onHide = () => {
    window.onBack();
  };

  openNotes = (horseId) => {
    if (horseId == null || typeof horseId == "object")
      horseId = this.state.horseId;
    //const horse = window.DataStorage.getAllHorses().find(h => h.id == horseId);
    //window.Managers.self.addNotes(horse);
    window.Managers.extention.showNotes(horseId);
  };
  openPedigree = (horseId) => {
    if (horseId == null || typeof horseId == "object")
      horseId = this.state.horseId;
    //const horse = window.DataStorage.getAllHorses().find(h => h.id == horseId);
    //window.Managers.self.showPedigree(horse);
    window.Managers.extention.showPedigree(horseId);
  };
  openResults = (horseId) => {
    if (horseId == null || typeof horseId == "object")
      horseId = this.state.horseId;
    window.Managers.extention.showResults(horseId);
  };
  openVaccinations = (horseId) => {
    if (horseId == null || typeof horseId == "object")
      horseId = this.state.horseId;
    //const horse = window.DataStorage.getAllHorses().find(h => h.id == horseId);
    //window.Managers.self.showHorseVaccination(horse);
    window.Managers.extention.showVaccination(horseId);
  };
  openHerpesVaccinations = (horseId) => {
    if (horseId == null || typeof horseId == "object")
      horseId = this.state.horseId;
    window.Managers.extention.showHerpesVaccination(horseId);
  };
  openEquipment = (horseId) => {
    if (horseId == null || typeof horseId == "object")
      horseId = this.state.horseId;
    window.Managers.extention.showEquipment(horseId);
  };
  openFarrier = (horseId) => {
    if (horseId == null || typeof horseId == "object")
      horseId = this.state.horseId;
    window.Managers.extention.showFarrier(horseId);
  };
  openMultimedia = (horseId) => {
    if (horseId == null || typeof horseId == "object")
      horseId = this.state.horseId;
    window.Managers.extention.showMultimedia(horseId);
  };
  openVeterinarian = (horseId) => {
    if (horseId == null || typeof horseId == "object")
      horseId = this.state.horseId;
    const horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == horseId
    );
    if (horse.userid != window.myid) {
      let role = window.DataStorage.rolesDataFromUsers.find(
        (r) => r.UserId == horse.userid
      );
      if (role != null && role.hSeeVET == false) {
        window.onBack();
        window.alert("You have no access to see VET on this horse");
        return;
      }
    }
    window.Managers.extention.showVeterinarian(horseId);
  };
  openReport = (horseId) => {
    if (horseId == null || typeof horseId == "object")
      horseId = this.state.horseId;
    const horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == horseId
    );
    window.Managers.self.showHorseReports(horse);
  };

  openSendSalesData = () => {
    let horseId = this.state.horseId;
    const horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == horseId
    );
    if (!horse) {
      alert("Something went wrong!");
      return;
    }
    // alert(JSON.stringify(horse));
    window.Managers.self.showSendSalesData(horse);
  };
  openTeeth = (horseId) => {
    if (horseId == null || typeof horseId == "object")
      horseId = this.state.horseId;
    window.Managers.extention.showTeeth(horseId);
  };
  openOwnerInfo = (horseId) => {
    if (horseId == null || typeof horseId == "object")
      horseId = this.state.horseId;
    //const horse = window.DataStorage.getAllHorses().find(h => h.id == horseId);
    //window.Managers.self.showHorseOwnerInfo(horse);
    window.Managers.extention.showOwnerInfo(horseId);
  };
  openPrice = (horseId) => {
    let horseItem = window.DataStorage.getAllHorses().find(
      (h) => h.id == this.state.horseId
    );
    if (horseItem.userid != window.myid) {
      let role = window.DataStorage.rolesDataFromUsers.find(
        (r) => r.UserId == horseItem.userid
      );
      if (role != null && role.hSeePriceInReport == false) {
        window.onBack();
        window.alert("You have no access to view price of this horse");
        return;
      }
    }
    if (horseId == null || typeof horseId == "object")
      horseId = this.state.horseId;
    window.Managers.extention.showHorsePrice(horseId);
  };
  openLog = (horseId) => {
    if (horseId == null || typeof horseId == "object")
      horseId = this.state.horseId;
    const horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == horseId
    );
    window.Managers.self.showHorseLog(horse);
  };
  openShare = (horseId) => {
    if (horseId == null || typeof horseId == "object")
      horseId = this.state.horseId;
    const horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == horseId
    );
    window.Managers.self.showHorseShare(horse);
  };
  openDelete = (horseId) => {
    if (horseId == null || typeof horseId == "object")
      horseId = this.state.horseId;
    const horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == horseId
    );
    window.Managers.self.showHorseDelete(horse);
  };
  openUnDelete = (horseId) => {
    if (horseId == null || typeof horseId == "object")
      horseId = this.state.horseId;
    const horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == horseId
    );
    DataGateActions.unDeleteHorse(horse);
  };
  openDeleteCompletly = (horseId) => {
    if (horseId == null || typeof horseId == "object")
      horseId = this.state.horseId;
    window.Spinner.show();
    window.DataGate.deleteHorseCompletly(horseId, () => {
      this.onHide();
      window.Managers.self.refreshHorses();
    });
  };

  onEditBack = (newHorseId) => {
    if (newHorseId == null && this.state.horseId == -1) {
      this.onHide();
    } else {
      if (newHorseId != null && newHorseId != this.props.horseId) {
        window.Managers.extention.showHorsePage(newHorseId);
      } else {
        this.setState({
          isViewMode: true,
          horseId: newHorseId != null ? newHorseId : this.state.horseId,
        });
      }
    }
  };

  getStableName = (horse) => {
    if (window.inUseStables == "Y") {
      //show box and stable
      if (horse.BoxName == null || horse.BoxName == "") return "";
      let box = window.DataStorage.boxesAndStables.find(
        (s) => s.IDBox == horse.BoxName
      );
      if (box != null) {
        return box.StableName + " " + box.BoxName;
      }
    } else {
      //show only stable
      if (horse.StableId == null || horse.StableId == "") return "";
      let stable = window.DataStorage.boxesAndStables.find(
        (s) => s.IdStable == horse.StableId
      );
      if (stable != null) {
        return stable.StableName;
      }
    }
    return "";
  };

  render() {
    const { horseId, isViewMode, pedigreeText } = this.state;

    window.TopMenu.Self.setVisible(isViewMode && horseId != -1);

    if (!isViewMode || horseId == -1) {
      return (
        <HorseEditPage
          horseId={horseId}
          userOwnerId={this.props.userOwnerId}
          onEditBack={this.onEditBack}
          openNotes={this.openNotes}
          openPedigree={this.openPedigree}
          openResults={this.openResults}
          openVaccinations={this.openVaccinations}
          openHerpesVaccinations={this.openHerpesVaccinations}
          openEquipment={this.openEquipment}
          openFarrier={this.openFarrier}
          openMultimedia={this.openMultimedia}
          openVeterinarian={this.openVeterinarian}
          openReport={this.openReport}
          openTeeth={this.openTeeth}
          openOwnerInfo={this.openOwnerInfo}
          openPrice={this.openPrice}
          openLog={this.openLog}
          openShare={this.openShare}
          openDelete={this.openDelete}
          openUnDelete={this.openUnDelete}
          openDeleteCompletly={this.openDeleteCompletly}
        />
      );
    }

    const horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == horseId
    );
    if (horse == null) {
      return <div></div>;
    }

    const horseLogo =
      horse.LogoFile != null
        ? window.DataGate.baseurl + horse.LogoFile
        : "img/horselogo.png";
    const currentUser = window.Managers.User;

    const GlobalData = window.GlobalData;
    const isShowBornIn = GlobalData.getItem(GlobalData.SHOW_BORNIN) == 1;
    const isShowArriveDate =
      GlobalData.getItem(GlobalData.SHOW_ARRIVEDATE) == 1;

    const isShowStable = GlobalData.getItem(GlobalData.SHOW_STABLE) == 1;
    const isShowGender = GlobalData.getItem(GlobalData.SHOW_GENDER) == 1;
    const isShowType = GlobalData.getItem(GlobalData.SHOW_TYPE) == 1;
    const isShowHeight = GlobalData.getItem(GlobalData.SHOW_HEIGHT) == 1;
    const isShowColor = GlobalData.getItem(GlobalData.SHOW_COLOR) == 1;
    const isShowHorseFollowsRider =
      GlobalData.getItem(GlobalData.SHOW_HORSE_FOLLOWS_RIDER) == 1;
    const isShowNotes = GlobalData.getItem(GlobalData.SHOW_NOTES) == 1;
    const isShowPedigree = GlobalData.getItem(GlobalData.SHOW_PEDIGREE) == 1;
    const isShowResults = GlobalData.getItem(GlobalData.SHOW_PEDIGREE) == 1;
    const isShowVaccinations =
      GlobalData.getItem(GlobalData.SHOW_VACCINATION) == 1;
    const isShowEquipment = GlobalData.getItem(GlobalData.SHOW_EQUIPMENT) == 1;
    const isShowFarrier = GlobalData.getItem(GlobalData.SHOW_FARRIER) == 1;
    const isShowMultimedia =
      GlobalData.getItem(GlobalData.SHOW_MULTUMEDIA) == 1;
    const isShowVeterinarian =
      GlobalData.getItem(GlobalData.SHOW_VETERINARIAN) == 1;
    const isShowReport = GlobalData.getItem(GlobalData.SHOW_REPORT) == 1;
    const isShowTeech = GlobalData.getItem(GlobalData.SHOW_TEECH) == 1;
    const isShowOwner = GlobalData.getItem(GlobalData.SHOW_OWNER) == 1;
    const isShowPrice = GlobalData.getItem(GlobalData.SHOW_PRICE) == 1;
    const isShowShareHorse =
      GlobalData.getItem(GlobalData.SHOW_SHARE_HORSE) == 1;
    const isShowLogPage = GlobalData.getItem(GlobalData.SHOW_LOG_PAGE) == 1;
    const isShowDeleteButton =
      GlobalData.getItem(GlobalData.SHOW_DELETE_BUTTON) == 1;

    const isShowSendData =
      GlobalData.getItem(GlobalData.SHOW_SEND_SALES_DATA) == 1;

    let stableColorArr = [];
    if (isShowStable) {
      let stableName = this.getStableName(horse);
      if (stableName != "") {
        stableColorArr.push(stableName);
      }
    }
    if (isShowGender && horse.Gender != "0") {
      stableColorArr.push(
        window.DataStorage.getHorseGenderArr()[parseInt(horse.Gender)]
      );
    }
    if (isShowType && horse.HorseType != null) {
      let horseType = window.DataStorage.horseTypes.find(
        (t) => t.ID == horse.HorseType
      );
      if (horseType != null) {
        stableColorArr.push(horseType.Name);
      }
    }
    if (isShowColor) {
      let horseColor = window.DataStorage.horseColors.find(
        (c) => c.Id == horse.ColorId
      );
      if (horseColor != null) {
        stableColorArr.push(horseColor.ColorName);
      }
    }

    let trainer = null;
    if (isShowHorseFollowsRider) {
      trainer = window.DataStorage.shareDataArray.find(
        (s) => s.sharewithid == horse.trainer && s.horseid == horse.id
      );
    }

    return (
      <div
        className={
          this.isMobile
            ? "pageContent pageScrollable hvmpaddingbottom"
            : "pageContent pageScrollable"
        }
      >
        <div style={{ textAlign: "center" }}>
          <img
            className="hvmLogo"
            style={{ backgroundImage: `url("${horseLogo}")` }}
            onClick={this.onEditClick}
          />
          <div>
            <div
              style={{
                fontSize: "1.8em",
                minWidth: "250px",
                display: "inline-block",
              }}
            >
              {horse.name}
            </div>
            {this.props.showGallery != true && (
              <img
                className="hvmEditIcon"
                src="img/edit_icon.png"
                onClick={this.onEditClick}
              />
            )}
          </div>
          {this.props.showGallery != true && (
            <>
              {isShowPedigree && (
                <div style={{ fontSize: "1.4em" }}>{pedigreeText}</div>
              )}
              <div style={{ fontSize: "1.4em" }}>
                {isShowBornIn && horse.BirthYear + " - "}
                {isShowHeight && horse.Height}
              </div>
              {isShowArriveDate && (
                <div style={{ fontSize: "1em" }}>
                  {window.lang.arrivDate}:{" "}
                  {horse.CreateDate == "01-01-1900" ? "" : horse.CreateDate}
                </div>
              )}
              <br />
              {stableColorArr.join(" • ")}
              {isShowHorseFollowsRider && trainer != null && (
                <div style={{ fontSize: "1.2em" }}>
                  {window.lang.riderLabel}: {trainer.uname}
                </div>
              )}
              <div className="hvpButtonsDiv">
                {isShowNotes && (
                  <div className="hvpButton" onClick={this.openNotes}>
                    <span>{window.lang.notes}</span>
                  </div>
                )}
                {isShowPedigree && (
                  <div className="hvpButton" onClick={this.openPedigree}>
                    <span>{window.lang.pedigree}</span>
                  </div>
                )}
                {isShowResults && (
                  <div className="hvpButton" onClick={this.openResults}>
                    <span>{window.lang.result}</span>
                  </div>
                )}
                {isShowVaccinations && (
                  <div className="hvpButton" onClick={this.openVaccinations}>
                    <span>{window.lang.vaccination}</span>
                  </div>
                )}
                {isShowVaccinations && (
                  <div
                    className="hvpButton"
                    onClick={this.openHerpesVaccinations}
                  >
                    <span>{window.lang.herpesVaccination}</span>
                  </div>
                )}
                {isShowEquipment && (
                  <div className="hvpButton" onClick={this.openEquipment}>
                    <span>{window.lang.equipment}</span>
                  </div>
                )}
                {isShowFarrier && (
                  <div className="hvpButton" onClick={this.openFarrier}>
                    <span>{window.lang.shoes}</span>
                  </div>
                )}
                {isShowMultimedia && (
                  <div className="hvpButton" onClick={this.openMultimedia}>
                    <span>Multimedia</span>
                  </div>
                )}
                {isShowVeterinarian && (
                  <div className="hvpButton" onClick={this.openVeterinarian}>
                    <span>{window.lang.veterinarian}</span>
                  </div>
                )}
                {isShowReport && (
                  <div className="hvpButton" onClick={this.openReport}>
                    <span>{window.lang.report}</span>
                  </div>
                )}
                {isShowSendData && (
                  <div className="hvpButton" onClick={this.openSendSalesData}>
                    <span>{window.lang.sendSalesData}</span>
                  </div>
                )}
                {isShowTeech && (
                  <div className="hvpButton" onClick={this.openTeeth}>
                    <span>{window.lang.teeth}</span>
                  </div>
                )}
                {isShowOwner && (
                  <div className="hvpButton" onClick={this.openOwnerInfo}>
                    <span>{window.lang.owner}</span>
                  </div>
                )}
                {isShowPrice && (
                  <div className="hvpButton" onClick={this.openPrice}>
                    <span>{window.lang.price}</span>
                  </div>
                )}
                {isShowLogPage && (
                  <div className="hvpButton" onClick={this.openLog}>
                    <span>Log page</span>
                  </div>
                )}
                {isShowShareHorse && horse.userid == window.myid && (
                  <div className="hvpButton" onClick={this.openShare}>
                    <span>{window.lang.share}</span>
                  </div>
                )}
                {isShowDeleteButton &&
                  horse.userid == window.myid &&
                  (horse.u == "u" ? (
                    <div className="hvpButton" onClick={this.openDelete}>
                      <span>{window.lang.deleteHorse}</span>
                    </div>
                  ) : (
                    <>
                      <div className="hvpButton" onClick={this.openUnDelete}>
                        <span>{window.lang.undeleteHorse}</span>
                      </div>
                      <div
                        className="hvpButton"
                        onClick={this.openDeleteCompletly}
                      >
                        <span>{window.lang.deleteHorse}</span>
                      </div>
                    </>
                  ))}
              </div>
            </>
          )}
        </div>

        <HorseGallery
          showYoutube={false}
          showUploadButton={true}
          type={"Multimedia"}
          horse={horse}
          title={"Galleri"}
        />
      </div>
    );
  }
}

export default HorseViewPage;
