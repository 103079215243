import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import DatePicker from "react-datepicker";
import RiderRole from "../RiderRole/RiderRole";
import SharePage from "./SharePage";
import "./SharePage.css";
import CreateRiderPage from "./CreateRiderPage";
import ToggleView from "../Controls/ToggleView/ToggleView";

function ShareColumn(props) {
  const { i, myStables } = props;
  const [selectedStables, setSelectedStables] = useState(i.stablesShared);
  const [nonSelectedStables, setNonSelectedStables] = useState([]);

  useEffect(() => {
    setNonSelectedStables(
      myStables.filter((x) => !selectedStables.find((y) => y == x.Id))
    );
  }, [myStables, selectedStables]);

  const selectStable = (id) => {
    if (id == 0) return;
    if (id == -1) {
      const newStables = nonSelectedStables.map((x) => x.Id);
      window.Spinner.show();
      window.DataGate.shareStable(i.id, newStables, (code, data) => {
        if (code == window.DataGate.CODE_OK) {
          let aux = [...selectedStables];
          aux.push(...newStables);
          setSelectedStables(aux);
          window.Spinner.hide();
        }
      });
      return;
    }
    window.Spinner.show();
    window.DataGate.shareStable(i.id, [id], (code, data) => {
      if (code == window.DataGate.CODE_OK) {
        let aux = [...selectedStables];
        aux.push(id);
        setSelectedStables(aux);
        window.Spinner.hide();
      }
    });
  };
  const getItemFromId = (id) => myStables.find((x) => x.Id == id);

  const deselectStable = (target) => {
    window.Spinner.show();
    window.DataGate.DeleteShareStable(i.id, [target], (code, data) => {
      if (code == window.DataGate.CODE_OK) {
        setSelectedStables([...selectedStables].filter((x) => x != target));
        window.Spinner.hide();
      }
    });
  };
  return (
    <div className="sharedHorses--column-holder">
      <div className="sharedHorses--column">
        {selectedStables.map((stableId) => (
          <span
            key={stableId}
            onClick={() => deselectStable(stableId)}
            className="badge"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
            {getItemFromId(stableId).StableName}
          </span>
        ))}
      </div>

      {selectedStables.length < myStables.length && (
        <select
          value={0}
          className="sharedHorses--column-select"
          onChange={(e) => {
            e.preventDefault();
            selectStable(e.target.value);
          }}
        >
          <option value={0}>{window.lang.selectStable}</option>
          <option value={-1}>{window.lang.showAllStables}</option>
          {nonSelectedStables.map((stable) => (
            <option key={stable.Id} value={stable.Id}>
              {stable.StableName}
            </option>
          ))}
        </select>
      )}
    </div>
  );
}

class SharingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myStables: [],
      shareArr: [],
      riderRoles: [],
      changeLicenseItem: null,
      changeResetPasswordItem: null,
      resetPasswordInputValue: "",
      resetPasswordRetypeInputValue: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props != prevProps) {
      this.loadData();
    }
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    window.Spinner.show();
    window.db.getRiderRoles(window.myid, (roles) => {
      window.DataGate.getShareWithData(async (code, responseData) => {
        console.log(responseData);
        const data = responseData.Item1;
        const stablesData = responseData.Item2;

        if (code != 1) {
          return;
        }
        console.log(data);
        // return;
        var dataArr = [];
        data.forEach((itemD) => {
          let curr = dataArr.find((i) => i.id == itemD.ShareWith);
          if (!curr) {
            let item = data.find(
              (i) => i.ShareWith == itemD.ShareWith && i.RoleId != null
            );
            if (!item) item = itemD;
            //find all stables
            let stablesOfUser = [];
            for (let x of stablesData) {
              if (x.ShareWith == itemD.ShareWith)
                stablesOfUser.push(x.StableId);
            }

            dataArr.push({
              name: item.Name,
              email: item.Email,
              id: item.ShareWith,
              currentRole: item.RoleId ? item.RoleId : "None",
              sender_id: item.sender_id || false,
              notAccepted: item.NotAccepted,
              createLicenseTo: item.CreateLicenseTo
                ? new Date(
                    parseInt(
                      item.CreateLicenseTo.replace("/Date(", "").replace(
                        ")/",
                        ""
                      )
                    )
                  )
                : null,
              licenseExpire: new Date(item.licenseExpire * 1000),
              stablesShared: stablesOfUser,
            });
          }
        });
        roles.push({ Id: "None", Name: "None" });

        let stables = await window.db.getAllStablesAsync(window.myid);
        console.log(dataArr);
        this.setState(
          {
            myStables: stables,
            shareArr: dataArr,
            riderRoles: roles,
          },
          window.Spinner.hide()
        );
      });
    });
  };

  onRoleChange = (e) => {
    window.Spinner.show();
    window.DataGate.setRiderRole(
      e.currentTarget.dataset.id,
      window.myid,
      e.target.value,
      e.currentTarget.dataset.notaccepted,
      () => {
        this.loadData();
        window.Spinner.hide();
      }
    );
  };

  onShareRoleButtonClick = () => {
    var riderRole = new RiderRole();
    window.showPage("#riderRolePage");
    riderRole.showRiderRolePage();
  };

  onOpenShareButtonClick = (e) => {
    var sharePage = new SharePage();
    window.showPage("#sharePage");
    sharePage.showSharePage(
      e.currentTarget.dataset.id,
      e.currentTarget.dataset.email,
      e.currentTarget.dataset.role
    );
  };

  onCreateRiderClick = () => {
    var createRider = new CreateRiderPage();
    createRider.showPage();
  };

  onDeleteButtonClick = (e) => {
    let id = e.currentTarget.dataset.id;
    let notaccepted = e.currentTarget.dataset.notaccepted;
    window.$("#sharingPopupDialog").popup("open");
    window.$("#sharingPopupDialogTitle").html(window.lang.alertDeleteShare);
    window.$("#sharingDelete").unbind("click");
    window.$("#sharingDelete").click(() => {
      if (notaccepted == 0) {
        window.db.setShareWithUpdate(window.myid, id, "horseId", "d");
        // }
        setTimeout(() => {
          window.showPage("#sharingPage");
          window.managers.extention.showSharingPage();
        }, 500);
      } else {
        window.db.deleteNotAcceptedHorseByNotAccepted(id);
        setTimeout(() => {
          window.showPage("#sharingPage");
          window.managers.extention.showSharingPage();
        }, 500);
      }
      window.db.getLocalSettingsById(window.myid, (dataSettings) => {
        if (dataSettings.length != 0) {
          if (
            dataSettings[0]["ShareAllUsersId"] != null &&
            dataSettings[0]["ShareAllUsersId"] != ""
          ) {
            window.Managers.shareAllHorsesUsersId = JSON.parse(
              dataSettings[0]["ShareAllUsersId"]
            );
            for (
              var i = 0;
              i < window.Managers.shareAllHorsesUsersId.length;
              i++
            ) {
              if (window.Managers.shareAllHorsesUsersId[i] == id) {
                window.Managers.shareAllHorsesUsersId.splice(i, 1);
                window.db.updateLocalSettingsShareAllHorses(
                  JSON.stringify(window.Managers.shareAllHorsesUsersId)
                );
              }
            }
          }
        }
      });
    });
    window.$("#sharingDeleteCancel").unbind("click");
    window.$("#sharingDeleteCancel").click(() => {
      window.$("#sharingPopupDialog").popup("close");
    });
  };

  onShareHorsesWithNewRiderClick = () => {
    var sharePage = new SharePage();
    window.showPage("#sharePage");
    sharePage.showSharePage();
  };

  onOkClick = () => {
    window.showPage("#settingsPage");
    window.$("#settingsPage").css("min-height", "95%");
  };

  onChangeLicenseDate = (item) => {
    this.setState({
      changeLicenseItem: { ...item },
    });
  };

  handleChangeLicenseDate = (date) => {
    this.setState({
      changeLicenseItem: {
        ...this.state.changeLicenseItem,
        licenseExpire: date,
      },
    });
  };

  saveChangeLicenseDate = () => {
    let arr = this.state.shareArr;
    let shareItem = arr.find(
      (item) => item.id == this.state.changeLicenseItem.id
    );
    shareItem.licenseExpire = this.state.changeLicenseItem.licenseExpire;
    this.setState({
      shareArr: arr,
    });
    this.closeChangeLicensePopup();
    window.Spinner.show();
    window.DataGate.changeCreateHorseLicenseDate(
      window.myid,
      shareItem.id,
      shareItem.licenseExpire,
      () => {
        window.Spinner.hide();
      }
    );
  };

  handleResetPassword = (item) => {
    this.setState({
      changeResetPasswordItem: item,
      resetPasswordInputValue: "",
      resetPasswordRetypeInputValue: "",
    });
  };

  resetPassword = () => {
    if (
      this.state.resetPasswordInputValue == "" ||
      this.state.resetPasswordRetypeInputValue == ""
    ) {
      alert("Passwords cannot be empty");
      return;
    }
    if (
      this.state.resetPasswordInputValue !=
      this.state.resetPasswordRetypeInputValue
    ) {
      alert("Passwords do not match");
      return;
    }

    //reset password
    window.DataGate.changePasswordForSharedUser(
      window.myid,
      this.state.changeResetPasswordItem.id,
      this.state.resetPasswordInputValue,
      (err) => {
        if (err) {
          alert("Something went wrong");
          return;
        }
        alert("Password updated");
        this.setState({ changeResetPasswordItem: null });
      }
    );
  };

  toggleShareOtherTasks = (id) => {
    let arr = [...this.state.shareArr];

    let shareItem = arr.find((item) => item.id == id);
    if (!shareItem) return;
    console.log(shareItem);

    window.DataGate.changeCreateHorseShareOtherTasks(shareItem.id, (data) => {
      if (data?.Data == "0") {
        shareItem.sender_id = shareItem.id;
      } else {
        shareItem.sender_id = false;
      }
      this.setState({ shareArr: arr });
    });
  };

  closeChangeLicensePopup = () => {
    this.setState({ changeLicenseItem: null });
  };

  render() {
    const { shareArr, riderRoles, changeLicenseItem } = this.state;
    var inches =
      Math.sqrt(
        Math.pow(window.screen.width, 2) + Math.pow(window.screen.height, 2)
      ) *
      (1 / window.inch.offsetWidth);
    return (
      <div className="pageScrollable">
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            id="sharingBackButton"
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={this.onOkClick}
          />
          <span className="ppTitle">{window.lang.share}</span>
          <img
            id="sharingOkButton"
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onOkClick}
          />
        </div>
        <div className="ppContent">
          {{ inches } < 9 ||
          /Android/.test(navigator.userAgent) ||
          /iPhone/.test(navigator.userAgent) ? (
            <div>
              {shareArr.length ? (
                <div>
                  {shareArr.map((i) => (
                    <div key={Math.uuid()}>
                      <div>
                        {i.name + " (" + i.email + ")"} -{" "}
                        <span>
                          {!i.notAccepted ? "Accepted" : "Not Accepted"}
                        </span>
                      </div>
                      <div>
                        {riderRoles.length ? (
                          <select
                            onChange={this.onRoleChange}
                            data-id={i.id}
                            value={i.currentRole}
                            data-notaccepted={i.notAccepted}
                          >
                            {riderRoles.map((j) => (
                              <option key={j.Id} value={j.Id}>
                                {j.Name}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div>
                        <button
                          className="openShareButton ui-btn ui-shadow ui-corner-all"
                          disabled={i.notAccepted}
                          data-id={i.id}
                          data-email={i.email}
                          data-role={i.currentRole}
                          onClick={this.onOpenShareButtonClick}
                        >
                          {window.lang.sharedHorses}
                        </button>
                      </div>
                      {/* @todo */}
                      <div>
                        <button
                          className="openShareButton ui-btn ui-shadow ui-corner-all"
                          disabled={i.notAccepted}
                          data-id={i.id}
                          data-email={i.email}
                          data-role={i.currentRole}
                          onClick={this.onOpenShareButtonClick}
                        >
                          {window.lang.sharedHorses}
                        </button>
                      </div>
                      <div>
                        <button
                          className="unshareButton ui-btn ui-shadow ui-corner-all"
                          data-id={i.id}
                          data-notaccepted={i.notAccepted}
                          onClick={this.onDeleteButtonClick}
                        >
                          {window.lang.deleteL}
                        </button>
                      </div>
                      {i.licenseExpire && (
                        <div>
                          <span style={{ marginRight: "10px" }}>
                            License to: {i.licenseExpire.formatDesh()}
                          </span>
                          <button
                            className="ui-btn ui-btn-inline ui-shadow ui-corner-all"
                            onClick={() => this.onChangeLicenseDate(i)}
                          >
                            {window.lang.edit}
                          </button>
                        </div>
                      )}
                      {!i.notAccepted && (
                        <div>
                          <button
                            className="ui-btn ui-btn-inline ui-shadow ui-corner-all"
                            onClick={() => this.handleResetPassword(i)}
                          >
                            {"Reset password"}
                          </button>
                        </div>
                      )}

                      <br />
                    </div>
                  ))}
                  <div>
                    <button
                      style={{ width: "33%" }}
                      className="allShareButton ui-btn ui-shadow ui-corner-all"
                      onClick={this.onShareHorsesWithNewRiderClick}
                    >
                      {" "}
                      {window.lang.shareHorsesWithNewRider}{" "}
                    </button>
                    <button
                      style={{ width: "33%" }}
                      className="shareRoleButton ui-btn ui-shadow ui-corner-all"
                      onClick={this.onShareRoleButtonClick}
                    >
                      {" "}
                      Rider roles
                    </button>
                    <button
                      style={{ width: "33%" }}
                      className="ui-btn ui-shadow ui-corner-all"
                      onClick={this.onCreateRiderClick}
                    >
                      {window.lang.createNewRider}
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <button
                    style={{ width: "33%" }}
                    className="allShareButton ui-btn ui-shadow ui-corner-all"
                    onClick={this.onShareHorsesWithNewRiderClick}
                  >
                    {" "}
                    {window.lang.shareHorsesWithNewRider}{" "}
                  </button>
                  <button
                    style={{ width: "33%" }}
                    className="shareRoleButton ui-btn ui-shadow ui-corner-all"
                    onClick={this.onShareRoleButtonClick}
                  >
                    {" "}
                    Rider roles
                  </button>
                  <button
                    style={{ width: "33%" }}
                    className="ui-btn ui-shadow ui-corner-all"
                    onClick={this.onCreateRiderClick}
                  >
                    {window.lang.createNewRider}
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div>
              {shareArr.length ? (
                <div>
                  <table
                    className="shareTable"
                    style={{ width: "70%", textAlign: "center" }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "30%" }}></th>
                        <th style={{ width: "10%" }}> Role </th>
                        <th style={{ width: "10%" }} colSpan={2}>
                          {window.lang.sharedHorses}
                        </th>
                        <th style={{ width: "10%" }}>{window.lang.deleteL}</th>
                        <th style={{ width: "10%" }}>{window.lang.accepted}</th>
                        <th style={{ width: "20%" }}>Share other tasks</th>
                        <th style={{ width: "10%" }}>License</th>
                        <th style={{ width: "10%" }}>Password</th>
                      </tr>
                    </thead>
                    <tbody>
                      {shareArr.map((i) => (
                        <tr key={Math.uuid()}>
                          <td>{i.name + " (" + i.email + ")"}</td>
                          <td>
                            {riderRoles.length ? (
                              <select
                                onChange={this.onRoleChange}
                                data-id={i.id}
                                value={i.currentRole}
                                data-notaccepted={i.notAccepted}
                              >
                                {riderRoles.map((j) => (
                                  <option key={j.Id} value={j.Id}>
                                    {j.Name}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td>
                            <div>
                              <button
                                className="openShareButton ui-btn ui-shadow ui-corner-all"
                                disabled={i.notAccepted}
                                data-id={i.id}
                                data-email={i.email}
                                data-role={i.currentRole}
                                onClick={this.onOpenShareButtonClick}
                              >
                                {window.lang.sharedHorses}
                              </button>
                            </div>
                          </td>
                          <td>
                            <ShareColumn
                              myStables={this.state.myStables}
                              i={i}
                            />
                          </td>
                          <td>
                            <div>
                              <button
                                className="unshareButton ui-btn ui-shadow ui-corner-all"
                                data-id={i.id}
                                data-notaccepted={i.notAccepted}
                                onClick={this.onDeleteButtonClick}
                              >
                                {window.lang.deleteL}
                              </button>
                            </div>
                          </td>
                          <td>
                            {!i.notAccepted ? "Accepted" : "Not Accepted"}
                          </td>
                          <td>
                            <ToggleView
                              name={""}
                              checked={i.sender_id}
                              onClick={() => {
                                this.toggleShareOtherTasks(i.id);
                              }}
                            />
                            {/* TODO */}
                          </td>
                          <td>
                            {i.licenseExpire && (
                              <>
                                <div>{i.licenseExpire.formatDesh()}</div>
                                <button
                                  className="ui-btn ui-shadow ui-corner-all"
                                  style={{ padding: "2px" }}
                                  onClick={() => this.onChangeLicenseDate(i)}
                                >
                                  {window.lang.edit}
                                </button>
                              </>
                            )}
                          </td>
                          <td>
                            {!i.notAccepted && (
                              <div>
                                <button
                                  className="ui-btn ui-btn-inline ui-shadow ui-corner-all"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => this.handleResetPassword(i)}
                                >
                                  {"Reset password"}
                                </button>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <table style={{ width: "90%", textAlign: "center" }}>
                    <tbody>
                      <tr>
                        <td>
                          <button
                            className="allShareButton ui-btn ui-shadow ui-corner-all"
                            onClick={this.onShareHorsesWithNewRiderClick}
                          >
                            {" "}
                            {window.lang.shareHorsesWithNewRider}{" "}
                          </button>
                        </td>
                        <td>
                          <button
                            className="shareRoleButton ui-btn ui-shadow ui-corner-all"
                            onClick={this.onShareRoleButtonClick}
                          >
                            {" "}
                            Rider roles
                          </button>
                        </td>
                        <td>
                          <button
                            className="ui-btn ui-shadow ui-corner-all"
                            onClick={this.onCreateRiderClick}
                          >
                            {window.lang.createNewRider}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>
                  <button
                    style={{ width: "33%" }}
                    className="allShareButton ui-btn ui-shadow ui-corner-all"
                    onClick={this.onShareHorsesWithNewRiderClick}
                  >
                    {" "}
                    {window.lang.shareHorsesWithNewRider}{" "}
                  </button>
                  <button
                    style={{ width: "33%" }}
                    className="shareRoleButton ui-btn ui-shadow ui-corner-all"
                    onClick={this.onShareRoleButtonClick}
                  >
                    {" "}
                    Rider roles
                  </button>
                  <button
                    style={{ width: "33%" }}
                    className="ui-btn ui-shadow ui-corner-all"
                    onClick={this.onCreateRiderClick}
                  >
                    {window.lang.createNewRider}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        {changeLicenseItem && (
          <div className="changeLicensePopup">
            <img
              className="changeLicenseClose"
              src="img/icon_canceln.png"
              onClick={this.closeChangeLicensePopup}
            />
            <div
              className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset"
              style={{ marginTop: "40px" }}
            >
              <DatePicker
                dateFormat="dd-MM-yyyy"
                selected={changeLicenseItem.licenseExpire}
                onChange={this.handleChangeLicenseDate}
              />
            </div>
            <button
              className="ui-btn ui-shadow ui-corner-all"
              onClick={this.saveChangeLicenseDate}
            >
              OK
            </button>
          </div>
        )}

        {this.state.changeResetPasswordItem && (
          <div className="changeLicensePopup">
            <img
              className="changeLicenseClose"
              src="img/icon_canceln.png"
              onClick={() => this.setState({ changeResetPasswordItem: null })}
            />
            <div
              className="reset-password--input-holder"
              style={{ marginTop: "40px" }}
            >
              <span>New password</span>
              <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                <div>
                  <input
                    type="text"
                    value={this.state.resetPasswordInputValue}
                    onChange={(e) =>
                      this.setState({
                        resetPasswordInputValue: e.currentTarget.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="reset-password--input-holder">
              <span>Retype new password</span>
              <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                <div>
                  <input
                    type="text"
                    value={this.state.resetPasswordRetypeInputValue}
                    onChange={(e) =>
                      this.setState({
                        resetPasswordRetypeInputValue: e.currentTarget.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <button
              className="ui-btn ui-shadow ui-corner-all"
              onClick={this.resetPassword}
            >
              OK
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default class Sharing {
  showSharingPage = () => {
    ReactDOM.render(<SharingPage />, document.getElementById("sharingReact"));
  };
}
