import React, { Component } from "react";
import HorseGallery from "../.././Multimedia/HorseGallery";

class ResultsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      results: "",
    };
  }

  componentDidMount() {
    window.$("#bottomMenu").hide();
    window.TopMenu.Self.setVisible(false);
    this.loadData();
  }

  loadData = () => {
    window.db.getNoteByHorse(this.props.horseId, (data) => {
      this.setState({
        results: data[0].Result || "",
      });
    });
  };

  onResultChange = (e) => {
    this.setState({
      results: e.target.value,
    });
  };

  saveCurrentText = (callback) => {
    console.log(this.props.horseId);
    console.log(this.state.results);
    window.db.updateResultByHorse(this.props.horseId, this.state.results);

    if (callback) callback();
  };

  onSaveClick = () => {
    this.saveCurrentText(() => {
      window.Managers.extention.showHorsePage(this.props.horseId);
      window.$("#bottomMenu").show();
    });
  };

  render() {
    const { results } = this.state;
    let horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == this.props.horseId
    );
    return (
      <div className="pageScrollable">
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            loading="lazy"
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={this.onSaveClick}
          />
          <span className="npTitle">{window.lang.result}</span>
          <span
            id="resultHorseInfo_"
            style={{
              position: "absolute",
              right: "15%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {horse.name}
          </span>
          <img
            loading="lazy"
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onSaveClick}
          />
        </div>
        <div className="npContent">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <textarea
              id="addHorseResultText"
              className="ui-input-text ui-shadow-inset ui-body-inherit ui-corner-all ui-textinput-autogrow"
              style={{ width: "100%" }}
              rows="50"
              value={results}
              onChange={this.onResultChange}
            ></textarea>
          </div>
        </div>
        <HorseGallery
          showUploadButton={true}
          type={"Result"}
          horse={horse}
          title={"Result"}
          acceptPdf={true}
        />
      </div>
    );
  }
}

export default ResultsPage;
