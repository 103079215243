import React from "react";
import "./BasicButton.css";
export default function BasicButton({ onClick, text, svgIcon }) {
  console.log(svgIcon);
  return (
    <button
      className="ui-btn ui-shadow ui-corner-all basic-button"
      onClick={onClick}
    >
      {svgIcon}
      {text}
    </button>
  );
}
