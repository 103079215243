import React, { useEffect, useState } from "react";
import "./OtherTasksViewLight.css";

import ClickNHold from "react-click-n-hold";
import GeneralTaskPopup from "../GeneralTask/GeneralTaskPopup";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import OtherTasksSort from "../SVGs/OtherTasksSort";

export default function OtherTasksViewLight({ date, generalTaskArr }) {
  const [generalTasksDaily, setGeneralTasksDaily] = useState([]);
  const [generalTasksWeekly, setGeneralTasksWeekly] = useState([]);
  const [generalTasksMonthly, setGeneralTasksMonthly] = useState([]);

  useEffect(() => {
    setGeneralTasksDaily(generalTaskArr?.filter((task) => task.tasktype == 1));
    setGeneralTasksWeekly(generalTaskArr?.filter((task) => task.tasktype == 2));
    setGeneralTasksMonthly(
      generalTaskArr?.filter((task) => task.tasktype == 3)
    );
  }, [generalTaskArr]);

  const onCellHoldCLick = (task) => {
    let task_aux = { ...task };
    task_aux.Color = task.Color == "red" ? "green" : "red";

    task_aux.Flag = "Update";
    task_aux["ConnectionId"] = window.notification.connectionId;

    window.DataGate.insertOrUpdateGeneralTask(task_aux, (code, data) => {
      if (code == window.DataGate.CODE_OK) {
        window.training.refresh({ showSpinner: false });
      }
    });
  };

  const renderGeneralTaskTableDaily = () => {
    const currentTasks = generalTasksDaily;
    return (
      <div>
        <table
          className="riderTableR otherTasksViewTable"
          style={{ maxWidth: "350px" }}
        >
          <thead>
            <tr>
              <td colSpan={3}>Other tasks</td>
            </tr>
          </thead>
          <tbody>
            {currentTasks &&
              currentTasks.map((i, index) => (
                <tr key={index}>
                  <td
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => onGeneralTaskClick(i, 1)}
                  >
                    <ClickNHold
                      time={1.5}
                      onClickNHold={() => {
                        onCellHoldCLick(i);
                      }}
                    >
                      <div>
                        <div style={{ fontSize: "0.6em" }}>{i.TimeOfDay}</div>
                        <span
                          className="otherTasksViewTable--task-name"
                          style={
                            i.Color == "green"
                              ? {
                                  fontWeight: "bold",
                                  textDecoration: "line-through",
                                  textShadow: "none",
                                }
                              : {
                                  fontWeight: "bold",
                                  color: "red",
                                }
                          }
                        >
                          {window.DataStorage.allUsers.length
                            ? i.TrainerId
                              ? i.Name +
                                " (" +
                                window.getInitials(
                                  window.DataStorage.allUsers.find(
                                    (t) => t.Id == i.TrainerId
                                  ).Name
                                ) +
                                ")"
                              : i.Name + "(N)"
                            : i.Name}
                        </span>
                        <div style={{ fontSize: "0.6em" }}>{i.Remark}</div>
                      </div>
                    </ClickNHold>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  };
  const renderGeneralTaskTableWeekly = () => {
    const currentTasks = generalTasksWeekly;
    return (
      <div>
        <table className="riderTableR otherTasksViewTable">
          <thead>
            <tr>
              <td colSpan={3}>Weekly tasks</td>
            </tr>
          </thead>
          <tbody>
            {currentTasks &&
              currentTasks.map((i, index) => (
                <tr key={index}>
                  <td
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => onGeneralTaskClick(i, 2)}
                  >
                    <ClickNHold
                      time={1.5}
                      onClickNHold={() => {
                        onCellHoldCLick(i);
                      }}
                    >
                      <div>
                        <div style={{ fontSize: "0.6em" }}>{i.TimeOfDay}</div>
                        <span
                          className="otherTasksViewTable--task-name"
                          style={
                            i.Color == "green"
                              ? {
                                  fontWeight: "bold",
                                  textDecoration: "line-through",
                                  textShadow: "none",
                                }
                              : {
                                  fontWeight: "bold",
                                  color: "red",
                                }
                          }
                        >
                          {window.DataStorage.allUsers.length
                            ? i.TrainerId
                              ? i.Name +
                                " (" +
                                window.getInitials(
                                  window.DataStorage.allUsers.find(
                                    (t) => t.Id == i.TrainerId
                                  ).Name
                                ) +
                                ")"
                              : i.Name + "(N)"
                            : i.Name}
                        </span>
                        <div style={{ fontSize: "0.6em" }}>{i.Remark}</div>
                      </div>
                    </ClickNHold>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  };
  const renderGeneralTaskTableMonthly = () => {
    const currentTasks = generalTasksMonthly;
    return (
      <div>
        <table className="riderTableR otherTasksViewTable">
          <thead>
            <tr>
              <td colSpan={3}>Monthly tasks</td>
            </tr>
          </thead>
          <tbody>
            {currentTasks &&
              currentTasks.map((i, index) => (
                <tr key={index}>
                  <td
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => onGeneralTaskClick(i, 3)}
                  >
                    <ClickNHold
                      time={1.5}
                      onClickNHold={() => {
                        onCellHoldCLick(i);
                      }}
                    >
                      <div>
                        <div style={{ fontSize: "0.6em" }}>{i.TimeOfDay}</div>
                        <span
                          className="otherTasksViewTable--task-name"
                          style={
                            i.Color == "green"
                              ? {
                                  fontWeight: "bold",
                                  textDecoration: "line-through",
                                  textShadow: "none",
                                }
                              : {
                                  fontWeight: "bold",
                                  color: "red",
                                }
                          }
                        >
                          {window.DataStorage.allUsers.length
                            ? i.TrainerId
                              ? i.Name +
                                " (" +
                                window.getInitials(
                                  window.DataStorage.allUsers.find(
                                    (t) => t.Id == i.TrainerId
                                  ).Name
                                ) +
                                ")"
                              : i.Name + "(N)"
                            : i.Name}
                        </span>
                        <div style={{ fontSize: "0.6em" }}>{i.Remark}</div>
                      </div>
                    </ClickNHold>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  };

  const onGeneralTaskClick = (task, taskType) => {
    let options = {
      date: new Date(task.Time),
      simplified: true,
    };
    new GeneralTaskPopup().showPopup(options, task, () => {});
  };

  return (
    <div className="otherTasksViewHolderWrapper">
      <div className="otherTasksViewHolder otherTasksViewHolderScrollArea">
        <div style={{ flex: 1, maxWidth: "350px" }}>
          {renderGeneralTaskTableDaily()}
        </div>
        <div style={{ flex: 1, maxWidth: "350px" }}>
          {renderGeneralTaskTableWeekly()}
        </div>
        <div style={{ flex: 1, maxWidth: "350px" }}>
          {renderGeneralTaskTableMonthly()}
        </div>
      </div>
    </div>
  );
}
