import React, { Component } from "react";
import "./TeethView.css";
import ToggleView from "../../Controls/ToggleView/ToggleView";
import TrainingPopup from "../../TrainingPopup/TrainingPopup";
import { capitalize } from "lodash";

export default class TeethView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teethData: [],
    };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props != prevProps) {
      this.loadData();
    }
  };

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    window.Spinner.show();
    window.db.getTeethDataForUser(window.myid, (data) => {
      let teethData = [];
      if (data.length) {
        let teeth = [];
        data.filter((item) => {
          let curr = teeth.find((i) => item.horseid == i.horseid);
          if (!curr) {
            teeth.push(item);
            return true;
          } else {
            return false;
          }
        });

        for (let i = 0; i < teeth.length; i++) {
          teethData.push({
            id: teeth[i].Id,
            horseId: teeth[i].horseid,
            horse: teeth[i].Name,
            RFBid: teeth[i].RFBid,
            bornIn: teeth[i].BirthYear,
            remarks: teeth[i].Remark,
            starttime: teeth[i].starttime,
            isDone: teeth[i].Color == "green",
            nextTime: teeth[i].NextDewormingPeriod,
          });
        }

        teethData = teethData.sort(function (h1, h2) {
          return h1.horse.toLowerCase() > h2.horse.toLowerCase() ? 1 : -1;
        });

        this.setState(
          {
            teethData: teethData,
          },
          window.Spinner.hide()
        );
      } else window.Spinner.hide();
    });
  };

  onMakePdfClick = () => {
    window.Spinner.show();
    let reportDataArr = [];
    let tasksNotDoneReportArr = [];
    tasksNotDoneReportArr.push([
      window.lang.horse,
      "Reg. nr",
      window.lang.bornIn,
      window.lang.date,
      window.lang.nextTeeth,
      window.lang.remark,
    ]);
    let tasksNotDone = this.state.teethData.filter((i) => !i.isDone);
    tasksNotDone.map((i) =>
      tasksNotDoneReportArr.push([
        i.horse,
        i.RFBid,
        i.bornIn,
        i.starttime,
        this.periodToNextTeethDate(i.nextTime, i.starttime),
        i.remarks,
      ])
    );
    reportDataArr.push({
      header: "Tasks not done",
      data: tasksNotDoneReportArr,
    });

    let tasksDoneReportArr = [];
    tasksDoneReportArr.push([
      window.lang.horse,
      "Reg. nr",
      window.lang.bornIn,
      window.lang.date,
      window.lang.nextTeeth,
      window.lang.remark,
    ]);
    let tasksDone = this.state.teethData.filter((i) => i.isDone);
    tasksDone.map((i) =>
      tasksDoneReportArr.push([
        i.horse,
        i.RFBid,
        i.bornIn,
        i.starttime,
        this.periodToNextTeethDate(i.nextTime, i.starttime),
        i.remarks,
      ])
    );
    reportDataArr.push({
      header: "Tasks done",
      data: tasksDoneReportArr,
    });

    window.DataGate.makePdf(
      reportDataArr,
      "Teeth report",
      function (data, url) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.target = "_blank";
        a.download = "Report.pdf";
        document.body.appendChild(a);
        a.click();
        window.Spinner.hide();
      }
    );
  };
  onMakeXlsxClick = () => {
    window.Spinner.show();
    let reportDataArr = [];
    let tasksNotDoneReportArr = [];
    tasksNotDoneReportArr.push([
      window.lang.horse,
      "Reg. nr",
      window.lang.bornIn,
      window.lang.date,
      window.lang.nextTeeth,
      window.lang.remark,
    ]);
    let tasksNotDone = this.state.teethData.filter((i) => !i.isDone);
    tasksNotDone.map((i) =>
      tasksNotDoneReportArr.push([
        i.horse,
        i.RFBid,
        i.bornIn,
        i.starttime,
        this.periodToNextTeethDate(i.nextTime, i.starttime),
        i.remarks,
      ])
    );
    reportDataArr.push({
      header: "Tasks not done",
      data: tasksNotDoneReportArr,
    });

    let tasksDoneReportArr = [];
    tasksDoneReportArr.push([
      window.lang.horse,
      "Reg. nr",
      window.lang.bornIn,
      window.lang.date,
      window.lang.nextTeeth,
      window.lang.remark,
    ]);
    let tasksDone = this.state.teethData.filter((i) => i.isDone);
    tasksDone.map((i) =>
      tasksDoneReportArr.push([
        i.horse,
        i.RFBid,
        i.bornIn,
        i.starttime,
        this.periodToNextTeethDate(i.nextTime, i.starttime),
        i.remarks,
      ])
    );
    reportDataArr.push({
      header: "Tasks done",
      data: tasksDoneReportArr,
    });

    window.DataGate.makeXlsx(
      reportDataArr,
      "Teeth report",
      function (data, url) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.target = "_blank";
        a.download = "Report.pdf";
        document.body.appendChild(a);
        a.click();
        window.Spinner.hide();
      }
    );
  };

  onAddClick = (isDone) => {
    let horse = window.DataStorage.getAllHorses()[0];
    let trainingTypes = window.DataStorage.trainTypes.filter(
      (i) => i.owner == horse.userid
    );
    console.log(trainingTypes);
    let teethTraining = trainingTypes.filter((i) => i.IsVeterinarian);
    let date = new Date();
    if (!isDone) {
      let weeks = window.GlobalData.getItem(
        window.GlobalData.SETTINGS_FARRIER_WEEKS
      );
      if (weeks < 7) {
        date.setDate(
          date.getDate() + ((parseInt(weeks) + 1 + 7 - date.getDay()) % 7)
        );
      } else if (weeks > 7) {
        date = new Date().addDays(7 * (parseInt(weeks) - 4));
      }
    }
    let currentTrainerId = horse.trainer;
    if (!currentTrainerId)
      var currentTrainer = window.DataStorage.allUsers.find(
        (i) => i.Id == window.myid
      );
    else
      var currentTrainer = window.DataStorage.allUsers.find(
        (i) => i.Id == currentTrainerId
      );
    let options = {
      trainingType: teethTraining[0].id,
      vetSubType: 3,
      date: date,
      isDone: isDone,
      trainerId: currentTrainer.Id,
      trainerName: currentTrainer.Name,
    };
    let trainingPopup = new TrainingPopup();
    trainingPopup.showPopup(options, null, () => {
      this.loadData();
      //   window.HomePage.latestIconClick = "homeIconTeeth";
      //   window.HomePage.prototype.onFarrierMenuClick();
    });
  };

  onAddForHorseClick = (horseId) => {
    let horse = window.DataStorage.getAllHorses().filter(
      (i) => i.id == horseId
    );
    let trainingTypes = window.DataStorage.trainTypes.filter(
      (i) => i.owner == horse[0].userid
    );
    let teethTraining = trainingTypes.filter((i) => i.IsVeterinarian);
    let date = new Date();
    let farrWeek = window.GlobalData.getItem(
      window.GlobalData.SETTINGS_FARRIER_WEEKS + "&" + horseId
    );
    // if (!farrWeek)
    //   farrWeek = window.GlobalData.getItem(
    //     window.GlobalData.SETTINGS_FARRIER_WEEKS
    //   );
    // if (farrWeek < 7) {
    //   date.setDate(
    //     date.getDate() + ((parseInt(farrWeek) + 1 + 7 - date.getDay()) % 7)
    //   );
    // } else if (farrWeek > 7) {
    //   date = new Date().addDays(7 * (parseInt(farrWeek) - 4));
    // }
    let currentTrainerId = horse.trainer;
    if (!currentTrainerId)
      var currentTrainer = window.DataStorage.allUsers.find(
        (i) => i.Id == window.myid
      );
    else
      var currentTrainer = window.DataStorage.allUsers.find(
        (i) => i.Id == currentTrainerId
      );
    let options = {
      trainingType: teethTraining[0].id,
      date: date,
      isDone: false,
      vetSubType: 3,
      horseId: horse[0].id,
      farrWeek: farrWeek,
      trainerId: currentTrainer.Id,
      trainerName: currentTrainer.Name,
    };
    let trainingPopup = new TrainingPopup();
    trainingPopup.showPopup(options, null, () => {
      this.loadData();
      //   window.HomePage.latestIconClick = "homeIconFarrier";
      //   window.HomePage.prototype.onFarrierMenuClick();
    });
  };

  onEditClick = (id) => {
    window.DataGate.getTraining(id).then((item) => {
      window.db.getTrainers(item.HorseId, (data) => {
        let horse = window.DataStorage.getAllHorses().find(
          (h) => h.id == item.HorseId
        );
        let trainingTypes = window.DataStorage.trainTypes.filter(
          (i) => i.owner == horse.userid
        );
        let currentTraining = trainingTypes.filter((i) => i.id == item.Type);
        let currentTrainer = data.filter((i) => i.id == item.Trainer);
        let newItem = {
          color: item.Color,
          duration: item.Duration,
          Evaluation: item.Evaluation,
          FarrierType: item.FarrierType,
          FarrierTypeName: item.FarrierTypeName,
          HasMultimedia: item.HasMultimedia,
          HorseId: item.HorseId,
          HorseName: item.HorseName,
          id: item.Id,
          isCanCopy: item.IsCanCopy,
          PaddockPeriod: item.PaddockPeriod,
          remark: item.Remark,
          ReminderType: item.ReminderType,
          StartTime: item.StartTime,
          status: item.Status,
          SyncTime: item.SyncTime,
          TimeOfDay: item.TimeOfDay,
          trainer: item.Trainer,
          Type: item.Type,
          U: item.U,
          VeterinarianType: item.VeterinarianType,
          VeterinarianVacType: item.VeterinarianVacType,
          name: currentTraining[0].name,
          shortname: currentTraining[0].shortname,
          trainername: currentTrainer[0].name,
          NextDewormingPeriod: item.NextDewormingPeriod,
        };

        let options = {
          horseId: item.HorseId,
          trainingType: item.Type,
          date: Date.getNewDate(item.StartTime),
          trainerId: currentTrainer[0].id,
          trainerName: currentTrainer[0].name,
        };

        let trainingPopup = new TrainingPopup();
        trainingPopup.showPopup(options, newItem, () => {
          this.loadData();
        });
      });
    });
  };

  onDeleteClick = (item) => {
    window.Spinner.show();
    window.DataGate.deleteTrainings(
      item.id,
      "d",
      item.horseId,
      item.starttime,
      () => {
        this.loadData();
      }
    );
  };
  renderNotDoneTasks = () => {
    const { teethData } = this.state;
    if (!teethData) return;
    const tasks = teethData
      .filter((i) => !i.isDone)
      .map((i) => {
        i.days = this.calculateDaysSince(i.starttime);
        return i;
      })
      .sort((t1, t2) => t2.days - t1.days);
    return (
      <>
        <tr>
          <th className="task_done_td">{window.lang.trained}</th>
          <th>{window.lang.horse}</th>
          <th>Reg. nr</th>
          <th>{capitalize(window.lang.days)}</th>

          <th>{window.lang.date}</th>
          <th>{window.lang.nextTeeth}</th>
          <th>{window.lang.remark}</th>
          <th></th>
        </tr>
        {tasks.map((i) => (
          <tr key={Math.uuid()}>
            <td className="task_done_td">
              <ToggleView
                checked={i.isDone}
                onClick={() => this.onToggleClick(i.id, i.isDone)}
              />
            </td>
            <td>{i.horse}</td>
            <td>{i.RFBid}</td>
            <td>{i.days}</td>

            <td>{i.starttime}</td>
            <td>{this.periodToNextTeethDate(i.nextTime, i.starttime)}</td>
            <td>{i.remarks}</td>
            <td>
              <img
                loading="lazy"
                className="actionIcon"
                src="img/edit_icon.png"
                onClick={() => this.onEditClick(i.id)}
              />
              <img
                loading="lazy"
                className="actionIcon"
                src="img/trashcan.png"
                onClick={() => this.onDeleteClick(i)}
              />
            </td>
          </tr>
        ))}
      </>
    );
  };

  onToggleClick = (training_id, oldColor) => {
    // updateTrainingDoneState
    const newColor = oldColor ? "red" : "green";
    window.DataGate.updateTrainingDoneState(training_id, newColor, () => {
      this.loadData();
    });
  };
  periodToNextTeethDate = (period, starttime) => {
    let nextDewormingDate = "";
    switch (period) {
      case "0":
        var date = new Date(JSON.parse(JSON.stringify(starttime)));
        date = new Date(date.setMonth(date.getMonth() + 1));
        nextDewormingDate = date.formatDesh();
        break;
      case "1":
        var date = new Date(JSON.parse(JSON.stringify(starttime)));
        date = new Date(date.setMonth(date.getMonth() + 3));
        nextDewormingDate = date.formatDesh();
        break;
      case "2":
        var date = new Date(JSON.parse(JSON.stringify(starttime)));
        date = new Date(date.setMonth(date.getMonth() + 6));
        nextDewormingDate = date.formatDesh();
        break;
      case "3":
        var date = new Date(JSON.parse(JSON.stringify(starttime)));
        date = new Date(date.setMonth(date.getMonth() + 12));
        nextDewormingDate = date.formatDesh();
        break;
    }
    return nextDewormingDate;
  };

  calculateDaysSince = (startDateString) => {
    const startDate = new Date(startDateString); // Convert the string to a Date object
    const currentDate = new Date(); // Get the current date
    const timeDifference = currentDate - startDate; // Difference in milliseconds
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert to days
    return daysDifference;
  };

  renderDoneTasks = () => {
    const { teethData } = this.state;
    if (!teethData) return;
    const tasks = teethData
      .filter((i) => i.isDone)
      .map((i) => {
        i.days = this.calculateDaysSince(i.starttime);
        return i;
      })
      .sort((t1, t2) => t2.days - t1.days);
    return (
      <>
        <tr>
          <th className="task_done_td">{window.lang.trained}</th>
          <th>{window.lang.horse}</th>
          <th>Reg. nr</th>
          <th>{capitalize(window.lang.days)}</th>

          <th>{window.lang.date}</th>
          <th>{window.lang.nextTeeth}</th>
          <th>{window.lang.remark}</th>
          <th></th>
          <th></th>
        </tr>
        {tasks.map((i) => (
          <tr key={Math.uuid()}>
            <td className="task_done_td">
              <ToggleView
                checked={i.isDone}
                onClick={() => this.onToggleClick(i.id, i.isDone)}
              />
            </td>
            <td>{i.horse}</td>
            <td>{i.RFBid}</td>
            <td>{i.days}</td>

            <td>{i.starttime}</td>
            <td>{this.periodToNextTeethDate(i.nextTime, i.starttime)}</td>
            <td>{i.remarks}</td>
            <td>
              <img
                loading="lazy"
                className="actionIcon"
                src="img/edit_icon.png"
                onClick={() => this.onEditClick(i.id)}
              />
              <img
                loading="lazy"
                className="actionIcon"
                src="img/trashcan.png"
                onClick={() => this.onDeleteClick(i)}
              />
            </td>
            <td>
              <button
                className="ui-btn ui-shadow ui-corner-all"
                onClick={() => this.onAddForHorseClick(i.horseId)}
              >
                {window.lang.planTeeth}
              </button>
            </td>
          </tr>
        ))}
      </>
    );
  };
  renderRemainingHorsesTable = () => {
    const data = this.state.teethData;
    if (!data) return;
    let horses = window.DataStorage.getAllHorses();
    console.log(data);
    let horsesArr = horses.filter(
      (e) => data.findIndex((i) => e.id == i.horseId) === -1
    );
    horsesArr = horsesArr.sort((t1, t2) => {
      if (t1.name > t2.name) return 1;
      if (t1.name < t2.name) return -1;
      return 0;
    });
    return (
      <>
        <tr>
          <th>{window.lang.horse}</th>
          <th></th>
        </tr>
        {horsesArr.length ? (
          horsesArr.map((i) => (
            <tr key={Math.uuid()}>
              <td>{i.name}</td>
              <td>
                <button
                  className="ui-btn ui-shadow ui-corner-all"
                  onClick={() => this.onAddForHorseClick(i.id)}
                >
                  {window.lang.planTeeth}
                </button>
              </td>
            </tr>
          ))
        ) : (
          <></>
        )}
      </>
    );
  };

  render() {
    return (
      <div id="farrierViewDiv">
        <div className="topRow">
          <button
            className="ui-btn ui-shadow ui-corner-all"
            onClick={() => this.onAddClick(true)}
          >
            {window.lang.newTeeth}
          </button>
          <button
            className="ui-btn ui-shadow ui-corner-all"
            onClick={() => this.onAddClick(false)}
          >
            {window.lang.planTeeth}
          </button>
          <img
            loading="lazy"
            id="makePDFButton0"
            className="reportFarrButton"
            src="img/pdf_download.png"
            onClick={this.onMakePdfClick}
          />
          <img
            loading="lazy"
            id="makeXlsxButton0"
            className="reportFarrButton"
            src="img/excel_download.png"
            onClick={this.onMakeXlsxClick}
          />
        </div>
        <table className="farrierTable">
          <tbody>
            {this.renderNotDoneTasks()}
            {this.renderDoneTasks()}
          </tbody>
        </table>
        <h3 style={{ marginLeft: "20px" }}>
          {window.lang.remainingHorsesTeeth}
        </h3>
        <table
          className="remainingHorsesFarrierTable"
          style={{ borderCollapse: "collapse" }}
        >
          <tbody>{this.renderRemainingHorsesTable()}</tbody>
        </table>
      </div>
    );
  }
}
