import React, { Component } from "react";
import ReactDOM from "react-dom";
import * as XLSX from "xlsx";
import "./Report.css";
import MultimediaHelper from "../Multimedia/MultimediaHelper";

class ReportsPage extends Component {
  constructor(props) {
    super(props);

    this.purchasePrice = "Indkøbspris:";
    this.withVAT = "Med moms:";
    this.currentAssessment = "Vurdering aktuelt:";
    this.assessmentPotential = "Vurdering Potentielt:";
    this.salePrice = "Salgspris:";
    this.costPrice1 = "Kostpris 1:";
    this.costPrice2 = "Kostpris 2:";
    this.costPrice3 = "Kostpris 3:";
    this.purchasePriceLength = 12;
    this.withVATLength = 9;
    this.currentAssessmentLength = 18;
    this.assessmentPotentialLength = 21;
    this.salePriceLength = 10;
    this.costPrice1Length = 11;
    this.costPrice2Length = 11;
    this.costPrice3Length = 11;

    this.state = {
      farVetSelector: [],
      reportDataArray: [],
      selectedValue: "",
      horse: props.horseId,
      headerTitle: "",
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.horseId !== this.props.horseId) {
      this.setState({ horse: this.props.horseId }, () => {
        this.loadData();
      });
    }
    if (prevProps.date !== this.props.date && prevProps.date != null) {
      this.onFarVetSelectorChange({
        target: { value: this.state.selectedValue },
      });
    }
  };

  componentDidMount = () => {
    const horseTypes = JSON.parse(
      window.GlobalData.getItem(window.GlobalData.SHOW_IN_TRAINING)
    );
    this.isHistoricHorses = horseTypes[0] == 101;
    this.loadData();
  };

  loadData = () => {
    let farVetSelector;

    if (this.state.horse) {
      farVetSelector = [
        { value: 1, label: window.lang.vetReport },
        { value: 2, label: window.lang.farReport },
        { value: 3, label: window.lang.allHorsesAndTasksToday },
        { value: 4, label: window.lang.horse45days },
        { value: 5, label: window.lang.taskHorsesWithRemarks },
        { value: 6, label: window.lang.allHorsesByType },
        { value: 7, label: window.lang.stabletime },
        { value: 8, label: window.lang.allHorsesWithPrice },
        { value: 9, label: window.lang.allHorsesTodayWithNotes },
        { value: 10, label: window.lang.horse45daysRemarks },
        { value: 11, label: window.lang.allHorses45days },
        { value: 12, label: "Horses with id and notats" },
        { value: 13, label: window.lang.allVacinations },
        { value: 14, label: "Horses with sales info" },
        { value: 15, label: window.lang.ownerHorseType },
        { value: 16, label: window.lang.ownerHorse },
        { value: 17, label: window.lang.horseFollowsRider },
        { value: 18, label: window.lang.horseAge },
        { value: 19, label: window.lang.salesNotesHorse },
        { value: 20, label: window.lang.salesNotesHorseType },
        { value: 21, label: window.lang.trainingHistory },
        { value: 22, label: window.lang.vaccinationHorse },
        { value: 23, label: window.lang.vaccinationHorseType },
        { value: 24, label: window.lang.farrierHorse },
        { value: 25, label: window.lang.farrierHorseType },
        { value: 26, label: window.lang.vetHorse },
        { value: 27, label: window.lang.vetHorseType },
        { value: 28, label: window.lang.priceReport },
        { value: 29, label: "All horses all data" },
        { value: 30, label: "This horse with remarks and workouts" },
        { value: 31, label: "Stable History Report" },
      ];
      this.setState({ selectedValue: 4 }, () => {
        this.createHorse45DaysReport();
      });
    } else {
      farVetSelector = [
        { value: 1, label: window.lang.vetReport },
        { value: 2, label: window.lang.farReport },
        { value: 3, label: window.lang.allHorsesAndTasksToday },
        { value: 6, label: window.lang.allHorsesByType },
        { value: 7, label: window.lang.stabletime },
        { value: 8, label: window.lang.allHorsesWithPrice },
        { value: 9, label: window.lang.allHorsesTodayWithNotes },
        { value: 11, label: window.lang.allHorses45days },
        { value: 12, label: "Horses with id and notats" },
        { value: 13, label: window.lang.allVacinations },
        { value: 14, label: "Horses with sales info" },
        { value: 15, label: window.lang.ownerHorseType },
        { value: 16, label: window.lang.ownerHorse },
        { value: 17, label: window.lang.horseFollowsRider },
        { value: 18, label: window.lang.horseAge },
        { value: 19, label: window.lang.salesNotesHorse },
        { value: 20, label: window.lang.salesNotesHorseType },
        { value: 21, label: window.lang.trainingHistory },
        { value: 22, label: window.lang.vaccinationHorse },
        { value: 23, label: window.lang.vaccinationHorseType },
        { value: 24, label: window.lang.farrierHorse },
        { value: 25, label: window.lang.farrierHorseType },
        { value: 26, label: window.lang.vetHorse },
        { value: 27, label: window.lang.vetHorseType },
        { value: 28, label: window.lang.priceReport },
        { value: 29, label: "All horses all data" },
      ];
      this.setState({ selectedValue: 6 }, () => {
        this.createAllHorsesByTypeReport();
      });
    }

    const settingsReportStr = window.GlobalData.getItem(
      window.GlobalData.SETTINGS_REPPORT
    );
    let settingsReport = [];
    if (settingsReportStr) {
      settingsReport = JSON.parse(settingsReportStr);
    }
    farVetSelector = farVetSelector.map((item) => {
      const settingsReportItem = settingsReport.find(
        (r) => r.value == item.value
      );
      return {
        ...item,
        isVisible: settingsReportItem ? settingsReportItem.isVisible : true,
        orderIndex: settingsReportItem ? settingsReportItem.orderIndex : 1000,
      };
    });
    farVetSelector = farVetSelector.sort((a, b) => a.orderIndex - b.orderIndex);

    this.setState({
      farVetSelector: farVetSelector,
    });
  };

  onFarVetSelectorChange = (e) => {
    switch (e.target.value) {
      case "1":
        window.Spinner.show();
        this.createVetReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "2":
        window.Spinner.show();
        this.createFarReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "3":
        window.Spinner.show();
        this.createAllHorsesAndTasksTodayReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "4":
        window.Spinner.show();
        this.createHorse45DaysReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "5":
        window.Spinner.show();
        this.createTaskHorsesWithRemarksReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "6":
        window.Spinner.show();
        this.createAllHorsesByTypeReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "7":
        window.Spinner.show();
        this.createStableTimeReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "8":
        window.Spinner.show();
        this.createAllHorsesWithPrice();
        this.setState({ selectedValue: e.target.value });
        break;
      case "9":
        window.Spinner.show();
        this.createAllHorsesTodayWithNotesReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "10":
        window.Spinner.show();
        this.createHorse45DaysRemarksReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "11":
        window.Spinner.show();
        this.createAllHorses45daysReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "12":
        window.Spinner.show();
        this.createHorsesWithIdAndNotesReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "13":
        window.Spinner.show();
        this.createAllVaccinationReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "14":
        window.Spinner.show();
        this.createHorsesSalesReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "15":
        window.Spinner.show();
        this.createOwnerTypeReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "16":
        window.Spinner.show();
        this.createOwnerHorseReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "17":
        window.Spinner.show();
        this.createHorseFollowsRiderReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "18":
        window.Spinner.show();
        this.createHorseAgeReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "19":
        window.Spinner.show();
        this.createSalesNotesHorseReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "20":
        window.Spinner.show();
        this.createSalesNotesTypeReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "21":
        window.Spinner.show();
        this.createTrainingHistoryReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "22":
        window.Spinner.show();
        this.createVaccinationsHorseReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "23":
        window.Spinner.show();
        this.createVaccinationsTypeReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "24":
        window.Spinner.show();
        this.createFarrierHorseReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "25":
        window.Spinner.show();
        this.createFarrierHorseTypeReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "26":
        window.Spinner.show();
        this.createVetHorseReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "27":
        window.Spinner.show();
        this.createVetHorseTypeReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "28":
        window.Spinner.show();
        this.createPriceReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "29":
        window.Spinner.show();
        this.createAllHorsesAllDataReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "30":
        window.Spinner.show();
        this.createThisHorseWithRemarksAndWorkoutsReport();
        this.setState({ selectedValue: e.target.value });
        break;
      case "31":
        window.Spinner.show();
        this.createThisHorseStableBoxHistoryLogReport();
        this.setState({ selectedValue: e.target.value });
        break;
      default:
        break;
    }
  };

  getPermission = (horseId) => {
    let horseItem = window.DataStorage.getAllHorses().find(
      (h) => h.id == horseId
    );
    if (horseItem) {
      if (horseItem.userid != window.myid) {
        let role = window.DataStorage.rolesDataFromUsers.find(
          (r) => r.UserId == horseItem.userid
        );
        if (role != null && role.rSeeHorseData == false) return false;
        else return true;
      } else return true;
    } else {
      return false;
    }
  };

  createVetReport = () => {
    window.db.getVetFarTrainingsByUser(
      window.myid,
      this.isHistoricHorses,
      (data) => {
        let farTrainings = [];
        let vetTrainings = [];
        for (var i = 0; i < data.length; i++) {
          if (
            data[i]["ShortName"] == "VET" ||
            data[i]["ShortName"] == "DYR" ||
            data[i]["ShortName"] == "TI"
          ) {
            if (!this.getPermission(data[i].horseId)) continue;
            vetTrainings.push(data[i]);
          } else {
            farTrainings.push(data[i]);
          }
        }
        let tableArr = [
          ["Date", window.lang.name, window.lang.remark],
          ...vetTrainings.map((t) => [t.StartTime, t.Name, t.Remark]),
        ];
        this.setState({
          reportDataArray: [
            {
              header: "",
              data: tableArr,
            },
          ],
          headerTitle: window.lang.vetReport,
        });
        window.Spinner.hide();
      }
    );
  };

  createFarReport = () => {
    window.db.getVetFarTrainingsByUser(
      window.myid,
      this.isHistoricHorses,
      (data) => {
        let farTrainings = [];
        let vetTrainings = [];
        for (var i = 0; i < data.length; i++) {
          if (
            data[i]["ShortName"] == "VET" ||
            data[i]["ShortName"] == "DYR" ||
            data[i]["ShortName"] == "TI"
          ) {
            if (data[i]["SeeVet"] == "n") {
              continue;
            }
            vetTrainings.push(data[i]);
          } else {
            if (!this.getPermission(data[i].horseId)) continue;
            farTrainings.push(data[i]);
          }
        }
        let tableArr = [
          ["Date", window.lang.name, window.lang.remark],
          ...farTrainings.map((t) => [t.StartTime, t.Name, t.Remark]),
        ];
        this.setState({
          reportDataArray: [
            {
              header: "",
              data: tableArr,
            },
          ],
          headerTitle: window.lang.farReport,
        });
        window.Spinner.hide();
      }
    );
  };

  createAllHorsesAndTasksTodayReport = () => {
    let startDate = new Date().formatDesh();
    var endDate = new Date().formatDesh();
    window.db.getTrainingsByHorseAndDateForMyHorses(
      window.myid,
      startDate,
      endDate,
      (trainData) => {
        window.db.getMyAndSharedHorses(
          window.myid,
          (hdata1, hdata2) => {
            var rows = [];
            var data = hdata1.concat(hdata2);
            var names = [];
            for (var i = 0; i < data.length; i++) {
              names[data[i]["id"]] = data[i]["name"];
            }
            for (var i = 0; i < trainData.length; i++) {
              if (!this.getPermission(trainData[i]["horseid"])) continue;
              var trainItem = trainData.item(i);
              var name = names[trainItem["horseid"]];
              var row = [
                name,
                trainItem["name"],
                this.getSmileyForItem(trainItem["Evaluation"]),
                trainItem["trainername"],
                trainItem["remark"],
              ];
              rows.push(row);
            }

            let tableArr = [
              [
                window.lang.horse,
                window.lang.type,
                window.lang.evaluation,
                window.lang.riders,
                window.lang.notes,
              ],
              ...rows,
            ];

            this.setState({
              reportDataArray: [
                {
                  header: "",
                  data: tableArr,
                },
              ],
              headerTitle: window.lang.allHorsesAndTasksToday,
            });
            window.Spinner.hide();
          },
          this.isHistoricHorses ? 101 : 1
        );
      },
      this.isHistoricHorses
    );
  };

  getSmileyForItem = (item) => {
    var str = "";
    if (item && item != 0) {
      str = '<img loading="lazy"src="img/smiley' + item + '.png">';
    }
    return str;
  };

  createHorse45DaysReport = () => {
    const { horse } = this.state;
    let startDate = new Date();
    let endDate = new Date().formatDesh();
    startDate.setDate(startDate.getDate() - 45);
    startDate = startDate.formatDesh();
    window.db.getTrainingsByHorseAndDate(
      horse.id,
      startDate,
      endDate,
      (trainData) => {
        trainData = trainData.sort(this.sortStartTime);
        if (!this.getPermission(horse.id))
          var tableArr = [
            [
              "Date",
              window.lang.type,
              window.lang.evaluation,
              window.lang.riders,
              window.lang.notes,
            ],
          ];
        else
          var tableArr = [
            [
              "Date",
              window.lang.type,
              window.lang.evaluation,
              window.lang.riders,
              window.lang.notes,
            ],
            ...trainData.map((t) => [
              Date.reformatToDMY(t.starttime),
              t.name,
              this.getSmileyForItem(t.Evaluation),
              t.trainername,
              t.remark,
            ]),
          ];
        this.setState({
          reportDataArray: [
            {
              header: "",
              data: tableArr,
            },
          ],
          headerTitle: this.state.horse.name,
        });
        window.Spinner.hide();
      },
      this.isHistoricHorses
    );
  };

  sortStartTime = (a, b) => {
    const startTimeA = a.starttime;
    const statrTimeB = b.starttime;
    let comparison = 0;
    if (startTimeA < statrTimeB) {
      comparison = 1;
    } else if (startTimeA > statrTimeB) {
      comparison = -1;
    }
    return comparison;
  };

  createTaskHorsesWithRemarksReport = () => {
    const { horse } = this.state;
    let startDate = new Date();
    let endDate = new Date().formatDesh();
    startDate.setMonth(startDate.getMonth() - 1);
    startDate = startDate.formatDesh();
    window.db.getTrainingsByHorseAndDateWithRemark(
      horse.id,
      startDate,
      endDate,
      (trainData) => {
        if (!this.getPermission(horse.id))
          var tableArr = [
            [
              "Date",
              window.lang.type,
              window.lang.evaluation,
              window.lang.riders,
              window.lang.notes,
            ],
          ];
        else
          var tableArr = [
            [
              "Date",
              window.lang.type,
              window.lang.evaluation,
              window.lang.riders,
              window.lang.notes,
            ],
            ...trainData.map((t) => [
              Date.reformatToDMY(t.starttime),
              t.name,
              this.getSmileyForItem(t.Evaluation),
              t.trainername,
              t.remark,
            ]),
          ];
        this.setState({
          reportDataArray: [
            {
              header: "",
              data: tableArr,
            },
          ],
          headerTitle: this.state.horse.name,
        });
        window.Spinner.hide();
      }
    );
  };

  createAllHorsesByTypeReport = () => {
    window.db.getHorsesForReportByTypeAndWithPrice(
      this.isHistoricHorses,
      (dataHorses) => {
        var typesNotNull = [];
        for (var i = 0; i < dataHorses.length; i++) {
          if (!this.getPermission(dataHorses[i].id)) continue;
          if (typesNotNull.indexOf(dataHorses[i]["Type"]) == -1) {
            typesNotNull.push(dataHorses[i]["Type"]);
          }
        }
        let reportData = [];

        for (var j = 0; j < typesNotNull.length; j++) {
          let tableArr = [];
          var typeName = typesNotNull[j] != null ? typesNotNull[j] : "None";
          tableArr.push([
            window.lang.name,
            window.lang.age,
            "Sex",
            window.lang.pedigree,
            window.lang.result,
            "Reg. nr",
          ]);
          for (var i = 0; i < dataHorses.length; i++) {
            if (!this.getPermission(dataHorses[i].id)) continue;
            if (dataHorses[i].Type == typesNotNull[j]) {
              tableArr.push([
                dataHorses[i].Name,
                dataHorses[i].BirthYear,
                this.setGender(dataHorses[i].Gender),
                this.setPeddi(dataHorses[i].PedigreeJsonText),
                dataHorses[i].Result,
                dataHorses[i].RFBid,
              ]);
            }
          }
          reportData.push({
            header: typeName,
            data: tableArr,
          });
        }

        this.setState({
          reportDataArray: reportData,
          headerTitle: window.lang.allHorsesByType,
        });
        window.Spinner.hide();
      }
    );
  };

  setGender = (index) => {
    var genderText = "";
    if (index == 0) {
      genderText = "None";
    } else if (index == 1) {
      genderText = window.lang.horseTypeMare;
    } else if (index == 2) {
      genderText = window.lang.horseTypeGelding;
    } else {
      genderText = window.lang.horseTypeStallion;
    }
    return genderText;
  };

  setPeddi = (jsonText) => {
    var text = JSON.parse(jsonText);
    if (text != null) {
      var peddiText =
        text["mather"] +
        " / " +
        text["grmather_father"] +
        " / " +
        text["grmather_grfather_f"];
    } else {
      var peddiText = "";
    }
    return peddiText;
  };

  createStableTimeReport = () => {
    var nDate = new Date().formatDeshStartDay().split("-");
    var month = nDate[1] - 1;
    var periodDay = new Date();
    var today = new Date();
    var lastDayOfMonth = new Date(today.getFullYear(), month, 0);
    periodDay.setMonth(periodDay.getMonth() - 1);
    periodDay.setDate(lastDayOfMonth.getDate());
    var periodStart = new Date(
      lastDayOfMonth.getFullYear(),
      month - 1,
      1
    ).formatDeshStartDay();
    var periodEnd = periodDay.formatDeshStartDay();
    window.db.getRepportFreeBoxesByUser(
      window.myid,
      periodStart,
      periodEnd,
      (data) => {
        var dataNorm = [];
        for (var i = 0; i < data.length; i++) {
          var f = data[i]["Finish"].split("-");
          var s = data[i]["Start"].split("-");
          if (data[i]["Finish"] != "null") {
            if (parseInt(f[1]) < month) {
              continue;
            } else if (parseInt(f[1]) > month) {
              var finishDate = new Date(
                lastDayOfMonth.getFullYear(),
                month - 1,
                lastDayOfMonth.getDate()
              );
            } else {
              var finishDate = new Date(f[2], f[1] - 1, f[0]);
            }
          } else {
            var finishDate = new Date(
              lastDayOfMonth.getFullYear(),
              month - 1,
              lastDayOfMonth.getDate()
            );
          }
          if (parseInt(s[1]) < month) {
            var startDate = new Date(
              lastDayOfMonth.getFullYear(),
              month - 1,
              "01"
            );
          } else {
            var startDate = new Date(s[2], s[1] - 1, s[0]);
          }
          var one_day = 1000 * 60 * 60 * 24;
          var dayCount = (finishDate - startDate) / one_day + 1;
          data[i]["Summ"] = dayCount;
          if (!dataNorm[data[i]["Name"]]) {
            dataNorm[data[i]["Name"]] = data[i];
          } else {
            if (dataNorm[data[i]["Name"]]["Name"] == data[i]["Name"]) {
              dataNorm[data[i]["Name"]]["Summ"] += dayCount;
            }
          }
        }

        let dataArray = [];
        for (var key in dataNorm) {
          if (!this.getPermission(dataNorm[key].Id)) continue;
          var cdate =
            dataNorm[key]["CreateDate"] == null
              ? ""
              : dataNorm[key]["CreateDate"] == "01-01-1900"
              ? ""
              : dataNorm[key]["CreateDate"];
          var owner =
            (dataNorm[key]["Owner"] == null ? "" : dataNorm[key]["Owner"]) +
            " and " +
            (dataNorm[key]["Company"] == null ? "" : dataNorm[key]["Company"]);
          dataArray.push([
            dataNorm[key].Name,
            owner,
            dataNorm[key].Company,
            cdate.toString(),
            dataNorm[key].Summ.toString(),
            dataNorm[key].Notes,
          ]);
        }
        let tableArr = [
          [
            window.lang.name,
            window.lang.owner,
            window.lang.arrival,
            window.lang.departure,
            window.lang.Sum,
            window.lang.notes,
          ],
          ...dataArray,
        ];

        this.setState({
          reportDataArray: [
            {
              header: "",
              data: tableArr,
            },
          ],
          headerTitle: window.lang.stabletime,
        });
        window.Spinner.hide();
      },
      this.isHistoricHorses
    );
  };

  createAllHorsesWithPrice = () => {
    window.db.getHorsesForReportByTypeAndWithPrice(
      this.isHistoricHorses,
      (dataHorses) => {
        var typesNotNull = [];
        for (let i = 0; i < dataHorses.length; i++) {
          if (!this.getPermission(dataHorses[i].id)) continue;
          if (typesNotNull.indexOf(dataHorses[i]["Type"]) == -1) {
            typesNotNull.push(dataHorses[i]["Type"]);
          }
        }
        let reportData = [];
        for (let j = 0; j < typesNotNull.length; j++) {
          let dataArray = [];
          var typeName = typesNotNull[j] != null ? typesNotNull[j] : "None";
          dataArray.push([
            window.lang.name,
            window.lang.age,
            "Sex",
            window.lang.pedigree,
            window.lang.color,
            window.lang.height,
            window.lang.result,
            "Reg. nr",
            window.lang.price,
          ]);
          for (let t = 0; t < dataHorses.length; t++) {
            if (!this.getPermission(dataHorses[t].id)) continue;
            if (dataHorses[t]["Type"] == typesNotNull[j]) {
              var color =
                dataHorses[t].ColorName == null ? "" : dataHorses[t].ColorName;
              var price =
                dataHorses[t].Price == null ? "" : dataHorses[t].Price;
              var peddi = this.setPeddi(dataHorses[t].PedigreeJsonText);
              dataArray.push([
                dataHorses[t].Name,
                dataHorses[t].BirthYear,
                this.setGender(dataHorses[t].Gender),
                peddi,
                color,
                dataHorses[t].Height,
                dataHorses[t].Result,
                dataHorses[t].RFBid,
                price,
              ]);
            }
          }
          reportData.push({
            header: typeName,
            data: dataArray,
          });
        }

        this.setState({
          reportDataArray: reportData,
          headerTitle: window.lang.allHorsesWithPrice,
        });
        window.Spinner.hide();
      }
    );
  };

  createAllHorsesTodayWithNotesReport = () => {
    let startDate = new Date();
    startDate = startDate.formatDesh();
    let endDate = new Date().formatDesh();
    window.db.getTrainingsWithRemark(
      startDate,
      endDate,
      (trainData) => {
        let tData = [];
        for (let i = 0; i < trainData.length; i++) {
          if (!this.getPermission(trainData[i].horseid)) continue;
          tData.push(trainData[i]);
        }
        let tableArr = [
          [
            "Date",
            window.lang.horse,
            window.lang.type,
            window.lang.evaluation,
            window.lang.riders,
            window.lang.notes,
          ],
          ...tData.map((t) => [
            Date.reformatToDMY(t.starttime),
            t.horsename,
            t.name,
            this.getSmileyForItem(t.Evaluation),
            t.trainername,
            t.remark,
          ]),
        ];

        this.setState({
          reportDataArray: [
            {
              header: "",
              data: tableArr,
            },
          ],
          headerTitle: window.lang.allHorsesTodayWithNotes,
        });
        window.Spinner.hide();
      },
      this.isHistoricHorses
    );
  };

  createHorse45DaysRemarksReport = () => {
    const { horse } = this.state;
    let startDate = new Date();
    let endDate = new Date().formatDesh();
    startDate.setDate(startDate.getDate() - 45);
    startDate = startDate.formatDesh();
    window.db.getTrainingsByHorseAndDate(
      horse.id,
      startDate,
      endDate,
      (trainData) => {
        trainData = trainData.sort(this.sortStartTime);
        let dataArr = [];
        if (this.getPermission(horse.id))
          for (var i = 0; i < trainData.length; i++) {
            var trainItem = trainData.item(i);
            if (trainItem.remark == "") {
              continue;
            }
            var dateStr = trainItem.starttime;
            dataArr.push([
              Date.reformatToDMY(dateStr),
              trainItem.name,
              this.getSmileyForItem(trainItem.Evaluation),
              trainItem.trainername,
              trainItem.remark,
            ]);
          }

        let tableArr = [
          [
            "Date",
            window.lang.type,
            window.lang.evaluation,
            window.lang.riders,
            window.lang.notes,
          ],
          ...dataArr,
        ];
        this.setState({
          reportDataArray: [
            {
              header: "",
              data: tableArr,
            },
          ],
          headerTitle: this.state.horse.name,
        });
        window.Spinner.hide();
      }
    );
  };

  createAllHorses45daysReport = () => {
    var startDate = new Date();
    var endDate = new Date().formatDesh();
    startDate.setDate(startDate.getDate() - 45);
    startDate = startDate.formatDesh();
    window.db.getTrainingsByHorseAndDateForMyHorses(
      window.myid,
      startDate,
      endDate,
      (trainData) => {
        window.db.getMyAndSharedHorses(
          window.myid,
          (hdata1, hdata2) => {
            var data = hdata1.concat(hdata2);
            var names = [];
            for (var i = 0; i < data.length; i++) {
              names[data[i]["id"]] = data[i]["name"];
            }
            var rows = [];
            trainData = trainData.sort(this.sortStartTime);
            for (var i = 0; i < trainData.length; i++) {
              var trainItem = trainData.item(i);
              if (!this.getPermission(trainItem.horseid)) continue;
              var name = names[trainItem["horseid"]];
              var row = [
                name,
                trainItem["starttime"],
                trainItem["name"],
                this.getSmileyForItem(trainItem["Evaluation"]),
                trainItem["trainername"],
                trainItem["remark"],
              ];
              names.push(name);
              rows.push(row);
            }
            names = names.filter(
              (item, index) => names.indexOf(item) === index
            );
            let reportData = [];
            for (var q = 0; q < names.length; q++) {
              let dataArr = [];
              dataArr.push([
                window.lang.horse,
                "Date",
                window.lang.type,
                window.lang.evaluation,
                window.lang.riders,
                window.lang.notes,
              ]);
              for (var i = 0; i < rows.length; i++) {
                if (rows[i][0] == names[q]) {
                  dataArr.push(rows[i]);
                }
              }
              reportData.push({
                header: names[q],
                data: dataArr,
              });
            }
            this.setState({
              reportDataArray: reportData,
              headerTitle: window.lang.allHorses45days,
            });
          },
          this.isHistoricHorses ? 101 : 1
        );
        window.Spinner.hide();
      },
      this.isHistoricHorses
    );
  };

  createHorsesWithIdAndNotesReport = () => {
    window.db.getReportHorseNotes(
      window.myid,
      false,
      (notes) => {
        let noteHeaders = [];
        for (let hi = 0; hi < notes.length; hi++) {
          if (noteHeaders.find((h) => h == notes[hi].HeaderNote) == null) {
            noteHeaders.push(notes[hi].HeaderNote);
          }
        }
        const horseArr = window.DataStorage.getAllHorses();
        let tableTh = [];
        tableTh = [window.lang.horse, "Reg nr", "Chipid", window.lang.notes];
        for (let hi = 0; hi < noteHeaders.length; hi++) {
          tableTh.push(noteHeaders[hi]);
        }

        let tdArray = [];
        for (let i = 0; i < horseArr.length; i++) {
          if (!this.getPermission(horseArr[i].id)) continue;
          tdArray.push(
            horseArr[i].name,
            horseArr[i].rfbid,
            horseArr[i].Chipid,
            horseArr[i].notes
          );
          for (let hi = 0; hi < noteHeaders.length; hi++) {
            const n = notes.find(
              (n) =>
                n.IdHorse == horseArr[i].id && n.HeaderNote == noteHeaders[hi]
            );
            if (n != null) {
              tdArray.push(n.TextNote);
            } else {
              tdArray.push("");
            }
          }
        }

        let size = tableTh.length;
        let tableArr = [tableTh];
        for (let i = 0; i < Math.ceil(tdArray.length / size); i++) {
          tableArr.push(tdArray.slice(i * size, i * size + size));
        }

        this.setState({
          reportDataArray: [
            {
              header: "",
              data: tableArr,
            },
          ],
          headerTitle: "Horses with id and notats",
        });
        window.Spinner.hide();
      },
      this.isHistoricHorses
    );
  };

  createHorsesSalesReport = () => {
    window.db.getReportHorseNotes(
      window.myid,
      true,
      (notes) => {
        let noteHeaders = [];
        for (let hi = 0; hi < notes.length; hi++) {
          if (!this.getPermission(notes[hi].IdHorse)) continue;
          if (noteHeaders.find((h) => h == notes[hi].HeaderNote) == null) {
            noteHeaders.push(notes[hi].HeaderNote);
          }
        }
        const horseArr = window.DataStorage.getAllHorses();
        let tableTh = [];
        tableTh = [window.lang.horse, "Reg nr", "Chipid", window.lang.price];
        for (let hi = 0; hi < noteHeaders.length; hi++) {
          tableTh.push(noteHeaders[hi]);
        }

        let tdArray = [];
        for (let i = 0; i < horseArr.length; i++) {
          if (!this.getPermission(horseArr[i].id)) continue;
          tdArray.push(
            horseArr[i].name,
            horseArr[i].rfbid,
            horseArr[i].Chipid,
            horseArr[i].Price
          );
          for (let hi = 0; hi < noteHeaders.length; hi++) {
            const n = notes.find(
              (n) =>
                n.IdHorse == horseArr[i].id && n.HeaderNote == noteHeaders[hi]
            );
            if (n != null) {
              tdArray.push(n.TextNote);
            } else {
              tdArray.push("");
            }
          }
        }

        let size = tableTh.length;
        let tableArr = [tableTh];
        for (let i = 0; i < Math.ceil(tdArray.length / size); i++) {
          tableArr.push(tdArray.slice(i * size, i * size + size));
        }

        this.setState({
          reportDataArray: [
            {
              header: "",
              data: tableArr,
            },
          ],
          headerTitle: "Horses with id and notats",
        });
        window.Spinner.hide();
      },
      this.isHistoricHorses
    );
  };

  createAllVaccinationReport = () => {
    let horseArr = [
      ...window.DataStorage.myHorseArray,
      ...window.DataStorage.notMyHorseArray,
    ];
    let reportData = [];
    for (let i = 0; i < horseArr.length; i++) {
      if (!this.getPermission(horseArr[i]["id"])) continue;
      window.db.getVaccinationLatest(horseArr[i]["id"], (rows) => {
        if (rows.length == 0) {
          return;
        }
        let lastItem = rows.item(0);
        let type = window.VaccinationPage.types[lastItem["vtype"] - 1];
        let nextType = window.VaccinationPage.types[type.NextIndex];
        let minMonths = nextType.MinMonth;
        let maxMonths = nextType.MaxMonth;
        let minDays = nextType.MinDay;
        let maxDays = nextType.MaxDay;
        let lastDate = Date.getNewDate(lastItem["vdate"]);
        let minDate = new Date(lastDate);
        let maxDate = new Date(lastDate);

        minDate.setMonth(minDate.getMonth() + minMonths);
        minDate = minDate.addDays(minDays);
        maxDate.setMonth(maxDate.getMonth() + maxMonths);
        maxDate = maxDate.addDays(maxDays);
        let daysLeft = Date.daysBetween(maxDate, new Date());
        let dataArr = [];
        dataArr.push(
          [
            window.lang.vaccinationType,
            "Last Vaccination Date",
            window.lang.nextVaccination,
          ],
          [
            nextType.Name,
            lastDate.formatDeshStartDay(),
            maxDate.formatDeshStartDay(),
          ],
          [
            window.lang.daysLeft + ": " + daysLeft,
            "",
            Date.daysBetween(new Date(), minDate) < 0
              ? window.lang.vacNotAllowed
              : "",
          ]
        );

        reportData.push({
          header: horseArr[i].name,
          data: dataArr,
          days: daysLeft,
        });
        reportData = reportData.sort(this.sortVacinationArr);

        this.setState({
          reportDataArray: reportData,
          headerTitle: window.lang.allVacinations,
        });
      });
    }
    window.Spinner.hide();
  };

  sortVacinationArr = (a, b) => {
    if (a.days < b.days) return -1;
    if (a.days > b.days) return 1;
    return 0;
  };

  createOwnerTypeReport = () => {
    window.db.getOwnerType(
      window.myid,
      (hdata1) => {
        window.db.getOwnerTypeSharedHorses(
          window.myid,
          (hdata2) => {
            window.db.getOwnerByUser(window.myid, (owners) => {
              var horses = hdata1.concat(hdata2);
              for (let i in horses) {
                if (
                  horses[i].PaperOwner != null &&
                  horses[i].PaperOwner != ""
                ) {
                  horses[i].PaperOwner = JSON.parse(horses[i].PaperOwner);
                  let ownerName = "";
                  for (let j in horses[i].PaperOwner) {
                    let item = owners.filter(
                      (item) => item.Id == horses[i].PaperOwner[j]
                    );
                    if (j == 0) item.map((k) => (ownerName += k.Name));
                    else item.map((k) => (ownerName += ", " + k.Name));
                  }
                  horses[i].PaperOwner = ownerName;
                } else horses[i].PaperOwner = "";
              }

              let dataArr = [];
              dataArr.push([
                window.lang.type,
                window.lang.name,
                "Reg. nr",
                "Chipid",
                window.lang.owner,
              ]);
              for (var q = 0; q < horses.length; q++) {
                if (!this.getPermission(horses[q].Id)) continue;
                dataArr.push([
                  horses[q].HorseType == null ? "None" : horses[q].HorseType,
                  horses[q].Name,
                  horses[q].RFBid,
                  horses[q].Chipid,
                  horses[q].PaperOwner,
                ]);
              }

              this.setState({
                reportDataArray: [
                  {
                    header: "",
                    data: dataArr,
                  },
                ],
                headerTitle: window.lang.ownerHorseType,
              });
              window.Spinner.hide();
            });
          },
          this.isHistoricHorses
        );
      },
      this.isHistoricHorses
    );
  };

  createOwnerHorseReport = () => {
    window.db.getOwnerType(
      window.myid,
      (hdata1) => {
        window.db.getOwnerTypeSharedHorses(
          window.myid,
          (hdata2) => {
            window.db.getOwnerByUser(window.myid, (owners) => {
              var horses = hdata1.concat(hdata2);
              for (let i in horses) {
                if (
                  horses[i].PaperOwner != null &&
                  horses[i].PaperOwner != ""
                ) {
                  horses[i].PaperOwner = JSON.parse(horses[i].PaperOwner);
                  let ownerName = "";
                  for (let j in horses[i].PaperOwner) {
                    let item = owners.filter(
                      (item) => item.Id == horses[i].PaperOwner[j]
                    );
                    if (j == 0) item.map((k) => (ownerName += k.Name));
                    else item.map((k) => (ownerName += ", " + k.Name));
                  }
                  horses[i].PaperOwner = ownerName;
                } else horses[i].PaperOwner = "";
              }

              let dataArr = [];
              dataArr.push([
                window.lang.name,
                window.lang.type,
                "Reg. nr",
                "Chipid",
                window.lang.owner,
              ]);
              for (var q = 0; q < horses.length; q++) {
                if (!this.getPermission(horses[q].Id)) continue;
                dataArr.push([
                  horses[q].Name,
                  horses[q].HorseType == null ? "None" : horses[q].HorseType,
                  horses[q].RFBid,
                  horses[q].Chipid,
                  horses[q].PaperOwner,
                ]);
              }

              this.setState({
                reportDataArray: [
                  {
                    header: "",
                    data: dataArr,
                  },
                ],
                headerTitle: window.lang.ownerHorse,
              });
              window.Spinner.hide();
            });
          },
          this.isHistoricHorses
        );
      },
      this.isHistoricHorses
    );
  };

  createHorseFollowsRiderReport = () => {
    window.db.getHorseFollowsRider(
      window.myid,
      (hdata1) => {
        window.db.getSharedHorseFollowsRider(
          window.myid,
          (hdata2) => {
            var horses = hdata1.concat(hdata2);
            let dataArr = [];
            dataArr.push([
              window.lang.trainerName,
              window.lang.name,
              window.lang.notes,
              window.lang.type,
            ]);
            for (var q = 0; q < horses.length; q++) {
              if (!this.getPermission(horses[q].Id)) continue;
              dataArr.push([
                horses[q].Trainer == null ? "" : horses[q].Trainer,
                horses[q].Name,
                horses[q].Notes,
                horses[q].HorseType == null ? "None" : horses[q].HorseType,
              ]);
            }

            this.setState({
              reportDataArray: [
                {
                  header: "",
                  data: dataArr,
                },
              ],
              headerTitle: window.lang.horseFollowsRider,
            });
            window.Spinner.hide();
          },
          this.isHistoricHorses
        );
      },
      this.isHistoricHorses
    );
  };

  createHorseAgeReport = () => {
    window.db.getHorseAge(
      window.myid,
      (hdata1) => {
        window.db.getSharedHorseAge(
          window.myid,
          (hdata2) => {
            window.db.getOwnerByUser(window.myid, (owners) => {
              var horses = hdata1.concat(hdata2);
              for (let i in horses) {
                if (!this.getPermission(horses[i].Id)) continue;
                if (
                  horses[i].PaperOwner != null &&
                  horses[i].PaperOwner != ""
                ) {
                  horses[i].PaperOwner = JSON.parse(horses[i].PaperOwner);
                  let ownerName = "";
                  for (let j in horses[i].PaperOwner) {
                    let item = owners.filter(
                      (item) => item.Id == horses[i].PaperOwner[j]
                    );
                    if (j == 0) item.map((k) => (ownerName += k.Name));
                    else item.map((k) => (ownerName += ", " + k.Name));
                  }
                  horses[i].PaperOwner = ownerName;
                } else horses[i].PaperOwner = "";
              }

              let dataArr = [];
              dataArr.push([
                window.lang.bornIn,
                window.lang.name,
                "Reg. nr",
                window.lang.type,
                "Chipid",
                window.lang.owner,
              ]);
              for (var q = 0; q < horses.length; q++) {
                if (!this.getPermission(horses[q].Id)) continue;
                dataArr.push([
                  horses[q].BirthYear,
                  horses[q].Name,
                  horses[q].RFBid,
                  horses[q].Chipid,
                  horses[q].HorseType == null ? "None" : horses[q].HorseType,
                  horses[q].PaperOwner,
                ]);
              }

              this.setState({
                reportDataArray: [
                  {
                    header: "",
                    data: dataArr,
                  },
                ],
                headerTitle: window.lang.horseAge,
              });
              window.Spinner.hide();
            });
          },
          this.isHistoricHorses
        );
      },
      this.isHistoricHorses
    );
  };

  createSalesNotesHorseReport = () => {
    window.db.getSalesNotesHorse(
      window.myid,
      (hdata1) => {
        window.db.getSalesNotesSharedHorse(
          window.myid,
          (hdata2) => {
            var horses = hdata1.concat(hdata2);
            let horsesArr = [];
            horses.filter((item) => {
              let curr = horsesArr.find((i) => item.Id == i.Id);
              if (!curr) {
                let header =
                  item.HeaderNote == null ? "" : item.HeaderNote + ": ";
                let text = item.TextNote == null ? "" : item.TextNote;
                let note = header + text;
                horsesArr.push({
                  Id: item.Id,
                  Name: item.Name,
                  HorseType: item.HorseType,
                  RFBid: item.RFBid,
                  Chipid: item.Chipid,
                  Note: note,
                });
                return true;
              } else {
                let header =
                  item.HeaderNote == null ? "" : item.HeaderNote + ": ";
                let text = item.TextNote == null ? "" : item.TextNote;
                let note = "; " + header + text;
                curr.Note += note;
                return false;
              }
            });

            let dataArr = [];
            dataArr.push([
              window.lang.name,
              window.lang.type,
              "Reg. nr",
              "Chipid",
              window.lang.priceSecondDefaultNote,
            ]);
            for (var q = 0; q < horsesArr.length; q++) {
              if (!this.getPermission(horsesArr[q].Id)) continue;
              dataArr.push([
                horsesArr[q].Name,
                horsesArr[q].HorseType == null
                  ? "None"
                  : horsesArr[q].HorseType,
                horsesArr[q].RFBid,
                horsesArr[q].Chipid,
                horsesArr[q].Note,
              ]);
            }

            this.setState({
              reportDataArray: [
                {
                  header: "",
                  data: dataArr,
                },
              ],
              headerTitle: window.lang.salesNotesHorse,
            });
            window.Spinner.hide();
          },
          this.isHistoricHorses
        );
      },
      this.isHistoricHorses
    );
  };

  createSalesNotesTypeReport = () => {
    window.db.getSalesNotesHorse(
      window.myid,
      (hdata1) => {
        window.db.getSalesNotesSharedHorse(
          window.myid,
          (hdata2) => {
            var horses = hdata1.concat(hdata2);
            let horsesArr = [];
            horses.filter((item) => {
              let curr = horsesArr.find((i) => item.Id == i.Id);
              if (!curr) {
                let header =
                  item.HeaderNote == null ? "" : item.HeaderNote + ": ";
                let text = item.TextNote == null ? "" : item.TextNote;
                let note = header + text;
                horsesArr.push({
                  Id: item.Id,
                  Name: item.Name,
                  HorseType: item.HorseType,
                  RFBid: item.RFBid,
                  Chipid: item.Chipid,
                  Note: note,
                });
                return true;
              } else {
                let header =
                  item.HeaderNote == null ? "" : item.HeaderNote + ": ";
                let text = item.TextNote == null ? "" : item.TextNote;
                let note = "; " + header + text;
                curr.Note += note;
                return false;
              }
            });

            let dataArr = [];
            dataArr.push([
              window.lang.type,
              window.lang.name,
              "Reg. nr",
              "Chipid",
              window.lang.priceSecondDefaultNote,
            ]);
            for (var q = 0; q < horsesArr.length; q++) {
              if (!this.getPermission(horsesArr[q].Id)) continue;
              dataArr.push([
                horsesArr[q].HorseType == null
                  ? "None"
                  : horsesArr[q].HorseType,
                horsesArr[q].Name,
                horsesArr[q].RFBid,
                horsesArr[q].Chipid,
                horsesArr[q].Note,
              ]);
            }

            this.setState({
              reportDataArray: [
                {
                  header: "",
                  data: dataArr,
                },
              ],
              headerTitle: window.lang.salesNotesHorseType,
            });
            window.Spinner.hide();
          },
          this.isHistoricHorses
        );
      },
      this.isHistoricHorses
    );
  };

  createTrainingHistoryReport = () => {
    var startDate = new Date();
    var endDate = new Date().formatDesh();
    startDate.setDate(startDate.getDate() - 45);
    startDate = startDate.formatDesh();
    window.db.getTrainingsByHorseAndDateForMyHorses(
      window.myid,
      startDate,
      endDate,
      (trainData) => {
        window.db.getMyAndSharedHorses(
          window.myid,
          (hdata1, hdata2) => {
            var data = hdata1.concat(hdata2);
            var names = [];
            for (var i = 0; i < data.length; i++) {
              if (!this.getPermission(data[i].id)) continue;
              names[data[i]["id"]] = data[i]["name"];
            }
            var rows = [];
            trainData = trainData.sort(this.sortStartTime);
            for (var i = 0; i < trainData.length; i++) {
              if (!this.getPermission(trainData[i].horseid)) continue;
              var trainItem = trainData.item(i);
              var name = names[trainItem["horseid"]];
              var row = [
                name,
                trainItem["trainername"],
                trainItem["name"],
                this.getSmileyForItem(trainItem["Evaluation"]),
                trainItem["remark"],
              ];
              names.push(name);
              rows.push(row);
            }
            names = names.filter(
              (item, index) => names.indexOf(item) === index
            );
            let reportData = [];
            for (var q = 0; q < names.length; q++) {
              let dataArr = [];
              dataArr.push([
                window.lang.horse,
                window.lang.riders,
                window.lang.type,
                window.lang.evaluation,
                window.lang.notes,
              ]);
              for (var i = 0; i < rows.length; i++) {
                if (rows[i][0] == names[q]) {
                  dataArr.push(rows[i]);
                }
              }
              reportData.push({
                header: names[q],
                data: dataArr,
              });
            }
            this.setState({
              reportDataArray: reportData,
              headerTitle: window.lang.trainingHistory,
            });
          },
          this.isHistoricHorses ? 101 : 1
        );
        window.Spinner.hide();
      },
      this.isHistoricHorses
    );
  };

  createVaccinationsHorseReport = () => {
    let horseArr = [
      ...window.DataStorage.myHorseArray,
      ...window.DataStorage.notMyHorseArray,
    ];
    let reportData = [];
    for (let i = 0; i < horseArr.length; i++) {
      if (!this.getPermission(horseArr[i].id)) continue;

      window.db.getVaccinationsHorse(horseArr[i]["id"], (rows) => {
        if (rows.length == 0) {
          return;
        }

        let dataArr = [];
        dataArr.push([
          window.lang.name,
          window.lang.type,
          "Reg. nr",
          "Chipid",
          window.lang.vaccinationType,
          window.lang.nextVaccination,
        ]);
        for (let j = 0; j < rows.length; j++) {
          let lastItem = rows.item(j);
          let type = window.VaccinationPage.types[lastItem["vtype"] - 1];
          let nextType = window.VaccinationPage.types[type.NextIndex];
          let maxMonths = nextType.MaxMonth;
          let maxDays = nextType.MaxDay;
          let lastDate = Date.getNewDate(lastItem["vdate"]);
          let maxDate = new Date(lastDate);

          maxDate.setMonth(maxDate.getMonth() + maxMonths);
          maxDate = maxDate.addDays(maxDays);

          dataArr.push([
            lastItem.Name,
            lastItem.HorseType == null ? "None" : lastItem.HorseType,
            lastItem.RFBid,
            lastItem.Chipid,
            nextType.Name,
            maxDate.formatDeshStartDay(),
          ]);
        }

        reportData.push({
          header: horseArr[i].name,
          data: dataArr,
        });

        this.setState({
          reportDataArray: reportData,
          headerTitle: window.lang.vaccinationHorse,
        });
      });
    }
    window.Spinner.hide();
  };

  createVaccinationsTypeReport = () => {
    let horseArr = [
      ...window.DataStorage.myHorseArray,
      ...window.DataStorage.notMyHorseArray,
    ];
    let reportData = [];
    for (let i = 0; i < horseArr.length; i++) {
      if (!this.getPermission(horseArr[i].id)) continue;

      window.db.getVaccinationsHorse(horseArr[i]["id"], (rows) => {
        if (rows.length == 0) {
          return;
        }

        let dataArr = [];
        dataArr.push([
          window.lang.type,
          window.lang.name,
          "Reg. nr",
          "Chipid",
          window.lang.vaccinationType,
          window.lang.nextVaccination,
        ]);
        for (let j = 0; j < rows.length; j++) {
          let lastItem = rows.item(j);
          let type = window.VaccinationPage.types[lastItem["vtype"] - 1];
          let nextType = window.VaccinationPage.types[type.NextIndex];
          let maxMonths = nextType.MaxMonth;
          let maxDays = nextType.MaxDay;
          let lastDate = Date.getNewDate(lastItem["vdate"]);
          let maxDate = new Date(lastDate);

          maxDate.setMonth(maxDate.getMonth() + maxMonths);
          maxDate = maxDate.addDays(maxDays);

          dataArr.push([
            lastItem.HorseType == null ? "None" : lastItem.HorseType,
            lastItem.Name,
            lastItem.RFBid,
            lastItem.Chipid,
            nextType.Name,
            maxDate.formatDeshStartDay(),
          ]);
        }

        reportData.push({
          header: horseArr[i].name,
          data: dataArr,
        });

        this.setState({
          reportDataArray: reportData,
          headerTitle: window.lang.vaccinationHorseType,
        });
      });
    }
    window.Spinner.hide();
  };

  createFarrierHorseReport = () => {
    let horseArr = [
      ...window.DataStorage.myHorseArray,
      ...window.DataStorage.notMyHorseArray,
    ];
    let reportData = [];
    for (let i = 0; i < horseArr.length; i++) {
      if (!this.getPermission(horseArr[i].id)) continue;

      window.db.getFarrierHorse(horseArr[i]["id"], (rows) => {
        if (rows.length == 0) {
          return;
        }
        let lastItem = rows.item(0);
        let dataArr = [];
        let days = Date.daysBetween(
          new Date(),
          Date.getNewDate(lastItem["starttime"])
        );
        var daysStr =
          days < 7
            ? days + " " + window.lang.days
            : (days / 7).toFixed(1) + " " + window.lang.week;
        dataArr.push(
          [
            window.lang.name,
            window.lang.type,
            "Reg. nr",
            "Chipid",
            window.lang.trainerName,
            window.lang.shoeDaysSinceLast,
          ],
          [
            lastItem.Horse,
            lastItem.HorseType == null ? "None" : lastItem.HorseType,
            lastItem.RFBid,
            lastItem.Chipid,
            lastItem.Trainer,
            daysStr,
          ]
        );

        reportData.push({
          header: horseArr[i].name,
          data: dataArr,
        });

        this.setState({
          reportDataArray: reportData,
          headerTitle: window.lang.farrierHorse,
        });
        window.Spinner.hide();
      });
    }
  };

  createFarrierHorseTypeReport = () => {
    let horseArr = [
      ...window.DataStorage.myHorseArray,
      ...window.DataStorage.notMyHorseArray,
    ];
    let reportData = [];
    for (let i = 0; i < horseArr.length; i++) {
      if (!this.getPermission(horseArr[i].id)) continue;

      window.db.getFarrierHorse(horseArr[i]["id"], (rows) => {
        if (rows.length == 0) {
          return;
        }
        let lastItem = rows.item(0);
        let dataArr = [];
        let days = Date.daysBetween(
          new Date(),
          Date.getNewDate(lastItem["starttime"])
        );
        var daysStr =
          days < 7
            ? days + " " + window.lang.days
            : (days / 7).toFixed(1) + " " + window.lang.week;
        dataArr.push(
          [
            window.lang.type,
            window.lang.name,
            "Reg. nr",
            "Chipid",
            window.lang.trainerName,
            window.lang.shoeDaysSinceLast,
          ],
          [
            lastItem.HorseType == null ? "None" : lastItem.HorseType,
            lastItem.Horse,
            lastItem.RFBid,
            lastItem.Chipid,
            lastItem.Trainer,
            daysStr,
          ]
        );

        reportData.push({
          header: horseArr[i].name,
          data: dataArr,
        });

        this.setState({
          reportDataArray: reportData,
          headerTitle: window.lang.farrierHorseType,
        });
        window.Spinner.hide();
      });
    }
  };

  createVetHorseReport = () => {
    let horseArr = [
      ...window.DataStorage.myHorseArray,
      ...window.DataStorage.notMyHorseArray,
    ];
    let reportData = [];
    for (let i = 0; i < horseArr.length; i++) {
      if (!this.getPermission(horseArr[i].id)) continue;

      window.db.getVetHorse(horseArr[i]["id"], (data) => {
        if (data.length == 0) {
          return;
        }
        let dataArr = [];
        dataArr.push([
          window.lang.name,
          window.lang.type,
          "Reg. nr",
          "Chipid",
          "VET Type",
          window.lang.remark,
        ]);
        for (let j = 0; j < data.length; j++) {
          let VetType = "";
          for (let k in window.VeterinarPage.VeterinarType) {
            if (
              data[j].VeterinarianType ==
              window.VeterinarPage.VeterinarType[k].value
            )
              VetType = window.VeterinarPage.VeterinarType[k].Name;
          }
          dataArr.push([
            data[j].Name,
            data[j].HorseType == null ? "None" : data[j].HorseType,
            data[j].RFBid,
            data[j].Chipid,
            VetType,
            data[j].remark,
          ]);
        }
        reportData.push({
          header: data[0].Name,
          data: dataArr,
        });
        this.setState({
          reportDataArray: reportData,
          headerTitle: window.lang.vetHorse,
        });
        window.Spinner.hide();
      });
    }
  };

  createVetHorseTypeReport = () => {
    let horseArr = [
      ...window.DataStorage.myHorseArray,
      ...window.DataStorage.notMyHorseArray,
    ];
    let reportData = [];
    for (let i = 0; i < horseArr.length; i++) {
      if (!this.getPermission(horseArr[i].id)) continue;

      window.db.getVetHorse(horseArr[i]["id"], (data) => {
        if (data.length == 0) {
          return;
        }
        let dataArr = [];
        dataArr.push([
          window.lang.type,
          window.lang.name,
          "Reg. nr",
          "Chipid",
          "VET Type",
          window.lang.remark,
        ]);
        for (let j = 0; j < data.length; j++) {
          let VetType = "";
          for (let k in window.VeterinarPage.VeterinarType) {
            if (
              data[j].VeterinarianType ==
              window.VeterinarPage.VeterinarType[k].value
            )
              VetType = window.VeterinarPage.VeterinarType[k].Name;
          }
          dataArr.push([
            data[j].HorseType == null ? "None" : data[j].HorseType,
            data[j].Name,
            data[j].RFBid,
            data[j].Chipid,
            VetType,
            data[j].remark,
          ]);
        }
        reportData.push({
          header: data[0].Name,
          data: dataArr,
        });
        this.setState({
          reportDataArray: reportData,
          headerTitle: window.lang.vetHorseType,
        });
        window.Spinner.hide();
      });
    }
  };

  getStableList = () => {
    let boxes = window.DataStorage.boxesAndStables;
    if (window.inUseStables == "Y") {
      let userId = window.myid;
      let resultboxes = boxes
        .filter((b) => b.IDBox != null && b.InUse != "Y" && b.UserId == userId)
        .map((b) => ({ key: b.IDBox, value: b.StableName + " " + b.BoxName }));
      return resultboxes;
    } else {
      let userId = window.myid;
      let stables = boxes
        .filter((b) => b.UserId == userId)
        .sort((a, b) => a.StableOrderBy - b.StableOrderBy)
        .map((b) => ({ key: b.IdStable, value: b.StableName }));

      let allStables = [];
      for (let i = 0; i < stables.length; i++) {
        if (allStables.find((s) => s.key == stables[i].key) == null) {
          allStables.push(stables[i]);
        }
      }
      return allStables;
    }
  };

  createPriceReport = () => {
    window.db.getPriceReportData(
      window.myid,
      (data) => {
        window.db.getAllOwnersByUser((owners) => {
          let ids = [];
          for (let i = 0; i < data.length; i++) {
            let item = data[i];
            if (item.LabelIds != null && item.LabelIds.length != 0) {
              let a = JSON.parse(item.LabelIds);
              ids.push(...a);
            }
          }
          window.db.getLabelsByIds(ids, (labelsData) => {
            console.log(labelsData);
            const genderArr = window.DataStorage.getHorseGenderArr();
            const stableArr = this.getStableList();

            let horsesArr = [];
            for (let i = 0; i < data.length; i++) {
              let item = data[i];
              let costPriceActual = "";
              let owner = "";
              if (item.PotentialDate) {
                let parseDay = this.parseDateStartDay(
                  item.PotentialDate
                ).formatDesh();
                let monthDifference =
                  new Date(parseDay).getMonth() -
                  new Date().getMonth() +
                  12 *
                    (new Date(parseDay).getFullYear() -
                      new Date().getFullYear());
                if (monthDifference < 0) monthDifference = 0;
                costPriceActual =
                  item.PurchasePrice +
                  item.CostPriceAccumulated +
                  monthDifference * item.Price;
              }

              if (item.PaperOwner != null && item.PaperOwner != "") {
                item.PaperOwner = JSON.parse(item.PaperOwner);
                let ownerName = "";
                for (let j in item.PaperOwner) {
                  let ownerItem = owners.filter(
                    (k) => k.Id == item.PaperOwner[j]
                  );
                  if (j == 0) ownerItem.map((l) => (ownerName += l.Name));
                  else ownerItem.map((l) => (ownerName += "\n" + l.Name));
                }
                owner = ownerName;
              } else owner = "";
              const priceActual =
                item.PurchasePrice + item.CostPriceAccumulated;
              const stableItem = stableArr.find((s) => s.key == item.StableId);

              let pedigree = "";
              if (item.PedigreeJsonText) {
                const p = JSON.parse(item.PedigreeJsonText);
                pedigree =
                  p.mather +
                  "/" +
                  p.grmather_father +
                  "/" +
                  p.grmather_grfather_f;
              }

              let labels = "";
              if (item.LabelIds != null && item.LabelIds.length != 0) {
                let al = JSON.parse(item.LabelIds);
                labels = al
                  .map((lid) => {
                    let lItem = labelsData.find((l) => l.Id == lid);
                    if (lItem) return lItem.Label;
                    return "";
                  })
                  .join();
              }

              horsesArr.push({
                Id: item.Id,
                Name: item.Name,
                RFBid: item.RFBid,
                Type: item.HorseType,
                CreateDate: item.CreateDate,
                Owner: owner,
                BirthYear: item.BirthYear,
                Indkøbspris: item.PurchasePrice,
                Moms: item.VAT,
                VurderingAktuelt: item.CurrentAssessment,
                Vurderingsdato: item.ValuationDate,
                PotentialVurdering: item.PotentialAssessment,
                PotentielDato: item.PotentialDate,
                Bemærkning: item.Remark,
                CostPriceAccumulated: item.CostPriceAccumulated,
                KostprisAktuel: priceActual,
                KostprisFremskreven: costPriceActual,
                SellingPrice: item.SellingPrice,
                Historisk: item.Historik,
                Chipid: item.Chipid,
                Height: item.Height,
                Gender: genderArr[item.Gender],
                ColorName: item.ColorName,
                StableName: stableItem ? stableItem.value : "",
                TrainerName: item.TrainerName,
                DepartureDate: item.DepartureDate,
                Pedigree: pedigree,
                Labels: labels,
              });
            }

            let dataArr = [];
            dataArr.push([
              window.lang.name,
              "Reg. nr",
              "Chip nr.",
              "Afstamning",
              window.lang.height,
              "Labels",
              window.lang.gender,
              window.lang.color,
              window.lang.stable,
              window.lang.horseFollowsRider,
              window.lang.type,
              window.lang.arrivDate,
              window.lang.departure,
              window.lang.owner,
              window.lang.bornIn,
              "Moms",
              "Indkøbspris",
              "Akkumuleret kostpris",
              "Kostpris aktuel",
              "Vurderingsdato",
              "Vurdering aktuelt",
              "Potentiel dato",
              "Potential vurdering",
              "Kostpris fremskreven",
              "Bemærkning",
              "Salgspris",
              "Historisk",
            ]);
            for (var q = 0; q < horsesArr.length; q++) {
              if (!this.getPermission(horsesArr[q].Id)) continue;
              dataArr.push([
                horsesArr[q].Name,
                horsesArr[q].RFBid,
                horsesArr[q].Chipid,
                horsesArr[q].Pedigree,
                horsesArr[q].Height,
                horsesArr[q].Labels,
                horsesArr[q].Gender,
                horsesArr[q].ColorName,
                horsesArr[q].StableName,
                horsesArr[q].TrainerName,
                horsesArr[q].Type == null ? "None" : horsesArr[q].Type,
                horsesArr[q].CreateDate == "01-01-1900"
                  ? ""
                  : horsesArr[q].CreateDate,
                horsesArr[q].DepartureDate == "01-01-1900"
                  ? ""
                  : horsesArr[q].DepartureDate,
                horsesArr[q].Owner,
                horsesArr[q].BirthYear,
                horsesArr[q].Moms ? horsesArr[q].Moms : "",
                horsesArr[q].Indkøbspris
                  ? horsesArr[q].Indkøbspris.toString().replace(
                      /\B(?=(?:\d{3})+(?!\d))/g,
                      "."
                    )
                  : "",
                horsesArr[q].CostPriceAccumulated
                  ? horsesArr[q].CostPriceAccumulated.toString().replace(
                      /\B(?=(?:\d{3})+(?!\d))/g,
                      "."
                    )
                  : "",
                horsesArr[q].KostprisAktuel
                  ? horsesArr[q].KostprisAktuel.toString().replace(
                      /\B(?=(?:\d{3})+(?!\d))/g,
                      "."
                    )
                  : "",
                horsesArr[q].Vurderingsdato ? horsesArr[q].Vurderingsdato : "",
                horsesArr[q].VurderingAktuelt
                  ? horsesArr[q].VurderingAktuelt.toString().replace(
                      /\B(?=(?:\d{3})+(?!\d))/g,
                      "."
                    )
                  : "",
                horsesArr[q].PotentielDato ? horsesArr[q].PotentielDato : "",
                horsesArr[q].PotentialVurdering
                  ? horsesArr[q].PotentialVurdering.toString().replace(
                      /\B(?=(?:\d{3})+(?!\d))/g,
                      "."
                    )
                  : "",
                horsesArr[q].KostprisFremskreven
                  ? horsesArr[q].KostprisFremskreven.toString().replace(
                      /\B(?=(?:\d{3})+(?!\d))/g,
                      "."
                    )
                  : "",
                horsesArr[q].Bemærkning ? horsesArr[q].Bemærkning : "",
                horsesArr[q].SellingPrice
                  ? horsesArr[q].SellingPrice.toString().replace(
                      /\B(?=(?:\d{3})+(?!\d))/g,
                      "."
                    )
                  : "",
                horsesArr[q].Historisk ? horsesArr[q].Historisk : "",
              ]);
            }
            this.setState({
              reportDataArray: [
                {
                  header: "",
                  data: dataArr,
                },
              ],
              headerTitle: window.lang.priceFirstDefaultNote,
            });
            window.Spinner.hide();
          });
        });
      },
      this.isHistoricHorses
    );
  };

  parseDateStartDay(dateStr) {
    if (dateStr == null || dateStr == "") return new Date("1900-01-01");

    let arr = dateStr.split("-");
    return new Date(`${arr[2]}-${arr[1]}-${arr[0]}`);
  }

  //createPriceReport = () => {
  //    window.db.getPriceReportData(window.myid, (hdata1) => {
  //        window.db.getPriceSharedHorsesReportData(window.myid, (hdata2) => {
  //            var horses = hdata1.concat(hdata2);
  //            let horsesArr = [];
  //            horses.filter(item => {
  //                let curr = horsesArr.find(i => item.Id == i.Id);

  //                if (!curr) {
  //                    horsesArr.push({
  //                        Id: item.Id, Name: item.Name, RFBid: item.RFBid, Type: item.HorseType, CreateDate: item.CreateDate, DepartureDate: item.DepartureDate,
  //                        Indkøbspris: '', MedMoms: '', VurderingAktuelt: '', VurderingPotentielt: '', Salgspris: '', Kostpris1: '', Kostpris2: '', Kostpris3: '',
  //                        TextNote: item.HeaderNote == 'Cost price' ? item.TextNote : ''
  //                    });
  //                    return true;
  //                } else {
  //                    if (item.HeaderNote == 'Cost price')
  //                        curr.TextNote = item.TextNote;
  //                    return false;
  //                }
  //            });

  //            for (let i in horsesArr) {
  //                let noteData = this.readDataFromNote(horsesArr[i].TextNote);
  //                horsesArr[i].Indkøbspris = noteData[0];
  //                horsesArr[i].MedMoms = noteData[1];
  //                horsesArr[i].VurderingAktuelt = noteData[2];
  //                horsesArr[i].VurderingPotentielt = noteData[3];
  //                horsesArr[i].Salgspris = noteData[4];
  //                horsesArr[i].Kostpris1 = noteData[5];
  //                horsesArr[i].Kostpris2 = noteData[6];
  //                horsesArr[i].Kostpris3 = noteData[7];
  //            }
  //            let dataArr = [];
  //            dataArr.push([window.lang.name, 'Reg. nr', window.lang.type, window.lang.arrivDate, window.lang.departure, 'Indkøbspris', 'Med moms', 'Vurdering aktuelt',
  //                'Vurdering Potentielt', 'Salgspris', 'Kostpris 1', 'Kostpris 2', 'Kostpris 3']);
  //            for (var q = 0; q < horsesArr.length; q++) {
  //                if (!this.getPermission(horsesArr[q].Id))
  //                    continue;
  //                dataArr.push([horsesArr[q].Name, horsesArr[q].RFBid, horsesArr[q].Type == null ? 'None' : horsesArr[q].Type, horsesArr[q].CreateDate, horsesArr[q].DepartureDate, horsesArr[q].Indkøbspris,
  //                horsesArr[q].MedMoms, horsesArr[q].VurderingAktuelt, horsesArr[q].VurderingPotentielt, horsesArr[q].Salgspris, horsesArr[q].Kostpris1, horsesArr[q].Kostpris2, horsesArr[q].Kostpris3]);
  //            }

  //            this.setState({
  //                reportDataArray: [
  //                    {
  //                        header: '',
  //                        data: dataArr
  //                    }
  //                ],
  //                headerTitle: window.lang.priceFirstDefaultNote
  //            });
  //            window.Spinner.hide();
  //        });
  //    });
  //}

  readDataFromNote = (textNote) => {
    let purchasePrice = "";
    let withVAT = "";
    let currentAssessment = "";
    let assessmentPotential = "";
    let salePrice = "";
    let costPrice1 = "";
    let costPrice2 = "";
    let costPrice3 = "";

    if (textNote != null) {
      let indexOfPurchasePrice = textNote.lastIndexOf(this.purchasePrice);
      let indexOfWithVAT = textNote.lastIndexOf(this.withVAT);
      let indexOfCurrentAssessment = textNote.lastIndexOf(
        this.currentAssessment
      );
      let indexOfAssessmentPotential = textNote.lastIndexOf(
        this.assessmentPotential
      );
      let indexOfSalePrice = textNote.lastIndexOf(this.salePrice);
      let indexOfCostPrice1 = textNote.lastIndexOf(this.costPrice1);
      let indexOfCostPrice2 = textNote.lastIndexOf(this.costPrice2);
      let indexOfCostPrice3 = textNote.lastIndexOf(this.costPrice3);

      let dataArr = [
        { index: indexOfPurchasePrice, label: this.purchasePrice },
        { index: indexOfWithVAT, label: this.withVAT },
        { index: indexOfCurrentAssessment, label: this.currentAssessment },
        { index: indexOfAssessmentPotential, label: this.assessmentPotential },
        { index: indexOfSalePrice, label: this.salePrice },
        { index: indexOfCostPrice1, label: this.costPrice1 },
        { index: indexOfCostPrice2, label: this.costPrice2 },
        { index: indexOfCostPrice3, label: this.costPrice3 },
      ];
      dataArr = dataArr.sort(this.sortIndex);
      dataArr = dataArr.filter((i) => i.index != "-1");

      for (let i = 0; i < dataArr.length; i++) {
        let end =
          i == dataArr.length - 1 ? textNote.length : dataArr[i + 1].index;
        if (dataArr[i].label == this.purchasePrice) {
          let start = dataArr[i].index + this.purchasePriceLength;
          purchasePrice = textNote.slice(start, end);
        } else if (dataArr[i].label == this.withVAT) {
          let start = dataArr[i].index + this.withVATLength;
          withVAT = textNote.slice(start, end);
        } else if (dataArr[i].label == this.currentAssessment) {
          let start = dataArr[i].index + this.currentAssessmentLength;
          currentAssessment = textNote.slice(start, end);
        } else if (dataArr[i].label == this.assessmentPotential) {
          let start = dataArr[i].index + this.assessmentPotentialLength;
          assessmentPotential = textNote.slice(start, end);
        } else if (dataArr[i].label == this.salePrice) {
          let start = dataArr[i].index + this.salePriceLength;
          salePrice = textNote.slice(start, end);
        } else if (dataArr[i].label == this.costPrice1) {
          let start = dataArr[i].index + this.costPrice1Length;
          costPrice1 = textNote.slice(start, end);
        } else if (dataArr[i].label == this.costPrice2) {
          let start = dataArr[i].index + this.costPrice2Length;
          costPrice2 = textNote.slice(start, end);
        } else if (dataArr[i].label == this.costPrice3) {
          let start = dataArr[i].index + this.costPrice3Length;
          costPrice3 = textNote.slice(start, end);
        }
      }
    }
    return [
      purchasePrice,
      withVAT,
      currentAssessment,
      assessmentPotential,
      salePrice,
      costPrice1,
      costPrice2,
      costPrice3,
    ];
  };

  sortIndex = (a, b) => {
    let indexA = a.index;
    let indexB = b.index;
    let comparison = 0;
    if (indexA > indexB) {
      comparison = 1;
    } else if (indexA < indexB) {
      comparison = -1;
    }
    return comparison;
  };

  createAllHorsesAllDataReport = () => {
    window.db.getAllHorsesData(
      window.myid,
      (hdata1) => {
        window.db.getAllSharedHorsesData(
          window.myid,
          (hdata2) => {
            window.db.getReportHorseNotes(
              window.myid,
              false,
              (notes) => {
                window.db.getReportHorseNotes(
                  window.myid,
                  true,
                  (salesNotes) => {
                    var horses = hdata1.concat(hdata2);
                    let dataArray = [];
                    let horsesArr = [];
                    let tableHeader = [
                      [
                        window.lang.name,
                        window.lang.bornIn,
                        "Reg. nr",
                        "Chipid",
                        window.lang.arrivDate,
                        window.lang.departure,
                        window.lang.height,
                        window.lang.stable,
                        window.lang.gender,
                        window.lang.type,
                        window.lang.color,
                        window.lang.horseFollowsRider,
                        window.lang.notes,
                        window.lang.priceSecondDefaultNote,
                        "Has Image",
                      ],
                    ];
                    for (let i in horses) {
                      if (!this.getPermission(horses[i].Id)) continue;
                      horsesArr.push(horses[i]);
                      horsesArr[i].SalesNotes = "";
                      horsesArr[i].HasImages = "";
                    }

                    notes.filter((item) => {
                      let curr = horsesArr.find((i) => item.IdHorse == i.Id);

                      if (!curr) {
                        return true;
                      } else {
                        let noteArr = curr.Notes
                          ? [curr.Notes, <br key={Math.uuid()} />]
                          : [curr.Notes];
                        noteArr.push([
                          <span key={Math.uuid()}>
                            <b>{item.HeaderNote}: </b>
                            {item.TextNote}
                          </span>,
                        ]);
                        curr.Notes = noteArr;
                        return false;
                      }
                    });

                    salesNotes.filter((item) => {
                      let curr = horsesArr.find((i) => item.IdHorse == i.Id);

                      if (!curr) {
                        return true;
                      } else {
                        let noteArr = curr.SalesNotes
                          ? [curr.SalesNotes, <br key={Math.uuid()} />]
                          : [curr.SalesNotes];
                        noteArr.push([
                          <span key={Math.uuid()}>
                            <b>{item.HeaderNote}: </b>
                            {item.TextNote}
                          </span>,
                        ]);
                        curr.SalesNotes = noteArr;
                        return false;
                      }
                    });

                    for (let i = 0; i < horsesArr.length; i++) {
                      window.Spinner.show();
                      let multimedia = new MultimediaHelper(
                        horsesArr[i].Id,
                        horsesArr[i].UserId
                      );
                      multimedia.hasImages((hasImage) => {
                        horsesArr[i].HasImages = hasImage;
                        let dArray = [];
                        for (let j = 1; j < dataArray.length; j++)
                          dArray.push(dataArray[j]);

                        dArray.push([
                          horsesArr[i].Name,
                          horsesArr[i].BirthYear,
                          horsesArr[i].RFBid,
                          horsesArr[i].Chipid,
                          horsesArr[i].CreateDate == "01-01-1900"
                            ? ""
                            : horsesArr[i].CreateDate,
                          horsesArr[i].DepartureDate == "01-01-1900"
                            ? ""
                            : horsesArr[i].DepartureDate,
                          horsesArr[i].Height,
                          horsesArr[i].StableName,
                          this.setGender(horsesArr[i].Gender),
                          horsesArr[i].HorseType == null
                            ? "None"
                            : horsesArr[i].HorseType,
                          horsesArr[i].Color,
                          horsesArr[i].Trainer,
                          horsesArr[i].Notes,
                          horsesArr[i].SalesNotes,
                          horsesArr[i].HasImages
                            ? '<img loading="lazy"src="img/photo_icon.png" style="height: 40px">'
                            : "",
                        ]);

                        dArray = dArray.sort(this.sortAllDataArr);
                        dataArray = [...tableHeader, ...dArray];
                        this.setState(
                          {
                            reportDataArray: [
                              {
                                header: "",
                                data: dataArray,
                              },
                            ],
                            headerTitle: "All horses all data",
                          },
                          window.Spinner.hide()
                        );
                      });
                    }
                  },
                  this.isHistoricHorses
                );
              },
              this.isHistoricHorses
            );
          },
          this.isHistoricHorses
        );
      },
      this.isHistoricHorses
    );
  };

  createThisHorseWithRemarksAndWorkoutsReport = () => {
    window.db.getTrainingsByHorseWithRemarks(
      this.state.horse.id,
      (trainData) => {
        if (!this.getPermission(this.state.horse.id))
          var tableArr = [
            [
              "Date",
              window.lang.type,
              window.lang.evaluation,
              window.lang.riders,
              window.lang.notes,
            ],
          ];
        else
          var tableArr = [
            [
              "Date",
              window.lang.type,
              window.lang.evaluation,
              window.lang.riders,
              window.lang.notes,
            ],
            ...trainData.map((t) => [
              Date.reformatToDMY(t.starttime),
              t.name,
              this.getSmileyForItem(t.Evaluation),
              t.trainername,
              t.remark,
            ]),
          ];
        this.setState({
          reportDataArray: [
            {
              header: "",
              data: tableArr,
            },
          ],
          headerTitle: this.state.horse.name,
        });
        window.Spinner.hide();
      }
    );
  };

  createThisHorseStableBoxHistoryLogReport = () => {
    window.db.getStableBoxHistoryLogReport(
      this.state.horse.id,
      (reportData) => {
        if (!this.getPermission(this.state.horse.id))
          var tableArr = [
            ["Date", "Old Stable", "New Stable", "Old Box", "New Box"],
          ];
        else
          var tableArr = [
            ["Date", "Old Stable", "New Stable", "Old Box", "New Box"],
            ...reportData.map((t) => [
              new Date(reportData[0].Time).formatDeshStartDay(),
              t.oldStable ?? "",
              t.newStable ?? "",
              t.oldBox ?? "",
              t.newBox ?? "",
            ]),
          ];
        this.setState({
          reportDataArray: [
            {
              header: "",
              data: tableArr,
            },
          ],
          headerTitle: this.state.horse.name,
        });
        window.Spinner.hide();
      }
    );
  };

  sortAllDataArr = (a, b) => {
    if (a[0] < b[0]) return -1;
    if (a[0] > b[0]) return 1;
    return 0;
  };

  onExitClick = () => {
    if (window.HomePage.isReportClicked) {
      new window.HomePage().show();
    } else {
      window.onBack();
    }
  };

  onMakePdfClick = () => {
    window.Spinner.show();
    window.DataGate.makePdf(
      this.state.reportDataArray,
      this.state.headerTitle,
      function (data, url) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.target = "_blank";
        a.download = "Report.pdf";
        document.body.appendChild(a);
        a.click();
        window.Spinner.hide();
      }
    );
  };

  onMakeXlsxClick = () => {
    window.Spinner.show();
    window.DataGate.makeXlsx(
      this.state.reportDataArray,
      this.state.headerTitle,
      function (data, url) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "Report.xlsx";
        document.body.appendChild(a);
        a.click();
        window.Spinner.hide();
      }
    );
  };

  getCellContent = (t) => {
    if (t != null && t.indexOf("<img") >= 0) {
      return <div dangerouslySetInnerHTML={{ __html: t }} />;
    }
    return t;
  };

  onExcelFileSelect = (e) => {
    if (e.target.files.length) {
      let reader = new FileReader();
      reader.onload = (e) => {
        let wb = XLSX.read(e.target.result, { type: "binary" });
        let wsname = wb.SheetNames[0];
        let ws = wb.Sheets[wsname];
        let data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        data = data.filter((i) => i.length > 2);
        let regNr = "";
        let titleArrIndex = "";
        let costPrice1Index = "";
        let costPrice2Index = "";
        let costPrice3Index = "";
        let horsePriceArr = [];
        for (let i in data) {
          if (data[i].indexOf("Reg. nr") != -1) {
            regNr = data[i].indexOf("Reg. nr");
            if (
              data[i].indexOf(
                this.costPrice1.substring(0, this.costPrice1.length - 1)
              ) != -1
            ) {
              costPrice1Index = data[i].indexOf(
                this.costPrice1.substring(0, this.costPrice1.length - 1)
              );
              titleArrIndex = parseInt(i);
            }
            if (
              data[i].indexOf(
                this.costPrice2.substring(0, this.costPrice2.length - 1)
              ) != -1
            ) {
              costPrice2Index = data[i].indexOf(
                this.costPrice2.substring(0, this.costPrice2.length - 1)
              );
            }
            if (
              data[i].indexOf(
                this.costPrice3.substring(0, this.costPrice3.length - 1)
              ) != -1
            ) {
              costPrice3Index = data[i].indexOf(
                this.costPrice3.substring(0, this.costPrice3.length - 1)
              );
            }
          }
        }
        if (
          regNr ||
          (regNr == 0 && titleArrIndex) ||
          (titleArrIndex == 0 && costPrice1Index) ||
          costPrice1Index == 0
        ) {
          for (let i = titleArrIndex + 1; i < data.length; i++) {
            if (data[i][regNr] || data[i][regNr] === 0) {
              horsePriceArr.push(data[i]);
            }
          }
        }
        this.updatePriceNote(
          horsePriceArr,
          regNr,
          costPrice1Index,
          costPrice2Index,
          costPrice3Index
        );
      };
      reader.readAsBinaryString(e.target.files[e.target.files.length - 1]);
    }
  };

  updatePriceNote = (
    dataArr,
    regNr,
    costPrice1Index,
    costPrice2Index,
    costPrice3Index
  ) => {
    for (let i in dataArr) {
      window.db.getPrisNote(dataArr[i][regNr], (data) => {
        if (data.length) {
          let noteData = this.readDataFromNote(data[0].TextNote);
          window.Spinner.show();
          let noteText = "";
          if (
            !noteData[0] &&
            !noteData[1] &&
            !noteData[2] &&
            !noteData[3] &&
            !noteData[4] &&
            !noteData[5] &&
            !noteData[6] &&
            !noteData[7]
          )
            noteText += data[0].TextNote + "\n";

          let costPrice1 =
            dataArr[i][costPrice1Index] == undefined
              ? ""
              : dataArr[i][costPrice1Index];
          let costPrice2 =
            dataArr[i][costPrice2Index] == undefined
              ? ""
              : dataArr[i][costPrice2Index];
          let costPrice3 =
            dataArr[i][costPrice3Index] == undefined
              ? ""
              : dataArr[i][costPrice3Index];
          noteText +=
            this.purchasePrice +
            noteData[0].toString().replace(/\n/g, "") +
            "\n" +
            this.withVAT +
            noteData[1].toString().replace(/\n/g, "") +
            "\n" +
            this.currentAssessment +
            noteData[2].toString().replace(/\n/g, "") +
            "\n" +
            this.assessmentPotential +
            noteData[3].toString().replace(/\n/g, "") +
            "\n" +
            this.salePrice +
            noteData[4].toString().replace(/\n/g, "") +
            "\n" +
            this.costPrice1 +
            " " +
            costPrice1.toString().replace(/\n/g, "") +
            "\n" +
            this.costPrice2 +
            " " +
            costPrice2.toString().replace(/\n/g, "") +
            "\n" +
            this.costPrice3 +
            " " +
            costPrice3.toString().replace(/\n/g, "");

          window.db.updateNoteHorse(data[0].Id, noteText, () => {
            window.Spinner.hide();
            this.createPriceReport();
          });
        } else {
          let horseId = window.DataStorage.getAllHorses().filter(
            (j) => j.rfbid == dataArr[i][regNr]
          )[0].id;
          let costPrice1 =
            dataArr[i][costPrice1Index] == undefined
              ? ""
              : dataArr[i][costPrice1Index];
          let costPrice2 =
            dataArr[i][costPrice2Index] == undefined
              ? ""
              : dataArr[i][costPrice2Index];
          let costPrice3 =
            dataArr[i][costPrice3Index] == undefined
              ? ""
              : dataArr[i][costPrice3Index];
          let textNote =
            this.purchasePrice +
            "\n" +
            this.withVAT +
            "\n" +
            this.currentAssessment +
            "\n" +
            this.assessmentPotential +
            "\n" +
            this.salePrice +
            "\n" +
            this.costPrice1 +
            " " +
            costPrice1.toString().replace(/\n/g, "") +
            "\n" +
            this.costPrice2 +
            " " +
            costPrice2.toString().replace(/\n/g, "") +
            "\n" +
            this.costPrice3 +
            " " +
            costPrice3.toString().replace(/\n/g, "");
          this.addNewPriceNote(horseId, textNote);
        }
      });
    }
  };

  addNewPriceNote = (horseId, textNote) => {
    let noteNew = {
      Id: Math.uuid(),
      HeaderNote: "Cost price",
      IdHorse: horseId,
      IsPrice: 1,
      OrderBy: Math.round(new Date().getTime() / 1000),
      TextNote: textNote,
    };
    window.Spinner.show();
    window.db.insertPriceNote(
      noteNew.Id,
      noteNew.IdHorse,
      noteNew.HeaderNote,
      noteNew.IsPrice,
      noteNew.TextNote,
      noteNew.OrderBy,
      () => {
        window.Spinner.hide();
        this.createPriceReport();
      }
    );
  };

  render() {
    const { farVetSelector, reportDataArray, headerTitle } = this.state;
    let isMobile = window.isMobileDevice();
    return (
      <div>
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={this.onExitClick}
          />
          <span className="ppTitle">{headerTitle}</span>
          <img
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onExitClick}
          />
        </div>
        <div data-role="content">
          <div className={isMobile ? "pageScrollable" : ""}>
            <table>
              <tbody>
                <tr key={Math.uuid()}>
                  <td key={Math.uuid()}>
                    <label style={{ marginRight: "20px" }}>
                      {window.lang.report}
                    </label>
                  </td>
                  <td key={Math.uuid()}>
                    {farVetSelector.length ? (
                      <select
                        id="showFarVetSelect"
                        style={{ float: "right" }}
                        data-role="none"
                        value={this.state.selectedValue}
                        onChange={this.onFarVetSelectorChange}
                      >
                        {farVetSelector.map((item) => (
                          <option
                            key={item.value}
                            value={item.value}
                            style={{
                              display: item.isVisible ? "inherit" : "none",
                            }}
                          >
                            {item.label}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td key={Math.uuid()}>
                    <img
                      id="makePDFButton0"
                      className="reportButton"
                      src="img/pdf_download.png"
                      onClick={this.onMakePdfClick}
                    />
                  </td>
                  <td key={Math.uuid()}>
                    <img
                      id="makeXlsxButton0"
                      className="reportButton"
                      src="img/excel_download.png"
                      onClick={this.onMakeXlsxClick}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div id="reportTableReact">
              {reportDataArray.length ? (
                reportDataArray.map((tableObj) => (
                  <div key={Math.uuid()}>
                    <br />
                    <div key={Math.uuid()}>{tableObj.header}</div>
                    <br />
                    <table className="reportTable">
                      <tbody>
                        {tableObj.data.map((row, rowIndex) => (
                          <tr key={Math.uuid()}>
                            {rowIndex == 0
                              ? row.map((h) => <th key={Math.uuid()}>{h}</th>)
                              : row.map((h) => (
                                  <td key={Math.uuid()}>
                                    <div className="centered">
                                      {this.getCellContent(h)}
                                    </div>
                                  </td>
                                ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
          <div id="vetFarContainer0" style={{ marginTop: "15px" }}></div>
        </div>
      </div>
    );
  }
}

export default class Report {
  createReport(horseId) {
    ReactDOM.render(
      <ReportsPage horseId={horseId} date={new Date()} />,
      document.getElementById("reportsPageReact")
    );
  }
}
