import React, { Component } from 'react';
import HorseCell from "../HorseCell/HorseCell";

export default class TimeSinceLastHA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            horseDataArr: []
        }
    }

    componentDidMount = () => {
        this.getLastDatesForTrainingTypes();
    }

    getLastDatesForTrainingTypes = () => {
        let haTypes = window.DataStorage.trainTypes.filter(t => t.shortname == 'HA').map(t => t.id);
        window.DataGate.getLastDatesForTrainingTypes(window.myid, haTypes, (code, data) => {
            if (code == window.DataGate.CODE_OK) {
                this.makeHorseArr(data);
            }
        });
    }

    makeHorseArr = (data) => {
        let horseArr = [];
        this.props.filteredHorses.map(i => (
            horseArr.push({ horse: i, date: '' })
        ));
        data.filter(item => {
            let curr = horseArr.filter(i => item.HorseId == i.horse.id);
            if (!curr) {
                return true;
            } else {
                curr[0].date = item.Date;
                return false;
            }
        });
        horseArr.filter(i => {
            if (i.date) {
                i.date = Math.ceil((new Date(this.props.date) - new Date(i.date)) / 1000 / 60 / 60 / 24);
                return false;
            }
        });
        horseArr.sort((a, b) => {
            if (a.date == b.date) return 0;
            return a.date < b.date ? 1 : -1;
        });
        this.setState({
            horseDataArr: horseArr
        });
    }

    render() {
        const { filteredHorses } = this.props;
        const { horseDataArr } = this.state;
        let widthFC = 0;
        if (window.GlobalData.getItem(window.GlobalData.SHOW_HORSELOGO) == 1) {
            widthFC = window.isMobileDevice() ? 150 : 200;
        } else {
            widthFC = 200;
        }
        let widthCol2 = 200;
        let tableWidth = widthFC + widthCol2;

        return (
            <div style={{ height: 'auto', maxHeight: 'calc(100vh - 180px)', overflowY: 'auto' }}>
                <table className="lastHaTable" style={{ width: tableWidth }}>
                    <thead>
                        <tr>
                            <td style={{ width: widthFC }}>
                                {window.lang.horse}
                            </td>
                            <td>
                                {window.lang.days}
                            </td>
                        </tr>
                    </thead>
                    <tbody style={{ textAlign: "center" }}>
                        {horseDataArr.length ?
                            horseDataArr.map(i =>
                                <tr key={Math.uuid()}>
                                    <td key={i.horse.id} style={{ textAlign: "left" }}>
                                        <HorseCell horse={i.horse} />
                                    </td>
                                    <td key={Math.uuid()}>
                                        {i.date}
                                    </td>
                                </tr>)
                            :
                            filteredHorses.length ?
                                filteredHorses.map(i =>
                                    <tr key={Math.uuid()}>
                                        <td key={i.id} style={{ textAlign: "left" }}>
                                            <HorseCell horse={i} />
                                        </td>
                                        <td key={Math.uuid()}>
                                        </td>
                                    </tr>)
                                :
                                <></>
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}