import React, { Component } from "react";
import ReactDOM from "react-dom";

class LogHorsePricePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logData: "",
      horse: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props != prevProps) {
      this.loadData();
    }
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    const { horseId } = this.props;
    const horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == horseId
    );
    window.db.getLogPriceByHorse(this.props.horseId, (data) => {
      if (data.length) {
        let logData = [];
        for (let i in data) {
          let d = new Date();
          d.setTime(Date.parse(data[i]["DateTime"]));
          logData.push({
            Date: d.toLocaleString(),
            Email: data[i]["Email"] ? data[i]["Email"] : "Updated by server",
            LogData: data[i]["LogData"],
          });
        }
        this.setState({
          logData: logData,
          horse: horse,
        });
      } else
        this.setState({
          logData: data,
          horse: horse,
        });
    });
  };

  onOkClick = () => {
    window.showPage("#addHorsePage");
    if (!window.location.href.includes("?id="))
      window.location.href = window.location.href + "?id=" + this.props.horseId;
    window.Managers.extention.showHorsePrice(this.props.horseId);
  };

  render() {
    const { logData, horse } = this.state;
    return (
      <div className="pageScrollable">
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            loading="lazy"
            id="sharingBackButton"
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={this.onOkClick}
          />
          <span className="ppTitle">{horse.name}</span>
          <img
            loading="lazy"
            id="sharingOkButton"
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onOkClick}
          />
        </div>
        <div className="ppContent">
          {logData.length ? (
            <table className="farrierTable">
              <tbody>
                <tr>
                  <th style={{ width: "20%" }}>DateTime</th>
                  <th style={{ width: "20%" }}>User</th>
                  <th>Action</th>
                </tr>
                {logData.map((i) => (
                  <tr key={Math.uuid()}>
                    <td>{i.Date}</td>
                    <td>{i.Email}</td>
                    <td>{i.LogData}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}

export default class LogHorsePrice {
  showLogHorsePrice(horseId) {
    ReactDOM.render(
      <LogHorsePricePage horseId={horseId} />,
      document.getElementById("logHorsePricePageReact")
    );
  }
}
