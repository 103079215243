import React, { Component } from "react";
import "./VaccinationView.css";
import HerpesVaccinationPopup from "./HerpesVaccinationPopup";

class HerpesVaccinationView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vaccinationData: [],
      remainingHorses: [],
    };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props != prevProps) {
      this.loadData();
    }
  };

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    window.db.getHerpesVaccinationDataForUser(window.myid, (data) => {
      let vaccinationData = [];
      if (data.length) {
        let vaccination = [];
        data.filter((item) => {
          let curr = vaccination.find((i) => item.horseid == i.horseid);

          if (!curr) {
            vaccination.push(item);
            return true;
          } else {
            return false;
          }
        });

        for (let i in vaccination) {
          let item = vaccination[i];
          let type =
            window.DataStorage.herpesVaccinationTypes[item["vtype"] - 1];
          let nextType =
            window.DataStorage.herpesVaccinationTypes[type.NextIndex];
          let minMonths = nextType.MinMonth;
          let maxMonths = nextType.MaxMonth;
          let minDays = nextType.MinDay;
          let maxDays = nextType.MaxDay;
          let lastDate = Date.getNewDate(item["vdate"]);
          let minDate = new Date(lastDate);
          let maxDate = new Date(lastDate);

          minDate.setMonth(minDate.getMonth() + minMonths);
          minDate = minDate.addDays(minDays);
          maxDate.setMonth(maxDate.getMonth() + maxMonths);
          maxDate = maxDate.addDays(maxDays);
          let daysLeft = Date.daysBetween(maxDate, new Date());
          vaccinationData.push({
            id: vaccination[i].id,
            lastVaccination: lastDate.formatDesh(),
            nextVaccination: maxDate.formatDesh(),
            horse: vaccination[i].HorseName,
            daysLeft: daysLeft,
            type: type.Name,
            remarks: "",
            horseid: vaccination[i].horseid,
            vtype: type,
            HorseType: vaccination[i].HorseType,
            RFBid: vaccination[i].RFBid,
            Chipid: vaccination[i].Chipid,
            stable: vaccination[i].StableName ? vaccination[i].StableName : "",
          });
        }
      }
      let horses = window.DataStorage.getAllHorses();
      let remainingHorses = horses.filter(
        (e) => vaccinationData.findIndex((i) => e.id == i.horseid) === -1
      );
      remainingHorses = remainingHorses.sort((t1, t2) => {
        if (t1.name.toLowerCase() > t2.name.toLowerCase()) return 1;
        if (t1.name.toLowerCase() < t2.name.toLowerCase()) return -1;
        return 0;
      });
      this.setState({
        vaccinationData: vaccinationData,
        remainingHorses: remainingHorses,
      });
    });
  };

  onNewVaccinationButtonClick = () => {
    let horseArr = window.DataStorage.getAllHorses();
    horseArr = horseArr.sort((h1, h2) => (h1.name > h2.name ? 1 : -1));
    window.db.getHerpesVaccinationLatest(horseArr[0].id, (rows) => {
      let vtype = null;
      if (rows.length != 0) {
        var lastItem = rows.item(0);
        var nextTypeIndex =
          window.DataStorage.herpesVaccinationTypes[lastItem["vtype"] - 1]
            .NextIndex;
        vtype = window.DataStorage.herpesVaccinationTypes[nextTypeIndex];
      } else {
        vtype = window.DataStorage.herpesVaccinationTypes[0];
      }
      let options = {
        horseId: horseArr[0].id,
        date: new Date(),
        vtype: vtype,
      };
      let vaccinationPopup = new HerpesVaccinationPopup();
      vaccinationPopup.showVaccinationPopup(options, null, () => {
        this.loadData();
      });
    });
  };

  onEditVaccinationButtonClick = (item) => {
    let options = {
      horseId: item.horseid,
      date: new Date(item.lastVaccination),
    };
    let vaccinationPopup = new HerpesVaccinationPopup();
    vaccinationPopup.showVaccinationPopup(options, item, () => {
      this.loadData();
    });
  };

  onDeleteButtonClick = (item) => {
    window.db.setHerpesVaccinationUpdate(item.id, "d", () => {
      this.loadData();
    });
  };

  onNewVaccinationForHorseButtonClick = (horseid) => {
    window.db.getHerpesVaccinationLatest(horseid, (rows) => {
      let vtype = null;
      if (rows.length != 0) {
        var lastItem = rows.item(0);
        var nextTypeIndex =
          window.DataStorage.herpesVaccinationTypes[lastItem["vtype"] - 1]
            .NextIndex;
        vtype = window.DataStorage.herpesVaccinationTypes[nextTypeIndex];
      } else {
        vtype = window.DataStorage.herpesVaccinationTypes[0];
      }
      let options = {
        horseId: horseid,
        date: new Date(),
        vtype: vtype,
      };
      let vaccinationPopup = new HerpesVaccinationPopup();
      vaccinationPopup.showVaccinationPopup(options, null, () => {
        this.loadData();
      });
    });
  };

  onMakePdfClick = () => {
    window.Spinner.show();
    let reportDataArr = [];
    let tasksReportArr = [];
    tasksReportArr.push([
      "Last Vaccination Date",
      "Next Vaccination Date",
      window.lang.horse,
      "Type",
      "Reg. nr",
      "Chipid",
      window.lang.daysLeft,
      window.lang.vaccinationType,
      window.lang.stable,
    ]);
    let vaccData = this.state.vaccinationData;
    vaccData.map((i) =>
      tasksReportArr.push([
        i.lastVaccination,
        i.nextVaccination,
        i.horse,
        i.HorseType ? i.HorseType : "None",
        i.RFBid,
        i.Chipid,
        i.daysLeft,
        i.type,
        i.stable,
      ])
    );
    reportDataArr.push({
      header: "",
      data: tasksReportArr,
    });
    let remainingHorsesReportData = [];
    let remainingHorsesArr = [];
    remainingHorsesArr.push([window.lang.horse]);
    this.state.remainingHorses.map((i) => remainingHorsesArr.push([i.name]));
    remainingHorsesReportData.push({
      header: "",
      data: remainingHorsesArr,
    });

    window.DataGate.makeVaccinationReportPdf(
      reportDataArr,
      remainingHorsesReportData,
      "Herpes Vaccination report",
      function (data, url) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.target = "_blank";
        a.download = "Report.pdf";
        document.body.appendChild(a);
        a.click();
        window.Spinner.hide();
      }
    );
  };

  onMakeXlsxClick = () => {
    window.Spinner.show();
    let reportDataArr = [];
    let tasksReportArr = [];
    tasksReportArr.push([
      "Last Vaccination Date",
      "Next Vaccination Date",
      window.lang.horse,
      "Type",
      "Reg. nr",
      "Chipid",
      window.lang.daysLeft,
      window.lang.vaccinationType,
      window.lang.stable,
    ]);
    let vaccData = this.state.vaccinationData;
    vaccData.map((i) =>
      tasksReportArr.push([
        i.lastVaccination,
        i.nextVaccination,
        i.horse,
        i.HorseType ? i.HorseType : "None",
        i.RFBid,
        i.Chipid,
        i.daysLeft,
        i.type,
        i.stable,
      ])
    );
    reportDataArr.push({
      header: "",
      data: tasksReportArr,
    });

    window.DataGate.makeXlsx(
      reportDataArr,
      "Herpes Vaccination report",
      function (data, url) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "Report.xlsx";
        document.body.appendChild(a);
        a.click();
        window.Spinner.hide();
      }
    );
  };

  render() {
    const vaccinationData = this.state.vaccinationData.sort(
      (a, b) => a.daysLeft - b.daysLeft
    );
    const { remainingHorses } = this.state;
    return (
      <div id="vaccinationViewDiv">
        <div className="topRow">
          <button
            className="ui-btn ui-shadow ui-corner-all"
            onClick={this.onNewVaccinationButtonClick}
          >
            {window.lang.newVaccination}
          </button>
          <img
            loading="lazy"
            id="makePDFButton0"
            className="reportVaccButton"
            src="img/pdf_download.png"
            onClick={this.onMakePdfClick}
          />
          <img
            loading="lazy"
            id="makeXlsxButton0"
            className="reportVaccButton"
            src="img/excel_download.png"
            onClick={this.onMakeXlsxClick}
          />
        </div>
        <table className="VaccinationTable">
          <tbody>
            <tr>
              <th>Last Vaccination Date</th>
              <th>Next Vaccination Date</th>
              <th>{window.lang.horse}</th>
              <th>Type</th>
              <th>Reg. nr</th>
              <th>Chipid</th>
              <th>{window.lang.daysLeft}</th>
              <th>{window.lang.vaccinationType}</th>
              <th>{window.lang.remark}</th>
              <th></th>
              <th></th>
            </tr>
            {vaccinationData.length ? (
              vaccinationData.map((i) => (
                <tr key={Math.uuid()}>
                  <td>{i.lastVaccination}</td>
                  <td>{i.nextVaccination}</td>
                  <td>{i.horse}</td>
                  <td>{i.HorseType ? i.HorseType : "None"}</td>
                  <td>{i.RFBid}</td>
                  <td>{i.Chipid}</td>
                  <td
                    style={
                      i.daysLeft > 0 ? { color: "black" } : { color: "red" }
                    }
                  >
                    {i.daysLeft}
                  </td>
                  <td>{i.type}</td>
                  <td>{i.remarks}</td>
                  <td>
                    <img
                      loading="lazy"
                      className="actionIcon"
                      src="img/edit_icon.png"
                      onClick={() => this.onEditVaccinationButtonClick(i)}
                    />
                    <img
                      loading="lazy"
                      className="actionIcon"
                      src="img/trashcan.png"
                      onClick={() => this.onDeleteButtonClick(i)}
                    />
                  </td>
                  <td>
                    <button
                      className="ui-btn ui-shadow ui-corner-all"
                      onClick={() =>
                        this.onNewVaccinationForHorseButtonClick(i.horseid)
                      }
                    >
                      {window.lang.newVaccination}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </table>
        <h3 style={{ marginLeft: "20px" }}>
          {window.lang.remainingHorsesVacc}
        </h3>
        <table
          className="remainingHorsesVaccinationTable"
          style={{ borderCollapse: "collapse" }}
        >
          <tbody>
            <tr>
              <th>{window.lang.horse}</th>
              <th></th>
            </tr>
            {remainingHorses.length ? (
              remainingHorses.map((i) => (
                <tr key={Math.uuid()}>
                  <td>{i.name}</td>
                  <td>
                    <button
                      className="ui-btn ui-shadow ui-corner-all"
                      onClick={() =>
                        this.onNewVaccinationForHorseButtonClick(i.id)
                      }
                    >
                      {window.lang.newVaccination}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default HerpesVaccinationView;
