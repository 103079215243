import React, { useEffect, useRef, useState } from "react";
import "./OtherTasksView.css";

import ClickNHold from "react-click-n-hold";
import GeneralTaskPopup from "../GeneralTask/GeneralTaskPopup";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import useClickOutside from "../Hooks/useClickOutside";
export default function OtherTasksView({ date, generalTaskArr }) {
  const [generalTasksDaily, setGeneralTasksDaily] = useState([]);
  const [generalTasksWeekly, setGeneralTasksWeekly] = useState([]);
  const [generalTasksMonthly, setGeneralTasksMonthly] = useState([]);

  const [reorderMode, setReorderMode] = useState([false, false, false]);

  const [draggable, setDraggable] = useState(
    window.GlobalData.getItem(window.GlobalData.DRAG_DROP_TRAININGS) == 1
  );

  const [isDragging, setIsDragging] = useState(false);

  const ref = useRef();
  useClickOutside(ref, () => {
    let auxOrderMode = [...reorderMode];
    for (let index in auxOrderMode) {
      if (auxOrderMode[index]) {
        saveNewOrder(index + 1);
      }
      auxOrderMode[index] = false;
    }
    setReorderMode(auxOrderMode);
  });

  /**
   *  Initialisation function
   *  @description subscribes to the settings observables to be responsive to the changes in settings (drag and drop setting in this component)
   *
   */
  useEffect(() => {
    const globalDataChangeHandler = () => {
      setDraggable(
        window.GlobalData.getItem(window.GlobalData.DRAG_DROP_TRAININGS) == 1
      );
    };
    // subscribe
    window.GlobalData.subscribe(globalDataChangeHandler);
    // unsubscribe
    return () => {
      window.GlobalData.unsubscribe(globalDataChangeHandler);
    };
  }, []);

  const saveNewOrder = (type) => {
    let items = [...getGeneralTaskOfType(type)];
    const ids = items.map((task) => task.Id);
    window.DataGate.reorderGeneralTasks(ids, () => {});
  };

  const drawSortIcon = (index) => {
    const handleClick = () => {
      let auxOrderMode = [...reorderMode];
      if (auxOrderMode[index]) {
        saveNewOrder(index + 1);
      }
      auxOrderMode[index] = !auxOrderMode[index];
      setReorderMode(auxOrderMode);
    };
    if (reorderMode[index]) {
      return (
        <svg
          onClick={handleClick}
          width="20px"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      );
    }

    return (
      <svg
        onClick={handleClick}
        width="20px"
        viewBox="0 0 145.71022 117.9534"
        version="1.1"
        id="svg1"
      >
        <g id="layer1" transform="translate(-41.973339,-70.709651)">
          <g id="g1" transform="translate(-8.402214,-2.9225092)">
            <path
              d="m 139.2629,167.31188 22.1141,22.1141 c 2.87944,2.87944 7.55565,2.87944 10.43509,0 l 22.1141,-22.1141 c 2.87944,-2.87944 2.87944,-7.55565 0,-10.43509 -2.87943,-2.87944 -7.55564,-2.87944 -10.43508,0 l -9.53671,9.53671 V 98.804227 l 9.53671,9.536713 c 2.87944,2.87944 7.55565,2.87944 10.43508,0 2.87944,-2.87944 2.87944,-7.55565 0,-10.435093 l -22.1141,-22.114108 c -2.87944,-2.879439 -7.55565,-2.879439 -10.43509,0 l -22.1141,22.114108 c -2.87945,2.879443 -2.87945,7.555653 0,10.435093 2.87944,2.87944 7.55565,2.87944 10.43509,0 l 9.51367,-9.513673 v 67.609263 l -9.53671,-9.5367 c -2.87944,-2.87944 -7.55565,-2.87944 -10.43509,0 -2.87944,2.87944 -2.87944,7.55564 0,10.43508 z"
              id="path1"
              style={{ strokeWidth: "0.230355" }}
            />
            <rect
              style={{ fill: "#000000", strokeWidth: "0.214758" }}
              id="rect1"
              width="73.062729"
              height="13.516605"
              x="50.539532"
              y="75.254616"
              ry="2.4392004"
            />
            <rect
              style={{ fill: "#000000", strokeWidth: "0.214758" }}
              id="rect1-8"
              width="73.062729"
              height="13.516605"
              x="50.375553"
              y="106.41897"
              ry="2.4392004"
            />
            <rect
              style={{ fill: "#000000", strokeWidth: "0.214758" }}
              id="rect1-8-7"
              width="73.062729"
              height="13.516605"
              x="50.446777"
              y="141.19354"
              ry="2.4392004"
            />
            <rect
              style={{ fill: "#000000", strokeWidth: "0.214758" }}
              id="rect1-8-7-1"
              width="73.062729"
              height="13.516605"
              x="50.77581"
              y="175.82845"
              ry="2.4392004"
            />
          </g>
        </g>
      </svg>
    );
  };

  /**
   * A function that updates the tasks with respect of the local client order
   * @param {the type of tasks (daily, weekly, montly) = (1,2,3)} type
   */
  const updateWithRespectOfLocalOrder = (type) => {
    let newDailyTasks = generalTaskArr?.filter((task) => task.tasktype == type);
    if (reorderMode[type - 1]) {
      console.log(newDailyTasks);
      let curTasks = getGeneralTaskOfType(type);
      let res = getGeneralTaskOfType(type);
      for (let newDailyTask of newDailyTasks) {
        let foundIndex = curTasks.findIndex((x) => x.Id == newDailyTask.Id);
        if (foundIndex >= 0) {
          curTasks[foundIndex] = newDailyTask;
        } else {
          res.push(newDailyTask);
        }
      }
      setGeneralTasksOfType(res, type);
    } else {
      setGeneralTasksOfType(newDailyTasks, type);
    }
  };

  useEffect(() => {
    //keep order
    updateWithRespectOfLocalOrder(1);
    updateWithRespectOfLocalOrder(2);
    updateWithRespectOfLocalOrder(3);
  }, [generalTaskArr]);

  const onNewGeneralTaskClick = (taskType) => {
    let option = {
      date: date,
      taskType,
    };
    new GeneralTaskPopup().showPopup(option, null, () => {});
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getGeneralTaskOfType = (type) => {
    if (type == 1) return generalTasksDaily;
    if (type == 2) return generalTasksWeekly;
    return generalTasksMonthly;
  };

  const setGeneralTasksOfType = (new_items, type) => {
    if (type == 1) return setGeneralTasksDaily(new_items);
    if (type == 2) return setGeneralTasksWeekly(new_items);
    return setGeneralTasksMonthly(new_items);
  };

  const onCellHoldCLick = (task) => {
    let task_aux = { ...task };
    task_aux.Color = task.Color == "red" ? "green" : "red";

    task_aux.Flag = "Update";
    task_aux["ConnectionId"] = window.notification.connectionId;

    window.DataGate.insertOrUpdateGeneralTask(task_aux, (code, data) => {
      if (code == window.DataGate.CODE_OK) {
        window.training.refresh({ showSpinner: false });
      }
    });
  };

  const onDragEnd = (result, type) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      getGeneralTaskOfType(type),
      result.source.index,
      result.destination.index
    );

    const ids = items.map((task) => task.Id);

    console.log(getGeneralTaskOfType(type));
    console.log(result);

    window.DataGate.reorderGeneralTasks(ids, () => {});
    setIsDragging(false);
    setGeneralTasksOfType(items, type);
  };

  const move_task = (type, index, lower) => {
    let items = [...getGeneralTaskOfType(type)];
    if (index == 0 && lower) return;
    if (index == items.length - 1 && !lower) return;

    let newIndex = lower ? index - 1 : index + 1;

    // console.log(getGeneralTaskOfType(type));
    // console.log(items[index]);
    // console.log(items[newIndex]);

    let aux = items[index];
    items[index] = items[newIndex];
    items[newIndex] = aux;
    // const newItems = [items[index], items[newIndex]];
    const ids = items.map((task) => task.Id);

    // window.DataGate.reorderGeneralTasks(ids, () => {});
    setIsDragging(false);
    setGeneralTasksOfType(items, type);
  };

  const renderGeneralTaskTableDaily = () => {
    const currentTasks = generalTasksDaily;
    return (
      <div>
        <table
          className="riderTableR otherTasksViewTable"
          style={{ maxWidth: "350px" }}
        >
          <thead>
            <tr>
              <td colSpan={3}>Other tasks</td>
            </tr>
          </thead>
          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, 1)}
            onBeforeDragStart={() => setIsDragging(true)}
          >
            <Droppable droppableId={"droppable-area-daily"}>
              {(provided, snapshot) => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {currentTasks &&
                    currentTasks.map((i, index) => (
                      <Draggable
                        key={Math.uuid()}
                        draggableId={"daily-" + i.Name + "-" + index}
                        index={index}
                        disabled={true}
                      >
                        {(provided, snapshot) => (
                          <tr
                            ref={draggable ? provided.innerRef : null}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                            }}
                          >
                            <td
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => onGeneralTaskClick(i, 1)}
                            >
                              <ClickNHold
                                time={1.5}
                                onClickNHold={() => {
                                  onCellHoldCLick(i);
                                }}
                              >
                                <div>
                                  <div style={{ fontSize: "0.6em" }}>
                                    {i.TimeOfDay}
                                  </div>
                                  <span
                                    className="otherTasksViewTable--task-name"
                                    style={
                                      i.Color == "green"
                                        ? {
                                            fontWeight: "bold",
                                            textDecoration: "line-through",
                                            textShadow: "none",
                                          }
                                        : {
                                            fontWeight: "bold",
                                            color: "red",
                                          }
                                    }
                                  >
                                    {window.DataStorage.allUsers.length
                                      ? i.TrainerId
                                        ? i.Name +
                                          " (" +
                                          window.getInitials(
                                            window.DataStorage.allUsers.find(
                                              (t) => t.Id == i.TrainerId
                                            ).Name
                                          ) +
                                          ")"
                                        : i.Name + "(N)"
                                      : i.Name}
                                  </span>
                                  <div style={{ fontSize: "0.6em" }}>
                                    {i.Remark}
                                  </div>
                                </div>
                              </ClickNHold>
                            </td>
                            {reorderMode[0] && (
                              <>
                                <td
                                  className="otherTasksViewTable--reorder-column"
                                  onClick={() => {
                                    move_task(1, index, true);
                                  }}
                                >
                                  <svg
                                    width="20px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                                    />
                                  </svg>
                                </td>
                                <td
                                  className="otherTasksViewTable--reorder-column"
                                  onClick={() => {
                                    move_task(1, index, false);
                                  }}
                                >
                                  <svg
                                    width="20px"
                                    style={{ transform: "rotate(180deg)" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                                    />
                                  </svg>
                                </td>
                              </>
                            )}
                          </tr>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}

                  <tr>
                    <td colSpan={3} style={{ cursor: "pointer" }}>
                      <div className="otherTasksViewTable--last-row">
                        <span onClick={() => onNewGeneralTaskClick(1)}>
                          Add new task
                        </span>
                        <div className="otherTasksViewTable--last-row--reorder">
                          {drawSortIcon(0)}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </table>
      </div>
    );
  };
  const renderGeneralTaskTableWeekly = () => {
    const currentTasks = generalTasksWeekly;
    return (
      <div>
        <table className="riderTableR otherTasksViewTable">
          <thead>
            <tr>
              <td colSpan={3}>Weekly tasks</td>
            </tr>
          </thead>
          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, 2)}
            onBeforeDragStart={() => setIsDragging(true)}
          >
            <Droppable droppableId={"droppable-area-weekly"}>
              {(provided, snapshot) => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {currentTasks &&
                    currentTasks.map((i, index) => (
                      <Draggable
                        key={Math.uuid()}
                        draggableId={"weekly-" + i.Name + "-" + index}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                            }}
                          >
                            <td
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => onGeneralTaskClick(i, 2)}
                            >
                              <ClickNHold
                                time={1.5}
                                onClickNHold={() => {
                                  onCellHoldCLick(i);
                                }}
                              >
                                <div>
                                  <div style={{ fontSize: "0.6em" }}>
                                    {i.TimeOfDay}
                                  </div>
                                  <span
                                    className="otherTasksViewTable--task-name"
                                    style={
                                      i.Color == "green"
                                        ? {
                                            fontWeight: "bold",
                                            textDecoration: "line-through",
                                            textShadow: "none",
                                          }
                                        : {
                                            fontWeight: "bold",
                                            color: "red",
                                          }
                                    }
                                  >
                                    {window.DataStorage.allUsers.length
                                      ? i.TrainerId
                                        ? i.Name +
                                          " (" +
                                          window.getInitials(
                                            window.DataStorage.allUsers.find(
                                              (t) => t.Id == i.TrainerId
                                            ).Name
                                          ) +
                                          ")"
                                        : i.Name + "(N)"
                                      : i.Name}
                                  </span>
                                  <div style={{ fontSize: "0.6em" }}>
                                    {i.Remark}
                                  </div>
                                </div>
                              </ClickNHold>
                            </td>
                            {reorderMode[1] && (
                              <>
                                <td
                                  className="otherTasksViewTable--reorder-column"
                                  onClick={() => {
                                    move_task(2, index, true);
                                  }}
                                >
                                  <svg
                                    width="20px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                                    />
                                  </svg>
                                </td>
                                <td
                                  className="otherTasksViewTable--reorder-column"
                                  onClick={() => {
                                    move_task(2, index, false);
                                  }}
                                >
                                  <svg
                                    width="20px"
                                    style={{ transform: "rotate(180deg)" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                                    />
                                  </svg>
                                </td>
                              </>
                            )}
                          </tr>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}

                  <tr>
                    <td colSpan={3} style={{ cursor: "pointer" }}>
                      <div className="otherTasksViewTable--last-row">
                        <span onClick={() => onNewGeneralTaskClick(2)}>
                          Add new task
                        </span>
                        <div className="otherTasksViewTable--last-row--reorder">
                          {drawSortIcon(1)}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </table>
      </div>
    );
  };
  const renderGeneralTaskTableMonthly = () => {
    const currentTasks = generalTasksMonthly;
    return (
      <div>
        <table className="riderTableR otherTasksViewTable">
          <thead>
            <tr>
              <td colSpan={3}>Monthly tasks</td>
            </tr>
          </thead>
          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, 3)}
            onBeforeDragStart={() => setIsDragging(true)}
          >
            <Droppable droppableId={"droppable-area-monthly"}>
              {(provided, snapshot) => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {currentTasks &&
                    currentTasks.map((i, index) => (
                      <Draggable
                        key={Math.uuid()}
                        draggableId={"monthly-" + i.Name + "-" + index}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                            }}
                          >
                            <td
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => onGeneralTaskClick(i, 3)}
                            >
                              <ClickNHold
                                time={1.5}
                                onClickNHold={() => {
                                  onCellHoldCLick(i);
                                }}
                              >
                                <div>
                                  <div style={{ fontSize: "0.6em" }}>
                                    {i.TimeOfDay}
                                  </div>
                                  <span
                                    className="otherTasksViewTable--task-name"
                                    style={
                                      i.Color == "green"
                                        ? {
                                            fontWeight: "bold",
                                            textDecoration: "line-through",
                                            textShadow: "none",
                                          }
                                        : {
                                            fontWeight: "bold",
                                            color: "red",
                                          }
                                    }
                                  >
                                    {window.DataStorage.allUsers.length
                                      ? i.TrainerId
                                        ? i.Name +
                                          " (" +
                                          window.getInitials(
                                            window.DataStorage.allUsers.find(
                                              (t) => t.Id == i.TrainerId
                                            ).Name
                                          ) +
                                          ")"
                                        : i.Name + "(N)"
                                      : i.Name}
                                  </span>
                                  <div style={{ fontSize: "0.6em" }}>
                                    {i.Remark}
                                  </div>
                                </div>
                              </ClickNHold>
                            </td>
                            {reorderMode[2] && (
                              <>
                                <td
                                  className="otherTasksViewTable--reorder-column"
                                  onClick={() => {
                                    move_task(3, index, true);
                                  }}
                                >
                                  <svg
                                    width="20px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                                    />
                                  </svg>
                                </td>
                                <td
                                  className="otherTasksViewTable--reorder-column"
                                  onClick={() => {
                                    move_task(3, index, false);
                                  }}
                                >
                                  <svg
                                    width="20px"
                                    style={{ transform: "rotate(180deg)" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                                    />
                                  </svg>
                                </td>
                              </>
                            )}
                          </tr>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}

                  <tr>
                    <td colSpan={3} style={{ cursor: "pointer" }}>
                      <div className="otherTasksViewTable--last-row">
                        <span onClick={() => onNewGeneralTaskClick(3)}>
                          Add new task
                        </span>
                        <div className="otherTasksViewTable--last-row--reorder">
                          {drawSortIcon(2)}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </table>
      </div>
    );
  };

  const onGeneralTaskClick = (task, taskType) => {
    let options = {
      date: new Date(task.Time),
    };
    new GeneralTaskPopup().showPopup(options, task, () => {});
  };

  return (
    <div className="otherTasksViewHolderWrapper" ref={ref}>
      <div className="otherTasksViewHolder otherTasksViewHolderScrollArea">
        <div style={{ flex: 1, maxWidth: "350px" }}>
          {renderGeneralTaskTableDaily()}
        </div>
        <div style={{ flex: 1, maxWidth: "350px" }}>
          {renderGeneralTaskTableWeekly()}
        </div>
        <div style={{ flex: 1, maxWidth: "350px" }}>
          {renderGeneralTaskTableMonthly()}
        </div>
      </div>
    </div>
  );
}
