export default class DataGateActions {
    static updateEquipment(data) {
        window.Spinner.show();
        window.db.updateEquipment(data, () => {
            window.Managers.self.refreshHorses();
            window.Spinner.hide();
        });
    }

    static getFeedData(callback) {
        window.Spinner.show();
        window.db.getFeed(window.myid, (data) => {
            window.Spinner.hide();
            if (callback != null) {
                callback(data);
            }
        });
    }

    static getFarrierData(callback) {
        window.Spinner.show();
        window.db.getFarrierDataForUser(window.myid, (data) => {
            window.Spinner.hide();
            if (callback != null) {
                callback(data);
            }
        });
    }

    static deleteTraining(id) {
        window.db.deleteTraining(id);
    }

    static makeLocalTrainingItemFromS(item) {
        item.id = item.Id;
        item.starttime = item.StartTime;
        item.userid = item.UserId;
        item.trainer = item.Trainer;
        item.type = item.Type;
        item.color = item.Color;
        item.remark = item.Remark;
        item.shortname = window.DataStorage.trainTypes.find(tt => tt.id == item.Type).shortname;

        return item;
    }

    static addTrainingForPermanentColumn(horse, type, date) {
        console.log('addPermanentTraining');
        let serverItem = {
            Color: "green", //is done
            Duration: 1,
            Evaluation: 0,
            FarrierType: undefined,
            HasMultimedia: 0,
            HorseId: horse.id, //horse
            Id: Math.uuid(),
            IsCanCopy: type.IsCanCopyType ? "1" : "0",
            PaddockPeriod: null,
            Remark: "",
            ReminderType: "0",
            StartTime: date.formatDesh(), //date
            Status: "",
            SyncTime: -1,
            TimeOfDay: "",
            Trainer: window.myid, //rider me
            Type: type.id, //type
            U: "u",
            VeterinarianType: "2",
            VeterinarianVacType: undefined
        };

        let localItem = {
            Evaluation: serverItem.Evaluation,
            FarrierType: serverItem.FarrierType,
            HasMultimedia: false,
            PaddockPeriod: null,
            ReminderType: 0,
            TimeOfDay: serverItem.TimeOfDay,
            VeterinarianType: 2,
            VeterinarianVacType: 10,
            color: serverItem.Color,
            duration: 1,
            horseid: serverItem.HorseId,
            id: serverItem.Id,
            isCanCopy: type.IsCanCopyType,
            name: type.name,
            remark: serverItem.Remark,
            shortname: type.shortname,
            starttime: serverItem.StartTime,
            status: serverItem.Status,
            synctime: serverItem.SyncTime,
            trainer: serverItem.Trainer,
            type: serverItem.Type,
            u: serverItem.U,
            trainername: window.Managers.User.name,
            TrainingFlag: window.DataStorage.TrainingFlagAdd
        };

        window.DataStorage.addTraining(localItem);
        window.DataGate.insertOrUpdateTrainings(serverItem, () => {
        });
    }

    static insertOrUpdateHorse(horse, horseLogo, isNewHorse, callback) {
        let resreshAndCallback = function () {
            window.Managers.self.refreshHorses(horse["Id"], () => {
                window.Spinner.hide();
                window.training.refresh();
                if (callback)
                    callback();
            });

            if (isNewHorse) {
                window.Managers.self.checkAutoShare(horse["Id"], horse.UserId);
            }
        };

        window.Spinner.show();
        window.DataGate.insertOrUpdateHorse(horse, function (code, data) {
            if (code == window.DataGate.CODE_OK) {
                if (horseLogo != null) {
                    window.db.saveLogo(horse["Id"], horseLogo, window.DataBase.LOGO_HORSE, () => {
                        resreshAndCallback();
                    });
                } else {
                    resreshAndCallback();
                }
            }
        });
    }

    static unDeleteHorse(horse) {
        window.db.setHorseUpdate(horse["id"], 'u', function () {
            window.onDataRefresh();
            window.onBack();
        });
    }
}