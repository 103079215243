﻿import React, { Component } from "react";
import ResizePanel from "react-resize-panel";
import { Resizable } from "re-resizable";
import "./RiderView.css";
import TrainingCellMap from "../TrainingCellMap";
import HorseCell from "../HorseCell/HorseCell";
import TrainingDay, { TrainingCell } from "../TrainingCell/TrainingDay";
import DataGateActions from "../../DataGateActions";
import GeneralTaskPopup from "../../GeneralTask/GeneralTaskPopup";

export default class StaticRiderView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ridersOrder: [],
      filteredHorses: [],
      horseEquipmentFlag: false,
      showGeneralTasks: "",
    };

    this.dates = [];
    this.permanentTypes = [];
    this.defaultWidth = window.GlobalData.getItem(
      window.GlobalData.RIDER_COLUMN_WIDTH
    );
    if (this.defaultWidth == null) {
      this.defaultWidth = [];
      for (let i = 0; i < 100; i++) this.defaultWidth[i] = 500;
    } else {
      this.defaultWidth = JSON.parse(this.defaultWidth);
    }
    this.showRider =
      window.GlobalData.getItem(window.GlobalData.SHOW_RIDER_ON_TASKS) != 0;
  }

  componentDidUpdate = () => {
    if (this.state.filteredHorses != this.props.filteredHorses)
      this.setState({
        filteredHorses: this.props.filteredHorses,
      });
    if (
      this.state.showGeneralTasks !=
      window.GlobalData.getItem(window.GlobalData.VIEW_OTHER_TASKS)
    )
      this.setState({
        showGeneralTasks: window.GlobalData.getItem(
          window.GlobalData.VIEW_OTHER_TASKS
        ),
      });
  };

  componentDidMount = () => {
    let ridersOrder = JSON.parse(
      JSON.stringify(
        window.GlobalData.getItem(window.GlobalData.KEY_RIDERS_ORDER)
      )
    );
    if (ridersOrder == null) {
      window.db.getUserOrdered(window.myid, (data) => {
        ridersOrder = data[0].OrderedRider;
        window.GlobalData.setItem(
          window.GlobalData.KEY_RIDERS_ORDER,
          ridersOrder
        );
        this.setState({
          ridersOrder: JSON.parse(ridersOrder),
        });
      });
    }

    this.setState({
      filteredHorses: this.props.filteredHorses,
      showGeneralTasks: window.GlobalData.getItem(
        window.GlobalData.VIEW_OTHER_TASKS
      ),
    });
  };

  onResizeStop = (rIndex, delta) => {
    this.defaultWidth[rIndex] += delta.width;
    window.GlobalData.setItem(
      window.GlobalData.RIDER_COLUMN_WIDTH,
      JSON.stringify(this.defaultWidth)
    );
  };

  equipmentUpdate = (e, horse) => {
    if (this.state.horseEquipmentFlag) {
      horse.Equipment = e.target.value;
      DataGateActions.updateEquipment({
        Equipment: e.target.value,
        HorseId: horse.id,
      });
      this.setState({
        horseEquipmentFlag: false,
      });
    }
  };

  equipmentChange = (e, horse) => {
    let arr = this.state.filteredHorses;
    arr.find((h) => h.id == horse.id).Equipment = e.target.value;
    this.setState({
      filteredHorses: arr,
      horseEquipmentFlag: true,
    });
  };

  renderRiderTable = (rider) => {
    let trainingCellMap = new TrainingCellMap();
    let trainingArr = this.props.filteredTrainings;
    if (
      window.training.mode == window.TrainingManager.riderStaticLight ||
      window.training.mode ==
        window.TrainingManager.riderStaticAndEquipmentLight
    ) {
      trainingArr = trainingArr.filter((i) => i.trainer == rider.id);
    }
    trainingCellMap.refreshMap([this.props.date.formatDesh()], trainingArr);
    let riderTrainings = trainingArr.filter((i) => i.trainer == rider.id);

    //let horses = rider.horses;
    let horses = [];
    if (
      (window.training.mode ==
        window.TrainingManager.riderStaticAndEquipmentLight ||
        window.training.mode == window.TrainingManager.riderStaticLight) &&
      rider.id != window.myid
    )
      horses = this.state.filteredHorses.filter((i) => i.trainer == rider.id);
    //for (let i in rider.horses) {
    //    let tArr = this.props.filteredTrainings.filter(j => j.horseid == rider.horses[i].id);
    //    if (tArr.length) {
    //        if (tArr.filter(j => j.trainer == rider.id).length)
    //            horses.push(rider.horses[i])
    //    } else {
    //        horses.push(rider.horses[i])
    //    }
    //}
    else horses = rider.horses;

    let otherHorses = [];
    for (let i in riderTrainings) {
      if (this.state.filteredHorses.length) {
        if (!otherHorses.find((j) => j.id == riderTrainings[i].horseid)) {
          if (
            this.state.filteredHorses.find(
              (j) => j.id == riderTrainings[i].horseid
            )
          )
            otherHorses.push(
              this.state.filteredHorses.find(
                (j) => j.id == riderTrainings[i].horseid
              )
            );
        }
      }
    }
    otherHorses = otherHorses.filter(
      ({ id: id1 }) => !horses.some(({ id: id2 }) => id2 === id1)
    );
    otherHorses = otherHorses.sort(function (h1, h2) {
      return h1.name.toLowerCase() > h2.name.toLowerCase() ? 1 : -1;
    });

    let trainingTdWidth =
      Math.max(
        trainingCellMap.map[this.props.date.formatDesh()].columns.length,
        1
      ) * 80;

    let otherTasksArr = this.props.generalTaskArr.filter(
      (t) => t.TrainerId == rider.id
    );
    otherTasksArr = otherTasksArr.sort(function (h1, h2) {
      return h1.Name.toLowerCase() > h2.Name.toLowerCase() ? 1 : -1;
    });
    if (this.props.showEquipment)
      trainingCellMap.refreshMap(
        this.dates.map((d) => d.formatDesh()),
        this.filteredTrainings
      );

    return (
      <div>
        <table className="riderTableR" key={rider.id}>
          <thead>
            <tr>
              <td>{window.lang.horse}</td>
              <td>{rider.name}</td>
              {this.props.showEquipment &&
                this.permanentTypes.map((type) => (
                  <td key={type.id}>{type.name}</td>
                ))}
              {this.props.showEquipment && <td>{window.lang.equipment}</td>}
            </tr>
          </thead>
          <tbody>
            {horses.map((horse) => (
              <tr key={`${rider.id}&${horse.id}`}>
                <td style={{ textAlign: "left" }}>
                  <HorseCell horse={horse} />
                </td>
                <td
                  key={`${rider.id}&${horse.id}&tasks`}
                  style={{ minWidth: "60px" }}
                >
                  <TrainingDay
                    trainings={trainingArr.filter((t) => t.horseid == horse.id)}
                    date={this.props.date}
                    isSelectedDate={true}
                    horse={horse}
                    trainingCellMap={trainingCellMap}
                    rider={rider.id}
                    showRider={this.showRider}
                  />
                </td>
                {this.props.showEquipment &&
                  this.permanentTypes.map((type) => (
                    <td key={`${horse.id}&${type.id}`}>
                      {this.permanentCell(horse, type, rider.id)}
                    </td>
                  ))}
                {this.props.showEquipment && (
                  <td>
                    <textarea
                      className="equipmentInput"
                      value={horse.Equipment != null ? horse.Equipment : ""}
                      onChange={(e) => this.equipmentChange(e, horse)}
                      onBlur={(e) => this.equipmentUpdate(e, horse)}
                    />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {otherHorses.length ? (
          <div>
            <span style={{ padding: "10px" }}>Other horses</span>
            <table className="riderTableR" key={Math.uuid()}>
              <tbody>
                {otherHorses.map((horse) => (
                  <tr key={`${rider.id}&${horse.id}`}>
                    <td style={{ textAlign: "left" }}>
                      <HorseCell horse={horse} />
                    </td>
                    <td
                      key={`${rider.id}&${horse.id}&tasks`}
                      style={{ minWidth: "60px" }}
                    >
                      <TrainingDay
                        trainings={trainingArr.filter(
                          (t) => t.horseid == horse.id
                        )}
                        date={this.props.date}
                        isSelectedDate={true}
                        horse={horse}
                        trainingCellMap={trainingCellMap}
                        rider={rider.id}
                        showRider={this.showRider}
                      />
                    </td>
                    {this.props.showEquipment &&
                      this.permanentTypes.map((type) => (
                        <td key={`${horse.id}&${type.id}`}>
                          {this.permanentCell(horse, type, rider.id)}
                        </td>
                      ))}
                    {this.props.showEquipment && (
                      <td>
                        <textarea
                          className="equipmentInput"
                          value={horse.Equipment != null ? horse.Equipment : ""}
                          onChange={(e) => this.equipmentChange(e, horse)}
                          onBlur={(e) => this.equipmentUpdate(e, horse)}
                        />
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <></>
        )}
        {this.state.showGeneralTasks == 1 && otherTasksArr.length ? (
          <div>
            <span style={{ padding: "10px" }}>Other tasks</span>
            <table className="riderTableR" key={Math.uuid()}>
              <tbody>
                {otherTasksArr.map((i) => (
                  <tr key={Math.uuid()}>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => this.onGeneralTaskClick(i)}
                    >
                      <div>
                        <div style={{ fontSize: "0.6em" }}>{i.TimeOfDay}</div>
                        <div
                          style={
                            i.Color == "green"
                              ? {
                                  fontWeight: "bold",
                                  textDecoration: "line-through",
                                  textShadow: "none",
                                }
                              : { fontWeight: "bold", color: "red" }
                          }
                        >
                          {i.Name} ({window.getInitials(rider.name)})
                        </div>
                        <div style={{ fontSize: "0.6em" }}>{i.Remark}</div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  renderGeneralTaskTable = () => {
    let generalTaskArr = this.props.generalTaskArr;
    generalTaskArr = generalTaskArr.sort(function (h1, h2) {
      return h1.Name.toLowerCase() > h2.Name.toLowerCase() ? 1 : -1;
    });
    return (
      <div>
        <table className="riderTableR">
          <thead>
            <tr>
              <td>Other tasks</td>
            </tr>
          </thead>
          <tbody>
            {generalTaskArr &&
              generalTaskArr.map((i) => (
                <tr key={Math.uuid()}>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => this.onGeneralTaskClick(i)}
                  >
                    <div>
                      <div style={{ fontSize: "0.6em" }}>{i.TimeOfDay}</div>
                      <div
                        style={
                          i.Color == "green"
                            ? {
                                fontWeight: "bold",
                                textDecoration: "line-through",
                                textShadow: "none",
                              }
                            : { fontWeight: "bold", color: "red" }
                        }
                      >
                        {window.DataStorage.allUsers.length
                          ? i.TrainerId
                            ? i.Name +
                              " (" +
                              window.getInitials(
                                window.DataStorage.allUsers.find(
                                  (t) => t.Id == i.TrainerId
                                ).Name
                              ) +
                              ")"
                            : i.Name + "(N)"
                          : i.Name}
                      </div>
                      <div style={{ fontSize: "0.6em" }}>{i.Remark}</div>
                    </div>
                  </td>
                </tr>
              ))}
            <tr>
              <td
                style={{ cursor: "pointer" }}
                onClick={this.onNewGeneralTaskClick}
              >
                Add new task
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  permanentCell = (horse, ttype, rider) => {
    let trainings = this.permanentTrainings.filter(
      (t) => t.horseid == horse.id && t.type == ttype.id
    );
    if (trainings.length == 0) {
      return (
        <TrainingCell
          holdEnabled={true}
          horse={horse}
          column={{ shortname: ttype.shortname }}
          date={this.dates[0]}
          showRider={this.showRider}
          callbackAdd={() =>
            this.addPermanentTraining(horse, ttype, this.dates[0])
          }
          useOnlyAddCallback={true}
          rider={rider}
        />
      );
    }

    return (
      <div className="trainingCellContainer">
        {trainings.map((t) => (
          <TrainingCell
            holdEnabled={true}
            key={t.id}
            training={t}
            horse={horse}
            date={this.dates[0]}
            showRider={this.showRider}
            rider={rider}
          />
        ))}
      </div>
    );
  };

  createRiderOrder = (riderId) => {
    this.state.ridersOrder[riderId] =
      Object.keys(this.state.ridersOrder).length + 1;
    return this.state.ridersOrder[riderId];
  };

  addPermanentTraining = (horse, type, date) => {
    DataGateActions.addTrainingForPermanentColumn(horse, type, date);
    window.training.refreshLayout();
  };

  onNewGeneralTaskClick = () => {
    let option = {
      date: this.props.date,
    };
    new GeneralTaskPopup().showPopup(option, null, () => {});
  };

  onGeneralTaskClick = (task) => {
    let options = {
      date: new Date(task.Time),
    };
    new GeneralTaskPopup().showPopup(options, task, () => {
      window.training.refresh();
    });
  };

  render() {
    const { filteredTrainings, date, showOnlyOwnHorses } = this.props;
    const { filteredHorses, showGeneralTasks } = this.state;
    let riderArray = [];
    riderArray.push({
      id: window.myid,
      name: window.lang.me,
      horses: [],
    });

    for (let i = 0; i < filteredHorses.length; i++) {
      let horse = filteredHorses[i];
      if (horse.trainer == null || horse.trainer == "") {
        riderArray[0].horses.push(horse);
      } else {
        let rider = riderArray.find((rider) => rider.id == horse.trainer);
        if (rider == null) {
          let riderData = window.DataStorage.allUsers.find(
            (t) => t.Id == horse.trainer
          );
          if (riderData != null) {
            riderArray.push({
              id: riderData.Id,
              name: riderData.Name,
              horses: [horse],
            });
          }
        } else {
          rider.horses.push(horse);
        }
      }
    }

    //filter by show rider settings
    this.state.ridersOrder = window.GlobalData.getItem(
      window.GlobalData.KEY_RIDERS_ORDER
    );
    if (this.state.ridersOrder == null) {
      this.state.ridersOrder = {};
    } else {
      this.state.ridersOrder = JSON.parse(this.state.ridersOrder) || {};
    }
    if (
      window.Managers.ridersIsShowing == null ||
      Object.keys(window.Managers.ridersIsShowing).length == 0
    ) {
      let f = window.GlobalData.getItem(
        window.GlobalData.KEY_RIDERS_IS_SHOWING
      );
      if (f != null) {
        window.Managers.ridersIsShowing = JSON.parse(f);
      }
    }
    riderArray = riderArray.filter(
      (r) => window.Managers.ridersIsShowing[r.id] != 0
    );

    var ridersMass = new Array();
    for (var i = 0; i < riderArray.length; i++) {
      ridersMass.push(riderArray[i]);
    }
    if (showGeneralTasks == 1 && ridersMass.indexOf((i) => i.id == "1") == -1)
      ridersMass.push({ id: "1", name: "Other tasks", email: "" });
    for (var i = 0; i < ridersMass.length; i++) {
      var order = this.state.ridersOrder[ridersMass[i]["id"]];
      if (order == null) {
        order = this.createRiderOrder(ridersMass[i]["id"]);
      }
      ridersMass[i]["orderC"] = order;
    }

    if (filteredHorses.length) {
      for (let i = 0; i < this.props.filteredTrainings.length; i++) {
        if (
          !ridersMass.filter(
            (j) => j.id == this.props.filteredTrainings[i].trainer
          ).length &&
          filteredHorses.filter(
            (k) => k.id == this.props.filteredTrainings[i].horseid
          ).length
        ) {
          let currRider = this.props.filteredTrainings[i].trainer;
          let newRider = window.DataStorage.allUsers.find(
            (i) => i.Id == currRider
          );
          let riderOrder = this.state.ridersOrder[currRider];
          if (newRider)
            ridersMass.push({
              id: filteredTrainings[i].trainer,
              name: newRider.Name,
              horses: [],
              orderC: riderOrder,
            });
        }
      }
    }

    let selectedRiders = JSON.parse(
      window.GlobalData.getItem(window.GlobalData.FILTER_RIDER)
    );
    if (selectedRiders[0] != 1) {
      let selectedRidersArr = [];
      for (let i in ridersMass)
        if (selectedRiders.includes(ridersMass[i].id))
          selectedRidersArr.push(ridersMass[i]);
      ridersMass = selectedRidersArr;
    }

    ridersMass.sort(function (first, second) {
      return first["orderC"] - second["orderC"];
    });

    this.dates = [];
    for (var i = 0; i < window.training.period; i++) {
      this.dates.push(window.training.startDate.addDays(i));
    }

    this.permanentTypes = [];
    this.filteredTrainings = this.props.filteredTrainings;
    if (this.dates.length == 1) {
      this.permanentTypes = window.DataStorage.trainTypes.filter(
        (tt) => tt.IsPermanent
      );
      const permanentTypeIds = this.permanentTypes.map((tt) => tt.id);
      if (this.permanentTypes.length != null) {
        this.filteredTrainings = this.props.filteredTrainings.filter(
          (t) => permanentTypeIds.indexOf(t.type) == -1
        );
        this.permanentTrainings = this.props.filteredTrainings.filter(
          (t) => permanentTypeIds.indexOf(t.type) != -1
        );
      }
    }

    let minBlockWidth = 180 + 60 + 4;
    if (this.props.showEquipment) {
      minBlockWidth += 100;
    }

    return (
      <div
        className={
          !window.isMobileDevice() && !window.isIphoneIpad()
            ? "allRidersDiv"
            : "allRidersDivMobile"
        }
      >
        <div className="allRidersContainer">
          {ridersMass.map((rider, rIndex) =>
            rider.id == "1" ? (
              <Resizable
                key={Math.uuid()}
                minWidth={minBlockWidth}
                enable={{
                  top: false,
                  right: true,
                  bottom: false,
                  left: false,
                  topRight: false,
                  bottomRight: false,
                  bottomLeft: false,
                  topLeft: false,
                }}
                handleComponent={
                  window.isTouchDevice() ? { right: <ResizeIcon /> } : {}
                }
              >
                {this.renderGeneralTaskTable()}
              </Resizable>
            ) : (
              <Resizable
                key={rider.id}
                defaultSize={{ width: this.defaultWidth[rIndex] }}
                minWidth={minBlockWidth}
                enable={{
                  top: false,
                  right: true,
                  bottom: false,
                  left: false,
                  topRight: false,
                  bottomRight: false,
                  bottomLeft: false,
                  topLeft: false,
                }}
                onResizeStop={(e, direction, ref, d) =>
                  this.onResizeStop(rIndex, d)
                }
                handleComponent={
                  window.isTouchDevice() ? { right: <ResizeIcon /> } : {}
                }
              >
                {this.renderRiderTable(rider)}
              </Resizable>
            )
          )}
        </div>
      </div>
    );
  }
}

export class ResizeIcon extends Component {
  render() {
    return (
      <img loading="lazy" className="resizeDivider" src="img/icon_resize.png" />
    );
  }
}
