import React, { Component } from "react";
import "./HorseGallery.css";
import MultimediaHelper from "./MultimediaHelper";
import SpinnerView from "../Controls/Spinner/SpinnerView";
import PdfViewer from "./PdfViewer";
import { FilePicker } from "@capawesome/capacitor-file-picker";
import { Capacitor } from "@capacitor/core";
import MediaTypeAlert from "../components/Alerts/MediaTypeAlert";

import {
  VideoEditor,
  MediaFileResult,
} from "@whiteguru/capacitor-plugin-video-editor";
import { Filesystem } from "@capacitor/filesystem";
import { file } from "jszip";
class HorseGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMounted: false,
      dataArray: [],
      showFullScreenView: false,
      showFullScreenItemIndex: 0,
      showFullScreenLoading: false,
      loading: true,
      uploadDataArray: [],
      showAddYoutube: false,
      addYoutubeLink: "",
      multiSelectMode: false,
      testUrl: null,
      selectUploadMediaType: false,
      helper: new MultimediaHelper(),
    };
  }

  componentDidMount() {
    this.setState({ isMounted: true, platform: Capacitor.getPlatform() });
    this.loadData();
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.horse != this.props.horse ||
      prevProps.noteOrTaskId != this.props.noteOrTaskId
    ) {
      this.loadData();
    }
  }

  toggleExtraButtons = () => {
    this.setState({
      multiSelectMode: !this.state.multiSelectMode,
    });
  };

  setOwnSettings = () => {
    if (this.props.generalTask)
      this.state.helper.setCurrentSettings(
        this.props.type,
        null,
        null,
        this.props.multimediaPage,
        this.props.noteOrTaskId
      );
    else
      this.state.helper.setCurrentSettings(
        this.props.type,
        this.props.horse.id,
        this.props.horse.userid,
        this.props.multimediaPage,
        this.props.noteOrTaskId
      );

    this.state.helper.setFinishCallback(this.onUploadedFile);
    this.state.helper.setProgressCallback(this.onProgressFile);
    this.state.helper.setRefreshCallback(() => {
      this.setState({
        uploadDataArray: this.state.helper
          .loadUploadQueue(document.location.href)
          .map((el) => el.file),
      });
    });
  };

  loadData = () => {
    // if (this.props.isConcurrent) {
    // } else {
    // this.state.helper = window.multimediaHelper;
    // }
    this.setState({ loading: true, uploadDataArray: [], dataArray: [] });
    this.setOwnSettings();

    this.state.helper.loadList(
      document.location.href,
      (data) => {
        // alert("DA");
        // console.log(data);
        if (data != null) {
          // set a copy of the data for parent.
          if (this.props.setAuxiliarImageList) {
            this.props.setAuxiliarImageList(data);
          }
          this.setState({
            dataArray: data,
            uploadDataArray: this.state.helper
              .loadUploadQueue(document.location.href)
              .map((el) => el.file),
            loading: false,
          });

          return;
        }
      },
      this.props.type
    );
  };

  getItemName = (name) => {
    if (name != null && name != "") {
      return name.substring(0, name.lastIndexOf("."));
    }
    return " ";
  };

  getShortFileName = (name) => {
    let parts = name.split("/");
    return parts[parts.length - 1];
  };

  showFullscreen = (item, itemIndex) => {
    if (item.fullUrl == null) {
      this.setState({
        showFullScreenView: true,
        showFullScreenItemIndex: itemIndex,
        showFullScreenLoading: true,
      });
      this.state.helper.getItemLink(item, (url) => {
        this.setState({
          showFullScreenView: true,
          showFullScreenItemIndex: itemIndex,
          showFullScreenLoading: false,
        });
      });
    } else {
      this.setState({
        showFullScreenView: true,
        showFullScreenItemIndex: itemIndex,
        showFullScreenLoading: false,
      });
    }
  };

  showFullscreenRight = () => {
    const index = this.state.showFullScreenItemIndex + 1;
    if (index < this.state.dataArray.length) {
      this.showFullscreen(this.state.dataArray[index], index);
    }
  };

  showFullscreenLeft = () => {
    const index = this.state.showFullScreenItemIndex - 1;
    if (index >= 0) {
      this.showFullscreen(this.state.dataArray[index], index);
    }
  };

  hideFullscreen = () => {
    this.setState({
      showFullScreenView: false,
    });
  };

  /**
   * A function that is called when a file from the upload queue is done.
   * @param {string} target_origin the location.href of the page that requested the file upload.
   * @param {string} file_name the name (identifier) of the file that was poped from the upload queue.
   */
  onUploadedFile = (target_origin, file_name, doneItem) => {
    console.log(file_name);
    console.log(doneItem);
    if (!this.state.isMounted) return;
    if (
      !document.location.href.startsWith(target_origin) ||
      !target_origin.startsWith(document.location.href)
    ) {
      return;
    }
    let upload = this.state.uploadDataArray.find(
      (el) => el.fileName == file_name
    );
    if (!upload) return;
    let aux_upload_queue = this.state.uploadDataArray.filter(
      (el) => el.fileName != file_name
    );
    this.setState({
      uploadDataArray: aux_upload_queue,
      dataArray:
        doneItem != null
          ? [doneItem, ...this.state.dataArray]
          : this.state.dataArray,
    });
  };

  onProgressFile = (target_origin, file_name, progress) => {
    if (!this.state.isMounted) return;

    if (
      !document.location.href.startsWith(target_origin) &&
      !target_origin.startsWith(document.location.href)
    ) {
      return;
    }

    const aux_upload_data_array = this.state.uploadDataArray.map(
      (original_el) => {
        let el = { ...original_el };
        if (el.fileName == file_name) {
          let aux_el = el;
          aux_el.progress = progress;
          return aux_el;
        }
        return el;
      }
    );
    this.setState({
      ...this.state,
      uploadDataArray: [...aux_upload_data_array],
    });
  };

  /**
   * Active upload function on web
   *
   * @param e files upload event
   */
  onFileSelected = (e) => {
    const files_to_upload = Object.keys(e.target.files).map((key) => {
      const file_item = e.target.files[key];

      let file = new File([file_item], encodeURI(file_item.name), {
        type: e.target.files[key].type,
      });
      let fileName = file.name;
      let isImage = file.type.indexOf("image") != -1;

      return {
        fileName: fileName,
        fileSize: file_item.size,
        imageUrl: URL.createObjectURL(file_item),
        actual_image: file_item,
        progress: 0,
        type: isImage
          ? MultimediaHelper.TYPE_IMAGE
          : MultimediaHelper.TYPE_VIDEO,
      };
    });

    // update the local state
    this.setState({
      uploadDataArray: [...this.state.uploadDataArray, ...files_to_upload],
    });

    // add files to queue.
    this.state.helper.addToUploadQueue(document.location.href, files_to_upload);
    return;
  };

  /**
   * Select images from the gallery and adds them to the upload queue.
   */
  uploadImages = async () => {
    this.setState({ loading: true });
    try {
      const result = await FilePicker.pickImages({
        multiple: true,
        readData: true,
      });

      this.setState({ loading: false });
      let files_to_upload = [];
      for (let key in Object.keys(result.files)) {
        const data_blob = await fetch(
          "data:image/png;base64," + result.files[key].data
        ).then((r) => r.blob());
        const file = new File([data_blob], result.files[key].name, {
          type: result.files[key].mimeType,
        });

        let fileName = file.name;
        let isImage = file.type.indexOf("image") != -1;

        files_to_upload.push({
          fileName: fileName,
          fileSize: result.files[key].size,
          media_path: result.files[key].path,
          imageUrl: URL.createObjectURL(data_blob),
          actual_image: file,
          progress: 0,
          type: isImage
            ? MultimediaHelper.TYPE_IMAGE
            : MultimediaHelper.TYPE_VIDEO,
        });
      }

      // update the local state
      this.setState({
        uploadDataArray: [...this.state.uploadDataArray, ...files_to_upload],
      });

      // alert(JSON.stringify(files_to_upload));
      // alert("uploading");
      // return;
      // add files to queue.
      this.state.helper.addToUploadQueue(
        document.location.href,
        files_to_upload
        // this.onUploadedFile
      );
    } catch (ex) {
      this.setState({ loading: false });
      console.error(ex);
    }
  };

  /**
   * Select videos from the gallery and adds them to the upload queue.
   */
  uploadVideos = async () => {
    this.setState({ loading: true });
    try {
      const result = await FilePicker.pickVideos({
        multiple: true,
      });
      let files_to_upload = [];
      this.setState({ loading: false });
      for (let key in result.files) {
        let fileName = result.files[key].name;
        let fileSize = result.files[key].size;
        let isImage = result.files[key].mimeType.indexOf("image") != -1;

        const thumbNailMediaResult = await VideoEditor.thumbnail({
          path: result.files[key].path,
          width: 480,
          height: 320,
          at: 300,
        });

        const contents = await Filesystem.readFile({
          path: thumbNailMediaResult.file.path,
        });

        let file_to_upload = {
          fileName: fileName,
          imageUrl: "data:image/png;base64," + contents.data,
          fileSize: fileSize,
          media_path: result.files[key].path,
          progress: 0,
          mimeType: result.files[key].mimeType,
          type: isImage
            ? MultimediaHelper.TYPE_IMAGE
            : MultimediaHelper.TYPE_VIDEO,
        };
        files_to_upload.push(file_to_upload);
      }
      this.setState({
        uploadDataArray: [...this.state.uploadDataArray, ...files_to_upload],
      });

      this.state.helper.addToUploadQueue(
        document.location.href,
        files_to_upload
      );
    } catch (ex) {
      this.setState({ loading: false });
      console.error(ex);
    }
  };

  onCancelUploading = (item, index) => {
    this.state.helper.cancelUploading(item.fileName);
  };

  onShowAddYoutube = () => {
    this.setState({
      showAddYoutube: true,
      addYoutubeLink: "",
    });
  };

  hideAddYoutube = () => {
    this.setState({
      showAddYoutube: false,
    });
  };

  onYoutubeLinkChange = (e) => {
    this.setState({
      addYoutubeLink: e.target.value,
    });
  };

  onAddYoutubeLink = () => {
    this.state.helper.uploadYoutube(this.state.addYoutubeLink, (item) => {
      this.setState({
        dataArray: [item, ...this.state.dataArray],
      });
    });
    this.setState({
      showAddYoutube: false,
      addYoutubeLink: "",
    });
  };

  selectItem = (item, index) => {
    let dataArray = this.state.dataArray;
    dataArray[index].isSelected = !dataArray[index].isSelected;
    this.setState({
      dataArray: dataArray,
    });
  };

  onDeleteClick = () => {
    let items = this.state.dataArray.filter((f) => f.isSelected);
    this.setState({
      multiSelectMode: false,
      loading: items.length != 0,
    });
    if (items.length != 0) {
      this.state.helper.deleteItems(items, () => {
        this.loadData();
      });
    }
  };

  onDownloadClick = () => {
    let items = this.state.dataArray.filter((f) => f.isSelected);
    this.setState({
      multiSelectMode: false,
    });
    if (items.length <= 0) return;
    const imagesToDownload = items.map((el) => {
      return { url: el.fullUrl, name: el.name };
    });
    if (items.length < 5) {
      MultimediaHelper.downloadFilesOneByOne(imagesToDownload);
    } else {
      MultimediaHelper.downloadZip(imagesToDownload);
    }
  };

  onShareClick = async () => {
    let items = this.state.dataArray.filter((f) => f.isSelected);
    this.setState({
      multiSelectMode: false,
    });
    this.state.helper.shareItems(items);
  };

  onImageChangeNameStart = (item, index) => {
    let dataArray = this.state.dataArray;
    if (dataArray[index].type != MultimediaHelper.TYPE_YOUTUBE) {
      dataArray[index].changeNameMode = true;
      dataArray[index].nameNew = this.getItemName(dataArray[index].name);
      this.setState({
        dataArray: dataArray,
      });
    }
  };

  onImageNameNewChange = (e, item, index) => {
    let dataArray = this.state.dataArray;
    dataArray[index].nameNew = e.target.value;
    this.setState({
      dataArray: dataArray,
    });
  };

  onImageChangeNameEnd = (item, index) => {
    let dataArray = this.state.dataArray;
    let ext = dataArray[index].name.split(".").pop();
    const oldName = dataArray[index].name;
    let nameNew = dataArray[index].nameNew + "." + ext;
    let nameIsUpdated = dataArray[index].name != nameNew;
    dataArray[index].changeNameMode = false;
    dataArray[index].name = nameNew;
    this.setState({
      dataArray: dataArray,
    });
    if (nameIsUpdated) {
      this.state.helper.changeName(oldName, dataArray[index].name, (status) => {
        // let dataArray = this.state.dataArray;
        // dataArray[index] = itemChanged;
        // this.setState({
        //   dataArray: dataArray,
        // });
      });
    }
  };

  render() {
    let urlAddYoutubeFrame = null;
    if (this.state.helper != null && this.state.showAddYoutube) {
      urlAddYoutubeFrame = this.state.helper._getYoutubeCode(
        this.state.addYoutubeLink
      );
    }
    const showFullScreenLeft = this.state.showFullScreenItemIndex > 0;
    const showFullScreenRight =
      this.state.showFullScreenItemIndex < this.state.dataArray.length - 1;

    if (this.props.hideEditImages && this.state.dataArray.length == 0)
      return <></>;
    return (
      <div className="horse-gallery-holder">
        <MediaTypeAlert
          show={this.state.selectUploadMediaType}
          setShow={(newVal) => this.setState({ selectUploadMediaType: newVal })}
          selectImages={this.uploadImages}
          selectVideos={this.uploadVideos}
        />

        {this.props.customHeader}

        {!this.props.customHeader && (
          <div className="hgHeader">
            <div style={{ float: "left" }}>{this.props.title}</div>
            {!this.state.multiSelectMode ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                {this.props.showUploadButton ? (
                  this.state.platform != "web" ? (
                    <img
                      className="hgHeaderIcon"
                      src="img/photo_icon.png"
                      onClick={() =>
                        this.setState({ selectUploadMediaType: true })
                      }
                    />
                  ) : (
                    <div>
                      <input
                        id={"hgAddImage" + this.props.type}
                        type="file"
                        accept={
                          "video/*,image/jpg,image/jpeg,image/png" +
                          (this.props.acceptPdf ? ",application/pdf" : "")
                        }
                        style={{ display: "none" }}
                        multiple
                        onChange={this.onFileSelected}
                      />
                      <label htmlFor={"hgAddImage" + this.props.type}>
                        <img
                          className="hgHeaderIcon"
                          src="img/photo_icon.png"
                        />
                      </label>
                    </div>
                  )
                ) : (
                  <></>
                )}
                {this.props.showYoutube ? (
                  <img
                    className="hgHeaderIcon"
                    src="img/youtube_logo.png"
                    onClick={this.onShowAddYoutube}
                  />
                ) : (
                  <></>
                )}
                <div
                  style={{ cursor: "pointer" }}
                  onClick={this.toggleExtraButtons}
                >
                  {window.lang.select}
                </div>
              </div>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                {this.state.platform != "web" ? (
                  <img
                    className="hgHeaderIcon"
                    src="img/share.png"
                    onClick={this.onShareClick}
                  />
                ) : (
                  <img
                    className="hgHeaderIcon"
                    src="img/download.png"
                    onClick={this.onDownloadClick}
                  />
                )}
                <img
                  className="hgHeaderIcon"
                  src="img/trashcan.png"
                  onClick={this.onDeleteClick}
                />
                <div
                  style={{ cursor: "pointer" }}
                  onClick={this.toggleExtraButtons}
                >
                  {window.lang.cancel2}
                </div>
              </div>
            )}
          </div>
        )}

        {this.state.testUrl != null && (
          <img loading="lazy" src={this.state.testUrl} />
        )}

        {this.state.loading && <SpinnerView />}
        <div className="hgThumbImagesList">
          {/*uploading items*/}
          {this.state.uploadDataArray
            .slice()
            .reverse()
            .map((item, index) => (
              <div key={"x" + index} className="hgImageItem">
                <div className="hgImageName">
                  <span className="hgImageNameContainer">
                    <span className="hgImageNameTitle">
                      {this.getItemName(this.getShortFileName(item.fileName))}
                    </span>
                  </span>
                </div>
                {item.type == MultimediaHelper.TYPE_IMAGE ? (
                  <div
                    className="hgThumbImage"
                    style={{ backgroundImage: `url('${item.imageUrl}')` }}
                  />
                ) : this.state.platform == "web" ? (
                  <video src={item.imageUrl} className="hgThumbImage"></video>
                ) : (
                  <div
                    className="hgThumbImage"
                    style={{ backgroundImage: `url(${item.imageUrl})` }}
                  ></div>
                )}

                <div style={{}}>
                  <progress max={100} value={item.progress * 100} />
                  <span
                    style={{ float: "right", cursor: "pointer" }}
                    onClick={() => this.onCancelUploading(item, index)}
                  >
                    Cancel
                  </span>
                </div>
              </div>
            ))}

          {/*current items*/}
          {this.state.dataArray.map((item, index) => (
            <div key={"y" + index} className="hgImageItem">
              {!this.props.hideEditImages && (
                <div className="hgImageName">
                  {!item.changeNameMode ? (
                    <span
                      className="hgImageNameContainer"
                      onClick={() => this.onImageChangeNameStart(item, index)}
                    >
                      <span className="hgImageNameTitle">
                        {this.getItemName(item.name)}
                      </span>
                      <img
                        className="hgImageNameSave"
                        src="img/edit_icon.png"
                      />
                    </span>
                  ) : (
                    <>
                      <div className="containerHgImageNameInput">
                        <input
                          value={item.nameNew}
                          className="hgImageNameInput"
                          autoFocus={true}
                          onChange={(e) =>
                            this.onImageNameNewChange(e, item, index)
                          }
                        />
                      </div>
                      <img
                        className="hgImageNameSave m-auto"
                        src="img/icon_okn.png"
                        onClick={() => this.onImageChangeNameEnd(item, index)}
                      />
                    </>
                  )}
                </div>
              )}
              {this.props.hideEditImages && (
                <div className="hgImageName">
                  <span className="hgImageNameContainer">
                    <span className="hgImageNameTitle">
                      {this.getItemName(item.name)}
                    </span>
                  </span>
                </div>
              )}
              <div
                className="hgThumbImage"
                style={{ backgroundImage: `url('${item.thumbUrl}')` }}
                onClick={() =>
                  !this.state.multiSelectMode
                    ? this.showFullscreen(item, index)
                    : this.selectItem(item, index)
                }
              >
                {(item.type == MultimediaHelper.TYPE_VIDEO ||
                  item.type == MultimediaHelper.TYPE_YOUTUBE) && (
                  <img
                    loading="lazy"
                    src="img/play_arrow.svg"
                    className="hgThumbImagePlay"
                  />
                )}
                {this.state.multiSelectMode && (
                  <img
                    className="hgThumbSelectIcon"
                    src={
                      item.isSelected
                        ? "img/icon_okn.png"
                        : "img/icon_circle.png"
                    }
                  />
                )}
              </div>
            </div>
          ))}
        </div>

        {this.state.showAddYoutube && (
          <div className="hgAddYoutubePopup">
            <table>
              <tbody>
                <tr>
                  <td style={{ width: "95%" }}>
                    <input
                      value={this.state.addYoutubeLink}
                      className="hgInput"
                      style={{ width: "95%" }}
                      onChange={this.onYoutubeLinkChange}
                    />
                  </td>
                  <td>
                    <img
                      src="img/icon_okn.png"
                      style={{ height: "40px" }}
                      onClick={this.onAddYoutubeLink}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {urlAddYoutubeFrame != null && (
              <iframe
                src={`https://www.youtube.com/embed/${urlAddYoutubeFrame}`}
                style={{ width: "100%", height: "300px" }}
                frameBorder={0}
              />
            )}
            <img
              className="hgAddYoutubePopupClose"
              src="img/icon_canceln.png"
              onClick={this.hideAddYoutube}
            />
          </div>
        )}

        {this.state.showFullScreenView && (
          <div className="hgFullScreenView">
            {!this.state.showFullScreenLoading && this.renderFullscreenItem()}
            <img
              className="hgFullScreenClose"
              src="img/icon_canceln.png"
              onClick={this.hideFullscreen}
            />
            {this.state.dataArray[this.state.showFullScreenItemIndex].type !=
              MultimediaHelper.TYPE_PDF && (
              <>
                <img
                  className="hgFullScreenLeft"
                  src="img/icon_left.svg"
                  onClick={this.showFullscreenLeft}
                  style={showFullScreenLeft ? {} : { display: "none" }}
                />
                <img
                  className="hgFullScreenRight"
                  src="img/icon_right.svg"
                  onClick={this.showFullscreenRight}
                  style={showFullScreenRight ? {} : { display: "none" }}
                />
              </>
            )}
          </div>
        )}
      </div>
    );
  }

  renderFullscreenItem = () => {
    const item = this.state.dataArray[this.state.showFullScreenItemIndex];
    switch (item.type) {
      case MultimediaHelper.TYPE_IMAGE:
        return (
          <img loading="lazy" src={item.fullUrl} className="hgFullScreenItem" />
        );
      case MultimediaHelper.TYPE_VIDEO:
        // @todo Add a thumbnail
        console.log(item);
        return (
          <video
            controls="controls"
            poster={item.thumbUrl}
            className="hgFullScreenItem"
          >
            <source src={item.fullUrl} type="video/mp4" />
          </video>
        );
      case MultimediaHelper.TYPE_YOUTUBE:
        return (
          <iframe
            src={item.fullUrl}
            className="hgFullScreenYoutube"
            frameborder={0}
          />
        );
      case MultimediaHelper.TYPE_PDF:
        return <PdfViewer src={item.fullUrl} className="hgFullScreenItem" />;
    }
    return <div></div>;
  };
}

export default HorseGallery;
