import React, { Component } from "react";
import Select from "react-select";
import "./OwnerInfoPage.css";

class OwnerInfoPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      owners: [],
    };

    window.TopMenu.Self.setVisible(false);
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.horseId != prevProps.horseId) {
      this.loadData();
    }
    window.TopMenu.Self.setVisible(false);
  }

  loadData() {
    this.loadOwnwers(() => {
      window.Spinner.show();
      window.db.getPaperOwnerByHorse(this.props.horseId, (paperOwner) => {
        let selectedOwners = [];
        if (
          paperOwner != null &&
          paperOwner[0].PaperOwner != null &&
          paperOwner[0].PaperOwner != ""
        ) {
          selectedOwners = JSON.parse(paperOwner[0].PaperOwner);
        } else {
        }
        if (selectedOwners.length == 0) {
          selectedOwners.push(-1);
        }
        let owners = this.state.owners;
        for (let i = 0; i < owners.length; i++) {
          owners[i].isSelected = selectedOwners.indexOf(owners[i].Id) != -1;
          owners[i].isEdit = owners[i].Id != -1 ? false : true;
        }
        this.setState({
          owners: owners,
        });
        window.Spinner.hide();
      });
    });
  }

  loadOwnwers = (callback) => {
    window.Spinner.show();
    window.db.getOwnerByUser(window.myid, (owners) => {
      owners = [
        {
          Id: -1,
          Name: "Create",
          Address: "",
          City: "",
          Company: "",
          Country: "",
          Email: "",
          Phone: "",
        },
        ...owners,
      ];
      this.setState({
        owners: owners,
      });
      window.Spinner.hide();
      if (callback) {
        callback();
      }
    });
  };

  handleSelectChange = (e) => {
    const selectedOwners = e.map((item) => item.value);
    let owners = this.state.owners;
    for (let i = 0; i < owners.length; i++) {
      owners[i].isSelected = selectedOwners.indexOf(owners[i].Id) != -1;
    }
    this.setState({
      owners: owners,
    });
  };

  onInputChange = (e, item) => {
    item[e.target.name] = e.target.value;
    const index = this.state.owners.findIndex((o) => o.Id == item.Id);
    let owners = [...this.state.owners];
    owners[index] = item;
    this.setState({
      owners: owners,
    });
  };

  onEditClick = (item) => {
    const index = this.state.owners.findIndex((o) => o.Id == item.Id);
    item.isEdit = true;
    let owners = [...this.state.owners];
    owners[index] = item;
    this.setState({
      owners: owners,
    });
  };

  onDeleteClick = (item) => {
    this.setState({
      owners: this.state.owners.filter((o) => o.Id != item.Id),
    });

    window.Spinner.show();
    window.db.deleteOwnerByHorse(item.Id, () => {
      window.Spinner.hide();
    });
  };

  onCallClick = (item) => {
    if (window.isMobileDevice()) {
      window.location = "eqapp://caller/tel:" + item.Phone;
    }
  };

  onSaveItemClick = (item) => {
    if (item.Id != -1) {
      window.Spinner.show();
      window.db.editOwner(
        item.Id,
        item.Name,
        item.Company,
        item.Address,
        item.City,
        item.Country,
        item.Phone,
        item.Email,
        () => {
          window.Spinner.hide();
        }
      );
    } else {
      if (item.Name != "" && item.Name != "Create") {
        window.Spinner.show();
        item.Id = Math.uuid();
        window.db.insertOwner(
          item.Id,
          item.Name,
          item.Company,
          item.Address,
          item.City,
          item.Country,
          item.Phone,
          item.Email,
          () => {
            window.Spinner.hide();
          }
        );
      } else {
        navigator.notification.confirm(
          "Please, enter Owner's Name",
          function (buttonIndex) {},
          "Equestrians App",
          window.lang.ok
        );
      }
    }

    const index = this.state.owners.findIndex((o) => o.Id == item.Id);
    item.isEdit = false;
    let owners = [...this.state.owners];
    owners[index] = item;
    this.setState({
      owners: owners,
    });
  };

  onSaveClick = () => {
    const selectedOwners = this.state.owners
      .filter((o) => o.isSelected)
      .map((o) => o.Id);
    const strData = JSON.stringify(selectedOwners);

    window.Spinner.show();
    window.db.setPaperOwnerByHorse(this.props.horseId, strData, () => {
      window.Spinner.hide();
      window.Managers.extention.showHorsePage(this.props.horseId);
    });
  };

  render() {
    const { horseId } = this.props;
    const horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == horseId
    );

    const selectedOwners = this.state.owners.filter((o) => o.isSelected);

    const options = this.state.owners.map((o) => ({
      value: o.Id,
      label: o.Name,
    }));
    const values = this.state.owners
      .filter((o) => o.isSelected)
      .map((o) => ({ value: o.Id, label: o.Name }));

    return (
      <div className="pageScrollable">
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            loading="lazy"
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={this.onSaveClick}
          />
          <img
            loading="lazy"
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onSaveClick}
          />
        </div>
        <div className="oipContent">
          <div style={{ fontSize: "2.5em", textAlign: "center" }}>
            {horse.name}
          </div>

          <Select
            value={values}
            isMulti
            options={options}
            onChange={this.handleSelectChange}
          />

          <div id="ownerInfoContent">
            {selectedOwners.map((item) => (
              <div key={item.Id}>
                <table style={{ width: "100%", marginTop: "10px" }}>
                  <tbody>
                    <tr>
                      <td style={{ width: "50%" }}>
                        <div>Name</div>
                        <input
                          className="r-input"
                          value={item.Name != "Create" ? item.Name : ""}
                          name="Name"
                          onChange={(e) => this.onInputChange(e, item)}
                          disabled={!item.isEdit}
                        />
                      </td>
                      <td>
                        <div>Company</div>
                        <input
                          className="r-input"
                          value={item.Company}
                          name="Company"
                          onChange={(e) => this.onInputChange(e, item)}
                          disabled={!item.isEdit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>
                        <div>Address</div>
                        <input
                          className="r-input"
                          value={item.Address}
                          name="Address"
                          onChange={(e) => this.onInputChange(e, item)}
                          disabled={!item.isEdit}
                        />
                      </td>
                      <td>
                        <div>City</div>
                        <input
                          className="r-input"
                          value={item.City}
                          name="City"
                          onChange={(e) => this.onInputChange(e, item)}
                          disabled={!item.isEdit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>
                        <div>Country</div>
                        <input
                          className="r-input"
                          value={item.Country}
                          name="Country"
                          onChange={(e) => this.onInputChange(e, item)}
                          disabled={!item.isEdit}
                        />
                      </td>
                      <td>
                        <div>Phone</div>
                        <input
                          className="r-input"
                          value={item.Phone}
                          name="Phone"
                          onChange={(e) => this.onInputChange(e, item)}
                          disabled={!item.isEdit}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <div>Email</div>
                        <input
                          className="r-input"
                          value={item.Email}
                          name="Email"
                          onChange={(e) => this.onInputChange(e, item)}
                          disabled={!item.isEdit}
                        />
                      </td>
                    </tr>
                    {!item.isEdit ? (
                      <>
                        <tr>
                          <td colSpan="2">
                            <button
                              className="ui-btn ui-corner-all"
                              onClick={() => this.onCallClick(item)}
                            >
                              Call
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <button
                              className="ui-btn ui-corner-all"
                              onClick={() => this.onDeleteClick(item)}
                            >
                              {window.lang.deleteL}
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <button
                              className="ui-btn ui-corner-all"
                              onClick={() => this.onEditClick(item)}
                            >
                              {window.lang.edit}
                            </button>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <tr>
                        <td colSpan="2">
                          <button
                            className="ui-btn ui-corner-all"
                            onClick={() => this.onSaveItemClick(item)}
                          >
                            Save
                          </button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
export default OwnerInfoPage;
