import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class GroupDeleteUpdateQuestion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDelete: false
        };
    }

    componentDidMount = () => {
        this.setState({
            isDelete: this.props.isDelete
        });
    }

    onGroupClick = () => {
        if (this.props.doneCallback)
            this.props.doneCallback(true);
        this.hide();
    }

    onSingleClick = () => {
        if (this.props.doneCallback)
            this.props.doneCallback(false);
        this.hide();
    }

    hide = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById('updDelGeneralTaskDivReact'));
    }

    render() {
        return (
            <div className="copy-task ui-page-theme-a" style={{ display: 'block' }}>
                <table>
                    {this.state.isDelete ?
                        <tbody>
                            <tr>
                                <td>
                                    <div id="tsWPText1">{window.lang.thisTaskIsPartOfaSeries + ' ' + window.lang.doYouWantTo + ':'}</div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{ textAlign: 'right' }}>
                                    <button id="tsWPOkButton" className=' ui-btn ui-shadow ui-corner-all' style={{ marginBottom: '0px' }} onClick={this.onSingleClick}>Delete this task only</button>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{ textAlign: 'right' }}>
                                    <button id="tsWPCancelButton" className=' ui-btn ui-shadow ui-corner-all' style={{ margin: '0px' }} onClick={this.onGroupClick}>Delete all tasks in this serie</button>
                                </td>
                            </tr>
                        </tbody>
                        :
                        <tbody>
                            <tr>
                                <td>
                                    <div id="tsWPText1">{window.lang.thisTaskIsPartOfaSeries + ' ' + window.lang.doYouWantTo + ':'}</div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{ textAlign: 'right' }}>
                                    <button id="tsWPOkButton" className=' ui-btn ui-shadow ui-corner-all' style={{ marginBottom: '0px' }} onClick={this.onSingleClick}>{window.lang.changeThisTaskOnly}</button>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{ textAlign: 'right' }}>
                                    <button id="tsWPCancelButton" className=' ui-btn ui-shadow ui-corner-all' style={{ margin: '0px' }} onClick={this.onGroupClick}>{window.lang.changeAllTasksInThisSerie}</button>
                                </td>
                            </tr>
                        </tbody>
                    }
                </table>
            </div>
        )
    }
}

export default class GroupDeleteUpdateQuestionWindow {
    showWindow(isDelete, doneCallback) {
        ReactDOM.render(
            <GroupDeleteUpdateQuestion
                isDelete={isDelete}
                doneCallback={doneCallback}
            />,
            document.getElementById('updDelGeneralTaskDivReact')
        )
    }
}