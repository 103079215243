import React, { Component } from "react";
import { Resizable } from "re-resizable";
import "./Rider2RowsView.css";
import TrainingCellMap from "../TrainingCellMap";
import HorseCell from "../HorseCell/HorseCell";
import TrainingDay from "../TrainingCell/TrainingDay";

export default class Rider2RowsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ridersOrder: [],
    };
  }

  renderRiderTable = (rider) => {
    let trainingCellMap = new TrainingCellMap();
    let trainingArr = this.props.filteredTrainings.filter(
      (t) => t.trainer == rider.id
    );
    trainingCellMap.refreshMap([this.props.date.formatDesh()], trainingArr);

    let horseIdArr = trainingArr.map((t) => t.horseid);
    let horses = this.props.filteredHorses.filter(
      (h) => horseIdArr.indexOf(h.id) != -1
    );
    if (!this.props.showOnlyOwnHorses && rider.id == window.myid) {
      let horseIdsWithTrainings = this.props.filteredTrainings.map(
        (t) => t.horseid
      );
      let horsesWihoutTrainings = this.props.filteredHorses.filter(
        (h) => horseIdsWithTrainings.indexOf(h.id) == -1
      );
      horses = [...horses, ...horsesWihoutTrainings];
    }
    horses = trainingCellMap.sortHorsesBySelectedDate(
      horses,
      this.props.date.formatDesh(),
      (horse, orderDate) => trainingArr.filter((t) => t.horseid == horse.id)
    );

    let trainingTdWidth =
      Math.max(
        trainingCellMap.map[this.props.date.formatDesh()].columns.length,
        1
      ) * 80;

    return (
      <table className="riderTableR" key={rider.id}>
        <thead>
          <tr>
            <td>{window.lang.horse}</td>
            <td>{rider.name}</td>
          </tr>
        </thead>
        <tbody>
          {horses.map((horse) => (
            <tr key={`${rider.id}&${horse.id}`}>
              <td style={{ textAlign: "left" }}>
                <HorseCell horse={horse} />
              </td>
              <td
                key={`${rider.id}&${horse.id}&tasks`}
                style={{ width: trainingTdWidth, minWidth: trainingTdWidth }}
              >
                <TrainingDay
                  trainings={trainingArr.filter((t) => t.horseid == horse.id)}
                  date={this.props.date}
                  isSelectedDate={true}
                  horse={horse}
                  trainingCellMap={trainingCellMap}
                  showRider={false}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  createRiderOrder = (riderId) => {
    this.state.ridersOrder[riderId] =
      Object.keys(this.state.ridersOrder).length + 1;
    return this.state.ridersOrder[riderId];
  };

  render() {
    const { filteredHorses, filteredTrainings, date, showOnlyOwnHorses } =
      this.props;
    let riderArray = [];
    riderArray.push({
      id: window.myid,
      name: window.lang.me,
    });
    if (!showOnlyOwnHorses) {
      for (let i = 0; i < filteredTrainings.length; i++) {
        let training = filteredTrainings[i];
        let rider = riderArray.find((rider) => rider.id == training.trainer);
        if (rider == null) {
          riderArray.push({
            id: training.trainer,
            name: training.trainername,
          });
        }
      }

      //filter by show rider settings
      this.state.ridersOrder = window.GlobalData.getItem(
        window.GlobalData.KEY_RIDERS_ORDER
      );
      if (this.state.ridersOrder == null) {
        this.state.ridersOrder = {};
      } else {
        this.state.ridersOrder = JSON.parse(this.state.ridersOrder);
      }
      if (
        window.Managers.ridersIsShowing == null ||
        Object.keys(window.Managers.ridersIsShowing).length == 0
      ) {
        let f = window.GlobalData.getItem(
          window.GlobalData.KEY_RIDERS_IS_SHOWING
        );
        if (f != null) {
          window.Managers.ridersIsShowing = JSON.parse(f);
        }
      }
    }
    riderArray = riderArray.filter(
      (r) => window.Managers.ridersIsShowing[r.id] != 0
    );
    var ridersMass = new Array();
    for (var i = 0; i < riderArray.length; i++) {
      ridersMass.push(riderArray[i]);
    }
    for (var i = 0; i < ridersMass.length; i++) {
      var order = this.state.ridersOrder[ridersMass[i]["id"]];
      if (order == null) {
        order = this.createRiderOrder(ridersMass[i]["id"]);
      }
      ridersMass[i]["orderC"] = order;
    }
    ridersMass.sort(function (first, second) {
      return first["orderC"] - second["orderC"];
    });

    return (
      <div
        className={
          !window.isMobileDevice() && !window.isIphoneIpad()
            ? "twoRidersDiv"
            : "twoRidersDivMobile"
        }
      >
        <div className="twoRidersContainer">
          {ridersMass.map((rider, rIndex) => (
            <Resizable
              key={rider.id}
              defaultSize={{ width: 500 }}
              enable={{
                top: false,
                right: true,
                bottom: false,
                left: false,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false,
              }}
            >
              {this.renderRiderTable(rider)}
            </Resizable>
          ))}
        </div>
      </div>
    );
  }
}
