import React, { Component } from "react";
import "./AllHorseDataWithPrice.css";
export default class AllHorseDataWithPrice extends Component {
  constructor(props) {
    super(props);

    window.Spinner.show();
    this.state = {
      headers: [
        window.lang.name,
        window.lang.type,
        window.lang.age,
        "Sex",
        window.lang.pedigree,
        window.lang.color,
        window.lang.height,
        window.lang.result,
        "Reg. nr",
        window.lang.price,
      ],
      reportData: [],
      sortedBy: null,
      sortedIncreasing: false,
    };
  }

  componentDidMount = () => {
    const horseTypes = JSON.parse(
      window.GlobalData.getItem(window.GlobalData.SHOW_IN_TRAINING)
    );
    this.isHistoricHorses = horseTypes[0] == 101;
    document.getElementById("trainFooter").style.display = "none";
    this.createAllHorsesWithPrice();
  };
  componentWillUnmount = () => {
    document.getElementById("trainFooter").style.display = "flex";
  };
  getPermission = (horseId) => {
    let horseItem = window.DataStorage.getAllHorses().find(
      (h) => h.id == horseId
    );
    if (horseItem) {
      if (horseItem.userid != window.myid) {
        let role = window.DataStorage.rolesDataFromUsers.find(
          (r) => r.UserId == horseItem.userid
        );
        if (role != null && role.rSeeHorseData == false) return false;
        else return true;
      } else return true;
    } else {
      return false;
    }
  };
  setPeddi = (jsonText) => {
    var text = JSON.parse(jsonText);
    if (text != null) {
      var peddiText =
        text["mather"] +
        " / " +
        text["grmather_father"] +
        " / " +
        text["grmather_grfather_f"];
    } else {
      var peddiText = "";
    }
    return peddiText;
  };
  setGender = (index) => {
    var genderText = "";
    if (index == 0) {
      genderText = "None";
    } else if (index == 1) {
      genderText = window.lang.horseTypeMare;
    } else if (index == 2) {
      genderText = window.lang.horseTypeGelding;
    } else {
      genderText = window.lang.horseTypeStallion;
    }
    return genderText;
  };
  createAllHorsesWithPrice = () => {
    window.db.getHorsesForReportByTypeAndWithPrice(
      this.isHistoricHorses,
      (dataHorses) => {
        let dataArray = [];
        for (let t = 0; t < dataHorses.length; t++) {
          if (!this.getPermission(dataHorses[t].id)) continue;
          var typeName = dataHorses[t]["Type"] || "None";
          var color =
            dataHorses[t].ColorName == null ? "" : dataHorses[t].ColorName;
          var price = dataHorses[t].Price == null ? "" : dataHorses[t].Price;
          var peddi = this.setPeddi(dataHorses[t].PedigreeJsonText);
          dataArray.push([
            dataHorses[t].Name,
            typeName,
            dataHorses[t].BirthYear,
            this.setGender(dataHorses[t].Gender),
            peddi,
            color,
            dataHorses[t].Height,
            dataHorses[t].Result,
            dataHorses[t].RFBid,
            price,
          ]);
        }

        this.setState({
          reportData: dataArray,
        });
        window.Spinner.hide();
      }
    );
  };

  executeSort = (attribute_index, increasing) => {
    let aux = this.state.reportData.sort((a, b) =>
      a[attribute_index].localeCompare(b[attribute_index])
    );
    if (!increasing) {
      aux = aux.reverse();
    }
    this.setState({ reportData: aux });
  };

  sortBy = (sort_index) => {
    if (this.state.sortedBy == sort_index) {
      this.executeSort(sort_index, !this.state.sortedIncreasing);
      this.setState({ sortedIncreasing: !this.state.sortedIncreasing });
      return;
    }

    this.executeSort(sort_index, false);
    this.setState({ sortedBy: sort_index, sortedIncreasing: false });
  };
  render() {
    return (
      <div
        id="sellDataDiv"
        style={{ overflowY: "scroll", maxHeight: "calc(100vh - 120px)" }}
      >
        <table
          className="TeethTable"
          style={{
            marginTop: "25px",
          }}
        >
          <tr>
            {this.state.headers.map((header, header_index) => (
              <th
                key={header_index}
                style={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  position: "relative",
                }}
                onClick={() => this.sortBy(header_index)}
              >
                <span style={{ marginLeft: "5px" }}>{header}</span>
                {this.state.sortedBy == header_index &&
                  this.state.sortedIncreasing && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 320 512"
                      fill="#fff"
                      style={{
                        position: "absolute",
                        transform: "translateX(5px)",
                      }}
                    >
                      <path d="M182.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" />
                    </svg>
                  )}
                {this.state.sortedBy == header_index &&
                  !this.state.sortedIncreasing && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 320 512"
                      fill="#fff"
                      style={{
                        position: "absolute",
                        transform: "translateX(5px)",
                      }}
                    >
                      <path d="M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z" />
                    </svg>
                  )}
              </th>
            ))}
          </tr>
          <tbody>
            {this.state.reportData.map((horseDetails, horse_index) => (
              <tr key={horse_index}>
                {horseDetails.map((el, el_index) => (
                  <td key={el_index} className="centered">
                    {el}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
