import React, { Component } from "react";
import "./NotesPage.css";
import HorseGallery from "../.././Multimedia/HorseGallery";

class NotesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mainNote: "",
      notes: [],
      selectedNoteIndex: -1,
      isAddShow: false,
      addNoteTitle: "",
    };
  }
  componentDidMount() {
    window.TopMenu.Self.setVisible(false);
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.horseId != prevProps.horseId) {
      this.loadData();
    }
    window.TopMenu.Self.setVisible(false);
  }

  loadData() {
    const horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == this.props.horseId
    );
    if (horse.notes != null) {
      this.setState({
        mainNote: horse.notes,
      });
    }

    window.Spinner.show();
    window.db.getNotesByHorse(this.props.horseId, (data) => {
      window.Spinner.hide();
      this.setState({
        notes: data,
      });
    });
  }

  onNoteTextChange = (e) => {
    if (this.state.selectedNoteIndex == -1) {
      this.setState({
        mainNote: e.target.value,
        isChanged: true,
      });
    } else {
      let notes = [...this.state.notes];
      notes[this.state.selectedNoteIndex].TextNote = e.target.value;
      notes[this.state.selectedNoteIndex].isChanged = true;
      this.setState({
        notes: notes,
      });
    }
  };

  selectNote = (noteIndex) => {
    this.setState({
      selectedNoteIndex: noteIndex,
    });
  };

  saveCurrentText = (callback) => {
    if (this.state.selectedNoteIndex == -1) {
      //save main note
      if (this.state.isChanged) {
        window.Spinner.show();
        window.db.updateNoteByHorse(
          this.props.horseId,
          this.state.mainNote,
          () => {
            let horse = window.DataStorage.getAllHorses().find(
              (h) => h.id == this.props.horseId
            );
            horse.notes = this.state.mainNote;
            window.training.refresh();
            window.Spinner.hide();
            this.setState({
              isChanged: false,
            });
            if (callback) callback();
          }
        );
      } else {
        if (callback) callback();
      }
    } else {
      //save selected note
      const note = this.state.notes[this.state.selectedNoteIndex];
      if (note.isChanged) {
        window.Spinner.show();
        window.db.updateNoteHorse(note.Id, note.TextNote, () => {
          window.Spinner.hide();
          if (callback) callback();
        });
      } else {
        if (callback) callback();
      }
    }
  };

  deleteNote = (note) => {
    navigator.notification.confirm(
      "Do you want delete note?",
      (buttonIndex) => {
        if (buttonIndex == 1) {
          window.Spinner.show();
          window.db.deleteNote(note.Id, () => {
            window.Spinner.hide();
            this.setState({
              notes: this.state.notes.filter((n) => n.Id != note.Id),
              selectedNoteIndex: -1,
            });
            this.needDropboxUpdate = true;
          });
        }
      }
    );
  };

  addNewNotesStart = () => {
    this.setState({
      isAddShow: true,
      addNoteTitle: "",
    });
  };

  onAddNoteTitleChange = (e) => {
    this.setState({
      addNoteTitle: e.target.value,
    });
  };

  addNewNotesFinish = () => {
    const noteNew = {
      Id: Math.uuid(),
      HeaderNote: this.state.addNoteTitle,
      IdHorse: this.props.horseId,
      IsPrice: 0,
      OrderBy: Math.round(new Date().getTime() / 1000),
      TextNote: "",
    };
    window.Spinner.show();
    window.db.insertNote(
      noteNew.Id,
      noteNew.IdHorse,
      noteNew.HeaderNote,
      noteNew.IsPrice,
      noteNew.OrderBy,
      () => {
        window.Spinner.hide();
        this.setState({
          isAddShow: false,
          notes: [...this.state.notes, noteNew],
          selectedNoteIndex: this.state.notes.length,
        });
        this.needDropboxUpdate = true;
      }
    );
  };

  onSaveClick = () => {
    this.saveCurrentText(() => {
      window.Managers.extention.showHorsePage(this.props.horseId);
    });
  };

  render() {
    const { mainNote, notes, selectedNoteIndex, isAddShow } = this.state;
    const { horseId } = this.props;
    const horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == horseId
    );
    let noteText = mainNote;
    if (selectedNoteIndex != -1) {
      noteText = notes[selectedNoteIndex].TextNote;
    }

    return (
      <div className="pageScrollable">
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            loading="lazy"
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={this.onSaveClick}
          />
          <span className="npTitle">{horse.name}</span>
          <img
            loading="lazy"
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onSaveClick}
          />
        </div>
        <div className="npContent">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <button
              className="ui-btn ui-corner-all npButton"
              style={
                selectedNoteIndex == -1 ? { backgroundColor: "lightgrey" } : {}
              }
              onClick={() => this.selectNote(-1)}
            >
              {window.lang.notes}
            </button>
            {notes.map((note, noteIndex) => (
              <button
                key={note.Id}
                className="ui-btn ui-corner-all npButton"
                style={
                  selectedNoteIndex == noteIndex
                    ? { backgroundColor: "lightgrey" }
                    : {}
                }
                onClick={() => this.selectNote(noteIndex)}
              >
                <span>{note.HeaderNote}</span>
                <img
                  loading="lazy"
                  className="npDeleteIcon"
                  src="img/delete-black.png"
                  onClick={() => this.deleteNote(note)}
                />
              </button>
            ))}
            {!isAddShow ? (
              <img
                loading="lazy"
                src="img/add.png"
                className="npAddIcon"
                onClick={this.addNewNotesStart}
              />
            ) : (
              <>
                <input
                  className="r-input"
                  style={{ width: "150px" }}
                  value={this.state.addNoteTitle}
                  onChange={this.onAddNoteTitleChange}
                />
                <img
                  loading="lazy"
                  src="img/icon_okn.png"
                  className="npAddIcon"
                  onClick={this.addNewNotesFinish}
                />
              </>
            )}
          </div>
          <textarea
            className="npTextarea"
            value={noteText}
            onChange={this.onNoteTextChange}
            onBlur={() => this.saveCurrentText()}
          ></textarea>
          {selectedNoteIndex == -1 ? (
            <HorseGallery
              showUploadButton={true}
              type={"Notes"}
              horse={horse}
              title={"Notes"}
            />
          ) : (
            <HorseGallery
              showUploadButton={true}
              type={"TextNote"}
              horse={horse}
              title={"Notes"}
              noteOrTaskId={notes[selectedNoteIndex].Id}
            />
          )}
        </div>
      </div>
    );
  }
}
export default NotesPage;
