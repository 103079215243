import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import DatePicker from "react-datepicker";
import './VaccinationPopup.css';

class HerpesVaccinationPopupWindow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            horseArr: [],
            currentHorseId: '',
            vaccinationTypes: [],
            date: '',
            nextVaccinationDate: '',
            currentVaccinationType: '',
            currentVaccinationId: '',
            currentHorseUserid: '',
            showHorseList: ''
        }
    }

    componentDidUpdate = () => {
        this.scrollToSelectedItems();
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        let horseArr = window.DataStorage.getAllHorses();
        horseArr = horseArr.sort((h1, h2) => (h1.name.toLowerCase() > h2.name.toLowerCase()) ? 1 : -1);
        let vaccinationTypes = window.DataStorage.herpesVaccinationTypes;
        let currentHorseId = '';
        let currentVaccinationType = '';
        let currentVaccinationId = '';

        if (!this.props.options.horseId)
            currentHorseId = horseArr[0].id;
        else
            currentHorseId = this.props.options.horseId;

        window.db.getHorse(currentHorseId, (horse) => {
            if (this.props.item) {
                currentVaccinationType = this.props.item.vtype;
                currentVaccinationId = this.props.item.id;
            } else {
                currentVaccinationType = this.props.options.vtype;
            }

            let date = new Date(JSON.parse(JSON.stringify(this.props.options.date)));
            let nextVaccinationDate = this.props.options.date;
            nextVaccinationDate.setMonth(nextVaccinationDate.getMonth() + currentVaccinationType.MaxMonth);
            nextVaccinationDate = nextVaccinationDate.addDays(currentVaccinationType.MaxDay);
            let showHorseList = this.props.options.showHorseList;
            this.setState({
                horseArr: horseArr,
                currentHorseId: currentHorseId,
                vaccinationTypes: vaccinationTypes,
                date: date,
                currentVaccinationType: currentVaccinationType,
                nextVaccinationDate: nextVaccinationDate.formatDesh(),
                currentVaccinationId: currentVaccinationId,
                currentHorseUserid: horse.userid,
                showHorseList: showHorseList
            });
        });
    }

    scrollToSelectedItems = () => {
        let selectedCollection = document.getElementsByClassName("ui-btn selectedListItem");
        let selectedArr = [].slice.call(selectedCollection);
        if (selectedArr.length)
            selectedArr.map(i => i.scrollIntoView());
    }

    hide = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById('vaccinationPopupReact'));
    }

    onCancelClick = () => {
        this.hide();
    }

    onHorseClick = (e) => {
        if (e.currentTarget.dataset.idf) {
            var horseid = e.currentTarget.dataset.idf;
            window.db.getHerpesVaccinationLatest(horseid, (rows) => {
                let nextTypeIndex = 0;
                if (rows.length != 0) {
                    let lastItem = rows.item(0);
                    nextTypeIndex = window.DataStorage.herpesVaccinationTypes[lastItem["vtype"] - 1].NextIndex;
                }
                this.setState({
                    currentHorseId: horseid,
                    currentVaccinationType: window.DataStorage.herpesVaccinationTypes[nextTypeIndex]
                });
            });
        }
    }

    handleChange = (date) => {
        let nextVaccinationDate = new Date(JSON.parse(JSON.stringify(date)));
        nextVaccinationDate.setMonth(nextVaccinationDate.getMonth() + this.state.currentVaccinationType.MaxMonth);
        nextVaccinationDate = nextVaccinationDate.addDays(this.state.currentVaccinationType.MaxDay);
        this.setState({
            date: date,
            nextVaccinationDate: nextVaccinationDate.formatDesh()
        });
    };

    onVaccinationTypeChange = (e) => {
        let nextVaccinationDate = new Date(JSON.parse(JSON.stringify(this.state.date)));
        nextVaccinationDate.setMonth(nextVaccinationDate.getMonth() + this.state.vaccinationTypes[e.currentTarget.value].MaxMonth);
        nextVaccinationDate = nextVaccinationDate.addDays(this.state.vaccinationTypes[e.currentTarget.value].MaxDay);
        this.setState({
            currentVaccinationType: this.state.vaccinationTypes[e.currentTarget.value],
            nextVaccinationDate: nextVaccinationDate.formatDesh()
        });
    }

    onOkClick = (e) => {
        var Id = this.state.currentVaccinationId ? this.state.currentVaccinationId : Math.uuid();
        var item = {
            Id: Id,
            HorseId: this.state.currentHorseId,
            VType: parseInt(window.$("#vacAddTypeReact :selected").val()) + 1,
            VDate: this.state.date.formatDesh(),
            U: 'u',
            SyncTime: -1
        };
        window.Spinner.show();
        window.db.insertOrUpdateHerpesVaccination(item, () => {
            this.hide();
            window.Spinner.hide();
            if (this.props.callback)
                this.props.callback();
        });
    }

    render() {
        const { horseArr, currentHorseId, vaccinationTypes, date, currentVaccinationType, nextVaccinationDate, showHorseList } = this.state;
        return (
            <div>
                <div id="vaccinationEditWindowReact" style={showHorseList ? { width: "500px" } : { width: "260px" }} className="ui-popup ui-page-theme-a">
                    <a href="#" className="ui-btn ui-corner-all ui-shadow ui-btn-a ui-icon-delete ui-btn-icon-notext ui-btn-right" onClick={this.onCancelClick}>
                    </a>
                    <table className="vaccinationEditWindowReactTable">
                        <tbody>
                            {showHorseList ?
                                <tr id="taskTextLine">
                                    <td align="center">{window.lang.horse}</td>
                                </tr>
                                : <></>
                            }
                            <tr id="taskListsLine">
                                {showHorseList ?
                                    <td>
                                        <div id="searchPickPlace" className="listCont">
                                            <ul data-role="listview" data-inset="true" className="ui-listview ui-listview-inset ui-corner-all ui-shadow" id="dates3" data-autodividers="true">
                                                {horseArr.length ?
                                                    horseArr.map((currentElement, index) => (
                                                        index == 0 ?
                                                            currentElement.id == currentHorseId ?
                                                                <li key={Math.uuid()} data-icon="false" data-idf={currentElement.id} className="ui-first-child" id="typeHorse" data-name={currentElement.name} type="" onClick={this.onHorseClick.bind(this)}>
                                                                    <a className="ui-btn selectedListItem" href="#">{currentElement.name}</a>
                                                                </li>
                                                                :
                                                                <li key={Math.uuid()} data-icon="false" data-idf={currentElement.id} className="ui-first-child" id="typeHorse" data-name={currentElement.name} type="" onClick={this.onHorseClick.bind(this)}>
                                                                    <a className="ui-btn unselectedListItem" href="#">{currentElement.name}</a>
                                                                </li>
                                                            :
                                                            index == horseArr.length - 1 ?
                                                                currentElement.id == currentHorseId ?
                                                                    <li key={Math.uuid()} data-icon="false" data-idf={currentElement.id} className="ui-last-child" id="typeHorse" data-name={currentElement.name} type="" onClick={this.onHorseClick.bind(this)}>
                                                                        <a className="ui-btn selectedListItem" href="#">{currentElement.name}</a>
                                                                    </li>
                                                                    :
                                                                    <li key={Math.uuid()} data-icon="false" data-idf={currentElement.id} className="ui-last-child" id="typeHorse" data-name={currentElement.name} type="" onClick={this.onHorseClick.bind(this)}>
                                                                        <a className="ui-btn unselectedListItem" href="#">{currentElement.name}</a>
                                                                    </li>
                                                                :
                                                                currentElement.id == currentHorseId ?
                                                                    <li key={Math.uuid()} data-icon="false" data-idf={currentElement.id} id="typeHorse" data-name={currentElement.name} type="" onClick={this.onHorseClick.bind(this)}>
                                                                        <a className="ui-btn selectedListItem" href="#">{currentElement.name}</a>
                                                                    </li>
                                                                    :
                                                                    <li key={Math.uuid()} data-icon="false" data-idf={currentElement.id} id="typeHorse" data-name={currentElement.name} type="" onClick={this.onHorseClick.bind(this)}>
                                                                        <a className="ui-btn unselectedListItem" href="#">{currentElement.name}</a>
                                                                    </li>
                                                    ))
                                                    : <></>
                                                }
                                            </ul>
                                        </div>
                                    </td>
                                    : <></>
                                }
                                <td>
                                    {vaccinationTypes.length ?
                                        <select id="vacAddTypeReact"
                                            style={{ width: '200px' }}
                                            value={vaccinationTypes.indexOf(currentVaccinationType)}
                                            onChange={this.onVaccinationTypeChange}
                                        >
                                            {vaccinationTypes.map((currentElement, index) =>
                                                <option key={index} value={index}>{currentElement.Name}</option>
                                            )}
                                        </select>
                                        :
                                        <></>
                                    }
                                    <table style={{ width: '100%', height: '80px' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ valign: "middle" }}>
                                                    <div id="dateTraining" className="dateVaccinationDivReact">
                                                        <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                                                            <DatePicker
                                                                customInput={<InputForDesktop />}
                                                                dateFormat="yyyy-MM-dd"
                                                                selected={date}
                                                                onChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table style={{ width: '100%', height: '50px' }}>
                                        <tbody>
                                            <tr style={{ textAlign: 'center' }}>
                                                <td>
                                                    <label> {window.lang.nextVaccinationShort}</label>
                                                </td>
                                                <td>
                                                    <label> {nextVaccinationDate}  </label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ valign: "middle" }}>
                                                    <button className="ui-btn ui-shadow ui-corner-all" style={{ width: '100%' }} onClick={this.onOkClick}>{window.lang.ok}</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div >
        )
    }
}

const InputForDesktop = (props) => {
    return (
        <input
            onClick={props.onClick}
            value={props.value}
            type="text"
            readOnly={true}
            style={{ backgroundColor: '#292A3C', color: '#FFFFFF' }}
        />
    )
}

export default class HerpesVaccinationPopup {
    showVaccinationPopup(options, item, callback) {
        if (options == null) {
            options = {};
        }
        if (options.date == null) {
            options.date = new Date();
        }
        if (options.showHorseList == null) {
            options.showHorseList = true;
        }
        ReactDOM.render(
            <HerpesVaccinationPopupWindow
                options={options}
                item={item}
                callback={callback}
            />,
            document.getElementById('vaccinationPopupReact')
        )
    }
}