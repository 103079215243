import React, { useEffect, useState } from "react";
import "./FieldAssignmentView.css";

import ClickNHold from "react-click-n-hold";
import { TrainingCell } from "../TrainingViews/TrainingCell/TrainingDay";

export default function FieldAssignmentView({
  fieldAreas,
  filteredHorses,
  filteredTrainings,
  date,
}) {
  const capitalizeAllWords = (str) =>
    str
      .split(" ")
      .map((word) => word[0].toUpperCase() + word.substring(1))
      .join(" ");

  const getHorse = (id) => filteredHorses.find((horse) => horse.id == id);
  const isField = (name) => {
    name = name.toLowerCase();

    if (!name) return false;
    return name == "field" || name == "fold" || name == "feld";
  };
  const returnCellContent = (field_area_id, field_id, time_id) => {
    let current_trainings = filteredTrainings.filter((training) => {
      if (!isField(training.name)) return false;

      if (
        training.FieldAreaId != field_area_id ||
        training.FieldId != field_id ||
        training.FieldAreaTimePeriodId != time_id
      )
        return false;

      return true;
    });

    let nrOfTasks = 1;
    for (let field_area of fieldAreas) {
      if (field_area.id == field_area_id) {
        for (let field of field_area.fields) {
          if (field.id == field_id) {
            nrOfTasks = field.maxNumberHorses;
          }
        }
      }
    }

    while (current_trainings.length < nrOfTasks) {
      current_trainings.push(null);
    }

    return (
      <td
        key={field_area_id + "-" + field_id + "-" + time_id}
        className="horseName"
        // draggable
        // onDragStart={(e) =>
        //   handleDragStart(e, field_area_id, field_id, time_id)
        // }
        // onDrop={(e) => handleDrop(e, field_area_id, field_id, time_id)}
        // onDragOver={handleDragOver}
      >
        {current_trainings.map((current_training, index) => {
          if (current_training == null) {
            return (
              <TrainingCell
                multipleOnSameColumn={index != current_trainings.length - 1}
                key={
                  field_area_id + "-" + "-" + time_id + field_id + "-" + index
                }
                training={null}
                horse={filteredHorses[0] || undefined}
                date={date}
                holdEnabled={false}
                fieldAssignInfo={{ field_area_id, field_id, time_id }}
                fromFieldAssignmentView={true}
                draggable={true}
              />
            );
          }
          return (
            <TrainingCell
              multipleOnSameColumn={index != current_trainings.length - 1}
              key={current_training?.id}
              training={current_training}
              horse={getHorse(current_training?.horseid)}
              fieldAssignInfo={{ field_area_id, field_id, time_id }}
              date={date}
              showHorseName={true}
              holdEnabled={false}
              setTimeOnFirstHold={true}
              fromFieldAssignmentView={true}
              draggable={true}
            />
          );
        })}
      </td>
    );
  };

  const renderFieldAreaTable = (fieldArea) => {
    if (fieldArea.fields.length == 0) {
      return <span>No fields available </span>;
    }
    if (fieldArea.timePeriods.length == 0) {
      return <span>No time periods available</span>;
    }

    return (
      <table className="riderTableR riderTableRField riderTableRFieldAssignment">
        <thead>
          <tr>
            <td>{capitalizeAllWords(window.lang.fieldName)}</td>
            {fieldArea.timePeriods.map((timePeriod) => (
              <td key={timePeriod.id}>{timePeriod.period}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {fieldArea.fields.map((field) => (
            <tr key={fieldArea.id + field.id}>
              {/* 1  + timePeriods.length td's */}
              <td className="horseName">{field.name}</td>
              {fieldArea.timePeriods.map((timePeriod) => {
                return returnCellContent(fieldArea.id, field.id, timePeriod.id);
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  return (
    <div className="field-area-scrollable">
      {fieldAreas.map((fieldArea, index) => (
        <section
          key={fieldArea.id}
          className="field-area-section"
          style={{
            border: 0,
            marginBottom: index == fieldAreas.length - 1 ? "50px" : 0,
          }}
        >
          <h3>
            {window.lang.fieldArea}:
            <span className="field-area-section--title">{fieldArea.name}</span>
          </h3>

          <div className="field-area-section--data-holder">
            {renderFieldAreaTable(fieldArea)}
          </div>
        </section>
      ))}
    </div>
  );
}
