import React, { Component } from "react";
import ReactDOM from "react-dom";
import DatePicker from "react-datepicker";
import NewGeneralTaskTypePopup from "./NewGeneralTaskTypePopup.js";
import CopyGeneralTaskWindow from "./CopyGeneralTaskPopupV2.js";
import SelectTimePopup from "../TrainingPopup/SelectTimePopup";
import HorseGallery from "../Multimedia/HorseGallery";
import DeleteUpdateGeneralTaskPopupWindow from "./DeleteUpdateGeneralTaskPopup.js";
import { Capacitor } from "@capacitor/core";

class GeneralTaskPopupWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      generalTaskTypeArr: [],
      trainersArr: [],
      currentTask: "",
      currentGeneralTaskTypeId: "",
      currentTaskId: "",
      currentTrainerId: "",
      currentUserForId: "",
      currentTaskTime: "",
      currentColor: "",
      currentRemark: "",
      currentTimeOfDay: "",
      currentTaskName: "",
      currentGroupId: null,
      currentCopyDuration: 0,
      currentDaysToCopy: "",
      isShowTimePopup: false,
      hasBeenEdited: false,
    };
  }

  componentDidUpdate = () => {
    this.scrollToSelectedItems();
  };

  componentDidMount = () => {
    this.loadData();
  };

  loadData = (typeIdToBeSelected) => {
    window.DataGate.getGeneralTaskType(
      window.GlobalData.getItem(window.GlobalData.SELECT_MAIN_USER_STABLE),
      (taskTypeArr) => {
        if (!taskTypeArr) taskTypeArr = [];
        taskTypeArr = taskTypeArr?.sort(function (h1, h2) {
          return h1.Name.toLowerCase() > h2.Name.toLowerCase() ? 1 : -1;
        });
        this.setState(
          {
            generalTaskTypeArr: taskTypeArr,
          },
          this.loadFromProps
        );
      }
    );
    window.db.getSharedHorseWithTrainerList(
      window.GlobalData.getItem(window.GlobalData.SELECT_MAIN_USER_STABLE),
      (trainersArr) => {
        let trainersArray = [
          { userid: null, Name: window.lang.none },
          { userid: window.myid, Name: window.lang.me },
        ];
        trainersArr.filter((item) => {
          let curr = trainersArray.find((i) => item.userid == i.userid);
          if (!curr) {
            trainersArray.push(item);
            return true;
          } else return false;
        });
        console.log(trainersArr);
        this.setState({
          trainersArr: trainersArray,
        });
      }
    );
    this.setState(
      {
        date: this.props.options.date,
        currentTaskTime: this.props.options.date.formatDesh(),
        hasBeenEdited: false,
      },
      () => {
        if (typeIdToBeSelected)
          this.setState({ currentGeneralTaskTypeId: typeIdToBeSelected });
      }
    );
  };

  loadFromProps = () => {
    if (
      !this.state.currentGeneralTaskTypeId &&
      this.state.generalTaskTypeArr.length
    ) {
      let currentTask = this.state.generalTaskTypeArr.filter(
        (i) => i.Id == this.state.generalTaskTypeArr[0].Id
      );
      this.setState({
        currentGeneralTaskTypeId: this.state.generalTaskTypeArr[0].Id,
        currentTaskName: currentTask[0].Name,
      });
    }
    if (this.props.item) {
      let item = this.props.item;
      let currentTaskId = item.Id;
      let currentTrainerId = item.TrainerId;
      let currentUserForId = item.UserForId;
      let currentTaskTime = item.Time;
      let currentColor = item.Color;
      let currentRemark = item.Remark;
      let currentGeneralTaskTypeId = item.TaskNameId;
      let currentTimeOfDay = item.TimeOfDay;
      let currentTaskName = item.Name;
      let currentGroupId = item.GroupId;
      let currentCopyDuration = item.CopyDuration;
      let currentDaysToCopy = item.DaysToCopy;

      console.log(item);
      this.setState({
        currentTask: item,
        currentTaskId: currentTaskId,
        currentTrainerId: currentTrainerId,
        currentUserForId: currentUserForId,
        currentTaskTime: currentTaskTime,
        currentColor: currentColor,
        currentRemark: currentRemark,
        currentGeneralTaskTypeId: currentGeneralTaskTypeId,
        currentTimeOfDay: currentTimeOfDay,
        currentTaskName: currentTaskName,
        currentGroupId: currentGroupId,
        currentCopyDuration: currentCopyDuration,
        currentDaysToCopy: currentDaysToCopy,
      });
    } else {
      let color = "red";
      if (this.props.options.isDone) color = "green";
      else color = "red";
      this.setState({
        currentTrainerId: window.myid,
        currentColor: color,
      });
    }
  };

  handleChange = (date) => {
    this.setState({
      date: date,
      currentTaskTime: date.formatDesh(),
    });
  };

  hide() {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("generalTaskPopupReact")
    );
  }

  onCancelClick = () => {
    this.hide();
  };

  isTaskModified = () => {
    const {
      currentTaskId,
      currentTrainerId,
      currentUserForId,
      currentTaskTime,
      currentColor,
      currentRemark,
      currentGeneralTaskTypeId,
      currentTimeOfDay,
      currentTaskName,
      currentGroupId,
      currentCopyDuration,
      currentDaysToCopy,
    } = this.state;
    if (this.state.currentTask.Id !== currentTaskId) return true;
    if (this.state.currentTask.Color !== currentColor) return true;
    if (this.state.currentTask.CopyDuration !== currentCopyDuration)
      return true;

    if (this.state.currentTask.DaysToCopy !== currentDaysToCopy) return true;
    if (this.state.currentTask.Id !== currentTaskId) return true;
    if (this.state.currentTask.Name !== currentTaskName) return true;
    if (this.state.currentTask.Remark !== currentRemark) return true;
    if (this.state.currentTask.Time !== currentTaskTime) return true;
    if (this.state.currentTask.TimeOfDay !== currentTimeOfDay) return true;
    if (this.state.currentTask.TrainerId !== currentTrainerId) return true;
    if (this.state.currentTask.UserForId !== currentUserForId) return true;

    return false;
  };

  onSaveClick = () => {
    if (!this.isTaskModified()) {
      this.hide();
      return;
    }

    if (
      this.state.currentTaskId &&
      this.state.currentGroupId &&
      this.state.currentColor != "green"
    ) {
      new DeleteUpdateGeneralTaskPopupWindow().showWindow(
        this.getCurrentTask(),
        false,
        () => {
          this.hide();
        }
      );
    } else {
      this.saveTask();
      this.hide();
    }
  };

  saveTask = () => {
    let generalTask = this.getCurrentTask();
    this.state.currentTask = generalTask;

    if (this.state.currentTaskId)
      generalTask.Flag = window.DataStorage.GeneralTaskFlagUpdate;
    else generalTask.Flag = window.DataStorage.GeneralTaskFlagAdd;
    window.DataStorage.addGeneralTask(
      generalTask,
      window.DataStorage.GeneralTaskFlagAdd
    );

    generalTask.TaskType = this.props.options.taskType || 1; //set default as daily tasks

    this.state.currentTaskId = generalTask["Id"];
    window.DataGate.insertOrUpdateGeneralTask(generalTask, (code, data) => {
      if (code == window.DataGate.CODE_OK) {
        // window.training.refresh({ showSpinner: false });
      }
      if (this.props.callback) {
        this.props.callback(code == window.DataGate.CODE_OK);
      }
    });
  };

  getCurrentTask = () => {
    const {
      currentGeneralTaskTypeId,
      currentTaskId,
      currentTrainerId,
      currentUserForId,
      currentTaskTime,
      currentColor,
      currentRemark,
      currentTimeOfDay,
      currentTaskName,
      currentGroupId,
      currentCopyDuration,
      currentDaysToCopy,
    } = this.state;
    let generalTask = {};
    generalTask["Id"] = currentTaskId ? currentTaskId : Math.uuid();
    generalTask["TrainerId"] = currentTrainerId ? currentTrainerId : null;
    generalTask["UserForId"] = currentUserForId
      ? currentUserForId
      : window.GlobalData.getItem(window.GlobalData.SELECT_MAIN_USER_STABLE);
    generalTask["Time"] = currentTaskTime;
    generalTask["Color"] = currentColor ? currentColor : "red";
    generalTask["Remark"] = currentRemark;
    generalTask["TaskNameId"] = currentGeneralTaskTypeId;
    generalTask["TimeOfDay"] = currentTimeOfDay;
    generalTask["Name"] = currentTaskName;
    generalTask["GroupId"] = currentGroupId;
    generalTask["CopyDuration"] = currentCopyDuration;
    generalTask["DaysToCopy"] = currentDaysToCopy;
    generalTask["ConnectionId"] = window.notification.connectionId;
    return generalTask;
  };

  //#region move popup
  onMouseDown = (e) => {
    this.isMovingMode = true;
    this.startX = e.clientX;
    this.startY = e.clientY;
    let el = document.getElementById("trainingEditWindowReact");
    this.elementStartLeft = el.offsetLeft;
    this.elementStartTop = el.offsetTop;
  };
  onMouseMove = (e) => {
    if (this.isMovingMode && e.target.id == "taskHeaderReact") {
      let el = document.getElementById("trainingEditWindowReact");
      el.style.left = this.elementStartLeft + (e.clientX - this.startX) + "px";
      el.style.top = this.elementStartTop + (e.clientY - this.startY) + "px";
    }
  };
  onMouseUp = (e) => {
    this.isMovingMode = false;
  };
  //#endregion

  onGeneralTaskTypeClick = (e) => {
    let currentTask = this.state.generalTaskTypeArr.filter(
      (i) => i.Id == e.currentTarget.dataset.idf
    );
    this.setState({
      currentGeneralTaskTypeId: e.currentTarget.dataset.idf,
      currentTaskName: currentTask[0].Name,
      hasBeenEdited: true,
    });
  };

  onGeneralTaskTypeChange = (e) => {
    let currentTask = this.state.generalTaskTypeArr.filter(
      (i) => i.Id == e.target.value
    );
    this.setState({
      currentGeneralTaskTypeId: e.target.value,
      currentTaskName: currentTask[0].Name,
      hasBeenEdited: true,
    });
  };

  onTrainerClick = (e) => {
    this.setState({
      currentTrainerId: e.currentTarget.dataset.idf,
      hasBeenEdited: true,
    });
  };

  onTrainerChange = (e) => {
    this.setState({
      currentTrainerId: e.target.value,
      hasBeenEdited: true,
    });
  };

  onNewGeneralTaskTypeClick = () => {
    let newGeneralTaskTypePopup = new NewGeneralTaskTypePopup();
    newGeneralTaskTypePopup.showPopup((newTypeId) => {
      this.loadData(newTypeId);
    });
  };

  onDoneClick = (e) => {
    if (e.target.checked) {
      this.setState({
        currentColor: "green",
        hasBeenEdited: true,
      });
    } else {
      this.setState({
        currentColor: "red",
        hasBeenEdited: true,
      });
    }
  };

  onRemarkChange = (e) => {
    this.setState({
      currentRemark: e.target.value,
      hasBeenEdited: true,
    });
  };

  onExtraTaskClick = () => {
    let option = {
      date: this.state.date,
    };
    this.onSaveClick();
    setTimeout(() => {
      new GeneralTaskPopup().showPopup(option, null, () => {});
    }, 100);
  };

  showTimeSelect = () => {
    this.setState({
      isShowTimePopup: true,
    });
  };

  hideTimeSelect = () => {
    this.setState({
      isShowTimePopup: false,
    });
  };

  onTimeChange = (val) => {
    this.setState({
      isShowTimePopup: false,
      currentTimeOfDay: val,
      hasBeenEdited: true,
    });
  };

  scrollToSelectedItems = () => {
    let selectedCollection = document.getElementsByClassName(
      "ui-btn selectedListItem"
    );
    let selectedArr = [].slice.call(selectedCollection);
    if (selectedArr.length) selectedArr.map((i) => i.scrollIntoView());
  };

  onDeleteClick = () => {
    if (this.state.currentTaskId) {
      if (this.state.currentGroupId)
        new DeleteUpdateGeneralTaskPopupWindow().showWindow(
          this.state.currentTask,
          true,
          () => {
            this.hide();
          }
        );
      else {
        window.DataStorage.deleteGeneralTaskById(this.state.currentTaskId);
        // window.training.refreshLayout();
        let userForId = this.state.currentUserForId;
        window.DataGate.deleteGeneralTask(
          this.state.currentTaskId,
          userForId,
          () => {
            // window.training.refresh({ showSpinner: false });
          }
        );
        this.hide();
      }
    } else {
      this.hide();
    }
  };

  onShowMultimediaClick = () => {
    if (!this.state.currentTaskId) {
      this.saveTask();
      // window.training.refresh({ showSpinner: false });
    }
    this.onCancelClick();
    let $ = window.$;
    window.showPage("#taskImagePage");
    $("#taskImagePage").css("min-height", "95%");
    $("#taskImageHeader").html(window.lang.image);
    $("#taskImageBackButton, #taskImageOkButton").unbind("click");
    $("#taskImageBackButton, #taskImageOkButton").bind("click", function (e) {
      window.onBack();
      $(".load-bar").css("top", "70px");
      e.stopPropagation();
    });
    ReactDOM.render(
      <HorseGallery
        showUploadButton={true}
        type={"GeneralTask"}
        horse={null}
        title={"GeneralTask"}
        noteOrTaskId={this.state.currentTaskId}
        generalTask={true}
      />,
      document.getElementById("taskImageDropTable")
    );
  };

  editGeneralTaskType = (generalTaskId) => {
    const edit_element = this.state.generalTaskTypeArr.find(
      (x) => x.Id == generalTaskId
    );
    let newGeneralTaskTypePopup = new NewGeneralTaskTypePopup();
    newGeneralTaskTypePopup.showPopup(() => {
      this.loadData();
    }, edit_element);
  };
  onCopyTaskClick = () => {
    if (!this.state.currentTaskId) this.saveTask();
    this.state.currentTask.ConnectionId = window.notification.connectionId;
    new CopyGeneralTaskWindow().showWindow(this.state.currentTask, () => {
      this.hide();
    });
  };

  render() {
    const {
      date,
      generalTaskTypeArr,
      currentGeneralTaskTypeId,
      trainersArr,
      currentTrainerId,
      currentColor,
      currentRemark,
      currentTimeOfDay,
    } = this.state;
    let inches =
      Math.sqrt(
        Math.pow(window.screen.width, 2) + Math.pow(window.screen.height, 2)
      ) *
      (1 / window.inch.offsetWidth);
    const selectTime =
      currentTimeOfDay != "" ? currentTimeOfDay : window.lang.selectTime;
    return (
      <div>
        <div
          data-role="page"
          id="trainingEditP"
          style={{
            marginTop: "45px",
            overflow: "scroll",
            width: "100vw",
            minHeight: "95%",
            paddingTop: "47px",
            zIndex: "11",
          }}
          tabIndex="0"
          className="ui-page ui-page-theme-a ui-page-active"
        >
          <div
            data-role="header"
            data-position="fixed"
            className="ui-header ui-header-fixed slidedown ui-bar-inherit"
            role="banner"
          >
            <img
              id="cancelEditTraining"
              className="headerIconLeft"
              src="img/icon_canceln.png"
              onClick={this.onCancelClick}
            />
            <h1 className="ui-title" role="heading" aria-level="1" />
            <img
              className="tcOkButton headerIconRignt"
              src="img/icon_okn.png"
              index-id="0"
              onClick={this.onSaveClick}
            />
          </div>
          <div data-role="content" className="ContentForMobile">
            {this.props.options.simplified && (
              <table
                style={{
                  width: "100%",
                  borderSpacing: "15px 0",
                  marginTop: "20px",
                }}
              >
                <tbody>
                  <tr>
                    <td id="colorSelectColumn">
                      <input
                        className="tcColor"
                        id="tcColorInput"
                        type="checkbox"
                        checked={currentColor == "green"}
                        data-role="none"
                        onChange={this.onDoneClick}
                      />
                      <label
                        htmlFor="tcColorInput"
                        id="colorSelectLable"
                        style={{ margin: "3px", display: "inline" }}
                      >
                        {window.lang.trained}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td id="remarksTraining" colSpan="2">
                      <textarea
                        className="tcRemark ui-input-text ui-shadow-inset ui-body-inherit ui-corner-all ui-textinput-autogrow"
                        rows="3"
                        value={currentRemark}
                        onChange={this.onRemarkChange}
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
            {!this.props.options.simplified && (
              <table style={{ width: "100%", borderSpacing: "15px 0" }}>
                <tbody>
                  <tr id="textLine1">
                    <td style={{ width: "50%" }}>Other tasks</td>
                    <td>{window.lang.trainerName}</td>
                  </tr>
                  <tr id="textLine2">
                    <td>
                      <select
                        className="tSelect tcType"
                        data-role="none"
                        style={{ width: "45vw" }}
                        value={currentGeneralTaskTypeId}
                        onChange={this.onGeneralTaskTypeChange}
                      >
                        {generalTaskTypeArr &&
                          generalTaskTypeArr.map((currentElement) => (
                            <option key={Math.uuid()} value={currentElement.Id}>
                              {currentElement.Name}
                            </option>
                          ))}
                      </select>
                    </td>
                    <td>
                      <select
                        className="trSelect tcTrainer"
                        data-role="none"
                        style={{ width: "45vw" }}
                        value={currentTrainerId}
                        onChange={this.onTrainerChange}
                      >
                        {trainersArr &&
                          trainersArr.map((currentElement) => (
                            <option
                              key={Math.uuid()}
                              value={currentElement.userid}
                            >
                              {currentElement.Name}
                            </option>
                          ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td id="timeTrainindddg">
                      <div style={{ marginTop: "5px" }}></div>
                      <button
                        className="openTimeSelectPopup ui-btn ui-shadow ui-corner-all"
                        style={{ margin: "0px", width: "45vw" }}
                        onClick={this.onNewGeneralTaskTypeClick}
                      >
                        + Add new task to list
                      </button>
                    </td>
                    <td id="timeTraining" style={{ verticalAlign: "bottom" }}>
                      <button
                        className="openTimeSelectPopup ui-btn ui-shadow ui-corner-all"
                        style={{ margin: "0px", width: "45vw" }}
                        data-time={selectTime}
                        onClick={this.showTimeSelect}
                      >
                        {selectTime}
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td id="dateTraining">
                      <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                        <DatePicker
                          customInput={<InputForMobile />}
                          dateFormat="dd-MM-yyyy"
                          selected={date}
                          onChange={this.handleChange}
                        />
                      </div>
                    </td>
                    <td>
                      <button
                        style={{ marginTop: "10px", width: "45vw" }}
                        className="openExtraTrainingButton ui-btn ui-shadow ui-corner-all"
                        onClick={this.onExtraTaskClick}
                      >
                        {window.lang.addExtraTraining}
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td id="colorSelectColumn">
                      <input
                        className="tcColor"
                        id="tcColorInput"
                        type="checkbox"
                        checked={currentColor == "green"}
                        data-role="none"
                        onChange={this.onDoneClick}
                      />
                      <label
                        htmlFor="tcColorInput"
                        id="colorSelectLable"
                        style={{ margin: "3px", display: "inline" }}
                      >
                        {window.lang.trained}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td id="remarksTraining" colSpan="2">
                      <textarea
                        className="tcRemark ui-input-text ui-shadow-inset ui-body-inherit ui-corner-all ui-textinput-autogrow"
                        rows="3"
                        value={currentRemark}
                        onChange={this.onRemarkChange}
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" style={{ textAlign: "center" }}>
                      <Footer
                        onShowMultimediaClick={this.onShowMultimediaClick}
                        onCopyTaskClick={this.onCopyTaskClick}
                        onDeleteClick={this.onDeleteClick}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>

        <div id="trainingEditWindowReact" className="ui-popup ui-page-theme-a">
          <div
            className="ui-bar-inherit"
            id="taskHeaderReact"
            onMouseDown={this.onMouseDown}
            onMouseMove={this.onMouseMove}
            onMouseUp={this.onMouseUp}
          >
            <img
              id="cancelEditTrainingReact"
              src="img/icon_canceln.png"
              onClick={this.onCancelClick}
            />
            <div id="dateTraining" className="dateTrainingDivReact">
              <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                <DatePicker
                  customInput={<InputForDesktop />}
                  dateFormat="dd-MM-yyyy"
                  selected={date}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <img
              id="tcOkButtonReact"
              src="img/icon_okn.png"
              index-id="${index}"
              onClick={this.onSaveClick}
            />
          </div>

          {this.props.options.simplified && (
            <table className="trainingEditWindowReactTable">
              <tbody>
                {" "}
                <tr>
                  <td>
                    <div
                      id="colorSelect"
                      style={{ float: "left", display: "" }}
                    >
                      <input
                        className="tcColor"
                        id="tcColorInput"
                        type="checkbox"
                        data-role="none"
                        checked={currentColor == "green"}
                        onChange={this.onDoneClick}
                      />
                      <label
                        htmlFor="tcColorInput"
                        id="colorSelectLable"
                        style={{ margin: "3px", display: "inline" }}
                      >
                        {window.lang.trained}
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td id="remarksTraining" colSpan="2">
                    <span>{window.lang.remark}</span>
                    <textarea
                      className="tcRemark ui-input-text ui-shadow-inset ui-body-inherit ui-corner-all ui-textinput-autogrow"
                      rows="3"
                      value={currentRemark}
                      onChange={this.onRemarkChange}
                    ></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          {!this.props.options.simplified && (
            <table className="trainingEditWindowReactTable">
              <tbody>
                <tr id="taskTextLine">
                  <td align="center">Other tasks</td>
                  <td align="center">{window.lang.trainerName}</td>
                </tr>
                <tr id="taskListsLine">
                  <td>
                    <div id="searchPickPlace" className="listCont">
                      <ul
                        data-role="listview"
                        data-inset="true"
                        className="ui-listview ui-listview-inset ui-corner-all ui-shadow general-task-ul"
                        id="dates3"
                        data-autodividers="true"
                      >
                        {generalTaskTypeArr &&
                          generalTaskTypeArr.map((currentElement, index) =>
                            index == 0 ? (
                              currentElement.Id == currentGeneralTaskTypeId ? (
                                <li
                                  key={Math.uuid()}
                                  data-icon="false"
                                  data-idf={currentElement.Id}
                                  className="ui-first-child"
                                  id="typeHorse"
                                  data-name={currentElement.Name}
                                  type=""
                                  onClick={this.onGeneralTaskTypeClick.bind(
                                    this
                                  )}
                                >
                                  <a
                                    className="ui-btn selectedListItem"
                                    href="#"
                                  >
                                    <div className="flex-with-distance">
                                      <span>{currentElement.Name}</span>

                                      <div className="general-task--edit">
                                        <svg
                                          onClick={() =>
                                            this.editGeneralTaskType(
                                              currentElement.Id
                                            )
                                          }
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth="1.5"
                                          width="25px"
                                          stroke="currentColor"
                                          className="w-6 h-6"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              ) : (
                                <li
                                  key={Math.uuid()}
                                  data-icon="false"
                                  data-idf={currentElement.Id}
                                  className="ui-first-child"
                                  id="typeHorse"
                                  data-name={currentElement.Name}
                                  type=""
                                  onClick={this.onGeneralTaskTypeClick.bind(
                                    this
                                  )}
                                >
                                  <a
                                    className="ui-btn unselectedListItem"
                                    href="#"
                                  >
                                    <div className="flex-with-distance">
                                      <span>{currentElement.Name}</span>

                                      <div className="general-task--edit">
                                        <svg
                                          onClick={() =>
                                            this.editGeneralTaskType(
                                              currentElement.Id
                                            )
                                          }
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth="1.5"
                                          width="25px"
                                          stroke="currentColor"
                                          className="w-6 h-6"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              )
                            ) : currentElement.Id ==
                              currentGeneralTaskTypeId ? (
                              <li
                                key={Math.uuid()}
                                data-icon="false"
                                data-idf={currentElement.Id}
                                id="typeHorse"
                                data-name={currentElement.Name}
                                type=""
                                onClick={this.onGeneralTaskTypeClick.bind(this)}
                              >
                                <a className="ui-btn selectedListItem" href="#">
                                  <div className="flex-with-distance">
                                    <span>{currentElement.Name}</span>

                                    <div className="general-task--edit">
                                      <svg
                                        onClick={() =>
                                          this.editGeneralTaskType(
                                            currentElement.Id
                                          )
                                        }
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        width="25px"
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </a>
                              </li>
                            ) : (
                              <li
                                key={Math.uuid()}
                                data-icon="false"
                                data-idf={currentElement.Id}
                                id="typeHorse"
                                data-name={currentElement.Name}
                                type=""
                                onClick={this.onGeneralTaskTypeClick.bind(this)}
                              >
                                <a
                                  className="ui-btn unselectedListItem"
                                  href="#"
                                >
                                  <div className="flex-with-distance">
                                    <span>{currentElement.Name}</span>

                                    <div className="general-task--edit">
                                      <svg
                                        onClick={() =>
                                          this.editGeneralTaskType(
                                            currentElement.Id
                                          )
                                        }
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        width="25px"
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </a>
                              </li>
                            )
                          )}
                        {generalTaskTypeArr.length ? (
                          <li
                            key={Math.uuid()}
                            data-icon="false"
                            className="ui-last-child"
                            id="typeHorse"
                            type=""
                            onClick={this.onNewGeneralTaskTypeClick}
                          >
                            <a className="ui-btn unselectedListItem" href="#">
                              + Add new task to list
                            </a>
                          </li>
                        ) : (
                          <li
                            key={Math.uuid()}
                            data-icon="false"
                            className="ui-first-child ui-last-child"
                            id="typeHorse"
                            type=""
                            onClick={this.onNewGeneralTaskTypeClick}
                          >
                            <a className="ui-btn unselectedListItem" href="#">
                              + Add new task to list
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </td>
                  <td style={{ verticalAlign: "top" }}>
                    <div id="searchPickPlace" className="listCont">
                      <ul
                        data-role="listview"
                        data-inset="true"
                        className="ui-listview ui-listview-inset ui-corner-all ui-shadow"
                        id="dates3"
                        data-autodividers="true"
                      >
                        {trainersArr &&
                          trainersArr.map((currentElement, index) =>
                            index == 0 ? (
                              currentElement.userid == currentTrainerId ? (
                                <li
                                  key={Math.uuid()}
                                  data-icon="false"
                                  data-idf={currentElement.userid}
                                  className="ui-first-child"
                                  id="typeTrainer"
                                  data-name={currentElement.Name}
                                  type=""
                                  onClick={this.onTrainerClick}
                                >
                                  <a
                                    className="ui-btn selectedListItem"
                                    href="#"
                                  >
                                    {currentElement.Name}
                                  </a>
                                </li>
                              ) : (
                                <li
                                  key={Math.uuid()}
                                  data-icon="false"
                                  data-idf={currentElement.userid}
                                  className="ui-first-child"
                                  id="typeTrainer"
                                  data-name={currentElement.Name}
                                  type=""
                                  onClick={this.onTrainerClick}
                                >
                                  <a
                                    className="ui-btn unselectedListItem"
                                    href="#"
                                  >
                                    {currentElement.Name}
                                  </a>
                                </li>
                              )
                            ) : index == trainersArr.length - 1 ? (
                              currentElement.userid == currentTrainerId ? (
                                <li
                                  key={Math.uuid()}
                                  data-icon="false"
                                  data-idf={currentElement.userid}
                                  className="ui-last-child"
                                  id="typeTrainer"
                                  data-name={currentElement.Name}
                                  type=""
                                  onClick={this.onTrainerClick}
                                >
                                  <a
                                    className="ui-btn selectedListItem"
                                    href="#"
                                  >
                                    {currentElement.Name}
                                  </a>
                                </li>
                              ) : (
                                <li
                                  key={Math.uuid()}
                                  data-icon="false"
                                  data-idf={currentElement.userid}
                                  className="ui-last-child"
                                  id="typeTrainer"
                                  data-name={currentElement.Name}
                                  type=""
                                  onClick={this.onTrainerClick}
                                >
                                  <a
                                    className="ui-btn unselectedListItem"
                                    href="#"
                                  >
                                    {currentElement.Name}
                                  </a>
                                </li>
                              )
                            ) : currentElement.userid == currentTrainerId ? (
                              <li
                                key={Math.uuid()}
                                data-icon="false"
                                data-idf={currentElement.userid}
                                id="typeTrainer"
                                data-name={currentElement.Name}
                                type=""
                                onClick={this.onTrainerClick}
                              >
                                <a className="ui-btn selectedListItem" href="#">
                                  {currentElement.Name}
                                </a>
                              </li>
                            ) : (
                              <li
                                key={Math.uuid()}
                                data-icon="false"
                                data-idf={currentElement.userid}
                                id="typeTrainer"
                                data-name={currentElement.Name}
                                type=""
                                onClick={this.onTrainerClick}
                              >
                                <a
                                  className="ui-btn unselectedListItem"
                                  href="#"
                                >
                                  {currentElement.Name}
                                </a>
                              </li>
                            )
                          )}
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      style={{ marginTop: "10px", maxWidth: "250px" }}
                      className="openExtraTrainingButton ui-btn ui-shadow ui-corner-all"
                      onClick={this.onExtraTaskClick}
                    >
                      {window.lang.addExtraTraining}
                    </button>
                  </td>
                  <td id="timeTraining">
                    <button
                      style={{ marginTop: "10px" }}
                      className="openTimeSelectPopup ui-btn ui-shadow ui-corner-all"
                      onClick={this.showTimeSelect}
                    >
                      {selectTime}
                    </button>
                  </td>
                </tr>
                <tr style={{ height: "20px" }}></tr>
                <tr>
                  <td id="doneTrainingLabel"></td>
                </tr>
                <tr>
                  <td>
                    <div
                      id="colorSelect"
                      style={{ float: "left", display: "" }}
                    >
                      <input
                        className="tcColor"
                        id="tcColorInput"
                        type="checkbox"
                        data-role="none"
                        checked={currentColor == "green"}
                        onChange={this.onDoneClick}
                      />
                      <label
                        htmlFor="tcColorInput"
                        id="colorSelectLable"
                        style={{ margin: "3px", display: "inline" }}
                      >
                        {window.lang.trained}
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td id="remarksTraining" colSpan="2">
                    <span>{window.lang.remark}</span>
                    <textarea
                      className="tcRemark ui-input-text ui-shadow-inset ui-body-inherit ui-corner-all ui-textinput-autogrow"
                      rows="3"
                      value={currentRemark}
                      onChange={this.onRemarkChange}
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" style={{ textAlign: "center" }}>
                    <Footer
                      onShowMultimediaClick={this.onShowMultimediaClick}
                      onCopyTaskClick={this.onCopyTaskClick}
                      onDeleteClick={this.onDeleteClick}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>

        {this.state.isShowTimePopup && (
          <SelectTimePopup
            value={currentTimeOfDay}
            onTimeChange={this.onTimeChange}
            onHide={this.hideTimeSelect}
          />
        )}
      </div>
    );
  }
}

const InputForMobile = (props) => {
  return (
    <input
      onClick={props.onClick}
      value={props.value}
      type="text"
      readOnly={true}
      style={{ backgroundColor: "#dddddd", color: "#000000" }}
    />
  );
};

const InputForDesktop = (props) => {
  return (
    <input
      onClick={props.onClick}
      value={props.value}
      type="text"
      readOnly={true}
      style={{ backgroundColor: "#292A3C", color: "#FFFFFF" }}
    />
  );
};

const Footer = (props) => {
  return (
    <>
      <span>
        <img
          className="tcDelButton imgWithBorder"
          src="img/trashcan.png"
          style={{ height: "11mm" }}
          onClick={props.onDeleteClick}
        />
      </span>
      <span id="multimediaTraining" style={{ marginLeft: "5px" }}>
        <img
          className="ImgIconTask imgWithBorder"
          src="img/photo_icon.png"
          style={{ height: "11mm" }}
          h-id={props.currentHorseId}
          onClick={props.onShowMultimediaClick}
        />
      </span>
      <span style={{ marginLeft: "5px" }}>
        <img
          id="copyButtonTraining"
          className="tcWeek imgWithBorder"
          src="img/copy_icon.png"
          style={{ height: "11mm" }}
          onClick={props.onCopyTaskClick}
        />
      </span>
    </>
  );
};

export default class GeneralTaskPopup {
  showPopup(options, item, callback) {
    if (options == null) {
      options = {};
    }
    if (options.date == null) {
      options.date = new Date();
    }
    ReactDOM.render(
      <GeneralTaskPopupWindow
        options={options}
        item={item}
        callback={callback}
        simplified={options.simplified}
      />,
      document.getElementById("generalTaskPopupReact")
    );
  }
}
