import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./NewGeneralTaskTypePopup.css";

class NewGeneralTaskTypePopupWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      generalTask: this.props.edit_element ? this.props.edit_element.Name : "",
    };
  }

  hide() {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("newGeneralTaskTypePopupReact")
    );
  }

  onCancelClick = () => {
    this.hide();
  };

  onSaveClick = () => {
    if (this.state.generalTask) {
      if (this.props.edit_element) {
        window.DataGate.editGeneralTaskType(
          this.props.edit_element.Id,
          this.state.generalTask,
          () => {
            if (this.props.callback) {
              window.training.refresh();
            }
          }
        );
      } else {
        window.DataGate.createGeneralTaskType(
          this.state.generalTask,
          (newTypeId) => {
            if (this.props.callback) {
              this.props.callback(newTypeId);
            }
          }
        );
      }
    }
    this.hide();
  };

  onGeneralTaskChange = (e) => {
    this.setState({
      generalTask: e.target.value,
    });
  };

  render() {
    const { generalTask } = this.state;
    return (
      <div
        id="newGeneralTaskTypeWindowReact"
        className="ui-popup ui-page-theme-a"
      >
        <div
          className="ui-bar-inherit"
          id="newGeneralTaskTypeReact"
          onMouseDown={this.onMouseDown}
          onMouseMove={this.onMouseMove}
          onMouseUp={this.onMouseUp}
        >
          <img
            id="cancelNewGeneralTaskTypeReact"
            src="img/icon_canceln.png"
            onClick={this.onCancelClick}
          />
          <img
            id="OkButtonReact"
            src="img/icon_okn.png"
            index-id="${index}"
            onClick={this.onSaveClick}
          />
        </div>
        <div style={{ marginTop: "5px" }}>
          <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
            <input
              id="inputNewGeneralTaskTypeReact"
              value={generalTask}
              onChange={this.onGeneralTaskChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default class NewGeneralTaskTypePopup {
  showPopup(callback, edit_element) {
    ReactDOM.render(
      <NewGeneralTaskTypePopupWindow
        edit_element={edit_element}
        callback={callback}
      />,
      document.getElementById("newGeneralTaskTypePopupReact")
    );
  }
}
