import React from 'react';
import ReactDOM from 'react-dom';
import HorseViewPage from './HorseViewPage';
import PedigreePage from './HorseData/PedigreePage';
import OwnerInfoPage from './HorseData/OwnerInfoPage';
import NotesPage from './HorseData/NotesPage';
import PricePage from './HorseData/PricePage';
import ResultsPage from './HorseData/ResultsPage';
import EquipmentPage from './HorseData/EquipmentPage';
import VaccinationPage from './HorseData/VaccinationPage';
import FarrierPage from './HorseData/FarrierPage';
import TeethPage from './HorseData/Teeth/TeethPage';
import VeterinarianPage from './HorseData/VeterinarianPage';
import MultimediaPage from './HorseData/MultimediaPage';
import HerpesVaccinationPage from './HorseData/HerpesVaccinationPage';

export default class HorsePageExtention {
    showHorsePage(horseId, options) {
        if (horseId != -1) {
            let horseItem = window.DataStorage.getAllHorses().find(h => h.id == horseId);
            if (horseItem) {
                if (horseItem.userid != window.myid) {
                    let role = window.DataStorage.rolesDataFromUsers.find(r => r.UserId == horseItem.userid);
                    if (role != null && role.hOpenHorse == false) {
                        window.onBack();
                        window.alert("You have no access to view this horse");
                        return;
                    }
                }
            }

            if (!window.location.href.includes("?id="))
                window.location.href = window.location.href + "?id=" + horseId;
            else
                window.location.href = window.location.href.split("?id=")[0] + "?id=" + horseId;
        }

        if (options == null) {
            options = {
                showGallery: false
            };
        }
        this.currentHorseId = horseId;
        this.currentHorseOprtions = options;
        ReactDOM.render(
            <HorseViewPage
                horseId={horseId}
                showGallery={options.showGallery}
                userOwnerId={options.userOwnerId}
            />,
            document.getElementById('addHorsePageContent'));
    }

    refreshHorsePage() {
        if (this.currentHorseId != null) {
            ReactDOM.render(
                <HorseViewPage
                    horseId={this.currentHorseId}
                    showGallery={this.currentHorseOprtions}
                    date={new Date()}
                />,
                document.getElementById('addHorsePageContent'));
        }
    }

    showPedigree(horseId) {
        if (!window.location.href.includes("&p=pedigree")) {
            window.location.href = window.location.href + "&p=pedigree";
        }

        ReactDOM.render(
            <PedigreePage
                horseId={horseId}
            />,
            document.getElementById('addHorsePageContent'));
    }

    showOwnerInfo(horseId) {
        if (!window.location.href.includes("&p=owner")) {
            window.location.href = window.location.href + "&p=owner";
        }

        ReactDOM.render(
            <OwnerInfoPage
                horseId={horseId}
            />,
            document.getElementById('addHorsePageContent'));
    }

    showNotes(horseId) {
        if (!window.location.href.includes("&p=notes")) {
            window.location.href = window.location.href + "&p=notes";
        }

        ReactDOM.render(
            <NotesPage
                horseId={horseId}
            />,
            document.getElementById('addHorsePageContent'));
    }

    showHorsePrice(horseId) {
        if (!window.location.href.includes("&p=price")) {
            window.location.href = window.location.href + "&p=price";
        }

        ReactDOM.render(
            <PricePage
                horseId={horseId}
            />,
            document.getElementById('addHorsePageContent'));
    }

    showResults(horseId) {
        if (!window.location.href.includes("&p=results")) {
            window.location.href = window.location.href + "&p=results";
        }

        ReactDOM.render(
            <ResultsPage
                horseId={horseId}
            />,
            document.getElementById('addHorsePageContent'));
    }

    showEquipment(horseId) {
        if (!window.location.href.includes("&p=equipment")) {
            window.location.href = window.location.href + "&p=equipment";
        }

        ReactDOM.render(
            <EquipmentPage
                horseId={horseId}
            />,
            document.getElementById('addHorsePageContent'));
    }

    showVaccination(horseId) {
        if (!window.location.href.includes("&p=vaccination")) {
            window.location.href = window.location.href + "&p=vaccination";
        }

        ReactDOM.render(
            <VaccinationPage
                horseId={horseId}
            />,
            document.getElementById('addHorsePageContent'));
    }

    showHerpesVaccination(horseId) {
        if (!window.location.href.includes("&p=herpesvaccination")) {
            window.location.href = window.location.href + "&p=herpesvaccination";
        }

        ReactDOM.render(
            <HerpesVaccinationPage
                horseId={horseId}
            />,
            document.getElementById('addHorsePageContent'));
    }

    showFarrier(horseId) {
        if (!window.location.href.includes("&p=farrier")) {
            window.location.href = window.location.href + "&p=farrier";
        }

        ReactDOM.render(
            <FarrierPage
                horseId={horseId}
            />,
            document.getElementById('addHorsePageContent'));
    }

    showTeeth(horseId) {
        if (!window.location.href.includes("&p=teeth")) {
            window.location.href = window.location.href + "&p=teeth";
        }

        ReactDOM.render(
            <TeethPage
                horseId={horseId}
            />,
            document.getElementById('addHorsePageContent'));
    }

    showVeterinarian(horseId) {
        if (!window.location.href.includes("&p=veterinarian")) {
            window.location.href = window.location.href + "&p=veterinarian";
        }

        ReactDOM.render(
            <VeterinarianPage
                horseId={horseId}
            />,
            document.getElementById('addHorsePageContent'));
    }

    showMultimedia(horseId) {
        if (!window.location.href.includes("&p=multimedia")) {
            window.location.href = window.location.href + "&p=multimedia";
        }

        ReactDOM.render(
            <MultimediaPage
                horseId={horseId}
                pageName={'Multimedia'}
            />,
            document.getElementById('addHorsePageContent'));
    }
}