import React, { Component } from "react";

export default class SpinnerView extends Component {
    render() {
        return (
            <div className="load-bar-r">
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
        );
    }
}