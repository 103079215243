import React, { Component } from "react";
import "./ToggleView.css";

export default class ToggleView extends Component {
  render() {
    const checked = this.props.checked;
    if (checked) {
      return (
        <div
          className="tvContainerChecked"
          onClick={this.props.onClick}
          name={this.props.name}
        >
          <div className="tvLabel">{window.lang.yes}</div>
          <div className="tvButton"></div>
        </div>
      );
    } else {
      return (
        <div
          className="tvContainerUnhecked"
          onClick={this.props.onClick}
          name={this.props.name}
        >
          <div className="tvButton"></div>
          <div className="tvLabel">{window.lang.no}</div>
        </div>
      );
    }
  }
}
