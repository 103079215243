import React, { Component } from "react";
import "./FarrierPage.css";
import ToggleView from "../../Controls/ToggleView/ToggleView";
import TrainingPopup from "../../TrainingPopup/TrainingPopup";
import FarrierAllPopup from "./FarrierPopup";
import HorseGallery from "../.././Multimedia/HorseGallery";

class FarrierPage extends Component {
  constructor(props) {
    super(props);

    this.farrierTrainingTypes = [];
    this.reminderNames = [
      window.lang.reminder_No,
      window.lang.reminder_SameDay,
      window.lang.reminder_1_dayBefore,
      window.lang.reminder_2_dayBefore,
      window.lang.reminder_3_dayBefore,
      window.lang.reminder_4_dayBefore,
      window.lang.reminder_5_dayBefore,
      window.lang.reminder_6_dayBefore,
      window.lang.reminder_7_dayBefore,
    ];
    this.state = {
      farrierData: [],
      horse: "",
    };
  }

  componentDidMount() {
    window.$("#bottomMenu").hide();
    window.TopMenu.Self.setVisible(false);
    this.loadData();
  }

  loadData = () => {
    let horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == this.props.horseId
    );
    this.getFarrierTypes(horse.userid, (fTrainingTypes) => {
      this.farrierTrainingTypes = fTrainingTypes;
      window.db.getFarrierTrainingsByHorse(
        this.props.horseId,
        window.myid,
        (farriers) => {
          this.setState({
            farrierData: farriers,
            horse: horse,
          });
        }
      );
    });
  };

  getFarrierTypes = (userId, callback) => {
    window.db.getFarrierTypes(userId, (ftypes) => {
      if (ftypes.length == 0) {
        this.insertDefaultTypes(userId, () => {
          callback(ftypes);
        });
        return;
      }
      callback(ftypes);
    });
  };

  insertDefaultTypes = (userId, callback) => {
    window.db.insertFarrierType(
      { UserId: userId, Name: window.lang.shoeType1, OrderC: 1 },
      () => {
        window.db.insertFarrierType(
          { UserId: userId, Name: window.lang.shoeType2, OrderC: 2 },
          () => {
            window.db.insertFarrierType(
              { UserId: userId, Name: window.lang.shoeType3, OrderC: 3 },
              () => {
                window.db.insertFarrierType(
                  { UserId: userId, Name: window.lang.shoeType4, OrderC: 4 },
                  () => {
                    callback();
                  }
                );
              }
            );
          }
        );
      }
    );
  };

  getFarrierTypeName = (farrierTypeId) => {
    for (var i = 0; i < this.farrierTrainingTypes.length; i++) {
      if (this.farrierTrainingTypes[i]["Id"] == farrierTypeId) {
        return this.farrierTrainingTypes[i]["Name"];
      }
    }
    return this.farrierTrainingTypes[0]["Name"];
  };

  addNewTrainingPopup = (isDone) => {
    let trainingTypes = window.DataStorage.trainTypes.filter(
      (i) => i.owner == this.state.horse.userid
    );
    let farTraining = trainingTypes.filter((i) => i.IsFarrier == true);
    var date = new Date();
    if (!isDone) date = new Date(date.setDate(date.getDate() + 35));
    let currentTrainerId = window.DataStorage.getAllHorses().find(
      (i) => i.id == this.state.horse.id
    ).trainer;
    if (!currentTrainerId)
      var currentTrainer = window.DataStorage.allUsers.find(
        (i) => i.Id == window.myid
      );
    else
      var currentTrainer = window.DataStorage.allUsers.find(
        (i) => i.Id == currentTrainerId
      );
    let options = {
      horseId: this.state.horse.id,
      trainingType: farTraining[0].id,
      date: date,
      isDone: isDone,
      trainerId: currentTrainer.Id,
      trainerName: currentTrainer.Name,
    };
    let trainingPopup = new TrainingPopup();
    trainingPopup.showPopup(options, null, () => {
      this.loadData();
    });
  };

  onDoneToggle = (item) => {
    item.color = item.color == "green" ? "red" : "green";
    let farrierData = [...this.state.farrierData];
    let index = farrierData.findIndex((t) => t.id == item.id);
    farrierData[index] = item;
    this.setState({
      farrierData: farrierData,
    });

    let trainingItem = window.DataStorage.addTrainingArr.find(
      (d) => d.id == item.id
    );
    if (trainingItem == null) {
      trainingItem = farrierData.find((d) => d.id == item.id);
    }
    trainingItem.color = item.color;
    window.DataStorage.updateTrainingBackground(trainingItem);
  };

  onEditButtonClick = (id) => {
    let trainingItem = window.DataStorage.addTrainingArr.find(
      (d) => d.id == id
    );
    if (trainingItem == null) {
      trainingItem = this.state.farrierData.find((d) => d.id == id);
    }
    var currentTrainer = window.DataStorage.allUsers.find(
      (i) => i.Id == trainingItem.trainer
    );
    const options = {
      horseId: trainingItem.horseid,
      trainingType: trainingItem.type,
      date: new Date(trainingItem.starttime),
      trainerId: currentTrainer.Id,
      trainerName: currentTrainer.Name,
    };
    new window.TrainingPopup().showPopup(options, trainingItem, () => {
      this.loadData();
    });
    return;
  };

  onAllFarrierButtonClick = () => {
    let farrierAllPopup = new FarrierAllPopup();
    farrierAllPopup.showFarrierAllPopup();
  };

  onDeleteButtonClick = (id) => {
    window.db.deleteTraining(id);
    this.loadData();
  };

  onOkClick = () => {
    window.Managers.extention.showHorsePage(this.props.horseId);
    window.$("#bottomMenu").show();
  };

  render() {
    const { farrierData } = this.state;
    let horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == this.props.horseId
    );

    return (
      <div className="pageScrollable">
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            loading="lazy"
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={this.onOkClick}
          />
          <span className="npTitle">{window.lang.shoes}</span>
          <span
            id="vacHorseInfo"
            style={{
              position: "absolute",
              right: "15%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {horse.name}
          </span>
          <img
            loading="lazy"
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onOkClick}
          />
        </div>
        <div id="farrierTable">
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ verticalAlign: "top", width: "15%" }}>
                  <button
                    id="shoeNewButton"
                    className="ui-btn ui-shadow ui-corner-all"
                    style={{ width: "initial" }}
                    onClick={() => this.addNewTrainingPopup(true)}
                  >
                    {" "}
                    {window.lang.shoeNewFarrier}
                  </button>
                </td>
                <td style={{ verticalAlign: "top", width: "15%" }}>
                  <button
                    id="planFarrierButton"
                    className="ui-btn ui-shadow ui-corner-all"
                    style={{ width: "initial" }}
                    onClick={() => this.addNewTrainingPopup(false)}
                  >
                    {window.lang.planFarrier}
                  </button>
                </td>
                <td style={{ verticalAlign: "top" }}>
                  <button
                    id="shoeNextButton"
                    className="ui-btn ui-shadow ui-corner-all"
                    style={{ width: "45%", float: "right" }}
                    onClick={this.onAllFarrierButtonClick}
                  >
                    {" "}
                    {window.lang.farrierView}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="FarrTable">
            <tbody>
              <tr>
                <th>Done</th>
                <th> {window.lang.planedFarrier}</th>
                <th>{window.lang.shoeType}</th>
                <th>Notification</th>
                <th>{window.lang.remark}</th>
                <th></th>
              </tr>
              {farrierData.length ? (
                farrierData.map((i) => (
                  <tr key={Math.uuid()}>
                    <td>
                      <ToggleView
                        checked={i.color == "green" ? true : false}
                        onClick={() => this.onDoneToggle(i)}
                      />
                    </td>
                    <td>{i.starttime}</td>
                    <td>{this.getFarrierTypeName(i.FarrierType)}</td>
                    <td>{this.reminderNames[i.ReminderType]}</td>
                    <td>{i.remark}</td>
                    <td>
                      <img
                        loading="lazy"
                        className="farrierEdit"
                        src="img/edit_icon.png"
                        style={{ width: "40px", height: "40px" }}
                        onClick={() => this.onEditButtonClick(i.id)}
                      />
                      <img
                        loading="lazy"
                        className="farrierDelete"
                        src="img/trashcan.png"
                        style={{ width: "40px", height: "40px" }}
                        onClick={() => this.onDeleteButtonClick(i.id)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </table>
          <HorseGallery
            showUploadButton={true}
            type={"Farrier"}
            horse={horse}
            title={"Farrier"}
          />
        </div>
      </div>
    );
  }
}

export default FarrierPage;
