import React, { Component } from "react";
import ReactDOM from "react-dom";
import PriceReport from "./PriceReport";

class LogPriceReportPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logData: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props != prevProps) {
      this.loadData();
    }
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    window.db.getLogPriceReportByUser(window.myid, (data) => {
      if (data.length) {
        let logData = [];
        for (let i in data) {
          let d = new Date();
          d.setTime(Date.parse(data[i]["DateTime"]));
          logData.push({
            Date: d.toLocaleString(),
            Email: data[i]["Email"],
            LogData: data[i]["LogData"],
          });
        }
        this.setState({
          logData: logData,
        });
      }
    });
  };

  onOkClick = () => {
    window.showPage("#showPriceReportPage");
    let logPrice = new PriceReport();
    logPrice.showPriceReport();
  };

  render() {
    const { logData } = this.state;
    return (
      <div className="pageScrollable">
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            loading="lazy"
            id="sharingBackButton"
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={this.onOkClick}
          />
          <span className="ppTitle">Logs</span>
          <img
            loading="lazy"
            id="sharingOkButton"
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onOkClick}
          />
        </div>
        <div className="ppContent">
          {logData.length ? (
            <table className="farrierTable">
              <tbody>
                <tr>
                  <th style={{ width: "20%" }}>DateTime</th>
                  <th style={{ width: "20%" }}>User</th>
                  <th>Action</th>
                </tr>
                {logData.map((i) => (
                  <tr key={Math.uuid()}>
                    <td>{i.Date}</td>
                    <td>{i.Email}</td>
                    <td>{i.LogData}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}

export default class LogPriceReport {
  showLogPriceReport() {
    ReactDOM.render(
      <LogPriceReportPage />,
      document.getElementById("logPriceReportPageReact")
    );
  }
}
