import React, { Component } from "react";
import ReactDOM from "react-dom";
import DatePicker from "react-datepicker";
import "./TrainingPopup.css";
import "react-datepicker/dist/react-datepicker.css";
import CopyTaskWindow from "./CopyTaskPopupV2";
import ReminderSelectWindow from "./ReminderSelectPopup.js";
import SelectTimePopup from "./SelectTimePopup";
import TaskXrayPage from "./TaskXrayPage";
import ReminderSelect from "./ReminderSelectPopup.js";
import HorseGallery from "../Multimedia/HorseGallery";
import GroupDeleteUpdateQuestionWindow from "./GroupDeleteUpdateQuestion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class TrainingPopupWindow extends Component {
  constructor(props) {
    super(props);
    const GlobalData = window.GlobalData;

    this.state = {
      currentTrainingId: "",
      currentHorseId: "",
      currentTrainerId: "",
      currentTrainingType: "",
      currentRemark: "",
      currentEvaluation: "",
      currentIsCanCopy: false,
      currentColor: "red",
      date: "",
      horseNameArr: [],
      trainersArr: [],
      trainingTypesArr: [],
      imgStrArr: [],
      showMultimediaTraining: GlobalData.getItem(
        GlobalData.SHOW_MULTIMEDIA_TRAINING
      ),
      showTimeTraining: GlobalData.getItem(GlobalData.SHOW_TIME_TRAINING),
      showDateTraining: GlobalData.getItem(GlobalData.SHOW_DATE_TRAINING),
      showDoneTraining: GlobalData.getItem(GlobalData.SHOW_DONE_TRAINING),

      showEvaluationTraining: GlobalData.getItem(
        GlobalData.SHOW_EVALUATION_TRAINING
      ),
      showCopyTaskTraining: GlobalData.getItem(
        GlobalData.SHOW_COPY_TASK_TRAINING
      ),
      showRemarksTraining: GlobalData.getItem(GlobalData.SHOW_REMARKS_TRAINING),
      showCopyButtonTraining: GlobalData.getItem(
        GlobalData.SHOW_COPY_BUTTON_TRAINING
      ),
      showRemainderTraining: GlobalData.getItem(
        GlobalData.SHOW_REMAINDER_TRAINING
      ),
      vetTrainingId: "",
      veterinarType: window.VeterinarPage.VeterinarType,
      currentVeterinarianType: this.props.options.vetSubType || "",
      farTrainingId: "",
      farrierType: [],
      currentFarrierType: "",
      farrierWeeks: [
        window.lang.nextMonday,
        window.lang.nextTuesday,
        window.lang.nextWednesday,
        window.lang.nextThursday,
        window.lang.nextFriday,
        window.lang.nextSaturday,
        window.lang.nextSunday,
        window.lang.today,
        window.lang.week4,
        window.lang.week5,
        window.lang.week6,
        window.lang.week7,
        window.lang.week8,
        window.lang.week9,
        window.lang.week10,
        window.lang.week11,
        window.lang.week12,
      ],
      currentFarrierWeek: "",
      currentTraining: "",
      currentHorseOwner: "",
      currentHasMultimedia: "",
      currentPaddockPeriod: "",
      currentReminderType: 0,
      currentTimeOfDay: "",
      currentVeterinarianVacType: "",
      currentDuration: "",
      currentTrainingName: "",
      currentTrainingShortname: "",
      currentStarttime: "",
      currentStatus: "",
      currentSynctime: "",
      currentTrainerName: "",
      isShowTimePopup: false,
      showReminderPopup: false,
      dewormingTypes: [],
      currentDewormingType: "",
      dewormingStages: window.lang.dewormingStages,
      dewormingTypesPeriod: [
        window.lang.oneMonth,
        window.lang.threeMonths,
        window.lang.sixMonths,
        window.lang.oneYear,
      ],
      currentNextDewormingPeriod: "",
      nextDewormingDate: "",
      fieldAreas: [],
      selectedFieldAreaIndex: -1,
      selectedFieldIndex: -1,
      selectedFieldTimePeriodIndex: -1,
      walkerAreas: [],
      selectedWalkerAreaIndex: -1,
      selectedWalkerIndex: -1,
      selectedWalkerTimePeriodIndex: -1,
      horseNotes: [],
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  componentDidUpdate = () => {
    this.scrollToSelectedItems();
  };

  trainingTypesForHorse(horseId) {
    let horse = window.DataStorage.getAllHorses().find((h) => h.id == horseId);
    let trainingTypes = window.DataStorage.trainTypes.filter(
      (i) => i.owner == horse.userid
    );
    return trainingTypes;
  }

  loadFieldAreas = (cur_horse_id, cur_training_name, isInit) => {
    this.loadWalkerAreas(cur_horse_id, cur_training_name, isInit);

    if (!this.isField(cur_training_name) && !isInit) return;

    window.DataGate.getAvailableFieldAreas(
      this.props.item ? this.props.item.id : "",
      this.props.options.date.formatDesh(),
      cur_horse_id,
      (code, data) => {
        if (!data)
          alert("Something went wrong when retrieveing data about fields");

        let fieldAreas = [...data.Item1];

        if (
          data.Item2 &&
          data.Item2.fieldAreaId != "00000000-0000-0000-0000-000000000000"
        ) {
          let auxFieldAreaIndex = fieldAreas.findIndex(
            (x) => x.id == data.Item2.fieldAreaId
          );
          let auxFieldIndex = fieldAreas[auxFieldAreaIndex]?.fields?.findIndex(
            (x) => x.id == data.Item2.fieldId
          );
          let auxTimePeriodIndex = fieldAreas[auxFieldAreaIndex]?.fields[
            auxFieldIndex
          ]?.timePeriods?.findIndex((x) => x.id == data.Item2.timePeriodId);

          this.setState({
            fieldAreas: fieldAreas,
            selectedFieldAreaIndex: auxFieldAreaIndex,
            selectedFieldIndex: auxFieldIndex,
            selectedFieldTimePeriodIndex: auxTimePeriodIndex,
          });
          return;
        }
        if (isInit && this.props.options.fieldAssignInfo) {
          const { field_area_id, field_id, time_id } =
            this.props.options.fieldAssignInfo;
          const fieldAreas = data.Item1;

          let auxFieldAreaIndex = fieldAreas.findIndex(
            (x) => x.id == field_area_id
          );

          let auxFieldIndex = fieldAreas[auxFieldAreaIndex]?.fields.findIndex(
            (x) => x.id == field_id
          );
          let auxTimePeriodIndex = fieldAreas[auxFieldAreaIndex]?.fields[
            auxFieldIndex
          ]?.timePeriods.findIndex((x) => x.id == time_id);

          this.setState({
            fieldAreas: data.Item1,
            selectedFieldAreaIndex: auxFieldAreaIndex,
            selectedFieldIndex: auxFieldIndex,
            selectedFieldTimePeriodIndex: auxTimePeriodIndex,
          });
        } else {
          this.setState({ fieldAreas: data.Item1 });
        }
      }
    );
  };

  loadWalkerAreas = (cur_horse_id, cur_training_name, isInit) => {
    if (!this.isWalker(cur_training_name) && !isInit) return;

    window.DataGate.getAvailableWalkerAreas(
      this.props.item ? this.props.item.id : "",
      this.props.options.date.formatDesh(),
      cur_horse_id,
      (code, data) => {
        if (!data)
          alert("Something went wrong when retrieveing data about fields");

        let walkerAreas = [...data.Item1];

        if (
          data.Item2 &&
          data.Item2.walkerAreaId != "00000000-0000-0000-0000-000000000000"
        ) {
          let auxWalkerAreaIndex = walkerAreas.findIndex(
            (x) => x.id == data.Item2.walkerAreaId
          );
          let auxWalkerIndex = walkerAreas[
            auxWalkerAreaIndex
          ]?.walkers?.findIndex((x) => x.id == data.Item2.walkerId);
          let auxTimePeriodIndex = walkerAreas[auxWalkerAreaIndex]?.walkers[
            auxWalkerIndex
          ]?.timePeriods?.findIndex((x) => x.id == data.Item2.timePeriodId);

          this.setState({
            walkerAreas: walkerAreas,
            selectedWalkerAreaIndex: auxWalkerAreaIndex,
            selectedWalkerIndex: auxWalkerIndex,
            selectedWalkerTimePeriodIndex: auxTimePeriodIndex,
          });
          return;
        }

        if (isInit && this.props.options.walkerAssignInfo) {
          const { walker_area_id, walker_id, time_id } =
            this.props.options.walkerAssignInfo;
          const walkerAreas = data.Item1;

          let auxWalkerAreaIndex = walkerAreas.findIndex(
            (x) => x.id == walker_area_id
          );

          let auxWalkerIndex = walkerAreas[
            auxWalkerAreaIndex
          ]?.walkers.findIndex((x) => x.id == walker_id);
          let auxTimePeriodIndex = walkerAreas[auxWalkerAreaIndex]?.walkers[
            auxWalkerIndex
          ]?.timePeriods.findIndex((x) => x.id == time_id);

          this.setState({
            walkerAreas: data.Item1,
            selectedWalkerAreaIndex: auxWalkerAreaIndex,
            selectedWalkerIndex: auxWalkerIndex,
            selectedWalkerTimePeriodIndex: auxTimePeriodIndex,
          });
        } else {
          this.setState({ walkerAreas: data.Item1 });
        }
      }
    );
  };

  loadData = () => {
    let horseArr = window.DataStorage.getAllHorses();
    horseArr = horseArr.sort((h1, h2) =>
      h1.name.toLowerCase() > h2.name.toLowerCase() ? 1 : -1
    );
    var imgArr = [
      { imgId: "5", src: "img/smiley5.png" },
      { imgId: "4", src: "img/smiley4.png" },
      { imgId: "3", src: "img/smiley3.png" },
      { imgId: "2", src: "img/smiley2.png" },
      { imgId: "1", src: "img/smiley1.png" },
    ];

    let trainingTypes = this.trainingTypesForHorse(this.props.options.horseId);
    let vetTraining = trainingTypes.filter((i) => i.IsVeterinarian == true);
    if (vetTraining.length) {
      this.setState({ vetTrainingId: vetTraining[0].id });
    }
    let farTraining = trainingTypes.filter((i) => i.IsFarrier == true);
    if (farTraining.length) {
      this.setState({ farTrainingId: farTraining[0].id });
    }

    var currentHorseId = this.props.options.horseId;
    // load field areas (with timeperiods that are available)

    this.loadFieldAreas(currentHorseId, this.props.item?.name, true);
    let currentHorse = horseArr.filter((i) => i.id == currentHorseId);
    let currentHorseOwner = "";
    if (currentHorse) currentHorseOwner = currentHorse[0].userid;
    if (this.props.options.farrWeek)
      var currentFarrierWeek = this.props.options.farrWeek;
    else
      var currentFarrierWeek = window.GlobalData.getItem(
        window.GlobalData.SETTINGS_FARRIER_WEEKS
      );

    this.setState(
      {
        trainingTypesArr: trainingTypes,
        horseNameArr: horseArr,
        imgStrArr: imgArr,
        date: this.props.options.date,
        currentStarttime: this.props.options.date.formatDesh(),
        currentHorseId: this.props.options.horseId,
        currentHorseOwner: currentHorseOwner,
        currentTrainingType: this.props.options.trainingType,
        currentFarrierWeek: currentFarrierWeek,
        currentTrainerId: this.props.options.trainerId,
        currentTrainerName: this.props.options.trainerName,
      },
      this.loadDataFromProps
    );

    window.db.getTrainers(this.props.options.horseId, (data) => {
      let ridersOrder = window.GlobalData.getItem(
        window.GlobalData.KEY_RIDERS_ORDER
      );
      if (ridersOrder != null) {
        try {
          ridersOrder = JSON.parse(ridersOrder);

          ridersOrder[window.myid] = 0;

          data.sort((a, b) => {
            let orderA = ridersOrder[a.id];
            let orderB = ridersOrder[b.id];
            if (orderA != null && orderB != null) return orderA - orderB;
            //if (orderA != null && orderB == null)
            //    return 1;
            //if (orderA == null && orderB != null)
            //    return -1;

            return 0;
          });
        } catch (e) {}
      }
      this.setState({
        trainersArr: data,
      });
    });

    window.db.getFarrierTypes(window.myid, (ftypes) => {
      let currentFarrierType = this.state.currentFarrierType;
      if (ftypes.length != 0) {
        if (ftypes.find((ft) => ft.Id == currentFarrierType) == null) {
          currentFarrierType = ftypes[0].Id;
        }
      } else {
        currentFarrierType = null;
      }
      this.setState({
        farrierType: ftypes,
        currentFarrierType: currentFarrierType,
      });
    });

    window.db.getUserDewormingType(window.myid, (dewormingTypes) => {
      this.setState({
        dewormingTypes: dewormingTypes,
      });
    });
  };

  loadDataFromProps = () => {
    let currentTrainingType = this.state.currentTrainingType;

    if (!this.state.currentTrainingType && this.state.trainingTypesArr.length) {
      currentTrainingType = this.state.trainingTypesArr[0].id;
      this.setState({
        currentTrainingType: this.state.trainingTypesArr[0].id,
      });
    }

    if (this.props.item) {
      let currentNextDewormingPeriod =
        this.state.vetTrainingId == currentTrainingType &&
        (this.props.item.VeterinarianType == 4 ||
          this.props.item.VeterinarianType == 3)
          ? this.props.item.NextDewormingPeriod
          : "";
      let nextDewormingDate = "";
      if (currentNextDewormingPeriod != "") {
        currentNextDewormingPeriod = parseInt(currentNextDewormingPeriod);
        switch (currentNextDewormingPeriod) {
          case 0:
            var date = new Date(this.props.options.date.formatDesh());
            date = new Date(date.setMonth(date.getMonth() + 1));
            nextDewormingDate = date.formatDesh();
            break;
          case 1:
            var date = new Date(this.props.options.date.formatDesh());
            date = new Date(date.setMonth(date.getMonth() + 3));
            nextDewormingDate = date.formatDesh();
            break;
          case 2:
            var date = new Date(this.props.options.date.formatDesh());
            date = new Date(date.setMonth(date.getMonth() + 6));
            nextDewormingDate = date.formatDesh();
            break;
          case 3:
            var date = new Date(this.props.options.date.formatDesh());
            date = new Date(date.setMonth(date.getMonth() + 12));
            nextDewormingDate = date.formatDesh();
            break;
        }
      }
      this.setState(
        {
          currentTrainingId: this.props.item.id,
          currentRemark: this.props.item.remark,
          currentEvaluation: this.props.item.Evaluation,
          currentIsCanCopy: this.props.item.isCanCopy,
          currentColor: this.props.item.color,
          currentVeterinarianType: this.props.item.VeterinarianType,
          currentFarrierType: this.props.item.FarrierType,
          currentTraining: this.props.item,
          currentHasMultimedia: this.props.item.HasMultimedia,
          currentPaddockPeriod: this.props.item.PaddockPeriod,
          currentReminderType: this.props.item.ReminderType,
          currentTimeOfDay: this.props.item.TimeOfDay,
          currentVeterinarianVacType: this.props.item.VeterinarianVacType,
          currentDuration: this.props.item.duration,
          currentTrainingName: this.props.item.name,
          currentTrainingShortname: this.props.item.shortname,
          currentStatus: this.props.item.status,
          currentSynctime: this.props.item.synctime,
          currentDewormingType: this.props.item.DewormingType,
          currentNextDewormingPeriod: currentNextDewormingPeriod,
          nextDewormingDate: nextDewormingDate,
        },
        () => {
          window.db.getNotesByHorse(this.state.currentHorseId, (notes) => {
            this.setState({ horseNotes: notes });
          });
        }
      );
    } else {
      let currentTraining = this.state.trainingTypesArr.filter(
        (item) => item.id == currentTrainingType
      );
      let color = "red";
      if (this.props.options.isDone) color = "green";
      else color = "red";

      var date = new Date(this.props.options.date.formatDesh());
      date = new Date(date.setMonth(date.getMonth() + 6));
      let nextDewormingDate = date.formatDesh();
      this.setState(
        {
          currentTrainingName: currentTraining[0].name,
          currentTrainingShortname: currentTraining[0].shortname,
          currentColor: color,
          currentNextDewormingPeriod: 2,
          nextDewormingDate: nextDewormingDate,
        },
        () => {
          window.db.getNotesByHorse(this.state.currentHorseId, (notes) => {
            this.setState({ horseNotes: notes });
          });
        }
      );
    }
  };

  handleChange = (date) => {
    this.setState({
      date: date,
      currentStarttime: date.formatDesh(),
    });
  };

  hide() {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("trainingPopupReact")
    );
  }

  onCancelClick = () => {
    this.hide();
  };

  onSaveClick = () => {
    if (this.isField(this.state.currentTrainingName)) {
      const enforceFieldData =
        window.DataStorage.getUserSetting(1, this.state.currentHorseOwner) == 1;
      if (
        enforceFieldData &&
        (this.state.selectedFieldAreaIndex < 0 ||
          this.state.selectedFieldIndex < 0 ||
          this.state.selectedFieldTimePeriodIndex < 0)
      ) {
        toast.error("Please select field area, field and time period");
        return;
      }
    }

    if (this.isWalker(this.state.currentTrainingName)) {
      const enforceFieldData =
        window.DataStorage.getUserSetting(2, this.state.currentHorseOwner) == 1;
      if (
        enforceFieldData &&
        (this.state.selectedWalkerAreaIndex < 0 ||
          this.state.selectedWalkerIndex < 0 ||
          this.state.selectedWalkerTimePeriodIndex < 0)
      ) {
        toast.error("Please select walker area, walker and time period");
        return;
      }
    }

    window.GlobalData.setItem(
      window.GlobalData.SETTINGS_FARRIER_WEEKS,
      this.state.currentFarrierWeek
    );
    window.GlobalData.setItem(
      window.GlobalData.SETTINGS_FARRIER_WEEKS +
        "&" +
        this.state.currentHorseId,
      this.state.currentFarrierWeek
    );
    if (this.props.item && this.props.item.GroupId) {
      new GroupDeleteUpdateQuestionWindow().showWindow(
        false,
        (isGroupSelected) => {
          this.saveTraining(() => {
            this.hide();
            if (isGroupSelected) {
              window.DataGate.updateCopyTraining(
                this.props.item.id,
                (code, data) => {
                  window.training.refresh({ showSpinner: false });
                }
              );
            }
          });
        }
      );
    } else {
      this.saveTraining(() => {});
    }
  };

  isField = (name) => {
    if (!name) return false;
    name = name.toLowerCase();

    if (!name) return false;
    return name == "field" || name == "fold" || name == "feld";
  };

  isWalker = (name) => {
    if (!name) return false;
    name = name.toLowerCase();

    return name == "walker";
  };

  getFieldNote = (notes) => {
    let fieldNote = notes.find((note) => this.isField(note.HeaderNote));
    return fieldNote?.TextNote && fieldNote;
  };
  saveTraining = (onDoneCallback) => {
    const {
      currentColor,
      currentDuration,
      currentEvaluation,
      currentFarrierType,
      currentHasMultimedia,
      currentHorseId,
      currentTrainingId,
      currentIsCanCopy,
      currentPaddockPeriod,
      currentRemark,
      currentReminderType,
      currentStarttime,
      currentStatus,
      currentSynctime,
      currentTimeOfDay,
      currentTrainerId,
      currentTrainingType,
      currentVeterinarianType,
      currentVeterinarianVacType,
      currentTrainingName,
      currentTrainingShortname,
      currentTrainerName,
      dewormingTypes,
      currentDewormingType,
      dewormingTypesPeriod,
      currentNextDewormingPeriod,
      farTrainingId,
      vetTrainingId,
    } = this.state;

    var trainItem = {};
    trainItem["Color"] = currentColor ? currentColor : "red";
    trainItem["Duration"] = currentDuration ? currentDuration : 1;
    trainItem["Evaluation"] = currentEvaluation ? currentEvaluation : 0;
    trainItem["FarrierType"] =
      currentTrainingType == farTrainingId
        ? currentFarrierType
          ? currentFarrierType
          : null
        : null;
    trainItem["HasMultimedia"] = currentHasMultimedia
      ? currentHasMultimedia
      : null;
    trainItem["HorseId"] = currentHorseId
      ? currentHorseId
      : this.props.options.horseId;
    trainItem["Id"] = currentTrainingId ? currentTrainingId : Math.uuid();
    trainItem["IsCanCopy"] = currentIsCanCopy ? currentIsCanCopy : null;
    trainItem["PaddockPeriod"] = currentPaddockPeriod
      ? currentPaddockPeriod
      : null;
    trainItem["Remark"] = currentRemark ? currentRemark : "";
    trainItem["ReminderType"] = currentReminderType ? currentReminderType : 0;
    trainItem["StartTime"] = currentStarttime;
    trainItem["Status"] = currentStatus;
    trainItem["SyncTime"] = currentSynctime ? currentSynctime : -1;
    trainItem["TimeOfDay"] = currentTimeOfDay ? currentTimeOfDay : "";
    trainItem["Trainer"] = currentTrainerId ? currentTrainerId : window.myid;
    trainItem["Type"] = currentTrainingType;
    trainItem["U"] = "u";
    trainItem["VeterinarianType"] =
      currentTrainingType == vetTrainingId
        ? currentVeterinarianType
          ? currentVeterinarianType
          : 2
        : null;
    trainItem["VeterinarianVacType"] =
      currentTrainingType == vetTrainingId
        ? currentVeterinarianVacType
          ? currentVeterinarianVacType
          : null
        : null;
    trainItem["DewormingType"] =
      currentTrainingType == vetTrainingId && currentVeterinarianType == 4
        ? currentDewormingType
          ? currentDewormingType
          : dewormingTypes[0].Id
        : null;
    trainItem["NextDewormingPeriod"] =
      currentTrainingType == vetTrainingId &&
      (currentVeterinarianType == 4 || currentVeterinarianType == 3)
        ? currentNextDewormingPeriod
          ? currentNextDewormingPeriod
          : 2
        : null;

    const isFieldTask = this.isField(this.state.currentTrainingName);

    if (isFieldTask && this.state.selectedFieldAreaIndex >= 0) {
      trainItem["FieldAreaId"] =
        this.state.fieldAreas[this.state.selectedFieldAreaIndex]?.id;
      trainItem["FieldId"] =
        this.state.fieldAreas[this.state.selectedFieldAreaIndex]?.fields[
          this.state.selectedFieldIndex
        ]?.id;
      trainItem["FieldAreaTimePeriodId"] =
        this.state.fieldAreas[this.state.selectedFieldAreaIndex]?.fields[
          this.state.selectedFieldIndex
        ]?.timePeriods[this.state.selectedFieldTimePeriodIndex]?.id;

      trainItem["FieldAssignmentFieldAreaName"] =
        this.state.fieldAreas[this.state.selectedFieldAreaIndex]?.name;
      trainItem["FieldAssignmentFieldName"] =
        this.state.fieldAreas[this.state.selectedFieldAreaIndex]?.fields[
          this.state.selectedFieldIndex
        ]?.name;
      trainItem["FieldAssignmentFieldTimePeriod"] =
        this.state.fieldAreas[this.state.selectedFieldAreaIndex]?.fields[
          this.state.selectedFieldIndex
        ]?.timePeriods[this.state.selectedFieldTimePeriodIndex]?.period;
    }

    if (
      this.state.selectedFieldAreaIndex == -1 &&
      this.props.options.fieldAssignInfo
    ) {
      const { field_area_id, field_id, time_id } =
        this.props.options.fieldAssignInfo;
      if (field_area_id) {
        trainItem["FieldAreaId"] = field_area_id;
        trainItem["FieldId"] = field_id;
        trainItem["FieldAreaTimePeriodId"] = time_id;
      }
    }

    //walker

    const isWalkerTask = this.isWalker(this.state.currentTrainingName);

    if (isWalkerTask && this.state.selectedWalkerAreaIndex >= 0) {
      trainItem["WalkerAreaId"] =
        this.state.walkerAreas[this.state.selectedWalkerAreaIndex]?.id;
      trainItem["WalkerId"] =
        this.state.walkerAreas[this.state.selectedWalkerAreaIndex]?.walkers[
          this.state.selectedWalkerIndex
        ]?.id;
      trainItem["WalkerAreaTimePeriodId"] =
        this.state.walkerAreas[this.state.selectedWalkerAreaIndex]?.walkers[
          this.state.selectedWalkerIndex
        ]?.timePeriods[this.state.selectedWalkerTimePeriodIndex]?.id;

      trainItem["WalkerAssignmentWalkerAreaName"] =
        this.state.walkerAreas[this.state.selectedWalkerAreaIndex]?.name;
      trainItem["WalkerAssignmentWalkerName"] =
        this.state.walkerAreas[this.state.selectedWalkerAreaIndex]?.walkers[
          this.state.selectedWalkerIndex
        ]?.name;
      trainItem["WalkerAssignmentWalkerTimePeriod"] =
        this.state.walkerAreas[this.state.selectedWalkerAreaIndex]?.walkers[
          this.state.selectedWalkerIndex
        ]?.timePeriods[this.state.selectedWalkerTimePeriodIndex]?.period;
    }

    if (
      this.state.selectedWalkerAreaIndex == -1 &&
      this.props.options.walkerAssignInfo
    ) {
      const { walker_area_id, walker_id, time_id } =
        this.props.options.walkerAssignInfo;
      if (walker_area_id) {
        trainItem["WalkerAreaId"] = walker_area_id;
        trainItem["WalkerId"] = walker_id;
        trainItem["WalkerAreaTimePeriodId"] = time_id;
      }
    }

    var tr = {};
    tr["id"] = trainItem["Id"];
    tr["horseid"] = trainItem["HorseId"];
    tr["starttime"] = trainItem["StartTime"];
    tr["status"] = trainItem["Status"];
    tr["duration"] = trainItem["Duration"];
    tr["u"] = trainItem["U"];
    tr["remark"] = trainItem["Remark"];
    tr["color"] = trainItem["Color"];
    tr["trainer"] = trainItem["Trainer"];
    tr["syncTime"] = -1;
    tr["isCanCopy"] = parseInt(trainItem["IsCanCopy"]) == 0 ? false : true;
    tr["type"] = trainItem["Type"];
    tr["TimeOfDay"] = trainItem["TimeOfDay"];
    tr["HasMultimedia"] = trainItem["HasMultimedia"];
    tr["Evaluation"] = trainItem["Evaluation"];
    tr["ReminderType"] = trainItem["ReminderType"];
    tr["FarrierType"] = trainItem["FarrierType"];
    tr["name"] = currentTrainingName;
    tr["shortname"] = currentTrainingShortname;
    tr["trainername"] = currentTrainerName;
    tr["PaddockPeriod"] = trainItem["PaddockPeriod"];
    tr["VeterinarianType"] = trainItem["VeterinarianType"];
    tr["VeterinarianVacType"] = trainItem["VeterinarianVacType"];
    tr["DewormingType"] = trainItem["DewormingType"];
    tr["NextDewormingPeriod"] = trainItem["NextDewormingPeriod"];

    tr["FieldAssignmentFieldAreaName"] =
      this.state.fieldAreas[this.state.selectedFieldAreaIndex]?.name;
    tr["FieldAssignmentFieldName"] =
      this.state.fieldAreas[this.state.selectedFieldAreaIndex]?.fields[
        this.state.selectedFieldIndex
      ]?.name;
    tr["FieldAssignmentFieldTimePeriod"] =
      this.state.fieldAreas[this.state.selectedFieldAreaIndex]?.fields[
        this.state.selectedFieldIndex
      ]?.timePeriods[this.state.selectedFieldTimePeriodIndex]?.period;

    tr["FieldAreaId"] = trainItem["FieldAreaId"];
    tr["FieldId"] = trainItem["FieldId"];
    tr["FieldAreaTimePeriodId"] = trainItem["FieldAreaTimePeriodId"];

    //Walker assignment
    tr["WalkerAssignmentWalkerAreaName"] =
      this.state.walkerAreas[this.state.selectedWalkerAreaIndex]?.name;
    tr["WalkerAssignmentWalkerName"] =
      this.state.walkerAreas[this.state.selectedWalkerAreaIndex]?.walkers[
        this.state.selectedWalkerIndex
      ]?.name;
    tr["WalkerAssignmentWalkerTimePeriod"] =
      this.state.walkerAreas[this.state.selectedWalkerAreaIndex]?.walkers[
        this.state.selectedWalkerIndex
      ]?.timePeriods[this.state.selectedWalkerTimePeriodIndex]?.period;

    tr["WalkerAreaId"] = trainItem["WalkerAreaId"];
    tr["WalkerId"] = trainItem["WalkerId"];
    tr["WalkerAreaTimePeriodId"] = trainItem["WalkerAreaTimePeriodId"];

    if (this.props.item) {
      tr.GroupId = this.props.item.GroupId;
      tr.CopyDuration = this.props.item.CopyDuration;
      tr.DaysToCopy = this.props.item.DaysToCopy;
    }

    this.state.currentTraining = tr;

    if (currentTrainingId) {
      tr.TrainingFlag = window.DataStorage.TrainingFlagUpdate;
    } else {
      tr.TrainingFlag = window.DataStorage.TrainingFlagAdd;
    }
    window.DataStorage.addTraining(tr);
    window.training.refreshLayout();
    this.state.currentTrainingId = trainItem["Id"];

    window.training.trainingRequestStart();

    window.DataGate.insertOrUpdateTrainings(trainItem, (code, data) => {
      if (code == window.DataGate.CODE_OK) {
        window.training.trainingRequestEnd();
        console.log("Training saved");
        //window.training.refresh({ showSpinner: false });
      } else {
        window.showConnectionError();
      }

      if (onDoneCallback) onDoneCallback();

      if (this.props.callback) {
        this.props.callback(code == window.DataGate.CODE_OK);
      }

      if (this.props.returnTrainingCallback) {
        this.props.returnTrainingCallback(tr);
      }
    });
    this.hide();
  };

  onExtraTrainingClick = () => {
    let oprionObj = {
      horseId: this.state.currentHorseId,
      date: this.state.date,
    };
    this.onSaveClick(true);
    setTimeout(() => {
      new TrainingPopup().showPopup(oprionObj, null, () => {});
    }, 100);
  };

  onDeleteTrainingClick = () => {
    if (this.props.item && this.props.item.GroupId) {
      new GroupDeleteUpdateQuestionWindow().showWindow(
        true,
        (isGroupSelected) => {
          this.deleteTraining(() => {
            this.hide();
            if (isGroupSelected) {
              window.DataGate.deleteCopyTraining(
                this.props.item.id,
                (code, data) => {
                  window.training.refresh({ showSpinner: false });
                }
              );
            }
          });
        }
      );
    } else {
      this.deleteTraining();
    }
    this.hide();
  };

  deleteTraining = (onDoneCallback) => {
    if (this.state.currentTrainingId) {
      window.DataStorage.deleteTrainingById(this.state.currentTrainingId);
      window.training.refreshLayout();

      window.training.trainingRequestStart();
      window.DataGate.deleteTrainings(
        this.state.currentTrainingId,
        "d",
        this.props.options.horseId,
        this.state.currentStarttime,
        (code) => {
          if (code == window.DataGate.CODE_OK) {
            window.training.trainingRequestEnd();
            //window.training.refresh({ showSpinner: false });
          } else {
            window.showConnectionError();
          }
          if (onDoneCallback) onDoneCallback();
        }
      );
    }
  };

  onShowMultimediaClick = () => {
    if (!this.state.currentTrainingId) {
      this.saveTraining();
      window.training.refresh({ showSpinner: false });
    }
    this.onCancelClick();
    let $ = window.$;
    window.showPage("#taskImagePage");
    $("#taskImagePage").css("min-height", "95%");
    $("#taskImageHeader").html(window.lang.image);
    $("#taskImageBackButton, #taskImageOkButton").unbind("click");
    $("#taskImageBackButton, #taskImageOkButton").bind("click", function (e) {
      window.onBack();
      $(".load-bar").css("top", "70px");
      e.stopPropagation();
    });
    let horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == this.state.currentHorseId
    );
    ReactDOM.render(
      <HorseGallery
        showUploadButton={true}
        type={"Task"}
        horse={horse}
        title={"Task"}
        noteOrTaskId={this.state.currentTrainingId}
      />,
      document.getElementById("taskImageDropTable")
    );
  };

  onXrayClick = () => {
    if (!this.state.currentTrainingId) {
      this.saveTraining();
      window.training.refresh({ showSpinner: false });
    }

    this.hide();
    var taskId = this.state.currentTraining.id;
    TaskXrayPage.show(taskId);
  };

  onCopyTaskClick = () => {
    if (!this.state.currentTrainingId) {
      this.saveTraining();
      window.training.refresh({ showSpinner: false });
    }
    let copyTaskWindow = new CopyTaskWindow();
    copyTaskWindow.showWindow(this.state.currentTraining, this.onCopyDone);
  };

  onCopyDone = () => {
    this.hide();
  };

  onReminderSelectClick = () => {
    this.setState({
      showReminderPopup: !this.state.showReminderPopup,
    });
  };

  onHorseClick = (e) => {
    if (!e.currentTarget.dataset.idf) return;

    const vx = e.currentTarget.dataset.idf;
    let nextHorseId = this.state.horseNameArr.find(
      (horse) => horse.id == vx
    ).id;

    if (nextHorseId == this.state.currentHorseId) return;

    let nextHorseOwnerId = this.state.horseNameArr.find(
      (horse) => horse.id == vx
    ).userid;
    let prevHorseOwnerId = this.state.horseNameArr.find(
      (horse) => horse.id == vx
    ).userid;

    let currentTrainerId = this.state.horseNameArr.find(
      (horse) => horse.id == vx
    ).trainer;
    if (!currentTrainerId) currentTrainerId = window.myid;
    window.db.getTrainers(nextHorseId, (data) => {
      let ridersOrder = window.GlobalData.getItem(
        window.GlobalData.KEY_RIDERS_ORDER
      );
      if (ridersOrder != null) {
        ridersOrder = JSON.parse(ridersOrder);

        try {
          ridersOrder[window.myid] = 0;

          data.sort((a, b) => {
            let orderA = ridersOrder[a.id];
            let orderB = ridersOrder[b.id];
            if (orderA != null && orderB != null) return orderA - orderB;
            return 0;
          });
        } catch (e) {}
      }
      this.setState(
        {
          trainersArr: data,
        },
        () => {
          if (nextHorseOwnerId != prevHorseOwnerId)
            this.loadFieldAreas(vx, this.state.currentTrainingName);

          this.setState(
            {
              currentHorseId: vx,
              trainingTypesArr: this.trainingTypesForHorse(vx),
              currentTrainerId: currentTrainerId,
            },
            () => {
              window.db.getNotesByHorse(vx, (notes) => {
                this.setState({ horseNotes: notes });
              });
            }
          );
        }
      );
    });
  };

  onHorseChange = (e) => {
    let currentTrainerId = this.state.horseNameArr.find(
      (horse) => horse.id == e.target.value
    ).trainer;

    if (!currentTrainerId) currentTrainerId = window.myid;

    this.setState(
      {
        currentHorseId: e.target.value,
        trainingTypesArr: this.trainingTypesForHorse(e.target.value),
        currentTrainerId: currentTrainerId,
      },
      () => {
        window.db.getTrainers(this.state.currentHorseId, (data) => {
          let ridersOrder = window.GlobalData.getItem(
            window.GlobalData.KEY_RIDERS_ORDER
          );
          if (ridersOrder != null) {
            ridersOrder = JSON.parse(ridersOrder);
            try {
              ridersOrder[window.myid] = 0;

              data.sort((a, b) => {
                let orderA = ridersOrder[a.id];
                let orderB = ridersOrder[b.id];
                if (orderA != null && orderB != null) return orderA - orderB;
                //if (orderA != null && orderB == null)
                //    return 1;
                //if (orderA == null && orderB != null)
                //    return -1;

                return 0;
              });
            } catch (e) {}
          }
          this.setState(
            {
              trainersArr: data,
            },
            () => {
              window.db.getNotesByHorse(this.state.currentHorseId, (notes) => {
                this.setState({ horseNotes: notes });
              });
            }
          );
        });
      }
    );
  };

  onTrainerClick = (e) => {
    let currentTrainerId = e.currentTarget.dataset.idf;

    if (currentTrainerId) {
      let currentTrainer = this.state.trainersArr.filter(
        (i) => i.id == currentTrainerId
      );
      this.setState({
        currentTrainerId: currentTrainerId,
        currentTrainerName: currentTrainer[0].name,
      });
    }
  };

  onTrainerChange = (e) => {
    let currentTrainerId = e.target.value;
    let currentTrainer = this.state.trainersArr.filter(
      (i) => i.id == currentTrainerId
    );
    this.setState({
      currentTrainerId: currentTrainerId,
      currentTrainerName: currentTrainer[0].name,
    });
  };

  onTrainingClick = (e) => {
    let currentTrainingId = e.currentTarget.dataset.idf;
    if (currentTrainingId) {
      let currentTraining = this.state.trainingTypesArr.filter(
        (i) => i.id == currentTrainingId
      );
      if (
        this.state.vetTrainingId == currentTrainingId &&
        (this.state.currentVeterinarianType == 4 ||
          this.state.currentVeterinarianType == 3) &&
        this.state.currentNextDewormingPeriod == undefined
      ) {
        this.state.currentNextDewormingPeriod = 2;
        var date = new Date(this.state.currentStarttime.formatDesh());
        date = new Date(date.setMonth(date.getMonth() + 6));
        this.state.nextDewormingDate = date.formatDesh();
      }
      this.setState({
        currentTrainingType: currentTrainingId,
        currentTrainingName: currentTraining[0].name,
        currentTrainingShortname: currentTraining[0].shortname,
      });
      this.loadFieldAreas(this.state.currentHorseId, currentTraining[0].name);
    }
  };

  onTrainingChange = (e) => {
    let currentTrainingId = e.target.value;
    let currentTraining = this.state.trainingTypesArr.filter(
      (i) => i.id == currentTrainingId
    );
    if (
      this.state.vetTrainingId == currentTrainingId &&
      (this.state.currentVeterinarianType == 4 ||
        this.state.currentVeterinarianType == 3) &&
      this.state.currentNextDewormingPeriod == undefined
    ) {
      this.state.currentNextDewormingPeriod = 2;
      var date = new Date(this.state.currentStarttime.formatDesh());
      date = new Date(date.setMonth(date.getMonth() + 6));
      this.state.nextDewormingDate = date.formatDesh();
    }

    this.setState({
      currentTrainingType: currentTrainingId,
      currentTrainingName: currentTraining[0].name,
      currentTrainingShortname: currentTraining[0].shortname,
    });
  };

  onDoneClick = (e) => {
    if (e.target.checked) {
      this.setState({
        currentColor: "green",
      });
    } else {
      this.setState({
        currentColor: "red",
      });
    }
  };

  onEvaluationClick = (e) => {
    if (e.target.dataset.id) {
      this.setState({
        currentEvaluation: e.target.dataset.id,
      });
    }
  };

  onCanCopyClick = (e) => {
    this.setState({
      currentIsCanCopy: e.target.checked,
    });
  };

  onRemarkChange = (e) => {
    this.setState({
      currentRemark: e.target.value,
    });
  };

  showTimeSelect = () => {
    this.setState({
      isShowTimePopup: true,
    });
  };

  hideTimeSelect = () => {
    this.setState({
      isShowTimePopup: false,
    });
  };

  onTimeChange = (val) => {
    this.setState({
      isShowTimePopup: false,
      currentTimeOfDay: val,
    });
  };

  onVeterinarianTypeChange = (e) => {
    if (
      (e.target.value == 4 || e.target.value == 3) &&
      this.state.currentNextDewormingPeriod == ""
    ) {
      this.state.currentNextDewormingPeriod = 2;
      var date = new Date(this.state.currentStarttime);
      date = new Date(date.setMonth(date.getMonth() + 6));
      this.state.nextDewormingDate = date.formatDesh();
    }
    this.setState({
      currentVeterinarianType: e.target.value,
    });
  };

  onFarrierTypeChange = (e) => {
    this.setState({
      currentFarrierType: e.target.value,
    });
  };

  onFarrierWeekChange = (e) => {
    let date = new Date();
    if (e.target.value < 7) {
      date.setDate(
        date.getDate() +
          ((parseInt(e.target.value) + 1 + 7 - date.getDay()) % 7)
      );
    } else if (e.target.value > 7) {
      date = new Date().addDays(7 * (parseInt(e.target.value) - 4));
    }
    this.setState({
      currentFarrierWeek: e.target.value,
      date: date,
      currentStarttime: date.formatDesh(),
    });
  };

  onDewormingTypeChange = (e) => {
    this.setState({
      currentDewormingType: e.target.value,
    });
  };

  onDewormingTypePeriodChange = (e) => {
    let currentNextDewormingPeriod = e.target.value;
    let nextDewormingDate = "";
    switch (currentNextDewormingPeriod) {
      case "0":
        var date = new Date(this.props.options.date.formatDesh());
        date = new Date(date.setMonth(date.getMonth() + 1));
        nextDewormingDate = date.formatDesh();
        break;
      case "1":
        var date = new Date(this.props.options.date.formatDesh());
        date = new Date(date.setMonth(date.getMonth() + 3));
        nextDewormingDate = date.formatDesh();
        break;
      case "2":
        var date = new Date(this.props.options.date.formatDesh());
        date = new Date(date.setMonth(date.getMonth() + 6));
        nextDewormingDate = date.formatDesh();
        break;
      case "3":
        var date = new Date(this.props.options.date.formatDesh());
        date = new Date(date.setMonth(date.getMonth() + 12));
        nextDewormingDate = date.formatDesh();
        break;
    }
    this.setState({
      currentNextDewormingPeriod: e.target.value,
      nextDewormingDate: nextDewormingDate,
    });
  };

  scrollToSelectedItems = () => {
    let selectedCollection = document.getElementsByClassName(
      "ui-btn selectedListItem"
    );
    let selectedArr = [].slice.call(selectedCollection);
    if (selectedArr.length) selectedArr.map((i) => i.scrollIntoView());
  };

  onRemiderSelect = (selectedId) => {
    this.setState({
      currentReminderType: selectedId,
      showReminderPopup: false,
    });
  };

  //#region move popup
  onMouseDown = (e) => {
    this.isMovingMode = true;
    this.startX = e.clientX;
    this.startY = e.clientY;
    let el = document.getElementById("trainingEditWindowReact");
    this.elementStartLeft = el.offsetLeft;
    this.elementStartTop = el.offsetTop;
  };
  onMouseMove = (e) => {
    if (this.isMovingMode && e.target.id == "taskHeaderReact") {
      let el = document.getElementById("trainingEditWindowReact");
      el.style.left = this.elementStartLeft + (e.clientX - this.startX) + "px";
      el.style.top = this.elementStartTop + (e.clientY - this.startY) + "px";
    }
  };
  onMouseUp = (e) => {
    this.isMovingMode = false;
  };
  //#endregion

  render() {
    const {
      currentTrainingId,
      currentHorseId,
      currentTrainerId,
      currentTrainingType,
      currentRemark,
      currentEvaluation,
      currentIsCanCopy,
      currentColor,
      date,
      horseNameArr,
      trainersArr,
      trainingTypesArr,
      imgStrArr,
      showMultimediaTraining,
      showTimeTraining,
      showDateTraining,
      showDoneTraining,
      showEvaluationTraining,
      showCopyTaskTraining,
      showRemarksTraining,
      showCopyButtonTraining,
      showRemainderTraining,
      vetTrainingId,
      veterinarType,
      currentVeterinarianType,
      farTrainingId,
      farrierType,
      currentFarrierType,
      farrierWeeks,
      currentFarrierWeek,
      currentTimeOfDay,
      currentReminderType,
      dewormingTypes,
      currentDewormingType,
      dewormingTypesPeriod,
      currentNextDewormingPeriod,
      nextDewormingDate,
    } = this.state;
    //var selectTime = window.lang.selectTime.length > 15 ? window.lang.selectTime.substring(0, 12) + '...' : window.lang.selectTime;
    const selectTime =
      currentTimeOfDay != "" ? currentTimeOfDay : window.lang.selectTime;
    var inches =
      Math.sqrt(
        Math.pow(window.screen.width, 2) + Math.pow(window.screen.height, 2)
      ) *
      (1 / window.inch.offsetWidth);

    if (currentHorseId) {
      let horseItem = window.DataStorage.getAllHorses().find(
        (h) => h.id == currentHorseId
      );
      if (horseItem.userid != window.myid) {
        var role = window.DataStorage.rolesDataFromUsers.find(
          (r) => r.UserId == horseItem.userid
        );
      }
    }

    const translateWord = (word) => {
      if (window.lang[word.toLowerCase()])
        return window.lang[word.toLowerCase()];

      return word;
    };

    return (
      <div>
        <div
          data-role="page"
          id="trainingEditPageMobile"
          tabIndex="0"
          className="ui-page ui-page-theme-a ui-page-active mobile-page"
        >
          <div
            data-role="header"
            data-position="fixed"
            className="ui-header ui-header-fixed slidedown ui-bar-inherit"
            role="banner"
          >
            <img
              id="cancelEditTraining"
              className="headerIconLeft"
              src="img/icon_canceln.png"
              onClick={this.onCancelClick}
            />
            <h1 className="ui-title" role="heading" aria-level="1" />
            <img
              className="tcOkButton headerIconRignt"
              src="img/icon_okn.png"
              index-id="0"
              onClick={this.onSaveClick}
            />
          </div>
          <div data-role="content" className="ContentForMobile">
            <table style={{ width: "100%", borderSpacing: "20px 0" }}>
              <tbody>
                <tr id="textLine1">
                  <td style={{ width: "50%" }}>{window.lang.trainerName}</td>
                  <td>{window.lang.training}</td>
                </tr>
                <tr id="textLine2">
                  <td>
                    <select
                      className="trSelect tcTrainer"
                      data-role="none"
                      style={{ width: "100%" }}
                      value={currentTrainerId}
                      onChange={this.onTrainerChange}
                    >
                      {trainersArr.length ? (
                        trainersArr.map((currentElement) =>
                          currentElement.id == window.myid ? (
                            <option key={Math.uuid()} value={currentElement.id}>
                              Me
                            </option>
                          ) : (
                            <option key={Math.uuid()} value={currentElement.id}>
                              {currentElement.name}
                            </option>
                          )
                        )
                      ) : (
                        <></>
                      )}
                    </select>
                  </td>
                  <td>
                    <select
                      className="tSelect tcType"
                      data-role="none"
                      style={{ width: "100%" }}
                      value={currentTrainingType}
                      onChange={this.onTrainingChange}
                    >
                      {trainingTypesArr.length ? (
                        trainingTypesArr.map((currentElement) => (
                          <option key={Math.uuid()} value={currentElement.id}>
                            {translateWord(currentElement.name) +
                              "(" +
                              currentElement.shortname +
                              ")"}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td id="timeTrainindddg">
                    <div style={{ marginTop: "5px" }}>{window.lang.horse}</div>
                    <select
                      className="thSelect tcHorse"
                      data-role="none"
                      style={{ width: "100%" }}
                      value={currentHorseId}
                      // disabled={currentTrainingId ? true : false}
                      onChange={this.onHorseChange}
                    >
                      {horseNameArr.length ? (
                        horseNameArr.map((currentElement) => (
                          <option key={Math.uuid()} value={currentElement.id}>
                            {currentElement.name}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </select>
                  </td>
                  {showTimeTraining == 1 ? (
                    <td id="timeTraining" style={{ verticalAlign: "bottom" }}>
                      <button
                        className="openTimeSelectPopup ui-btn ui-shadow ui-corner-all"
                        style={{ margin: "0px" }}
                        data-time={selectTime}
                        onClick={this.showTimeSelect}
                      >
                        {selectTime}
                      </button>
                    </td>
                  ) : (
                    <></>
                  )}
                </tr>
                <tr>
                  {showDateTraining == 1 ? (
                    <td id="dateTraining">
                      <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                        <DatePicker
                          customInput={<InputForMobile />}
                          dateFormat="dd-MM-yyyy"
                          selected={date}
                          onChange={this.handleChange}
                        />
                      </div>
                    </td>
                  ) : (
                    <></>
                  )}
                  <td>
                    {this.isField(this.state.currentTrainingName) &&
                      this.getFieldNote(this.state.horseNotes) && (
                        <span
                          onClick={() =>
                            alert(
                              this.getFieldNote(this.state.horseNotes)?.TextNote
                            )
                          }
                          className="field-note"
                        >
                          {window.lang.fieldNote + ": "}
                          {this.getFieldNote(this.state.horseNotes)?.TextNote}
                        </span>
                      )}
                  </td>
                </tr>
                <tr>
                  {showDoneTraining == 1 ? (
                    <td id="colorSelectColumn">
                      <input
                        className="tcColor"
                        id="tcColorInput"
                        type="checkbox"
                        checked={currentColor == "green"}
                        data-role="none"
                        onChange={this.onDoneClick}
                      />
                      <label
                        htmlFor="tcColorInput"
                        id="colorSelectLable"
                        style={{ margin: "3px", display: "inline" }}
                      >
                        {window.lang.trained}
                      </label>
                    </td>
                  ) : (
                    <td></td>
                  )}
                  {showCopyTaskTraining == 1 ? (
                    currentIsCanCopy ? (
                      <td
                        id="isCopySelectColumn"
                        style={{ textAlign: "right" }}
                      >
                        <input
                          className="isCanCopy"
                          id="isCanCopyInput"
                          type="checkbox"
                          checked="checked"
                          data-role="none"
                          onClick={this.onCanCopyClick}
                        />
                        <label
                          htmlFor="isCanCopyInput"
                          id="copyTaskTrainingLabel"
                          style={{ margin: "3px", display: "inline" }}
                        >
                          {window.lang.copyTask}
                        </label>
                      </td>
                    ) : (
                      <td
                        id="isCopySelectColumn"
                        style={{ textAlign: "right" }}
                      >
                        <input
                          className="isCanCopy"
                          id="isCanCopyInput"
                          type="checkbox"
                          data-role="none"
                          onClick={this.onCanCopyClick}
                        />
                        <label
                          htmlFor="isCanCopyInput"
                          id="copyTaskTrainingLabel"
                          style={{ margin: "3px", display: "inline" }}
                        >
                          {window.lang.copyTask}
                        </label>
                      </td>
                    )
                  ) : (
                    <td></td>
                  )}
                </tr>

                <tr>
                  <td colSpan={2}>
                    {/* SELECT FIELD AREA, FIELD & TIME_PERIOD */}
                    {this.isField(this.state.currentTrainingName) && (
                      <div className="training-field-select--mobile">
                        {/* FIELD AREA */}
                        <div className="task-select--field--mobile">
                          <span>{window.lang.fieldArea}</span>
                          <select
                            className="task-select--field task-select--field--mobile"
                            value={this.state.selectedFieldAreaIndex}
                            data-role="none"
                            onChange={(e) => {
                              this.setState({
                                selectedFieldAreaIndex: e.currentTarget.value,
                                selectedFieldIndex: -1,
                                selectedFieldTimePeriodIndex: -1,
                              });
                            }}
                          >
                            <option value="-1" selected="selected">
                              {window.lang.fieldArea}
                            </option>
                            {this.state.fieldAreas.map((fieldArea, index) => (
                              <option key={fieldArea.id} value={index}>
                                {fieldArea.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* FIELD */}
                        <div className="task-select--field--mobile">
                          <span>{window.lang.fieldName}</span>
                          <select
                            className="task-select--field task-select--field--mobile"
                            value={this.state.selectedFieldIndex}
                            onChange={(e) => {
                              this.setState({
                                selectedFieldIndex: e.currentTarget.value,
                              });
                            }}
                          >
                            <option value="-1">{window.lang.fieldName}</option>
                            {this.state.selectedFieldAreaIndex >= 0 &&
                              this.state.fieldAreas[
                                this.state.selectedFieldAreaIndex
                              ].fields.map((field, index) => (
                                <option key={field.id} value={index}>
                                  {field.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        {/* FIELD TIME PERIOD */}
                        <div className="task-select--field--mobile">
                          <span>{window.lang.timePeriod}</span>
                          <select
                            className="task-select--field task-select--field--mobile"
                            value={this.state.selectedFieldTimePeriodIndex}
                            onChange={(e) => {
                              this.setState({
                                selectedFieldTimePeriodIndex:
                                  e.currentTarget.value,
                              });
                            }}
                          >
                            <option value="-1">{window.lang.timePeriod}</option>
                            {this.state.selectedFieldAreaIndex >= 0 &&
                              this.state.selectedFieldIndex >= 0 &&
                              this.state.fieldAreas[
                                this.state.selectedFieldAreaIndex
                              ].fields[
                                this.state.selectedFieldIndex
                              ].timePeriods.map((time_period, index) => (
                                <option key={time_period.id} value={index}>
                                  {time_period.period}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    )}

                    {this.isWalker(this.state.currentTrainingName) && (
                      <div className="training-field-select--mobile">
                        {/* Walker AREA */}
                        <div className="task-select--field--mobile">
                          <span>{window.lang.walkerArea}</span>
                          <select
                            className="task-select--field task-select--field--mobile"
                            value={this.state.selectedWalkerAreaIndex}
                            data-role="none"
                            onChange={(e) => {
                              this.setState({
                                selectedWalkerAreaIndex: e.currentTarget.value,
                                selectedWalkerIndex: -1,
                                selectedWalkerTimePeriodIndex: -1,
                              });
                            }}
                          >
                            <option value="-1" selected="selected">
                              {window.lang.walkerArea}
                            </option>
                            {this.state.walkerAreas.map((walkerArea, index) => (
                              <option key={walkerArea.id} value={index}>
                                {walkerArea.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* Walker */}
                        <div className="task-select--field--mobile">
                          <span>{window.lang.walker}</span>
                          <select
                            className="task-select--field task-select--field--mobile"
                            value={this.state.selectedWalkerIndex}
                            onChange={(e) => {
                              this.setState({
                                selectedWalkerIndex: e.currentTarget.value,
                                selectedWalkerTimePeriodIndex: -1,
                              });
                            }}
                          >
                            <option value="-1">{window.lang.walker}</option>
                            {this.state.selectedWalkerAreaIndex >= 0 &&
                              this.state.walkerAreas[
                                this.state.selectedWalkerAreaIndex
                              ].walkers.map((walker, index) => (
                                <option key={walker.id} value={index}>
                                  {walker.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        {/* Walker TIME PERIOD */}
                        <div className="task-select--field--mobile">
                          <span>{window.lang.timePeriod}</span>
                          <select
                            className="task-select--field task-select--field--mobile"
                            value={this.state.selectedWalkerTimePeriodIndex}
                            onChange={(e) => {
                              this.setState({
                                selectedWalkerTimePeriodIndex:
                                  e.currentTarget.value,
                              });
                            }}
                          >
                            <option value="-1">{window.lang.timePeriod}</option>
                            {this.state.selectedWalkerAreaIndex >= 0 &&
                              this.state.selectedWalkerIndex >= 0 &&
                              this.state.walkerAreas[
                                this.state.selectedWalkerAreaIndex
                              ].walkers[
                                this.state.selectedWalkerIndex
                              ].timePeriods.map((time_period, index) => (
                                <option key={time_period.id} value={index}>
                                  {time_period.period}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>

                {vetTrainingId == currentTrainingType ? (
                  <tr>
                    <td colSpan="2">
                      <div
                        id="vetTypeDiv"
                        style={{ float: "left", marginRight: "5px" }}
                      >
                        <select
                          id="vetTypeSelectReact"
                          data-role="none"
                          value={currentVeterinarianType}
                          onChange={this.onVeterinarianTypeChange}
                        >
                          {veterinarType.map((i) => (
                            <option key={i.value} value={i.value}>
                              {i.Name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {currentVeterinarianType == 4 ? (
                        <div style={{ float: "right" }}>
                          <select
                            id="vetTypeSelectReact"
                            value={currentDewormingType}
                            data-role="none"
                            onChange={this.onDewormingTypeChange}
                          >
                            {dewormingTypes.map((i) => (
                              <option key={i.Id} value={i.Id}>
                                {i.Name}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                ) : farTrainingId == currentTrainingType ? (
                  <tr>
                    <td colSpan="2">
                      <div id="vetTypeDiv" style={{ float: "left" }}>
                        <select
                          id="vetTypeSelectReact"
                          data-role="none"
                          value={currentFarrierType}
                          onChange={this.onFarrierTypeChange}
                        >
                          {farrierType.map((i) => (
                            <option key={i.Id} value={i.Id}>
                              {i.Name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div id="farTimeDiv" style={{ float: "right" }}>
                        <select
                          id="farrierWeeksSelectorReact"
                          data-role="none"
                          value={currentFarrierWeek}
                          onChange={this.onFarrierWeekChange}
                        >
                          {farrierWeeks.map((i, index) => (
                            <option key={Math.uuid()} value={index}>
                              {i}
                            </option>
                          ))}
                        </select>
                      </div>
                    </td>
                  </tr>
                ) : role != null && role.tShowSmileOnTask == false ? (
                  <></>
                ) : showEvaluationTraining == 1 ? (
                  <tr>
                    <td colSpan="2">
                      <div
                        id="smilesDiv"
                        style={{ float: "left", marginRight: "5px" }}
                      >
                        {imgStrArr.length ? (
                          imgStrArr.map((i) =>
                            i.imgId == currentEvaluation ? (
                              <img
                                className="evaluation-img"
                                data-id={i.imgId}
                                key={Math.uuid()}
                                src={i.src}
                                onClick={this.onEvaluationClick}
                                style={{
                                  border: "3px solid blue",
                                  borderRadius: "15px",
                                }}
                              />
                            ) : (
                              <img
                                className="evaluation-img"
                                data-id={i.imgId}
                                key={Math.uuid()}
                                src={i.src}
                                onClick={this.onEvaluationClick}
                              />
                            )
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                {vetTrainingId == currentTrainingType &&
                currentVeterinarianType == 4 ? (
                  <tr>
                    <td colSpan="2">
                      <div id="vetTypeDiv" style={{ float: "left" }}>
                        <select
                          id="vetTypeSelectReact"
                          value={currentNextDewormingPeriod}
                          data-role="none"
                          onChange={this.onDewormingTypePeriodChange}
                        >
                          {dewormingTypesPeriod.map((i, index) => (
                            <option key={index} value={index}>
                              {i}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        id="vetTypeDiv"
                        style={{ float: "right", marginTop: "10px" }}
                      >
                        <label>
                          {window.lang.nextDeworming + ": " + nextDewormingDate}
                        </label>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                <tr>
                  {showRemarksTraining == 1 ? (
                    <td id="remarksTraining" colSpan="2">
                      <textarea
                        className="tcRemark ui-input-text ui-shadow-inset ui-body-inherit ui-corner-all ui-textinput-autogrow"
                        rows="3"
                        value={currentRemark}
                        onChange={this.onRemarkChange}
                      ></textarea>
                    </td>
                  ) : (
                    <td></td>
                  )}
                </tr>
                <tr>
                  <td colSpan="3" style={{ textAlign: "center" }}>
                    <Footer
                      showData={{
                        showMultimediaTraining: showMultimediaTraining,
                        showCopyButtonTraining: showCopyButtonTraining,
                        showRemainderTraining: showRemainderTraining,
                        showXray: vetTrainingId == currentTrainingType,
                      }}
                      onShowMultimediaClick={this.onShowMultimediaClick}
                      onCopyTaskClick={this.onCopyTaskClick}
                      onReminderSelectClick={this.onReminderSelectClick}
                      onDeleteClick={this.onDeleteTrainingClick}
                      onXrayClick={this.onXrayClick}
                      isVetPage={this.props.options.isVetPage}
                      onExtraTrainingClick={this.onExtraTrainingClick}
                    />
                    {this.state.showReminderPopup && (
                      <ReminderSelect
                        selectedId={currentReminderType}
                        onSelect={this.onRemiderSelect}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          id="trainingEditWindowReact"
          className="ui-popup ui-page-theme-a desktop-page"
        >
          <div
            className="ui-bar-inherit"
            id="taskHeaderReact"
            onMouseDown={this.onMouseDown}
            onMouseMove={this.onMouseMove}
            onMouseUp={this.onMouseUp}
          >
            <img
              id="cancelEditTrainingReact"
              src="img/icon_canceln.png"
              onClick={this.onCancelClick}
            />
            {showDateTraining == 1 ? (
              <div id="dateTraining" className="dateTrainingDivReact">
                <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                  <DatePicker
                    customInput={<InputForDesktop />}
                    dateFormat="dd-MM-yyyy"
                    selected={date}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            <img
              id="tcOkButtonReact"
              src="img/icon_okn.png"
              index-id="${index}"
              onClick={this.onSaveClick}
            />
          </div>
          <table className="trainingEditWindowReactTable">
            <tbody>
              <tr id="taskTextLine">
                <td align="center">{window.lang.horse}</td>
                <td align="center">{window.lang.trainerName}</td>
                <td align="center">{window.lang.training}</td>
              </tr>
              <tr id="taskListsLine">
                <td>
                  <div id="searchPickPlace" className="listCont">
                    <ul
                      data-role="listview"
                      data-inset="true"
                      className="ui-listview ui-listview-inset ui-corner-all ui-shadow"
                      id="dates3"
                      data-autodividers="true"
                    >
                      {horseNameArr.length ? (
                        horseNameArr.map((currentElement, index) =>
                          index == 0 ? (
                            currentElement.id == currentHorseId ? (
                              <li
                                key={Math.uuid()}
                                data-icon="false"
                                data-idf={currentElement.id}
                                className="ui-first-child"
                                id="typeHorse"
                                data-name={currentElement.name}
                                type=""
                                onClick={this.onHorseClick.bind(this)}
                              >
                                <a className="ui-btn selectedListItem" href="#">
                                  {currentElement.name}
                                </a>
                              </li>
                            ) : (
                              <li
                                key={Math.uuid()}
                                data-icon="false"
                                data-idf={currentElement.id}
                                className="ui-first-child"
                                id="typeHorse"
                                data-name={currentElement.name}
                                type=""
                                onClick={this.onHorseClick.bind(this)}
                              >
                                <a
                                  className="ui-btn unselectedListItem"
                                  href="#"
                                >
                                  {currentElement.name}
                                </a>
                              </li>
                            )
                          ) : index == horseNameArr.length - 1 ? (
                            currentElement.id == currentHorseId ? (
                              <li
                                key={Math.uuid()}
                                data-icon="false"
                                data-idf={currentElement.id}
                                className="ui-last-child"
                                id="typeHorse"
                                data-name={currentElement.name}
                                type=""
                                onClick={this.onHorseClick.bind(this)}
                              >
                                <a className="ui-btn selectedListItem" href="#">
                                  {currentElement.name}
                                </a>
                              </li>
                            ) : (
                              <li
                                key={Math.uuid()}
                                data-icon="false"
                                data-idf={currentElement.id}
                                className="ui-last-child"
                                id="typeHorse"
                                data-name={currentElement.name}
                                type=""
                                onClick={this.onHorseClick.bind(this)}
                              >
                                <a
                                  className="ui-btn unselectedListItem"
                                  href="#"
                                >
                                  {currentElement.name}
                                </a>
                              </li>
                            )
                          ) : currentElement.id == currentHorseId ? (
                            <li
                              key={Math.uuid()}
                              data-icon="false"
                              data-idf={currentElement.id}
                              id="typeHorse"
                              data-name={currentElement.name}
                              type=""
                              onClick={this.onHorseClick.bind(this)}
                            >
                              <a className="ui-btn selectedListItem" href="#">
                                {currentElement.name}
                              </a>
                            </li>
                          ) : (
                            <li
                              key={Math.uuid()}
                              data-icon="false"
                              data-idf={currentElement.id}
                              id="typeHorse"
                              data-name={currentElement.name}
                              type=""
                              onClick={this.onHorseClick.bind(this)}
                            >
                              <a className="ui-btn unselectedListItem" href="#">
                                {currentElement.name}
                              </a>
                            </li>
                          )
                        )
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </td>
                <td style={{ verticalAlign: "top" }}>
                  <div id="searchPickPlace" className="listCont">
                    <ul
                      data-role="listview"
                      data-inset="true"
                      className="ui-listview ui-listview-inset ui-corner-all ui-shadow"
                      id="dates3"
                      data-autodividers="true"
                    >
                      {trainersArr.length ? (
                        trainersArr.map((currentElement, index) =>
                          index == 0 ? (
                            currentElement.id == currentTrainerId ? (
                              <li
                                key={Math.uuid()}
                                data-icon="false"
                                data-idf={currentElement.id}
                                className="ui-first-child"
                                id="typeTrainer"
                                data-name={currentElement.name}
                                type=""
                                onClick={this.onTrainerClick}
                              >
                                <a className="ui-btn selectedListItem" href="#">
                                  {currentElement.id == window.myid
                                    ? "Me"
                                    : currentElement.name}
                                </a>
                              </li>
                            ) : (
                              <li
                                key={Math.uuid()}
                                data-icon="false"
                                data-idf={currentElement.id}
                                className="ui-first-child"
                                id="typeTrainer"
                                data-name={currentElement.name}
                                type=""
                                onClick={this.onTrainerClick}
                              >
                                <a
                                  className="ui-btn unselectedListItem"
                                  href="#"
                                >
                                  {currentElement.id == window.myid
                                    ? "Me"
                                    : currentElement.name}
                                </a>
                              </li>
                            )
                          ) : index == trainersArr.length - 1 ? (
                            currentElement.id == currentTrainerId ? (
                              <li
                                key={Math.uuid()}
                                data-icon="false"
                                data-idf={currentElement.id}
                                className="ui-last-child"
                                id="typeTrainer"
                                data-name={currentElement.name}
                                type=""
                                onClick={this.onTrainerClick}
                              >
                                <a className="ui-btn selectedListItem" href="#">
                                  {currentElement.id == window.myid
                                    ? "Me"
                                    : currentElement.name}
                                </a>
                              </li>
                            ) : (
                              <li
                                key={Math.uuid()}
                                data-icon="false"
                                data-idf={currentElement.id}
                                className="ui-last-child"
                                id="typeTrainer"
                                data-name={currentElement.name}
                                type=""
                                onClick={this.onTrainerClick}
                              >
                                <a
                                  className="ui-btn unselectedListItem"
                                  href="#"
                                >
                                  {currentElement.id == window.myid
                                    ? "Me"
                                    : currentElement.name}
                                </a>
                              </li>
                            )
                          ) : currentElement.id == currentTrainerId ? (
                            <li
                              key={Math.uuid()}
                              data-icon="false"
                              data-idf={currentElement.id}
                              id="typeTrainer"
                              data-name={currentElement.name}
                              type=""
                              onClick={this.onTrainerClick}
                            >
                              <a className="ui-btn selectedListItem" href="#">
                                {currentElement.id == window.myid
                                  ? "Me"
                                  : currentElement.name}
                              </a>
                            </li>
                          ) : (
                            <li
                              key={Math.uuid()}
                              data-icon="false"
                              data-idf={currentElement.id}
                              id="typeTrainer"
                              data-name={currentElement.name}
                              type=""
                              onClick={this.onTrainerClick}
                            >
                              <a className="ui-btn unselectedListItem" href="#">
                                {currentElement.id == window.myid
                                  ? "Me"
                                  : currentElement.name}
                              </a>
                            </li>
                          )
                        )
                      ) : (
                        <button
                          id="needCreateUserButton"
                          className=" ui-btn ui-shadow ui-corner-all"
                          style={{ display: "block" }}
                        >
                          Create new user
                        </button>
                      )}
                    </ul>
                  </div>
                </td>
                <td>
                  <div id="searchPickPlace" className="listCont">
                    <ul
                      data-role="listview"
                      data-inset="true"
                      className="ui-listview ui-listview-inset ui-corner-all ui-shadow"
                      id="dates3"
                      data-autodividers="true"
                    >
                      {trainingTypesArr.length ? (
                        trainingTypesArr.map((currentElement, index) =>
                          index == 0 ? (
                            currentElement.id == currentTrainingType ? (
                              <li
                                key={Math.uuid()}
                                data-icon="false"
                                data-idf={currentElement.id}
                                className="ui-first-child"
                                id="typeTraining"
                                data-name={currentElement.name}
                                type=""
                                onClick={this.onTrainingClick}
                              >
                                <a className="ui-btn selectedListItem" href="#">
                                  {translateWord(currentElement.name) +
                                    "(" +
                                    currentElement.shortname +
                                    ")"}
                                </a>
                              </li>
                            ) : (
                              <li
                                key={Math.uuid()}
                                data-icon="false"
                                data-idf={currentElement.id}
                                className="ui-first-child"
                                id="typeTraining"
                                data-name={currentElement.name}
                                type=""
                                onClick={this.onTrainingClick}
                              >
                                <a
                                  className="ui-btn unselectedListItem"
                                  href="#"
                                >
                                  {currentElement.name +
                                    "(" +
                                    currentElement.shortname +
                                    ")"}
                                </a>
                              </li>
                            )
                          ) : index == trainingTypesArr.length - 1 ? (
                            currentElement.id == currentTrainingType ? (
                              <li
                                key={Math.uuid()}
                                data-icon="false"
                                data-idf={currentElement.id}
                                className="ui-last-child"
                                id="typeTraining"
                                data-name={currentElement.name}
                                type=""
                                onClick={this.onTrainingClick}
                              >
                                <a className="ui-btn selectedListItem" href="#">
                                  {currentElement.name +
                                    "(" +
                                    currentElement.shortname +
                                    ")"}
                                </a>
                              </li>
                            ) : (
                              <li
                                key={Math.uuid()}
                                data-icon="false"
                                data-idf={currentElement.id}
                                className="ui-last-child"
                                id="typeTraining"
                                data-name={currentElement.name}
                                type=""
                                onClick={this.onTrainingClick}
                              >
                                <a
                                  className="ui-btn unselectedListItem"
                                  href="#"
                                >
                                  {currentElement.name +
                                    "(" +
                                    currentElement.shortname +
                                    ")"}
                                </a>
                              </li>
                            )
                          ) : currentElement.id == currentTrainingType ? (
                            <li
                              key={Math.uuid()}
                              data-icon="false"
                              data-idf={currentElement.id}
                              id="typeTraining"
                              data-name={currentElement.name}
                              type=""
                              onClick={this.onTrainingClick}
                            >
                              <a className="ui-btn selectedListItem" href="#">
                                {currentElement.name +
                                  "(" +
                                  currentElement.shortname +
                                  ")"}
                              </a>
                            </li>
                          ) : (
                            <li
                              key={Math.uuid()}
                              data-icon="false"
                              data-idf={currentElement.id}
                              id="typeTraining"
                              data-name={currentElement.name}
                              type=""
                              onClick={this.onTrainingClick}
                            >
                              <a className="ui-btn unselectedListItem" href="#">
                                {currentElement.name +
                                  "(" +
                                  currentElement.shortname +
                                  ")"}
                              </a>
                            </li>
                          )
                        )
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  {this.isField(this.state.currentTrainingName) &&
                    this.getFieldNote(this.state.horseNotes) && (
                      <span
                        onClick={() =>
                          alert(
                            this.getFieldNote(this.state.horseNotes)?.TextNote
                          )
                        }
                        className="field-note"
                      >
                        {window.lang.fieldNote + ": "}
                        {this.getFieldNote(this.state.horseNotes)?.TextNote}
                      </span>
                    )}
                </td>
                {showTimeTraining == 1 ? (
                  <td id="timeTraining">
                    <button
                      style={{ marginTop: "10px" }}
                      className="openTimeSelectPopup ui-btn ui-shadow ui-corner-all"
                      onClick={this.showTimeSelect}
                    >
                      {selectTime}
                    </button>
                  </td>
                ) : (
                  <></>
                )}
              </tr>
              <tr style={{ height: "20px" }}></tr>
              <tr>
                <td id="doneTrainingLabel"></td>
              </tr>
              <tr>
                <td colSpan="3" className="inline-flex">
                  {showDoneTraining == 1 ? (
                    <div
                      id="colorSelect"
                      style={{
                        float: "left",
                        display: "",
                        minWidth: "100px",
                      }}
                    >
                      <input
                        className="tcColor"
                        id="tcColorInput"
                        type="checkbox"
                        data-role="none"
                        checked={currentColor == "green"}
                        onChange={this.onDoneClick}
                      />
                      <label
                        htmlFor="tcColorInput"
                        id="colorSelectLable"
                        style={{ margin: "3px", display: "inline" }}
                      >
                        {window.lang.trained}
                      </label>
                    </div>
                  ) : (
                    <></>
                  )}
                  {vetTrainingId == currentTrainingType ? (
                    <div
                      id="vetTypeDiv"
                      style={{
                        float: "left",
                        marginLeft: "20px",
                        marginRight: "5px",
                      }}
                    >
                      <select
                        id="vetTypeSelectReact"
                        value={currentVeterinarianType}
                        data-role="none"
                        onChange={this.onVeterinarianTypeChange}
                      >
                        {veterinarType.map((i) => (
                          <option key={i.value} value={i.value}>
                            {i.Name}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : farTrainingId == currentTrainingType ? (
                    <div>
                      <div
                        id="farTypeDiv"
                        style={{
                          float: "left",
                          marginLeft: "20px",
                          marginRight: "5px",
                        }}
                      >
                        <select
                          id="vetTypeSelectReact"
                          data-role="none"
                          value={currentFarrierType}
                          onChange={this.onFarrierTypeChange}
                        >
                          {farrierType.map((i) => (
                            <option key={i.Id} value={i.Id}>
                              {i.Name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        id="farTimeDiv"
                        style={{
                          marginRight: "5px",
                          marginLeft: "20px",
                          float: "left",
                        }}
                      >
                        <select
                          id="farrierWeeksSelectorReact"
                          data-role="none"
                          value={currentFarrierWeek}
                          onChange={this.onFarrierWeekChange}
                        >
                          {farrierWeeks.map((i, index) => (
                            <option key={Math.uuid()} value={index}>
                              {i}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  ) : role != null && role.tShowSmileOnTask == false ? (
                    <></>
                  ) : showEvaluationTraining == 1 ? (
                    <div
                      id="smilesDiv"
                      style={{
                        float: "left",
                        marginLeft: "42px",
                        marginRight: "5px",
                        marginTop: "-30px",
                      }}
                    >
                      {imgStrArr.length ? (
                        imgStrArr.map((i) =>
                          i.imgId == currentEvaluation ? (
                            <img
                              className="evaluation-img"
                              data-id={i.imgId}
                              key={Math.uuid()}
                              src={i.src}
                              style={{
                                border: "3px solid blue",
                                borderRadius: "15px",
                              }}
                              onClick={this.onEvaluationClick}
                            />
                          ) : (
                            <img
                              className="evaluation-img"
                              data-id={i.imgId}
                              key={Math.uuid()}
                              src={i.src}
                              onClick={this.onEvaluationClick}
                            />
                          )
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}

                  {showCopyTaskTraining == 1 ? (
                    currentIsCanCopy ? (
                      <div id="switchTraining" style={{ float: "right" }}>
                        <input
                          className="isCanCopy"
                          id="isCanCopyInput"
                          type="checkbox"
                          checked="checked"
                          data-role="none"
                          onClick={this.onCanCopyClick}
                        />
                        <label
                          htmlFor="isCanCopyInput"
                          id="copyTaskTrainingLabel"
                          style={{ margin: "3px", display: "inline" }}
                        >
                          {window.lang.copyTask}
                        </label>
                      </div>
                    ) : (
                      <div id="switchTraining" style={{ float: "right" }}>
                        <input
                          className="isCanCopy"
                          id="isCanCopyInput"
                          type="checkbox"
                          data-role="none"
                          onClick={this.onCanCopyClick}
                        />
                        <label
                          htmlFor="isCanCopyInput"
                          id="copyTaskTrainingLabel"
                          style={{ margin: "3px", display: "inline" }}
                        >
                          {window.lang.copyTask}
                        </label>
                      </div>
                    )
                  ) : (
                    <></>
                  )}

                  {/* SELECT FIELD AREA, FIELD & TIME_PERIOD */}
                  {this.isField(this.state.currentTrainingName) &&
                    this.state.fieldAreas.length > 0 && (
                      <>
                        {/* FIELD AREA */}
                        <select
                          className="task-select--field"
                          value={this.state.selectedFieldAreaIndex}
                          data-role="none"
                          onChange={(e) => {
                            this.setState({
                              selectedFieldAreaIndex: e.currentTarget.value,
                              selectedFieldIndex: -1,
                              selectedFieldTimePeriodIndex: -1,
                            });
                          }}
                        >
                          <option value="-1" selected="selected">
                            {window.lang.fieldArea}
                          </option>
                          {this.state.fieldAreas.map((fieldArea, index) => (
                            <option key={fieldArea.id} value={index}>
                              {fieldArea.name}
                            </option>
                          ))}
                        </select>

                        {/* FIELD */}
                        <select
                          className="task-select--field"
                          value={this.state.selectedFieldIndex}
                          onChange={(e) => {
                            this.setState({
                              selectedFieldIndex: e.currentTarget.value,
                            });
                          }}
                        >
                          <option value="-1">{window.lang.fieldName}</option>
                          {this.state.selectedFieldAreaIndex >= 0 &&
                            this.state.fieldAreas[
                              this.state.selectedFieldAreaIndex
                            ].fields.map((field, index) => (
                              <option key={field.id} value={index}>
                                {field.name}
                              </option>
                            ))}
                        </select>
                        {/* FIELD TIME PERIOD */}
                        <select
                          className="task-select--field"
                          value={this.state.selectedFieldTimePeriodIndex}
                          onChange={(e) => {
                            this.setState({
                              selectedFieldTimePeriodIndex:
                                e.currentTarget.value,
                            });
                          }}
                        >
                          <option value="-1">{window.lang.timePeriod}</option>
                          {this.state.selectedFieldAreaIndex >= 0 &&
                            this.state.selectedFieldIndex >= 0 &&
                            this.state.fieldAreas[
                              this.state.selectedFieldAreaIndex
                            ].fields[
                              this.state.selectedFieldIndex
                            ].timePeriods.map((time_period, index) => (
                              <option key={time_period.id} value={index}>
                                {time_period.period}
                              </option>
                            ))}
                        </select>
                      </>
                    )}

                  {this.isWalker(this.state.currentTrainingName) &&
                    this.state.walkerAreas.length > 0 && (
                      <>
                        {/* WALKER AREA */}
                        <select
                          className="task-select--field"
                          value={this.state.selectedWalkerAreaIndex}
                          data-role="none"
                          onChange={(e) => {
                            this.setState({
                              selectedWalkerAreaIndex: e.currentTarget.value,
                              selectedWalkerIndex: -1,
                              selectedWalkerTimePeriodIndex: -1,
                            });
                          }}
                        >
                          <option value="-1" selected="selected">
                            {window.lang.walkerArea}
                          </option>
                          {this.state.walkerAreas.map((walkerArea, index) => (
                            <option key={walkerArea.id} value={index}>
                              {walkerArea.name}
                            </option>
                          ))}
                        </select>

                        {/* WALKER */}
                        <select
                          className="task-select--field"
                          value={this.state.selectedWalkerIndex}
                          onChange={(e) => {
                            this.setState({
                              selectedWalkerIndex: e.currentTarget.value,
                            });
                          }}
                        >
                          <option value="-1">{window.lang.walker}</option>
                          {this.state.selectedWalkerAreaIndex >= 0 &&
                            this.state.walkerAreas[
                              this.state.selectedWalkerAreaIndex
                            ].walkers.map((walker, index) => (
                              <option key={walker.id} value={index}>
                                {walker.name}
                              </option>
                            ))}
                        </select>
                        {/* WALKER TIME PERIOD */}
                        <select
                          className="task-select--field"
                          value={this.state.selectedWalkerTimePeriodIndex}
                          onChange={(e) => {
                            this.setState({
                              selectedWalkerTimePeriodIndex:
                                e.currentTarget.value,
                            });
                          }}
                        >
                          <option value="-1">{window.lang.timePeriod}</option>
                          {this.state.selectedWalkerAreaIndex >= 0 &&
                            this.state.selectedWalkerIndex >= 0 &&
                            this.state.walkerAreas[
                              this.state.selectedWalkerAreaIndex
                            ].walkers[
                              this.state.selectedWalkerIndex
                            ].timePeriods.map((time_period, index) => (
                              <option key={time_period.id} value={index}>
                                {time_period.period}
                              </option>
                            ))}
                        </select>
                      </>
                    )}
                </td>
              </tr>

              {vetTrainingId == currentTrainingType &&
              currentVeterinarianType == 4 ? (
                <tr style={{ height: "20px" }}></tr>
              ) : (
                <></>
              )}
              <tr>
                <td colSpan="3">
                  {vetTrainingId == currentTrainingType &&
                  (currentVeterinarianType == 4 ||
                    currentVeterinarianType == 3) ? (
                    <div>
                      {currentVeterinarianType == 4 && (
                        <div
                          id="vetTypeDiv"
                          style={{
                            float: "left",
                            marginLeft: "20px",
                            marginRight: "5px",
                          }}
                        >
                          <select
                            id="vetTypeSelectReact"
                            value={currentDewormingType}
                            data-role="none"
                            onChange={this.onDewormingTypeChange}
                          >
                            {dewormingTypes.map((i) => (
                              <option key={i.Id} value={i.Id}>
                                {i.Name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      <div
                        id="vetTypeDiv"
                        style={{
                          float: "left",
                          marginLeft: "20px",
                          marginRight: "15px",
                        }}
                      >
                        <select
                          id="vetTypeSelectReact"
                          value={currentNextDewormingPeriod}
                          data-role="none"
                          onChange={this.onDewormingTypePeriodChange}
                        >
                          {dewormingTypesPeriod.map((i, index) => (
                            <option key={index} value={index}>
                              {i}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        id="vetTypeDiv"
                        style={{
                          float: "left",
                          marginRight: "5px",
                          marginTop: "10px",
                        }}
                      >
                        <label>
                          {(currentVeterinarianType == 4
                            ? window.lang.nextDeworming
                            : window.lang.nextTeeth) +
                            ": " +
                            nextDewormingDate}
                        </label>
                      </div>
                      {/* DEWORMING STAGES */}
                      {/* <div
                        style={{
                          marginTop: "20px",
                          marginLeft: "20px",
                          marginRight: "5px",
                        }}
                      >
                        <select
                          id="dewormingStageSelect"
                          // value={currentDewormingType}
                          data-role="none"
                          // onChange={this.onDewormingTypeChange}
                        >
                          <option value={-1}>Select deworming stage</option>
                          {this.state.dewormingStages.map((i, i_index) => (
                            <option key={i} value={i_index}>
                              {i}
                            </option>
                          ))}
                        </select>
                      </div> */}
                    </div>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
              <tr>
                {showRemarksTraining == 1 ? (
                  <td id="remarksTraining" colSpan="3">
                    <span>{window.lang.remark}</span>
                    <textarea
                      className="tcRemark ui-input-text ui-shadow-inset ui-body-inherit ui-corner-all ui-textinput-autogrow"
                      rows="3"
                      value={currentRemark}
                      onChange={this.onRemarkChange}
                    ></textarea>
                  </td>
                ) : (
                  <td></td>
                )}
              </tr>
              <tr>
                <td
                  colSpan="3"
                  style={{
                    textAlign: "center",
                    display: "flex",
                    gap: "25px",
                  }}
                >
                  <Footer
                    showData={{
                      showMultimediaTraining: showMultimediaTraining,
                      showCopyButtonTraining: showCopyButtonTraining,
                      showRemainderTraining: showRemainderTraining,
                      showXray: vetTrainingId == currentTrainingType,
                    }}
                    onShowMultimediaClick={this.onShowMultimediaClick}
                    onCopyTaskClick={this.onCopyTaskClick}
                    onReminderSelectClick={this.onReminderSelectClick}
                    onDeleteClick={this.onDeleteTrainingClick}
                    onXrayClick={this.onXrayClick}
                    isVetPage={this.props.options.isVetPage}
                    onExtraTrainingClick={this.onExtraTrainingClick}
                  />
                  {this.state.showReminderPopup && (
                    <ReminderSelect
                      selectedId={currentReminderType}
                      onSelect={this.onRemiderSelect}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {this.state.isShowTimePopup && (
          <SelectTimePopup
            value={currentTimeOfDay}
            onTimeChange={this.onTimeChange}
            onHide={this.hideTimeSelect}
          />
        )}
        <ToastContainer />
      </div>
    );
  }
}

const InputForMobile = (props) => {
  return (
    <input
      onClick={props.onClick}
      value={props.value}
      type="text"
      readOnly={true}
      style={{ backgroundColor: "#dddddd", color: "#000000" }}
    />
  );
};

const InputForDesktop = (props) => {
  return (
    <input
      onClick={props.onClick}
      value={props.value}
      type="text"
      readOnly={true}
      style={{ backgroundColor: "#292A3C", color: "#FFFFFF" }}
    />
  );
};

const Footer = (props) => {
  return (
    <div className="trainingPopupFooter">
      {!props.isVetPage && (
        <button
          className="openExtraTrainingButton ui-btn ui-shadow ui-corner-all ui-btn-inline"
          onClick={props.onExtraTrainingClick}
        >
          {window.lang.addExtraTraining}
        </button>
      )}
      <span>
        {props.showData.showXray && (
          <span style={{ marginRight: "5px" }}>
            <img
              className="iplusFilesImgBorder"
              src="img/plusFiles.png"
              onClick={props.onXrayClick}
            />
          </span>
        )}
      </span>

      <span>
        <img
          className="tcDelButton imgWithBorder"
          src="img/trashcan.png"
          style={{ height: "11mm" }}
          onClick={props.onDeleteClick}
        />
      </span>
      {props.showData.showMultimediaTraining == 1 ? (
        <span id="multimediaTraining" style={{ marginLeft: "5px" }}>
          <img
            className="ImgIconTask imgWithBorder"
            src="img/photo_icon.png"
            style={{ height: "11mm" }}
            h-id={props.currentHorseId}
            onClick={props.onShowMultimediaClick}
          />
        </span>
      ) : (
        <></>
      )}
      {props.showData.showCopyButtonTraining == 1 ? (
        <span style={{ marginLeft: "5px" }}>
          <img
            id="copyButtonTraining"
            className="tcWeek imgWithBorder"
            src="img/copy_icon.png"
            style={{ height: "11mm" }}
            onClick={props.onCopyTaskClick}
          />
        </span>
      ) : (
        <></>
      )}
      {props.showData.showRemainderTraining == 1 ? (
        <span style={{ marginLeft: "5px" }}>
          <img
            id="reminderSelectIcon"
            className="imgWithBorder"
            src="img/bell_re.png"
            style={{ height: "11mm" }}
            onClick={props.onReminderSelectClick}
          />
          <span id="reminderSelectReact"></span>
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};

export default class TrainingPopup {
  showPopup(options, item, callback, returnTrainingCallback) {
    if (options == null) {
      options = {};
    }
    if (options.horseId == null) {
      let horseId = window.DataStorage.getAllHorses()[0].id;
      options.horseId = horseId;
    }
    if (options.date == null) {
      options.date = new Date();
    }
    ReactDOM.render(
      <TrainingPopupWindow
        options={options}
        item={item}
        callback={callback}
        returnTrainingCallback={returnTrainingCallback}
      />,
      document.getElementById("trainingPopupReact")
    );
  }
}
