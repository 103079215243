import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './FarrierPopup.css';

class FarrierWindow extends Component {
    constructor(props) {
        super(props);

        this.farrierTrainingTypes = [];
        this.state = {
            farrierData: []
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        window.Spinner.show();
        window.db.getFarrierDataForAllHorses(window.myid, (rows) => {
            let reportData = [];
            rows.filter(item => {
                let curr = reportData.find(i => item.horseid == i.horseid);

                if (!curr) {
                    let isHighPriority = item.FarrierTypeName == window.lang.shoeType4;
                    var days = null;
                    if (isHighPriority) {
                        days = Date.daysBetween(new Date(), Date.getNewDate(item.starttime));
                        let daysStr = (days < 7) ? (days + " " + window.lang.days) : ((days / 7).toFixed(1) + " " + window.lang.week);
                        var msgStr = window.lang.shoeDaysSinceLost + ': ' + daysStr;
                    } else {
                        days = Date.daysBetween(new Date(), Date.getNewDate(item.starttime));
                        let daysStr = (days < 7) ? (days + " " + window.lang.days) : ((days / 7).toFixed(1) + " " + window.lang.week);
                        var msgStr = window.lang.shoeDaysSinceLast + ': ' + daysStr;
                    }
                    reportData.push({ horseid: item.horseid, horse: item.Name, daysSinceLast: msgStr, days: days, isHighPriority: isHighPriority });
                    return true;
                } else {
                    return false;
                }
            });
            reportData = this.sortFarrierArrByPriority(reportData);
            this.setState({
                farrierData: reportData
            });
            window.Spinner.hide();
        });
    }

    sortFarrierArrByPriority = (data) => {
        let lowPriority = [];
        let highPriority = [];
        for (let i in data) {
            if (data[i].isHighPriority)
                highPriority.push(data[i]);
            else
                lowPriority.push(data[i]);
        }
        lowPriority = lowPriority.sort(this.sortFarrierArrByDays);
        highPriority = highPriority.sort(this.sortFarrierArrByDays);
        return [...highPriority, ...lowPriority];
    }

    sortFarrierArrByDays = (a, b) => {
        if (a.days > b.days)
            return -1;
        if (a.days < b.days)
            return 1;
        return 0;
    }

    onCancelClick = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById('allFarrierPopup'));
    }

    render() {
        const { farrierData } = this.state;
        let height = window.$(window).height();

        return (
            <div id="farrierPopup" className="ui-popup ui-page-theme-a ui-corner-all" style={{ maxHeight: height - "50" }}>
                <a href="#" className="ui-btn ui-corner-all ui-shadow ui-btn-a ui-icon-delete ui-btn-icon-notext ui-btn-right" onClick={this.onCancelClick}>
                </a>
                <div style={{ overflow: "scroll", maxHeight: height - "60", margin: "15px" }}>
                    <div style={{ paddingLeft: "15px", paddingTop: "15px", paddingRight: "25px", paddingBottom: "15px", width: "100%" }}>
                        {farrierData.length ?
                            farrierData.map(i => (
                                <div key={Math.uuid()} style={{ width: "100%", margin: "10px" }}>
                                    <span style={{ paddingLeft: "40%" }}><b>{i.horse}</b></span>
                                    <br />
                                    <span style={{ paddingRight: "10%" }}>{i.daysSinceLast}</span>
                                </div>
                            ))
                            : <></>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default class FarrierAllPopup {
    showFarrierAllPopup() {
        ReactDOM.render(
            <FarrierWindow />,
            document.getElementById('allFarrierPopup')
        )
    }
}