import React, { Component } from "react";
import ReactDOM from "react-dom";
import { set } from "lodash";
import MultimediaHelper from "../Multimedia/MultimediaHelper";

export default class TaskXrayPage extends Component {
  static show(taskId) {
    window.showPage("#taskXrayPage");
    ReactDOM.render(
      <TaskXrayPage taskId={taskId} />,
      document.getElementById("taskXrayPage")
    );

    setTimeout(() => {
      try {
        document.getElementById("xrayFileInput").value = null;
      } catch {}
    }, 100);
  }

  static DropboxToken =
    "HqmkA9nKm0wAAAAAAADLWpj-stRJN_4JyfLxtRoZD-Rzpjyz9nnuI_CMbkRkOoSk";

  constructor(props) {
    super(props);

    this.state = {
      entries: [],
      uploadingFiles: [],
      uploadingIndex: -1,
    };

    this.dropbox = new window.Dropbox.Dropbox({
      accessToken: TaskXrayPage.DropboxToken,
    });

    this.helper = new MultimediaHelper();
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.taskId != prevProps.taskId) {
      this.loadData();
    }
  }

  loadData() {
    this.path = `xray-${this.props.taskId}`;

    this.setState({
      entries: [],
      uploadingFiles: [],
    });

    window.Spinner.show();
    this.helper.handleCreateContainer(this.path).then(() => {
      this._getListFiles();
    });
    // };
    // this.dropbox
    //   .filesCreateFolder({
    //     path: this.path,
    //     autorename: false,
    //   })
    //   .then((response) => {
    //     this._getListFiles();
    //   })
    //   .catch((error) => {
    //     this._getListFiles();
    //   });
  }

  _getListFiles() {
    // this.dropbox
    //   .filesListFolder({ path: this.path })
    //   .then((response) => {
    //     this.setState({
    //       entries: response.entries,
    //     });

    //   })
    //   .catch((error) => {
    //     window.Spinner.hide();
    //   });
    this.helper.listBlobsWithProperties(this.path).then((response) => {
      this.setState({
        entries: response.map((el) => {
          el.path_display = el.name;
          el.fullUrl = el.url;
          el.thumbUrl = el.url;
          el.imageUrl = el.url;
          el.type = MultimediaHelper.getTypeItem(el);
          return el;
        }),
      });
      console.log(response);
      window.Spinner.hide();
    });
  }

  close = () => {
    window.showPage("#mainPage");
  };

  onFilesSeleted = (e) => {
    let arr = [];
    for (let i = 0; i < e.target.files.length; i++)
      arr.push({
        name: e.target.files[i].name,
        size: e.target.files[i].size,
        lastModifiedDate: e.target.files[i].lastModifiedDate,
        file: e.target.files[i],
      });
    this.setState({
      uploadingFiles: [...this.state.uploadingFiles, ...arr],
    });
    setTimeout(() => this.startUploading());
  };

  startUploading() {
    if (this.isUploading) return;
    this.isUploading = true;
    this._uploadNextFile();
  }

  _uploadNextFile() {
    let uploadingIndex = this.state.uploadingFiles.findIndex(
      (item) => !item.isUploaded
    );
    this.setState({
      uploadingIndex: uploadingIndex,
    });
    if (uploadingIndex == -1) {
      this.isUploading = false;
      this.loadData();
    } else {
      const file = this.state.uploadingFiles[uploadingIndex].file;
      const reader = new FileReader();
      reader.onload = () => {
        this._uploadWthSingleRequest(
          file,
          reader.result,
          (item) => {
            let uploadingFiles = [...this.state.uploadingFiles];
            uploadingFiles[this.state.uploadingIndex] = {
              ...uploadingFiles[this.state.uploadingIndex],
              isUploaded: true,
            };
            this.setState({
              uploadingFiles: uploadingFiles,
            });
            setTimeout(() => this._uploadNextFile());
          },
          () => {}
        );
      };
      reader.readAsArrayBuffer(file);
    }
  }

  _uploadWthSingleRequest = (
    file,
    arrayBuffer,
    doneCallback,
    progressCallback
  ) => {
    const path = this.path + "/" + encodeURI(file.name);

    const fileEntry = {
      actual_image: file,
      fileName: file.name,
    };
    this.helper._uploadWthSingleRequest(
      fileEntry,
      (status, res) => {
        doneCallback(res);
      },
      document.location.href,
      path
    );

    //ajax for getting pogress, stop workig because of CORS
    //window.$.ajax({
    //    url: "https://content.dropboxapi.com/2/files/upload",
    //    headers: {
    //        'Authorization': 'Bearer ' + TaskXrayPage.DropboxToken,
    //        //'Content-Length': file.size,
    //        'Dropbox-API-Arg': '{"path": "' + path + '"}'
    //    },
    //    crossDomain: true,
    //    crossOrigin: true,
    //    type: 'POST',
    //    contentType: 'application/octet-stream',//file.type,
    //    data: arrayBuffer,
    //    dataType: 'json',
    //    processData: false,
    //    xhr: () => {
    //        this.xhr = new window.XMLHttpRequest();
    //        this.xhr.upload.addEventListener("progress", function (evt) {
    //            if (evt.lengthComputable) {
    //                const percentComplete = parseInt(parseFloat(evt.loaded / evt.total) * 100);
    //                progressCallback(percentComplete);
    //            }
    //        }, false);
    //        return this.xhr;
    //    },
    //    success: function (result) {
    //        console.log(result);
    //        doneCallback(result);
    //    },
    //    error: function (jqXHR, textStatus, errorThrown) {
    //        console.log(errorThrown);
    //        doneCallback(null);
    //    }
    //});
  };

  _deleteItem = (item, callback) => {
    this.dropbox
      .filesDelete({ path: item.path_display })
      .then((response) => {
        callback();
      })
      .catch((error) => {
        callback();
      });
  };

  getFilesSize(bytes) {
    if (bytes < 1024) {
      return bytes + "b";
    }
    if (bytes < 1024 * 1024) {
      return (bytes / 1024).toFixed(1) + "kb";
    }
    return (bytes / 1024 / 1024).toFixed(1) + "mb";
  }

  onSelectAll = (e) => {
    const entries = this.state.entries.map((item) => ({
      ...item,
      isSelected: e.target.checked,
    }));
    this.setState({
      entries: entries,
    });
  };

  onSelectItem = (item, itemIndex) => {
    const entries = [...this.state.entries];
    entries[itemIndex] = {
      ...entries[itemIndex],
      isSelected: !entries[itemIndex].isSelected,
    };
    this.setState({
      entries: entries,
    });
  };

  onDeleteClick = () => {
    let arr = this.state.entries
      .filter((item) => item.isSelected)
      .map((el) => {
        return { ...el, type: MultimediaHelper.getTypeItem(el) };
      });

    if (arr.length <= 0) return;

    window.Spinner.show();
    this.helper.deleteItems(arr, () => {
      window.Spinner.hide();
      this.loadData();
    });
    // if (arr.length != 0) {
    //   window.Spinner.show();
    //   const deleteFunc = (index) => {
    //     if (index < arr.length) {
    //       this._deleteItem(arr[index], () => {
    //         deleteFunc(index + 1);
    //       });
    //     } else {
    //       window.Spinner.hide();
    //       this.loadData();
    //     }
    //   };
    //   deleteFunc(0);
    // }
  };

  downloadFiles = () => {
    window.Spinner.show();
    console.log(this.state.entries);

    try {
      const filesToDownload = this.state.entries.map((el) => {
        return { url: el.fullUrl, name: el.name };
      });
      if (this.state.entries.length < 5) {
        MultimediaHelper.downloadFilesOneByOne(filesToDownload).then(() => {
          window.Spinner.hide();
        });
      } else {
        MultimediaHelper.downloadZip(filesToDownload).then(() => {
          window.Spinner.hide();
        });
      }
    } catch (error) {
      window.Spinner.hide();
    }
  };

  render() {
    const { uploadingFiles, uploadingIndex, entries } = this.state;

    return (
      <div>
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={this.close}
          />
          <img
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.close}
          />
        </div>
        <div style={{ padding: "10px", paddingTop: "60px" }}>
          <div>
            <input
              id="xrayFileInput"
              type="file"
              style={{ display: "none" }}
              accept="*/*"
              multiple
              onChange={this.onFilesSeleted}
            />
            <label htmlFor="xrayFileInput" style={{ display: "inline-block" }}>
              <span
                className="ui-btn ui-shadow ui-corner-all"
                style={{ maxWidth: "200px" }}
              >
                Select files
              </span>
            </label>
            <div style={{ float: "right" }}>
              <span
                className="ui-btn ui-shadow ui-corner-all"
                style={{ maxWidth: "200px", display: "inline-block" }}
                onClick={this.onDeleteClick}
              >
                Delete selected
              </span>
              <span
                className="ui-btn ui-shadow ui-corner-all"
                style={{ maxWidth: "200px", display: "inline-block" }}
                onClick={this.downloadFiles}
              >
                Download all
              </span>
            </div>
          </div>
          <table className="tBordered">
            <tbody>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    defaultValue={false}
                    onClick={this.onSelectAll}
                  />
                </th>
                <th>Name</th>
                <th>Size</th>
                <th>Date</th>
                <th></th>
              </tr>
              {uploadingFiles.map((item, itemIndex) => (
                <tr key={item.name}>
                  <td></td>
                  <td>{item.name}</td>
                  <td>{this.getFilesSize(item.size)}</td>
                  <td>{item.lastModifiedDate.toLocaleString()}</td>
                  <td>
                    {item.isUploaded ? (
                      <span>Done</span>
                    ) : (
                      <>
                        {itemIndex == uploadingIndex ? (
                          <span>Uploading</span>
                        ) : (
                          <span>Waiting</span>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              ))}
              {entries.map((item, itemIndex) => (
                <tr key={item.name}>
                  <th>
                    <input
                      type="checkbox"
                      checked={item.isSelected}
                      onClick={() => this.onSelectItem(item, itemIndex)}
                    />
                  </th>
                  <td>{decodeURI(item.name)}</td>
                  <td>{this.getFilesSize(item.size)}</td>
                  <td>{item.lastModifiedDate.toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
