import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./NewGeneralTaskTypePopup.css";
class CopyGeneralTaskV2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      task: null,
      selectFreqOptions: [],
      frequencyId: 0,
      isCopyOnSunday: false,
      isCopyOnMonday: false,
      isCopyOnTuesday: false,
      isCopyOnWednesday: false,
      isCopyOnThursday: false,
      isCopyOnFriday: false,
      isCopyOnSaterday: false,
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    var daysToCopy = this.props.trainingItem.DaysToCopy;
    if (daysToCopy != null) this.setDaysToCopyFromProps(daysToCopy);

    let currentFreqOptions = [];
    if (this.props.trainingItem.tasktype == 3) {
      currentFreqOptions = [
        {
          id: 0,
          name: "For ever",
        },
      ];

      for (let i = 1; i <= 12; i++) {
        let name = i == 1 ? window.lang.oneMonth : i + " " + window.lang.months;
        currentFreqOptions.push({ id: i, name });
      }
    } else {
      currentFreqOptions = [
        {
          id: 0,
          name: "For ever",
        },
        {
          id: 1,
          name: window.lang.week1,
        },
        {
          id: 2,
          name: window.lang.week2,
        },
        {
          id: 3,
          name: window.lang.week3,
        },
        {
          id: 4,
          name: window.lang.week4,
        },
        {
          id: 5,
          name: window.lang.week5,
        },
        {
          id: 6,
          name: window.lang.week6,
        },
        {
          id: 7,
          name: window.lang.week7,
        },
        {
          id: 8,
          name: window.lang.week8,
        },
        {
          id: 9,
          name: window.lang.week9,
        },
        {
          id: 10,
          name: window.lang.week10,
        },
        {
          id: 11,
          name: window.lang.week11,
        },
        {
          id: 12,
          name: window.lang.week12,
        },
      ];
    }
    this.setState({
      selectFreqOptions: currentFreqOptions,
      frequencyId: this.props.trainingItem.CopyDuration,
      task: this.props.trainingItem,
    });
  };

  setDaysToCopyFromProps = (daysToCopy) => {
    daysToCopy = daysToCopy.substring(0, daysToCopy.length - 1);
    var daysToCopyArr = daysToCopy.split("/");
    for (const i in daysToCopyArr) {
      switch (daysToCopyArr[i]) {
        case "0":
          this.state.isCopyOnSunday = true;
          break;
        case "1":
          this.state.isCopyOnMonday = true;
          break;
        case "2":
          this.state.isCopyOnTuesday = true;
          break;
        case "3":
          this.state.isCopyOnWednesday = true;
          break;
        case "4":
          this.state.isCopyOnThursday = true;
          break;
        case "5":
          this.state.isCopyOnFriday = true;
          break;
        case "6":
          this.state.isCopyOnSaterday = true;
          break;
      }
    }
  };

  onFrequencyChange = (e) => {
    this.setState({
      frequencyId: parseInt(e.target.value),
    });
  };

  onCheckboxClick = (e) => {
    this.setState({
      [e.target.dataset.date]: e.target.checked,
    });
  };

  onOkClick = () => {
    let task = JSON.parse(JSON.stringify(this.state.task));
    task.DaysToCopy = "";
    if (this.state.isCopyOnSunday) task.DaysToCopy += "0/";
    if (this.state.isCopyOnMonday) task.DaysToCopy += "1/";
    if (this.state.isCopyOnTuesday) task.DaysToCopy += "2/";
    if (this.state.isCopyOnWednesday) task.DaysToCopy += "3/";
    if (this.state.isCopyOnThursday) task.DaysToCopy += "4/";
    if (this.state.isCopyOnFriday) task.DaysToCopy += "5/";
    if (this.state.isCopyOnSaterday) task.DaysToCopy += "6/";

    if (this.state.frequencyId == null) this.state.frequencyId = 0;
    task.CopyDuration = this.state.frequencyId;
    if (task.GroupId == null) {
      task.GroupId = task.Id;
      let index = window.DataStorage.generalTasks.findIndex(
        (t) => t.Id == task.Id
      );
      if (index >= 0) window.DataStorage.generalTasks[index] = task;
    }
    this.saveTask(task);

    let daysToCopy = [];
    if (this.state.isCopyOnSunday) daysToCopy.push(0);
    if (this.state.isCopyOnMonday) daysToCopy.push(1);
    if (this.state.isCopyOnTuesday) daysToCopy.push(2);
    if (this.state.isCopyOnWednesday) daysToCopy.push(3);
    if (this.state.isCopyOnThursday) daysToCopy.push(4);
    if (this.state.isCopyOnFriday) daysToCopy.push(5);
    if (this.state.isCopyOnSaterday) daysToCopy.push(6);

    window.DataGate.CopyGeneralTasksGroup(
      task,
      daysToCopy,
      task.tasktype == 3,
      (code, data) => {
        if (code == window.DataGate.CODE_OK) {
          window.training.refresh({ showSpinner: false });
        }
        if (this.props.callback) {
          this.props.callback(code == window.DataGate.CODE_OK);
        }
      }
    );

    this.onCancelClick();
    if (this.props.doneCallback) this.props.doneCallback();
  };

  saveTask = (generalTask) => {
    window.DataStorage.addGeneralTask(
      generalTask,
      window.DataStorage.GeneralTaskFlagAdd
    );

    window.DataGate.insertOrUpdateGeneralTask(generalTask, (code, data) => {
      if (code == window.DataGate.CODE_OK) {
        window.training.refresh({ showSpinner: false });
      }
      if (this.props.callback) {
        this.props.callback(code == window.DataGate.CODE_OK);
      }
    });
  };

  onCancelClick = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("ctGeneralTaskDivReact")
    );
  };

  onRemoveRepetitionClick = () => {
    this.deleteGeneralTaskGroup(this.state.task);
    this.onCancelClick();
    if (this.props.doneCallback) this.props.doneCallback();
  };

  deleteGeneralTaskGroup = (task) => {
    task["ConnectionId"] = window.notification.connectionId;
    window.DataGate.deleteGeneralTaskGroup(task, (code, data) => {
      if (code == window.DataGate.CODE_OK) {
        window.training.refresh({ showSpinner: false });
      }
      if (this.props.callback) {
        this.props.callback(code == window.DataGate.CODE_OK);
      }
      window.Spinner.hide();
    });
  };

  render = () => {
    const {
      selectFreqOptions,
      frequencyId,
      isCopyOnSunday,
      isCopyOnMonday,
      isCopyOnTuesday,
      isCopyOnWednesday,
      isCopyOnThursday,
      isCopyOnFriday,
      isCopyOnSaterday,
    } = this.state;
    return (
      <div className="copy-task ui-page-theme-a" style={{ display: "block" }}>
        <table>
          <tbody>
            <tr>
              <td colSpan="4">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>{window.lang.repeatForEach}</span>
                  <div id="placeForSelectFreq">
                    <select
                      className="copy-task-select"
                      data-role="none"
                      style={{ width: "100%" }}
                      value={frequencyId}
                      onChange={this.onFrequencyChange}
                    >
                      {selectFreqOptions.map((currentElement, index) => (
                        <option
                          key={currentElement.id}
                          value={currentElement.id}
                        >
                          {currentElement.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </td>
            </tr>
            <tr id="generalTaskPopupCopyDays">
              <td>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isCopyOnMonday}
                    data-date="isCopyOnMonday"
                    onChange={this.onCheckboxClick}
                  />
                  <span>
                    {this.state.task?.tasktype != 3
                      ? window.lang.daysNames[1]
                      : window.lang.everyMonth}
                  </span>
                </div>
              </td>

              {this.state.task?.tasktype == 1 && (
                <>
                  <td>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="checkbox"
                        checked={isCopyOnSunday}
                        data-date="isCopyOnSunday"
                        onChange={this.onCheckboxClick}
                      />
                      <span>{window.lang.daysNames[0]}</span>
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={isCopyOnTuesday}
                        data-date="isCopyOnTuesday"
                        onChange={this.onCheckboxClick}
                      />
                      <span>{window.lang.daysNames[2]}</span>
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={isCopyOnWednesday}
                        data-date="isCopyOnWednesday"
                        onChange={this.onCheckboxClick}
                      />
                      <span>{window.lang.daysNames[3]}</span>
                    </div>
                  </td>
                  <td>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="checkbox"
                        checked={isCopyOnThursday}
                        data-date="isCopyOnThursday"
                        onChange={this.onCheckboxClick}
                      />
                      <span>{window.lang.daysNames[4]}</span>
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={isCopyOnFriday}
                        data-date="isCopyOnFriday"
                        onChange={this.onCheckboxClick}
                      />
                      <span>{window.lang.daysNames[5]}</span>
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={isCopyOnSaterday}
                        data-date="isCopyOnSaterday"
                        onChange={this.onCheckboxClick}
                      />
                      <span>{window.lang.daysNames[6]}</span>
                    </div>
                  </td>
                </>
              )}
            </tr>
            <tr>
              <td colSpan="3">
                <div style={{ display: "flex" }}>
                  <button
                    className="ui-btn ui-shadow ui-corner-all"
                    onClick={this.onOkClick}
                  >
                    {window.lang.ok}
                  </button>
                  <button
                    className="ui-btn ui-shadow ui-corner-all"
                    style={{ marginLeft: "10px" }}
                    onClick={this.onCancelClick}
                  >
                    {window.lang.cancel2}
                  </button>
                  <button
                    className="ui-btn ui-shadow ui-corner-all"
                    style={{ marginLeft: "10px" }}
                    onClick={this.onRemoveRepetitionClick}
                  >
                    {window.lang.removeRepetition}
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
}

export default class CopyGeneralTaskWindow {
  showWindow(taskItem, doneCallback) {
    if (!taskItem.tasktype) {
      taskItem.tasktype = taskItem.TaskType;
    }
    ReactDOM.render(
      <CopyGeneralTaskV2 trainingItem={taskItem} doneCallback={doneCallback} />,
      document.getElementById("ctGeneralTaskDivReact")
    );
  }
}
