import React, { Component } from "react";
import HorseGallery from "../.././Multimedia/HorseGallery";

export default class MultimediaPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.$("#bottomMenu").hide();
    window.TopMenu.Self.setVisible(false);
  };

  onOkClick = () => {
    window.Managers.extention.showHorsePage(this.props.horseId);
    window.$("#bottomMenu").show();
  };

  render() {
    let horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == this.props.horseId
    );
    let logoSrc = "url(img/horselogo.png)";
    if (horse.LogoFile != null) {
      logoSrc = `url(${window.DataGate.baseurl + horse.LogoFile})`;
    }

    return (
      <div className="pageScrollable">
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={this.onOkClick}
          />
          <span className="npTitle">Multimedia</span>
          <img
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onOkClick}
          />
        </div>
        <br />
        <div data-role="content">
          <div style={{ height: "50px" }}>
            <div
              id="dropboxHorseLogo"
              style={{ backgroundImage: logoSrc }}
            ></div>
            <label
              style={{
                fontSize: "150%",
                display: "inline-block",
                position: "absolute",
                marginTop: "10px",
              }}
              id="dropboxHeaderText"
            >
              {horse.name}
            </label>
          </div>
          <HorseGallery
            showYoutube={false}
            showUploadButton={true}
            type={"Multimedia"}
            horse={horse}
            title={"Galleri"}
            isConcurrent={true}
            key={"horse_multimedia_1"}
          />
          <HorseGallery
            type={"Result"}
            showUploadButton={true}
            horse={horse}
            title={"Result"}
            isConcurrent={true}
            key={"horse_multimedia_2"}
          />
          <HorseGallery
            type={"Equipment"}
            showUploadButton={true}
            horse={horse}
            title={"Equipment"}
            isConcurrent={true}
            key={"horse_multimedia_3"}
          />
          <HorseGallery
            type={"Notes"}
            showUploadButton={true}
            multimediaPage={true}
            horse={horse}
            title={"Notes"}
            isConcurrent={true}
            key={"horse_multimedia_4"}
          />
          <HorseGallery
            type={"Task"}
            multimediaPage={true}
            showUploadButton={true}
            horse={horse}
            title={"Task"}
            isConcurrent={true}
            key={"horse_multimedia_5"}
          />
          <HorseGallery
            type={"Pedigree"}
            horse={horse}
            showUploadButton={true}
            title={"Pedigree"}
            isConcurrent={true}
            key={"horse_multimedia_6"}
          />
        </div>
      </div>
    );
  }
}
