import React, { Component } from "react";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";
import { Document, Page, pdfjs } from "react-pdf";
import SpinnerView from "../Controls/Spinner/SpinnerView";
import "./ZipViewer.css";

class ZipViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      isError: false,
      fileList: [],
      fullscreenImage: null,
      fullscreenVideo: null,
      pdfDocument: null,
      currentPdfPage: 1,
      pdfNumberOfPages: 1,
    };

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.pdfPageWidth = window.isMobileDevice()
      ? window.innerWidth - 40
      : Math.min(window.innerWidth - 40, 800);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({
      loading: true,
    });

    JSZipUtils.getBinaryContent(this.props.fileUrl, (err, data) => {
      if (err) {
        this.setState({ isError: true });
        return;
      }

      JSZip.loadAsync(data).then((zip) => {
        this.zip = zip;
        let fileList = [];
        zip.forEach(function (relativePath, file) {
          fileList.push(file);
        });
        this.setState({
          loading: false,
          fileList: fileList,
        });
      });
    });
  };

  onFileSelect = (file) => {
    this.zip
      .file(file.name)
      .async("blob")
      .then((res) => {
        let url = URL.createObjectURL(res);
        let ext = file.name.split(".").pop().toLowerCase();
        if (
          ext == "png" ||
          ext == "jpeg" ||
          ext == "jpg" ||
          ext == "gif" ||
          ext == "bmp"
        ) {
          this.setState({
            fullscreenImage: url,
          });
        } else if (
          ext == "mp4" ||
          ext == "mov" ||
          ext == "wmv" ||
          ext == "avi"
        ) {
          this.setState({
            fullscreenVideo: url,
          });
        } else if (ext == "pdf") {
          this.setState({
            pdfDocument: url,
          });
        }
      });
  };

  hideFullscreen = () => {
    this.setState({
      fullscreenImage: null,
      fullscreenVideo: null,
      pdfDocument: null,
    });
  };

  onPdfDocumentLoadSuccess = (args) => {
    this.setState({
      pdfNumberOfPages: args.numPages,
    });
  };

  onCurrentPdfPageChange = (offset) => {
    const currentPdfPage = this.state.currentPdfPage + offset;
    if (currentPdfPage <= 0 || currentPdfPage > this.state.pdfNumberOfPages)
      return;

    this.setState({
      currentPdfPage: currentPdfPage,
    });
  };

  render() {
    const { fileList } = this.state;
    return (
      <div style={this.props.style}>
        {this.state.loading && (
          <div>
            <SpinnerView />
          </div>
        )}
        {this.state.isError && (
          <div style={{ fontSize: "20px", color: "red" }}>
            Error in loading data. Please try again.
          </div>
        )}
        {fileList.map((file, fIndex) => (
          <div
            key={file.name}
            style={{
              cursor: "pointer",
              marginLeft: "20px",
              marginBottom: "10px",
            }}
            onClick={() => this.onFileSelect(file)}
          >
            <span>{file.name}</span>
            <img
              src="img/view_file.png"
              style={{ width: "20px", height: "20px", marginLeft: "10px" }}
            />
          </div>
        ))}
        {this.state.fullscreenImage && (
          <div className="hgFullScreenView">
            <img
              src={this.state.fullscreenImage}
              className="hgFullScreenItem"
            />
            ;
            <img
              className="hgFullScreenClose"
              src="img/icon_canceln.png"
              onClick={this.hideFullscreen}
            />
          </div>
        )}
        {this.state.fullscreenVideo && (
          <div className="hgFullScreenView">
            <video controls="controls" className="hgFullScreenItem">
              <source src={this.state.fullscreenVideo} type="video/mp4" />
            </video>
            <img
              className="hgFullScreenClose"
              src="img/icon_canceln.png"
              onClick={this.hideFullscreen}
            />
          </div>
        )}
        {this.state.pdfDocument && (
          <div className="hgFullScreenView">
            <div className="pdfDocument" style={{ width: this.pdfPageWidth }}>
              <Document
                file={this.state.pdfDocument}
                onLoadSuccess={this.onPdfDocumentLoadSuccess}
              >
                <Page
                  pageNumber={this.state.currentPdfPage}
                  renderTextLayer={false}
                  width={this.pdfPageWidth}
                />
              </Document>
            </div>
            <div className="pdfPages">
              <img
                className="pdfArrowIcon"
                src="img/icon_left.svg"
                onClick={() => this.onCurrentPdfPageChange(-1)}
              />
              <div>
                {this.state.currentPdfPage}/{this.state.pdfNumberOfPages}
              </div>
              <img
                className="pdfArrowIcon"
                src="img/icon_right.svg"
                onClick={() => this.onCurrentPdfPageChange(1)}
              />
            </div>
            <img
              className="hgFullScreenClose"
              src="img/icon_canceln.png"
              onClick={this.hideFullscreen}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ZipViewer;
